import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const POST_NEWSLETTER_ADD_CONTACT = "ExternalServices/newsletter-add-contact";

export function postNewsletterAddContact(_email) {
    return axios.post(EXTERNAL_API_URL + POST_NEWSLETTER_ADD_CONTACT,{
        email: _email,
      });
}
  