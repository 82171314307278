const componentStyles = (theme) => ({
    photoItemDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh",
      backgroundColor: theme.palette.white.smoke,
    },
    videoItemDiv: {
      display: "flex",
      justifyContent: "center",
      height: "50vh",
      backgroundColor: theme.palette.white.smoke,
    },
    indicatorIcon: {
      width: "20px",
      height: "20px",
    },
    img: {
      height: 'inherit',
      maxWidth: "100%",
      cursor: "pointer",
    },
    fullCoverageImg: {
      width: "100%"
    },
    previewDialogDiv: {
      height: "min-content",
      maxWidth: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.white.main,
    },
  });
  
  export default componentStyles;
  