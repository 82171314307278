import React from "react";
import { Controller } from "react-hook-form";
import { Box, FormGroup, FormHelperText, FormLabel, OutlinedInput } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useIntl } from "react-intl";
//style
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { messages } from "resources/resources";
const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function FormInput({
  name,
  label,
  control,
  rules,
  numberType,
  tooltipLabel,
  isRequired,
  disabled,
  ...rest
}) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <FormGroup>
      <FormLabel>
        {label}
        {tooltipLabel && (
          <Tooltip title={tooltipLabel}>
            <HelpIcon className={classes.tooltipInfoIcon} />
          </Tooltip>
        )}
      </FormLabel>

      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: isRequired,
            message: intl.formatMessage(messages.Message_Generic_MandatoryField),
          },
          ...rules,
        }}
        render={({ field: { onChange, value, ref, ...field }, fieldState: { error } }) => (
          <>
            <OutlinedInput
              {...rest}
              {...field}
              disabled={disabled}
              value={value}
              ref={ref}
              onChange={onChange}
              fullWidth
              autoComplete="off"
              type={numberType ? "number" : "text"}
              classes={{
                notchedOutline: clsx({
                  [classes.borderWarning]: !!error,
                }),
              }}
            />

            <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
              {error?.message}
            </FormHelperText>
          </>
        )}
      />
    </FormGroup>
  );
}
