import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
//MaterialUI
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import limitaString from "utils/string_utils";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";

import TableStateChip from "components/Cards/Tables/TableStateChip";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import { OPPORTUNITY_CANDIDATE_STATE_KEY, OPPORTUNITY_TYPE_KEY, TABLE_STATE_CHIP_COLOR, PAGINATION } from "utils/const";
import { getMyOpportunityApplicationsAction } from "redux/actions/opportunitiesAction";
import { titles } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function MyOpportunityApplicationsTab(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const [page, setPage] = useState(1);
  const intl = useIntl();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const tableHead = ["Nº Candidatura", "Empresa", "Tipo de Oferta", "Data de Candidatura", "Estado"];

  const getOpportunityTypeDescription = (opportunityTypeKey) => {
    switch (opportunityTypeKey) {
      case OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL:
        return "Estágios Profissionais";
      case OPPORTUNITY_TYPE_KEY.EMPREGO:
        return "Emprego";
      default:
        return "";
    }
  };

  const mapOpportunityCandidateStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_STATE_KEY.APROVADA:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.REJEITADA:
        return TABLE_STATE_CHIP_COLOR.RED;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.EMRECRUTAMENTO:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    let filterData = {
      opportunityTypeKey: props.opportunityTypeKey,
      pageIndex: page <= 0 ? 1 : page,
      pageSize: PAGINATION.PAGE_SIZE,
    };

    props.getMyOpportunityApplications(filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, props.opportunityTypeKey]);

  useEffect(() => {
    handleChangePage(undefined, PAGINATION.FIRST_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunityTypeKey]);

  return (
    <>
      <CardTableContainer
        title={intl.formatMessage(titles.Title_Table_UserSingular_OpportunityApplications, {
          opportunityType: getOpportunityTypeDescription(props.opportunityTypeKey),
        })}
        count={props.opportunitiesState?.myOpportunityApplications_total_records}
      >
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
                <TableCell
                  key="detailsCol"
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                  }}
                >
                  Detalhes
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.opportunitiesState?.myOpportunityApplications_result?.data?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }} title={prop.opportunityTitle ?? ""}>
                    <Box>{prop.candidateCode}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box fontWeight={"bold"}>NIF: {prop.companyTaxIdNumber}</Box>
                    <Box>{limitaString(prop.companyName, 60)}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box fontWeight={"bold"}>{prop.opportunityTypeDescription}</Box>
                    <Box>{limitaString(prop.opportunityTitle, 60)}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop.creationDate}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <TableStateChip
                      state={mapOpportunityCandidateStateToTableChipColors(prop.candidateStateKey)}
                      message={prop.candidateStateDescription}
                    />
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight,
                    }}
                  >
                    <Tooltip title="Detalhes">
                      <IconButton
                        aria-label="details"
                        component={Link}
                        to={`/admin/opportunity-application-details/${prop.candidateCode}`}
                      >
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
          <TableBackdrop open={props.opportunitiesState?.getMyOpportunityApplications_loading} />
        </TableContainer>

        <Pagination
          className={classes.alignPagination}
          count={props.opportunitiesState?.myOpportunityApplications_total_pages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        ></Pagination>
      </CardTableContainer>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getMyOpportunityApplications: (data) => dispatch(getMyOpportunityApplicationsAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOpportunityApplicationsTab);
