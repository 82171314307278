import axios from "axios"; // Connection library
import { EXTERNAL_API_URL } from "../index"; // Define which API to use
// {EXTERNAL, INTERNAL}

export const GET_SOCIAL_MEDIA_LINKS =
  "SocialMediaType/get-social-media-default-links";

export function getSocialMediaLinks() {
  return axios.get(EXTERNAL_API_URL + GET_SOCIAL_MEDIA_LINKS);
}
