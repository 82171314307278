import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function DirectorBiography() {
  const intl = useIntl();

  const directorBiographyData = [
    {
      title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyPosition),
      content: [
        subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyName_Title)),
        text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyName)),
        subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyBirthdate_Title)),
        text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyBirthdate)),
        subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyNationality_Title)),
        text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyNationality)),
        subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyStudies_Title)),
        text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyStudies)),
        subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualifications_Title)),
        firstBullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_1)),
        bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_2)),
        bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_3)),
        bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_4)),
        bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_5)),
        bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_6)),
        subtitle(
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperiences_Title)
        ),
        firstBullet(
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_1)
        ),
        bullet(
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_2)
        ),
        bullet(
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_3)
        ),
        bullet(
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_4)
        ),
        subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyLanguages_Title)),
        firstBullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_1)),
        bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_2)),
        bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_3)),
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(titles.Title_Menu_InstitutionalInformation_DirectorBiography)}
      data={directorBiographyData}
    />
  );
}

const subtitle = (label) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
    subtitle: label,
  };
};

const bullet = (label) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
    description: label,
  };
};

const firstBullet = (label) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
    description: label,
  };
};

const text = (label) => {
  return label + "\n\n";
};

export default DirectorBiography;
