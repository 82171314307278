import { React, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { DATASET } from "utils/const";
import "moment/locale/pt";
import moment from "moment";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getCandidateInformationAction } from "redux/actions/opportunitiesAction";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { createOpportunityCandidateMap } from "mappers/OpportunityMap";
import { createOpportunityCandidateAction } from "redux/actions/opportunitiesAction";
import { OPPORTUNITY_TYPE_KEY } from "utils/const";
import { titles } from "resources/resources";
import { getNameInitials } from "utils/converters";
import DateRange from "@material-ui/icons/DateRange";
import GenericButton from "components/Buttons/GenericButton";
import FormInput from "components/FormFields/FormInput";
import { messages, labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const useStyles = makeStyles((theme) => ({
  descriptionText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.gray[500],
  },
  paddingTop2px: {
    padding: "2px 0",
  },
  userNameBigBox: {
    fontSize: "20px",
    marginTop: "10px",
    fontWeight: "600",
  },
}));

function OpportunityData(props) {
  const classes = { ...useStylesGeneric(), ...useStyles() };
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  const methods = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const formKeys = Object.keys(methods.getValues());
    if (formKeys) {
      methods.unregister(formKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location?.key]);

  const getOpportunityCreationOffset = (createdOn) => {
    if (moment(createdOn)) {
      const days = Math.abs(moment(createdOn).diff(moment().startOf("day"), "days"));
      const yesterday = moment().subtract(1, "day");
      if (moment(createdOn).isSame(yesterday, "day")) return `Publicada Ontem`;
      if (days > 1) return `Publicada há ${days} dia(s)`;
      if (days === 0) return `Publicada Hoje`;
    }
  };

  const handleGetDataSetByName = (datasetName) => {
    if (props.datasetsState[datasetName] === undefined || props.datasetsState[datasetName]?.length === 0) {
      props.getDataSetByName(datasetName);
    }
  };

  useEffect(() => {
    handleGetDataSetByName(DATASET.LANGUAGES);
    handleGetDataSetByName(DATASET.ACADEMIC_LEVEL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.BUSINESS_PURPOSE] === undefined ||
      props.datasetsState[DATASET.BUSINESS_PURPOSE] === []
    ) {
      props.getDataSetByName(DATASET.BUSINESS_PURPOSE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.BUSINESS_PURPOSE]]);

  const getCardTitle = () => {
    switch (props.opportunityDetailsData?.opportunityTypeKey) {
      case OPPORTUNITY_TYPE_KEY.EMPREGO:
        return intl.formatMessage(titles.Title_Opportunity_Employment_Details_Card);
      case OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL:
        return intl.formatMessage(titles.Title_Opportunity_Internship_Details_Card);
      default:
        return "";
    }
  };

  const getCompanyBusinessPurposes = () => {
    var res = "";
    if (
      props.opportunityDetailsData?.userBusinessPurpose !== undefined &&
      props.opportunityDetailsData?.userBusinessPurpose !== [] &&
      props.datasetsState[DATASET.BUSINESS_PURPOSE] !== undefined
    ) {
      (props.opportunityDetailsData?.userBusinessPurpose).forEach((element, index) => {
        res += props.datasetsState[DATASET.BUSINESS_PURPOSE].find((r) => r.code === element)?.label;
        if (index + 1 < props.opportunityDetailsData?.userBusinessPurpose.length) res += "; ";
      });
    }
    return res;
  };

  const getMissingDocumentsMessage = (missingDocuments) => {
    return (
      <>
        <Typography className={classes.alertMessage}>
          {intl.formatMessage(messages.Message_Admin_Opportunity_Details_Missing_DocumentsMessage)}
        </Typography>
        <ul>
          {missingDocuments?.map((doc, key) => {
            return <li key={key}>{doc}</li>;
          })}
        </ul>
      </>
    );
  };

  const submitOpportunityCandidate = (data) => {
    props.createOpportunityCandidate(createOpportunityCandidateMap(data, props.opportunityDetailsData?.id));
  };

  function DisplayField(props) {
    return (
      <Grid item xs={props.xs} sm={props.sm} md={props.md}>
        <FormGroup>
          <FormLabel>{props.label}</FormLabel>
          <Box className={classes.descriptionText}>{props.data}</Box>
        </FormGroup>
      </Grid>
    );
  }

  const getDatasetLabelsByCodes = (datasetName, codesList) => {
    let result = null;

    codesList?.forEach((code) => {
      const datasetElement = props.datasetsState[datasetName]?.find((element) => element.code === code);
      if (datasetElement) {
        result = result ? result + ", " + datasetElement.label : datasetElement.label;
      }
    });

    return result ?? "-";
  };

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{
            title: classes.cardHeader,
          }}
          title={getCardTitle()}
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12} lg={3} md={3} className={classes.textAlignCenter}>
              <Box>
                {props.opportunityDetailsData?.userPhoto ? (
                  <Avatar
                    alt="..."
                    src={props.opportunityDetailsData.userPhoto}
                    classes={{
                      root: classes.avatarRootLarge,
                    }}
                  />
                ) : (
                  <Box component={Avatar} alt="..." classes={{ root: classes.avatarRootLarge }}>
                    {" "}
                    {getNameInitials(props.opportunityDetailsData?.userName)}
                  </Box>
                )}
              </Box>
              <Box className={classes.userNameBigBox}>{props.opportunityDetailsData?.userName}</Box>

              <Box marginTop="20px">
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <Typography className={classes.descriptionText}>
                    {props.opportunityDetailsData?.userProvince}
                  </Typography>
                </FormGroup>
              </Box>
              <Box>
                <FormGroup>
                  <FormLabel>Município</FormLabel>
                  <Typography className={classes.descriptionText}>
                    {props.opportunityDetailsData?.userMunicipality}
                  </Typography>
                </FormGroup>
              </Box>
              <Box>
                <FormGroup>
                  <FormLabel>NIF</FormLabel>
                  <Typography className={classes.descriptionText}>{props.opportunityDetailsData?.userNIF}</Typography>
                </FormGroup>
              </Box>
              <Box>
                <FormGroup>
                  <FormLabel>Sector Económico</FormLabel>
                  <Typography className={classes.descriptionText}>{getCompanyBusinessPurposes()}</Typography>
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} lg={9} md={9}>
              <CardHeader
                classes={{
                  title: classes.cardHeaderBig,
                }}
                title={props.opportunityDetailsData?.title}
              />
              <CardHeader
                subheader={
                  <Grid container component={Box}>
                    <Grid item xs={4} lg={4} md={4}>
                      <Box>
                        <Box component="span">
                          <FormLabel>{props.opportunityDetailsData?.provinceDescription}</FormLabel>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4} lg={4} md={4}>
                      <Box>
                        <Box component="span">
                          <FormLabel>{getOpportunityCreationOffset(props.opportunityDetailsData?.createdOn)}</FormLabel>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4} lg={4} md={4}>
                      <Box>
                        {props.opportunityDetailsData?.validPeriod && (
                          <Box component="span">
                            <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                            <FormLabel>{props.opportunityDetailsData?.validPeriod}</FormLabel>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{
                  root: classes.cardHeaderBig,
                }}
              />
              <CardHeader
                subheader={
                  <Grid item xs={12} className={classes.paddingLeft0px}>
                    <FormGroup>
                      <FormLabel>Descrição</FormLabel>
                      <Typography className={classes.descriptionText}>
                        {props.opportunityDetailsData?.description}
                      </Typography>
                    </FormGroup>
                  </Grid>
                }
              />
              <CardHeader
                subheader={
                  <Grid container component={Box}>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormGroup>
                        <FormLabel>Tipo de Oferta</FormLabel>
                        <Typography className={classes.descriptionText}>
                          {props.opportunityDetailsData?.opportunityTypeDescription}
                        </Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormGroup>
                        <FormLabel>Horário</FormLabel>
                        <Typography className={classes.descriptionText}>
                          {props.opportunityDetailsData?.timetableTypeDescription}
                        </Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormGroup>
                        <FormLabel>Área Profissional</FormLabel>
                        <Typography className={classes.descriptionText}>
                          {props.opportunityDetailsData?.opportunityProfessionalCategoryDescription}
                        </Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormGroup>
                        <FormLabel>
                          {intl.formatMessage(
                            labels.Label_Opportunity_UserSingular_OpportunityData_RequiredAcademicLevel
                          )}
                        </FormLabel>
                        <Typography className={classes.descriptionText}>
                          {getDatasetLabelsByCodes(DATASET.ACADEMIC_LEVEL, [
                            props.opportunityDetailsData?.academicLevelId,
                          ])}
                        </Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormGroup>
                        <FormLabel>
                          {intl.formatMessage(labels.Label_Opportunity_UserSingular_OpportunityData_RequiredLanguages)}
                        </FormLabel>
                        <Typography className={classes.descriptionText}>
                          {getDatasetLabelsByCodes(
                            DATASET.LANGUAGES,
                            props.opportunityDetailsData?.requiredLanguagesIds
                          )}
                        </Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormGroup>
                        <FormLabel>
                          {intl.formatMessage(labels.Label_Opportunity_UserSingular_OpportunityData_RequiredKnowledge)}
                        </FormLabel>
                        <Typography className={classes.descriptionText}>
                          {props.opportunityDetailsData?.requiredKnowledge ?? " - "}
                        </Typography>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormGroup>
                        <FormLabel>
                          {intl.formatMessage(labels.Label_Opportunity_UserSingular_OpportunityData_ActiveMonths)}
                        </FormLabel>
                        <Typography className={classes.descriptionText}>
                          {props.opportunityDetailsData?.activeMonths && props.opportunityDetailsData?.activeMonths >= 0
                            ? props.opportunityDetailsData?.activeMonths
                            : " - "}
                        </Typography>
                      </FormGroup>
                    </Grid>
                  </Grid>
                }
              />
              {!props.opportunityDetailsData?.userAlreadyApplied ? (
                <CardContent>
                  {props.opportunityDetailsData?.missingDocuments?.length === 0 ? (
                    <form onSubmit={methods.handleSubmit(submitOpportunityCandidate)}>
                      <Grid container component={Box}>
                        {props.opportunityDetailsData?.opportunityQuestions?.length > 0 ? (
                          props.opportunityDetailsData.opportunityQuestions.map((q) => {
                            return (
                              <Grid item xs={12} md={12} key={q.id.toString()}>
                                <FormInput
                                  name={q.id.toString()}
                                  label={q.question}
                                  control={methods.control}
                                  errors={methods.formState.errors}
                                  multiline={true}
                                  rows={2}
                                  isRequired
                                />
                              </Grid>
                            );
                          })
                        ) : (
                          <></>
                        )}
                        <Grid item xs={12} lg={12} md={12} className={classes.textAlignEnd}>
                          <GenericButton
                            typeSubmit={true}
                            color="primary"
                            loading={props.opportunitiesState?.opportunityCandidateSubmitLoading}
                          >
                            Candidatar
                          </GenericButton>
                        </Grid>
                      </Grid>
                    </form>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} lg={12} md={12}>
                        <Alert
                          severity="warning"
                          classes={{
                            message: classes.paddingTop2px,
                          }}
                        >
                          <AlertTitle>Aviso</AlertTitle>
                          {getMissingDocumentsMessage(props.opportunityDetailsData?.missingDocuments)}
                        </Alert>
                      </Grid>
                      <Grid item xs={12} lg={12} md={12} className={classes.textAlignEnd}>
                        <GenericButton
                          typeSubmit={true}
                          color="primary"
                          onClick={() => {
                            history.push("/admin/personal-profile");
                          }}
                          style={{ marginTop: "20px" }}
                        >
                          Concluir Perfil
                        </GenericButton>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              ) : (
                <CardContent>
                  {props.isOpportunityApplication ? (
                    <Grid container>
                      {props.opportunityDetailsData.opportunityQuestions?.map((prop, key) => (
                        <DisplayField key={key} xs={12} sm={12} md={12} label={prop.question} data={prop.answer} />
                      ))}
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} lg={12} md={12}>
                        <Alert
                          severity="warning"
                          classes={{
                            message: classes.paddingTop2px,
                          }}
                        >
                          <AlertTitle>Aviso</AlertTitle>
                          Já se candidatou a esta oferta.
                        </Alert>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  createOpportunityCandidate: (data) => dispatch(createOpportunityCandidateAction(data)),
  getDataSetByName: (name) => dispatch(getDataSetByNameAction(name)),
  getCandidateInformation: (userId) => dispatch(getCandidateInformationAction(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityData);
