import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import GenericButton from "components/Buttons/GenericButton";

// @material-ui/icons components
import { titles, labels, messages } from "resources/resources";
import { CONFIRMATION_ACTIONS } from "utils/const";

import componentStyles from "assets/theme/components/navbars/auth-navbar.js";
import { Grid, OutlinedInput } from "@material-ui/core";
import CustomDialogTitle from "components/Dialogs/CustomDialogTitle";

const useStyles = makeStyles(componentStyles);

function RecoverModal(props) {
  const classes = { ...useStyles() };
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Dialog
      open={props.recoverModal}
      onClose={props.handleCloseRecover}
      classes={{ paper: classes.modal }}
    >
      <CardContent classes={{ root: classes.cardContent }}>
        <Grid container>
          <Grid item>
            <Box
              color={theme.palette.gray[600]}
              textAlign="center"
              marginBottom="1rem"
              marginTop="0px"
              fontSize="2rem"
            >
              <Box
                style={{
                  fontSize: "2rem",
                  lineHeight: "3rem",
                  color: theme.palette.primary.greyBlue,
                }}
                className={classes.modalHeader}
              >
                {intl.formatMessage(titles.Title_Auth_Recover_Password_Modal)}
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <CustomDialogTitle
              id="customized-dialog-title"
              onClose={props.handleCloseRecover}
            />
          </Grid>
        </Grid>
        <Box marginLeft="-27px">
          <Box
            style={{
              marginBottom: "1.5rem",
              marginTop: ".5rem",
              fontSize: "1rem",
              color: theme.palette.gray[900],
              textAlign: "left",
            }}
            className={classes.modalRecoverHeader1}
          >
            {intl.formatMessage(messages.Message_Auth_Recover_Password_Modal_1)}
          </Box>
          <Box
            style={{
              marginBottom: "1.5rem",
              marginTop: ".5rem",
              fontSize: "1rem",
              color: theme.palette.gray[900],
              textAlign: "left",
            }}
            className={classes.modalRecoverHeader2}
          >
            {intl.formatMessage(messages.Message_Auth_Recover_Password_Modal_2)}
          </Box>
        </Box>
        <form>
          <FormControl className={classes.recoverFormControl}>
            <FormLabel className={classes.authenticationLabels}>
              {intl.formatMessage(
                labels.Label_Auth_Recover_Password_MobilePhone
              )}
            </FormLabel>
            <OutlinedInput
              placeholder={intl.formatMessage(
                titles.Label_RecoverPasswordModal_MobilePhoneInputPlaceholder
              )}
              autoComplete="off"
              type="text"
              onChange={(e) =>
                props.onChange("recoverPasswordPhone", e.target.value)
              }
            />
          </FormControl>
          <Box
            textAlign="right"
            marginTop="6.7rem"
            marginBottom="1.5rem"
            padding="5px"
          >
            <GenericButton
              typeSubmit={false}
              color="tertiary"
              onClick={props.handleCloseRecover}
            >
              Cancelar
            </GenericButton>
            <GenericButton
              typeSubmit={false}
              color="primary"
              onClick={function () {
                props.handleCloseRecover();
                props.showAuthenticationModal(
                  true,
                  props.state.recoverPasswordPhone,
                  CONFIRMATION_ACTIONS.RECOVER_PASSWORD
                );
              }}
              style={{ marginLeft: "15px" }}
              disabled={props.authState.login_loading}
              loading={props.authState.login_loading}
            >
              Ok
            </GenericButton>
          </Box>
        </form>
      </CardContent>
    </Dialog>
  );
}
const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(RecoverModal);
