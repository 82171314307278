import { React } from "react";
import { connect } from "react-redux";

import InformationPage from "components/GenericPage/InformationPage";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const"

function TermsAndConditionsPage() {

  const termsAndConditionsData = [
    {
      title: "1. OBJETO",
      content:[
        "O presente documento visa estabelecer as condições gerais de utilização do Portal INEFOP360, acessível na internet, composto por uma área pública e outra reservada. A área reservada é de acesso privado e de uso exclusivo aos utilizadores registados.",
      ]
    },
    {
      title: "2. REGISTO E AUTENTICAÇÃO NA ÁREA RESERVADA",
      content:[
        "Para que os utilizadores possam aceder aos serviços online disponíveis mediante autenticação, é necessário proceder ao registo dos seus dados. \n\nO acesso à plataforma é efetuado mediante registo solicitado pelo utilizador e a aceitação das presentes condições de utilização dos serviços online. \n\nNo ato de registo e adesão à plataforma o utilizador deverá possuir um número de telemóvel válido e ativo. Caso pretenda, poderá utilizar também um e-mail que seja válido e ativo. \n\nO número de telemóvel e/ou e-mail usado em conjunto com a palavra-passe de acesso constitui informação de uso exclusivo do utilizador e será exigido sempre que este pretenda aceder à plataforma, vinculando-o assim às operações que realiza. \n\nO utilizador pode, em qualquer momento, alterar a sua palavra-passe de acesso. \n\nO utilizador é responsável pela informação que, no âmbito dos serviços requeridos/prestados, for submetida através da plataforma e pela atualização de informação pessoal constante do registo, sendo-lhe imputada toda e qualquer responsabilidade pela informação fornecida. \n\nO utilizador obriga-se a manter a confidencialidade da palavra-passe de acesso e a prevenir o seu uso abusivo, assumindo a total responsabilidade pela sua utilização por terceiros, no acesso aos serviços disponibilizados nesta plataforma.",
      ]
    },
    {
      title: "3. RECUPERAÇÃO DE CREDENCIAIS DE ACESSO",
      content:[
        "Os utilizadores registados podem recuperar a palavra-passe em qualquer momento. ",
      ]
    },
    {
      title: "4. PROTEÇÃO DE DADOS PESSOAIS",
      content:[
        "A plataforma e todos os serviços prestados estão devidamente protegidos com ferramentas de segurança eletrónica adequadas. No entanto, o INEFOP não é  responsável por prejuízos provenientes de erros de transmissão e de deficiências técnicas ou derivados de interferências ou interceções ilegítimas que ocorram nos sistemas de comunicação e redes informáticas utilizados. \n\nCom esta declaração está salvaguardada a privacidade e direitos de confidencialidade do utilizador, sendo os dados usados exclusivamente pelo INEFOP para serviços online no âmbito do atual portal, conforme autorização expressa para o efeito, assumindo o compromisso de privacidade e segurança no processamento e na manutenção dos dados pessoais de cada utilizador. ",
      ]
    },
    {
      title:
        "5. CONDIÇÕES DE UTILIZAÇÃO DOS SERVIÇOS DISPONÍVEIS NA ÁREA RESERVADA",
      content:[ 
        "As condições de utilização dos serviços disponíveis na área reservada regulam a atividade do INEFOP, em matéria de prestação de serviços eletrónicos que requerem autenticação do utilizador. \n\nA aceitação das condições de utilização, aquando do registo de utilizador da área reservada, pressupõe a concordância do mesmo com as condições estabelecidas. \n\nOs termos destas condições podem ser alterados pelo INEFOP, sendo as mesmas consideradas válidas após divulgação. \n\nSempre que os utilizadores não aceitem as alterações às condições inicialmente previstas, devem proceder ao pedido de desativação do seu registo. O INEFOP reserva-se no direito de suspender ou fazer cessar o acesso aos serviços da plataforma se por imperativos de segurança for posta em causa a integridade e segurança nas comunicações. ",
      ]
    },
    {
      title: "6. SUBMISSÃO DE PEDIDOS ONLINE",
      content:[
        "Todos os pedidos submetidos pelo utilizador na área reservada terão plenos efeitos jurídicos, não podendo aquele alegar a falta de assinatura manuscrita ou eletrónica certificada para se dispensar de qualquer responsabilidade inerente ao seu pedido. ",
      ]
    },
    {
      title: "7. DEVERES DO UTILIZADOR",
      content:[
        "O utilizador compromete-se a cumprir as regras legais aplicáveis à utilização de páginas de Internet, bem como a agir de boa-fé na utilização dos serviços, sendo proibido preencher campos na área reservada com qualquer termo ameaçador, calunioso, difamatório, obsceno, escandaloso ou pornográfico, ou qualquer outro material que possa, de acordo com a lei ou com um tratado internacional, dar lugar a um processo cível ou criminal, obrigando-se nomeadamente a:",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: "Não difamar, abusar, aliciar, perseguir, ameaçar ou, de qualquer outra forma, violar direitos fundamentais ou de personalidade (nomeadamente dos direitos de publicidade) de outrem;"
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: "Não publicar, distribuir ou divulgar informação, mensagens indecentes ou ilegais;"
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: "Não recolher informação sobre terceiros, incluindo números de telemóvel e endereços de e-mail, sem o seu consentimento;"
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: "Não criar uma identidade falsa com a intenção de enganar terceiros quanto à identidade do remetente;"
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: "Não transmitir ou difundir qualquer informação, mensagens, imagem ou outros dados protegidos pelas leis da propriedade intelectual, direitos de privacidade, publicidade ou qualquer outra lei aplicável, a menos que o utilizador controle os direitos deste material ou tenha recebido o necessário consentimento;"
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: "Não efetuar uma hiperligação para a área reservada para outro fim, salvo se a ligação for expressamente autorizada por escrito pelo INEFOP;"
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: "Não procurar ou tentar aceder através da área reservada a informação sobre utilizadores registados, sistemas informáticos ou redes com ligação a esta área, através de pirataria ou qualquer outro meio."
        },
        "Ao preencher os seus dados no formulário de registo, o utilizador está a assumir a sua identidade e a garantir a veracidade da informação preenchida, sendo-lhe imputada toda e qualquer responsabilidade pela mesma. \n\n",
        "O utilizador compromete-se a guardar sob segredo a sua identificação de acesso - nome de utilizador e palavra-passe -, bem como a prevenir adequadamente a utilização abusiva por parte de terceiros. \n\n",
        "O utilizador é o único responsável por todos os prejuízos resultantes da utilização indevida destes serviços por parte de terceiros. Esta responsabilidade não pode ser imputada ao utilizador, apenas nos casos de perda, extravio, furto ou falsificação dos elementos de identificação, desde que a situação seja comunicada imediatamente, por escrito, ao INEFOP. \n\n",
        "Sempre que se verifique alguma alteração aos seus dados, o utilizador compromete-se a proceder à sua atualização. \n\n",
        "O INEFOP reserva-se o direito de usar todos os meios legais a que tenha direito para impedir a violação destas condições de utilização, incluindo o direito de vedar a um determinado endereço na Internet o acesso a esta área reservada."
      ]
    },
    {
      title: "8. DEVERES DO INEFOP",
      content:[
        "O INEFOP não será responsável, em caso algum, pelos prejuízos decorrentes de erros de transmissão, interferências, desconexões ou outras imprecisões de natureza técnica que tenham acorrido no âmbito do contacto do utilizador através dos serviços objeto das presentes condições de utilização. ",
      ]
    },
    {
      title: "9. SUSPENSÃO OU CESSAÇÃO DA PRESTAÇÃO DO SERVIÇO",
      content:[
        "O INEFOP poderá, a todo o tempo, suspender ou cessar a prestação de parte ou de todos os serviços da área reservada disponível na plataforma, se o utilizador não cumprir os termos destas condições de utilização. \n\n O INEFOP poderá ainda, a qualquer momento, suspender temporariamente o serviço ou extingui-lo, nomeadamente em caso de força maior, sem prejuízo do utilizador, no que diz respeito à satisfação de pedidos online em curso.",
      ]
    },
  ];

  return (
    <InformationPage
      title={"Termos e Condições"}
      data={termsAndConditionsData}
    />
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(TermsAndConditionsPage);
