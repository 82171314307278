import { APPLICATION_CONFIGURATION_ACTIONS } from "utils/actionsConsts";

const applicationsReducer = (
  state = {
    whoWeAre_loading: false,
  },
  action
) => {
  switch (action.type) {
    case APPLICATION_CONFIGURATION_ACTIONS.GET_WHO_WE_ARE:
      return {
        ...state, whoWeAre_result: action.payload,
      };
    case APPLICATION_CONFIGURATION_ACTIONS.GET_WHO_WE_ARE_LOADING:
      return { ...state, whoWeAre_loading: action.payload };
    default:
      return state;
  }
};

export default applicationsReducer;
