import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import componentStyles from "assets/theme/components/headers/page-title-header.js";

const useStyles = makeStyles(componentStyles);

const PageTitleHeader = ({ title, titleId }) => {
  const classes = useStyles();
  return (
    <Grid container classes={{ root: classes.containerPadding }}>
      <Grid item xs={12} md={12} className={classes.titleGrid}>
        <Box 
          data-testid={"page-title-header-" + titleId}
          className={classes.pageTitle}
        >
          {title}
        </Box>
      </Grid>
    </Grid>
  );
};

PageTitleHeader.propTypes = {
  title: PropTypes.string,
};

export default PageTitleHeader;
