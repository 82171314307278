import { DASHBOARD_ACTIONS } from "utils/actionsConsts";

const applicationsReducer = (
  state = {
    getUserRecentApplicationsDashboard_loading: false,
    getUserSingularRecentOpportunityCandidateDashboard_loading: false
  },
  action
) => {
  switch (action.type) {
    case DASHBOARD_ACTIONS.GET_USER_RECENT_APPLICATIONS_DASHBOARD:
      return { ...state, getUserRecentApplicationsDashboard_result: action.payload };
    case DASHBOARD_ACTIONS.GET_USER_RECENT_APPLICATIONS_DASHBOARD_LOADING:
      return { ...state, getUserRecentApplicationsDashboard_loading: action.payload };

    case DASHBOARD_ACTIONS.GET_USER_SINGULAR_RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD:
      return { ...state, getUserSingularRecentOpportunityCandidateDashboard_result: action.payload };
    case DASHBOARD_ACTIONS.GET_USER_SINGULAR_RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD_LOADING:
      return { ...state, getUserSingularRecentOpportunityCandidateDashboard_loading: action.payload };

    case DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_EMPLOYMENT_DASHBOARD:
      return { ...state, getUserCollectiveRecentOpportunityApplicationsEmploymentDashboard_result: action.payload };
    case DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_EMPLOYMENT_DASHBOARD_LOADING:
      return { ...state, getUserCollectiveRecentOpportunityApplicationsEmploymentDashboard_loading: action.payload };

    case DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_INTERNSHIP_DASHBOARD:
      return { ...state, getUserCollectiveRecentOpportunityApplicationsInternshipDashboard_result: action.payload };
    case DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_INTERNSHIP_DASHBOARD_LOADING:
      return { ...state, getUserCollectiveRecentOpportunityApplicationsInternshipDashboard_loading: action.payload };

    default:
      return state;
  }
};

export default applicationsReducer;
