import { React } from "react";
import { connect } from "react-redux";

// @material-ui/core components
import {
  Container,
} from "@material-ui/core";

//import custom components
import FollowUpHistory from "../../../Components/OpportunityApplicationsFollowUp/FollowUpHistory";

function FollowUpHistoryTab(props) {
  return (
    <>
      <Container maxWidth={false}>
        <FollowUpHistory
            opportunityApplicationFollowUpDetails={props.opportunityApplicationFollowUpDetails}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(FollowUpHistoryTab);