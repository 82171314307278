import React from "react";
import { useIntl } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
//MaterialUI
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import LinkMaterialUi from "@material-ui/core/Link";
import SvgIcon from "@material-ui/core/SvgIcon";

import componentStylesGeneric from "assets/theme/views/admin/generic";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function SearchIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M7 9H2V7h5v2zm0 3H2v2h5v-2zm13.59 7-3.83-3.83c-.8.52-1.74.83-2.76.83-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L22 17.59 20.59 19zM17 11c0-1.65-1.35-3-3-3s-3 1.35-3 3 1.35 3 3 3 3-1.35 3-3zM2 19h10v-2H2v2z" />
    </SvgIcon>
  );
}
export default function CardTableContainer(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <Card classes={{ root: classes.tableCardRoot }}>
      <CardHeader
        classes={{ root: classes.tableCardHeaderRoot }}
        title={
          <div>
            <div className={classes.tableCardHeaderTitle}>{props.title}</div>
            <div className={classes.tableCardElementsCount}>
              <SearchIcon classes={{ root: classes.tableCardHeaderIcon }} />
              {props.count ?? 0}
            </div>
          </div>
        }
        action={
          props.viewMoreLink ? (
            <LinkMaterialUi component={Link} variant="h5" to={props.viewMoreLink} className={classes.CardHeaderLink}>
              {intl.formatMessage(labels.Label_See_More)}
            </LinkMaterialUi>
          ) : (
            props.actionButtonOnClick && (
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="large"
                onClick={props.actionButtonOnClick}
                classes={classes.TableCardHeaderButton}
              >
                {props.actionButtonLabel}
              </GenericButton>
            )
          )
        }
      />
      {props.children}
    </Card>
  );
}
