import React from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";

function PartnershipsCard(props) {
  return (
    <Card 
      style={props.style}
    >
      <img
        src={props.item.path}
        alt={props.item.description}
        style={{
          height: "50px",
          margin: "auto"
        }}
      />
    </Card>
  );
}
const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(PartnershipsCard);