import { DATASET_ACTIONS } from "utils/actionsConsts";
import { DATASET } from "utils/const";

const datasetsReducer = (state = {}, action) => {
  switch (action.type) {
    case DATASET_ACTIONS.CLEAR_DATASET_BY_NAME:
      return { ...state, [action.dataSetName]: [] };
    case DATASET_ACTIONS.GET_DATASET_BY_NAME:
    case DATASET_ACTIONS.GET_DATASET_BY_INDEX:
    case DATASET_ACTIONS.GET_USER_APPLICATIONS_AWAITING_TRAINING:
    case DATASET_ACTIONS.GET_DATASET_BY_PROPERTY_ID:
    case DATASET_ACTIONS.GET_GEOGRAPHIC_DATA:
    case DATASET_ACTIONS.GET_GEOGRAPHIC_DATA_BY_PROVINCE:
    case DATASET_ACTIONS.GET_NATIONALITIES:
    case DATASET_ACTIONS.GET_ALL_PROGRAMS:
    case DATASET_ACTIONS.GET_SUBPROGRAM_BY_PROGRAM_ID:
    case DATASET_ACTIONS.GET_SHAPE_PROGRAMS:
    case DATASET_ACTIONS.GET_SHAPE_SUBSITES:
      return { ...state, [action.dataSetName]: action.payload };
    case DATASET_ACTIONS.DATASETS_LOADING:
      return {
        ...state,
        [DATASET.LOADING_DATASETS]: { ...state[DATASET.LOADING_DATASETS], [action.dataSetName]: action.payload },
      };
    default:
      return state;
  }
};

export default datasetsReducer;
