import { STATISTICAL_DATA_ACTIONS } from "utils/actionsConsts";

const statisticalDataReducer = (
  state = {
    getStatisticalData_loading: false,
  },
  action
) => {
  switch (action.type) {
    case STATISTICAL_DATA_ACTIONS.GET_STATISTICAL_DATA:
      return { ...state, getStatisticalData_result: action.payload };
    case STATISTICAL_DATA_ACTIONS.GET_STATISTICAL_DATA_LOADING:
      return { ...state, getStatisticalData_loading: action.payload };
    default:
      return state;
  }
};

export default statisticalDataReducer;
