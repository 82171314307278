import React from "react";
import { connect } from "react-redux";
import { loginAction } from "redux/actions/auth/loginAction";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import LoginModal from "components/Modals/Auth/LoginModal"
import RecoverModal from "components/Modals/Auth/RecoverModal"
import { showAuthenticationModalAction } from "redux/actions/auth/authenticationAction";
import { setSSOParametersAction } from "redux/actions/ssoAction";
import { validateSSOAccessAction } from "redux/actions/ssoAction";

//style
import { makeStyles } from "@material-ui/core/styles";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function SSOLogin(props) {
    const classes = { ...useStylesGeneric() };

    const [loginModal, setStateModal] = React.useState(true);
    const [recoverModal, setRecoverModal] = React.useState(false);
    const [state, setState] = React.useState({
        email: "",
        password: "",
        recoverPasswordPhone: "",
    });

    React.useEffect(() => {
        props.setSSOParameters(props.match.params.token, props.match.params.redirectURL)
        props.validateSSOAccess(props.match.params.token, document.referrer)
        // eslint-disable-next-line
    }, [props.match.params.token, props.match.params.redirectURL]);

    //redirect user to refere website after login cookie is set
    React.useEffect(() => {
        if (props.authState.loggedIn && !props.authState.mustConfirmPhone) {
            props.setSSOParameters("", "")
            setTimeout(() => {
                window.location.href = decodeURIComponent(props.match.params.redirectURL)
            },
                200)
        }
        // eslint-disable-next-line
    }, [props.authState.loggedIn, props.match.params.token]);

    const onChange = (stateName, value) => {
        setState((prevState) => ({
            ...prevState,
            [stateName]: value,
        }));
    };

    const handlerecoverModal = () => {
        // setStateModal(false);
        setRecoverModal(true);
    };
    const handleClose = () => {
        setStateModal(false);
    };
    const handleCloseRecover = () => {
        setRecoverModal(false);
    };

    const getContent = () => {
        if (props.authState.loggedIn && !props.authState.mustConfirmPhone) {
            return <Box>
                <h1 className={classes.centeredMessage}>Redirecionando para a aplicação de origem</h1>
                <CircularProgress size={50} className={classes.pageProgress} />
            </Box>
        }
        else if (props.ssoState?.validSSOAccess === true) {
            return <>
                <LoginModal
                    loginModal={loginModal}
                    handleClose={handleClose}
                    handlerecoverModal={handlerecoverModal}
                    showAuthenticationModal={props.showAuthenticationModal}
                    loginAction={props.loginAction}
                    onChange={onChange}
                    state={state}
                />
                <RecoverModal
                    recoverModal={recoverModal}
                    handleCloseRecover={handleCloseRecover}
                    showAuthenticationModal={props.showAuthenticationModal}
                    onChange={onChange}
                    state={state}
                />
            </>
        } else {
            return <Box>
                <h1 className={classes.centeredMessage}>Acesso negado</h1>
            </Box>
        }
    }

    return (
        getContent()
    );
}
const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = (dispatch) => ({
    loginAction: (email, password) =>
        dispatch(loginAction(email, password)),
    showAuthenticationModal: (toShow, username, action) =>
        dispatch(showAuthenticationModalAction(toShow, username, action)),
    setSSOParameters: (token, referer) =>
        dispatch(setSSOParametersAction(token, referer)),
    validateSSOAccess: (token, referer) =>
        dispatch(validateSSOAccessAction(token, referer)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SSOLogin);