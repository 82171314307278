import { Pagination } from "@material-ui/lab";
import { useCallback, useEffect, useState } from "react";

import { labels } from "resources/resources";
import { useIntl } from "react-intl";
import { PAGINATION } from "utils/const";
import { useDispatch, useSelector } from "react-redux";

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";

import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getFoUSInterventionOtherAreaAction } from "redux/actions/auth/getPersonalInformationAction";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import moment from "moment";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import useTokenHelper from "hooks/useTokenHelper";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function InterventionOtherAreaSection() {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const tokenHelper = useTokenHelper();

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const { getFoUSInterventionOtherArea_result, getFoUSInterventionOtherArea_loading } = useSelector(
    (state) => state.authState
  );

  const tableHead = [
    intl.formatMessage(labels.Label_InterventionOtherArea_Area),
    intl.formatMessage(labels.Label_Date),
    intl.formatMessage(labels.Label_InterventionOtherArea_ReasonForIntervention),
    intl.formatMessage(labels.Label_InterventionOtherArea_Results),
  ];

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const getData = useCallback(() => {
    const filter = {
      pageIndex: page <= 0 ? 1 : page,
      pageSize: PAGINATION.PAGE_SIZE,
      userId: tokenHelper.getUserId(),
    };

    dispatch(getFoUSInterventionOtherAreaAction(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <CardTableContainer
      title={intl.formatMessage(labels.Label_InterventionOtherArea)}
      count={getFoUSInterventionOtherArea_result?.totalCount}
    >
      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  classes={{
                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  {prop}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getFoUSInterventionOtherArea_result?.data?.map(
              ({ interventionOtherAreaDescription, date, reasonForIntervention, results, id }) => (
                <TableRow key={id}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{interventionOtherAreaDescription}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{moment(date).format("DD/MM/YYYY")}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{reasonForIntervention}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{results}</Box>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Box>
        <TableBackdrop open={getFoUSInterventionOtherArea_loading} />
      </TableContainer>

      <Pagination
        className={classes.alignPagination}
        count={getFoUSInterventionOtherArea_result?.totalPages}
        onChange={handleChangePage}
        page={page}
        color="primary"
      ></Pagination>
    </CardTableContainer>
  );
}
