import { React } from "react";
import { connect } from "react-redux";


import {
  Container,
} from "@material-ui/core";

//import custom components
import FollowUpDetails from "../../../Components/OpportunityApplicationsFollowUp/FollowUpDetails";

function FollowUpDetailsTab(props) {
  return (
    <>
      <Container maxWidth={false}>
        <FollowUpDetails
            opportunityApplicationFollowUpDetails={props.opportunityApplicationFollowUpDetails}
            isUserColective={false}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(FollowUpDetailsTab);
