const componentStyles = (theme) => ({
  containerCardTextImg: {
    height: "100%",
    border: '1px solid rgb(210, 210, 210)',
  },
  textCardTextImg: {
    component: "p",
    marginBottom: "0",
    fontWeight: "300",
    lineHeight: "1.7",
    fontSize: "1rem",
    marginTop: "0",
    marginRight: "10px",
  },
  imgContainer: {
    height: "auto",
    position: "absolute",
    right: 0
  },
  imgCardTextImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "130px",
    height: "130px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  containerCardTitle: {
    marginTop: "20px",
  },
  cardLine: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
    maxWidth: "70px",
    margin: "0",
    marginTop: "10px",
  },
  removePadding: {
    flex: "1 1 auto",
    padding: "0 20px",
  },
  textAndImageContainer: {
    display: "flex",
    marginTop: "20px",
    position: "relative",
  },
  oneLineEllipsis: {
    display: "-webkit-box",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": "1",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  doubleLineTitleEllipsis: {
    display: "-webkit-box",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": "2",
    lineHeight: "1.5rem",
    height: "3rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  descriptionSize: {
    height: "8.5rem",
    marginBottom: "1rem",
  },
  cardDate: {
    fontSize:"13px",
  },
  seeMoreButton: {
    position: "absolute",
    right: 0,
    fontSize:"13px",
  },
  tooltip: {
    maxWidth: "280px",
    marginTop: "1px",
    marginBottom: "1px",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.black.light,
    textAlign: "justify",
  },
  tooltipArrow: {
    color: theme.palette.grey[300],
  },
  quillOverflowHidden: {
    "& .ql-container .ql-editor": {
      overflowY: "hidden",
    },
  },
  greyImageColoredOnHover: {
    filter: "gray",
    "-webkit-filter": "grayscale(100%)",
    opacity: "0.6",
    "&:hover": {
      filter: "none",
      "-webkit-filter": "grayscale(0%)",
      opacity: "1"
  },
  }
});

export default componentStyles;
