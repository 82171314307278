import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { getNumberOfUnreadNotificationsAction } from "redux/actions/notificationsAction";
import LandingPage from "components/LandingPage/LandingPage";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import HomepageModal from "views/admin/Homepage/HomepageModal";
import {
  getApplicationVersionAction,
  hideShowNewsAction,
} from "redux/actions/applicationConfigurationAction";
import { setTargetPathAfterLoginAction } from "redux/actions/auth/loginAction";
import { USER_TYPE_DESCRIPTION } from "utils/const";
import { messages } from "resources/resources";

function AdminHomepage(props) {
  const intl = useIntl();

  const [showNewsModal, setShowNewsModal] = useState(false);
  const [showIncorrectPathUserTypeModal, setShowIncorrectPathUserTypeModal] =
    useState(
      props.authState?.targetPathAfterLogin &&
        props.authState?.targetPathAfterLoginUserType &&
        props.authState?.targetPathAfterLoginUserType !==
          props.authState?.userType
    );

  useEffect(() => {
    if (props.authState.loggedIn !== undefined) {
      props.getApplicationVersion(
        props.authState.userType,
        props.authState.lastLoginDate
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.authState.showNews) {
      setShowNewsModal(true);
    }
  }, [props.authState.showNews]);

  useEffect(() => {
    props.getNumberOfUnreadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCloseNewsModal() {
    setShowNewsModal(false);
    props.hideShowNews(props.authState.lastLoginDate);
  }

  function handleCloseIncorrectPathUserTypeModal() {
    setShowIncorrectPathUserTypeModal(false);
  }

  return (
    <>
      <LandingPage isLoggedIn={true} userType={props.authState.userType} />
      <GenericDialog maxWidth={"xl"} open={!!showNewsModal}>
        <HomepageModal
          title={intl.formatMessage(
            messages.Message_Admin_HomePage_App_New_Version
          )}
          message={props.authState.news}
          onClose={handleCloseNewsModal}
        />
      </GenericDialog>
      <GenericDialog open={!!showIncorrectPathUserTypeModal}>
        <HomepageModal
          title={intl.formatMessage(
            messages.Message_Admin_HomePage_No_Access_To_Page
          )}
          message={
            intl.formatMessage(
              messages.Message_Admin_HomePage_No_Access_To_Page_Description_1
            ) +
            " " +
            (props.authState?.userType === 1
              ? USER_TYPE_DESCRIPTION.INDIVIDUAL
              : USER_TYPE_DESCRIPTION.COLLECTIVE) +
            ". " +
            intl.formatMessage(
              messages.Message_Admin_HomePage_No_Access_To_Page_Description_2
            ) +
            " " +
            (props.authState?.userType === 1
              ? USER_TYPE_DESCRIPTION.COLLECTIVE
              : USER_TYPE_DESCRIPTION.INDIVIDUAL) +
            "."
          }
          onClose={() => {
            props.setTargetPathAfterLogin(null, null);
            handleCloseIncorrectPathUserTypeModal();
          }}
        />
      </GenericDialog>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getNumberOfUnreadNotifications: () =>
    dispatch(getNumberOfUnreadNotificationsAction()),
  getApplicationVersion: (data, date) =>
    dispatch(getApplicationVersionAction(data, date)),
  hideShowNews: (date) => dispatch(hideShowNewsAction(date)),
  setTargetPathAfterLogin: (targetPath, pathUserType) =>
    dispatch(setTargetPathAfterLoginAction(targetPath, pathUserType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminHomepage);
