const componentStyles = (theme) => ({
    avatarRoot: {
        width: "35px",
        height: "35px",
        fontWeight: 600,
        fontSize: "12.25px",
        lineHeight: "17px",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: theme.palette.primary.main
    },
    cardHeaderCounter: {
        fontSize: "40px !important",
        fontWeight: "600",
        color: theme.palette.white.main
    },
    actionsCell: {
        textAlign: "right"
    },
    errorIcon: {
        color: theme.palette.error.main,
        width: "20px",
        height: "20px",
    },
    dividerRoot: {
        width: "2px",
      },
    readNotificationDividerRoot: {
        backgroundColor: theme.palette.primary.main + "!important",
    },
    cellTextRoot: {
        fontSize: ".65rem",
    },
    cellTitleTextRoot: {
        fontWeight: "bold",
    },
    cardHeaderDashboard: {
        position: "relative",
        zIndex: '1', 
        backgroundColor: theme.palette.transparent.main, 
        height: '136px'
    },
    cardTitle: {
        color: theme.palette.white.main,
        fontSize: "20px",
        fontWeight: "600",
    },
    cardHeaderAction: {
        marginTop: "5px !important"
    },
    link: {
        textTransform: "uppercase",
        color: theme.palette.white.main,
        "&:hover": {
            textDecoration: "none",
            color: theme.palette.primary.mediumBlue,
        },
        marginRight: "10px"
    },
    backgroundImage: {
        height: "136px", 
        width: "100%", 
        objectFit: "cover",
        position: "absolute"
    }
});

export default componentStyles;