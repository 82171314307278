import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { showAuthenticationModalAction, confirmAuthenticationAction } from "redux/actions/auth/authenticationAction";
import { titles, messages } from "resources/resources";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CONFIRMATION_TOKEN_LENGTH } from "utils/const";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import CardContent from "@material-ui/core/CardContent";
//@material-ui/icons components
// core components
import componentStyles from "assets/theme/components/modals/auth/authentication-modal.js";
import GenericButton from "components/Buttons/GenericButton";
import { Grid } from "@material-ui/core";
import CustomDialogTitle from "components/Dialogs/CustomDialogTitle";
import FormVerificationInput from "components/FormFields/FormVerificationInput";

const useStyles = makeStyles(componentStyles);

function AuthenticationModal(props) {
  const [token, setToken] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const getShowModal = () => {
    return props.authState.confirmationModal !== undefined
      ? props.authState.confirmationModal.showConfirmationModal
      : false;
  };

  const handleCloseModal = () => {
    setShowError(false);
    props.showAuthenticationModal(false);
  };

  const getMobilePhoneLastDigits = () => {
    return props.authState.confirmationModal !== undefined
      ? props.authState.confirmationModal.mobilePhone?.substr(props.authState.confirmationModal.mobilePhone.length - 3)
      : false;
  };

  const handleChangeVerificationInput = (e) => {
    const numbersOnly = /^\d*$/; // Regular expression to allow only numbers

    if (e === "" || numbersOnly.test(e)) {
      setToken(e);
    }
  };

  const submit = () => {
    if (token !== undefined && token.length === CONFIRMATION_TOKEN_LENGTH) {
      setShowError(false);
      handleCloseModal();
      props.confirmAuthentication(
        props.authState.confirmationModal.mobilePhone,
        token,
        props.authState.confirmationModal.actionType
      );
    } else {
      setShowError(true);
    }
  };

  return (
    <Dialog open={getShowModal()} onClose={handleCloseModal} classes={{ paper: classes.modal }}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Grid container>
          <Grid item>
            <Box color={theme.palette.gray[600]} textAlign="left" marginBottom="1rem" marginTop="0px" fontSize="2rem">
              <Box
                style={{
                  fontSize: "2rem",
                  lineHeight: "3rem",
                  color: theme.palette.primary.greyBlue,
                }}
                className={classes.modalHeader}
              >
                {intl.formatMessage(titles.Title_Auth_Confirmation_Modal)}
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <CustomDialogTitle id="customized-dialog-title" onClose={handleCloseModal} />
          </Grid>
        </Grid>
        <Box
          style={{
            color: theme.palette.gray[900],
            textAlign: "left",
            marginBottom: "1.5rem",
            marginLeft: "0",
            marginTop: ".5rem",
            fontSize: ".9rem",
          }}
          className={classes.modalRecoverHeader1}
        >
          {intl.formatMessage(messages.Message_Auth_Confirmation_Modal, {
            lastDigits: getMobilePhoneLastDigits(),
          })}
        </Box>
        <form>
          <FormVerificationInput
            length={CONFIRMATION_TOKEN_LENGTH}
            value={token}
            handleOnChange={handleChangeVerificationInput}
            showError={showError}
          />

          <Box
            className={classes.modalRecoverFooter}
            style={{
              textAlign: "left",
              marginBottom: "1.5rem",
              marginLeft: "0",
              marginTop: "20px",
              fontSize: ".9rem",
            }}
          >
            {intl.formatMessage(messages.Message_Auth_ConfirmationFooter_Modal)}
          </Box>
          <Box
            style={{
              textAlign: "left",
              marginBottom: "1.5rem",
              marginLeft: "0",
              marginTop: "20px",
              fontSize: ".9rem",
            }}
            className={classes.modalRecoverFooterContacts}
          >
            Caso precise de ajuda contacte a Equipa do BackOffice:
            <br />
            Telefone: 921154482
            <br />
            Email: backoffice.pape@maptss.gov.ao
          </Box>
          <Box textAlign="right" marginTop="1.5rem" marginBottom="0">
            <GenericButton
              typeSubmit={false}
              color="primary"
              onClick={() => {
                submit();
              }}
            >
              Confirmo
            </GenericButton>
          </Box>
        </form>
      </CardContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  showAuthenticationModal: (toShow, username, action) =>
    dispatch(showAuthenticationModalAction(toShow, username, action)),
  confirmAuthentication: (username, token, action) => dispatch(confirmAuthenticationAction(username, token, action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationModal);
