import React from "react";

import { Avatar, IconButton, ThemeProvider } from "@material-ui/core";
import { makeStyles, createTheme } from "@material-ui/core/styles";

import componentStyles from "assets/theme/components/landingPage/landing-page.js";

function NeedHelpCircle(props) {
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();

  const theme = createTheme({
    overrides: {
      MuiIconButton: {
        root: {
          // This may seem useless but the Icons would not
          // change their fontSize if we didnt do this
          // first.
          fontSize: "0",
        },
      },
    },
  });

  return (
    <Avatar className={classes.needHelpCircle}>
      <ThemeProvider theme={theme}>
        <IconButton onClick={props.onClick} className={classes.needHelpIconButton}>
          {props.children}
        </IconButton>
      </ThemeProvider>
    </Avatar>
  );
}

export default NeedHelpCircle;
