import React  from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/tables/card-light-table-tables.js";
import componentStylesBadge from "assets/theme/components/badge.js";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(componentStylesBadge);

export default function ProgramTable(props) {
  const classes = { ...useStyles(), ...useStylesBadge() };
  return (
    <TableContainer>
      <Box component={Table} alignItems="center" marginBottom="15px!important">
        <TableHead>
          <TableRow>
          <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.programsTableCell,
              }}
            >
              Programa de Governo
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.programsTableCell,
              }}
            >
              Programa
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot + " " + classes.programsTableCell,
              }}
            >
              Subprograma
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
          <TableCell classes={{ root: classes.programsTableBodyCell }}>
              {props.governmentProgram}
            </TableCell>
            <TableCell classes={{ root: classes.programsTableBodyCell }}>
              {props.program}
            </TableCell>
            <TableCell classes={{ root: classes.programsTableBodyCell }}>
              {props.subprogram}
            </TableCell>
          </TableRow>
        </TableBody>
      </Box>
    </TableContainer>
  );
}
