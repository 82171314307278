import { useMemo } from "react";
import { useSelector } from "react-redux";

import TokenHelper from "utils/getKeyValueFromToken";

export default function useTokenHelper() {
  const token = useSelector((state) => state.authState?.token);

  return useMemo(() => new TokenHelper(token), [token]);
}
