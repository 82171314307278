import { React, useEffect, useState } from "react";
import { connect } from "react-redux";

import "moment/locale/pt";
import moment from "moment";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { DATASET } from "utils/const";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import HelpIcon from "@material-ui/icons/Help";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import { OPPORTUNITY_CANDIDATE_STATE_KEY, TABLE_STATE_CHIP_COLOR } from "utils/const";
import DisplayField from "components/FormFields/DisplayField";
import { getDatasetLabelByCode } from "utils/datasets";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidateGeneralDetails(props) {
  const classes = useStylesGeneric();
  const [data, setData] = useState();

  useEffect(() => {
    if (props.opportunitiesState?.candidateInfo !== null && props.opportunitiesState?.candidateInfo !== undefined) {
      setData(props.opportunitiesState.candidateInfo);
    } else {
      setData([]);
    }
  }, [props.opportunitiesState?.candidateInfo]);

  //#region DataSets Setup
  useEffect(() => {
    handleGetDataset(DATASET.GENDER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.GENDER]]);

  useEffect(() => {
    handleGetDataset(DATASET.MARITAL_STATUS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.MARITAL_STATUS]]);

  useEffect(() => {
    handleGetDataset(DATASET.NATIONALITIES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.NATIONALITIES]]);

  useEffect(() => {
    handleGetDataset(DATASET.PROVINCES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROVINCES]]);

  useEffect(() => {
    handleGetDataset(DATASET.MUNICIPALITIES);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.MUNICIPALITIES]]);

  useEffect(() => {
    handleGetDataset(DATASET.ACADEMIC_DEGREE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.ACADEMIC_DEGREE]]);

  //#endregion

  const handleGetDataset = (dataset) => {
    if (props.datasetsState[dataset] === undefined || props.datasetsState[dataset].length === 0) {
      props.getDataSetByName(dataset);
    }
  };

  const mapOpportunityCandidateStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_STATE_KEY.APROVADA:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.REJEITADA:
        return TABLE_STATE_CHIP_COLOR.RED;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.EMRECRUTAMENTO:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot}>
          <CardHeader
            classes={{
              action: classes.cardHeaderAction,
              title: classes.cardHeader,
            }}
            title={"Dados do Candidato"}
            action={
              <Grid container>
                <Grid item>
                  <TableStateChip
                    state={mapOpportunityCandidateStateToTableChipColors(
                      props.opportunitiesState?.candidateInfo?.opportunityCandidateStateKey
                    )}
                    message={props.opportunitiesState?.candidateInfo?.opportunityCandidateStateDescription}
                  />
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid container>
              <DisplayField xs={12} sm={6} md={4} lg={2} label="Nome" data={data?.name} />
              <DisplayField xs={12} sm={6} md={4} lg={2} label="Sobrenome" data={data?.userSingular?.lastName} />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Data de Nascimento"
                data={moment(data?.userSingular?.dateOfBirth).format("DD/MM/YYYY")}
              />
              <DisplayField xs={12} sm={6} md={4} lg={2} label="Nº BI" data={data?.userSingular?.identityCard} />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Género"
                data={getDatasetLabelByCode(props.datasetsState[DATASET.GENDER], data?.userSingular?.genderId)}
              />

              {/* New Line md */}
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Estado Civil"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.MARITAL_STATUS],
                  data?.userSingular?.maritalStatusId
                )}
              />
              <DisplayField xs={12} sm={6} md={4} lg={2} label="Pessoas a cargo" data={data?.userSingular?.household} />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Rendimento Familiar"
                data={data?.userSingular?.familyIncome ? data?.userSingular?.familyIncome + " Kz" : ""}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Nacionalidade"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.NATIONALITIES],
                  data?.userSingular?.nationalityId
                )}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Província"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.PROVINCES],
                  data?.userSingular?.compositeAddress?.provinceId
                )}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Município"
                data={getDatasetLabelByCode(
                  props.datasetsState[DATASET.MUNICIPALITIES],
                  data?.userSingular?.compositeAddress?.municipalityId
                )}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Bairro"
                data={data?.userSingular?.compositeAddress?.district}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Endereço"
                data={data?.userSingular?.compositeAddress?.address}
              />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label={
                  <>
                    NISS
                    <Tooltip title="Número de Identificação da Segurança Social">
                      <HelpIcon className={classes.tooltipInfoIcon} />
                    </Tooltip>
                  </>
                }
                data={data?.userSingular?.NISS}
              />
              <DisplayField xs={12} sm={6} md={4} lg={2} label="Telemóvel" data={data?.mobilePhone} />
              <DisplayField xs={12} sm={6} md={4} lg={2} label="E-mail" data={data?.email} />
              <DisplayField
                xs={12}
                sm={6}
                md={4}
                lg={2}
                label="Idade"
                data={moment().diff(data?.userSingular?.dateOfBirth, "years") + " anos"}
              />
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateGeneralDetails);
