import { SSO_ACTIONS } from "utils/actionsConsts";
import { validateSSOAccess } from "crud/sso.crud";

export const setSSOParametersAction = (_token, _redirectURL) => async (dispatch) => {
    dispatch({ type: SSO_ACTIONS.SET_SSO_PARAMETERS, payload: { token: _token, redirectURL: _redirectURL } });
};

export const validateSSOAccessAction = (_token, _URL) => async (dispatch) => {
    validateSSOAccess(_token, _URL)
        .then((res) => {
            dispatch({ type: SSO_ACTIONS.VALIDATE_SSO_ACCESS, payload: res.data });
        })
        .catch(() => {
            dispatch({ type: SSO_ACTIONS.VALIDATE_SSO_ACCESS, payload: false });
        });
};

export default setSSOParametersAction;
