import { React, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//import custom components
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getOpportunitiesDetailsAction } from "redux/actions/opportunitiesAction";
import RecommendedOpportunities from "./RecommendedOpportunities";
import OpportunityData from "./OpportunityData";

import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityDetails(props) {
  const classes = useStylesGeneric();

  useEffect(() => {
    if (
      props.opportunitiesState.opportunityDetailsData === undefined ||
      props.opportunitiesState.opportunityDetailsData.opportunityId !==
      props.match.params.opportunityId
    ) {
      props.getOpportunityDetails(props.match.params.opportunityId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.opportunityId]);

  return (
    <>
      <GenericBackdrop
        open={props.opportunitiesState.get_opportunity_details_loading}
      />
      <Container
        maxWidth={false}
        component={Box}
        classes={{ root: classes.containerRoot }}
      >
        <LocationHeader
          subsection={"Emprego e Estágio Profissional"}
          section={"Detalhes de Oferta"}
          subsectionLink={"/admin/available-opportunities"}
        />

        <PageTitleHeader
          title={props.opportunitiesState.opportunityDetailsData?.title}
        />

        <Box component={Grid} container>
          <Grid item xs={12} xl={3}>
            <RecommendedOpportunities
              pageSize={3}
              fullWidth={true}
              excludeOpportunityId={props.match.params.opportunityId}
            />
          </Grid>
          <Grid item 
            xs={12} 
            xl={9}
          >
            <OpportunityData
              opportunityDetailsData={props.opportunitiesState.opportunityDetailsData}
            />
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getOpportunityDetails: (opportunityId) =>
    dispatch(getOpportunitiesDetailsAction(opportunityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetails);
