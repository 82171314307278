import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesLandingPage from "assets/theme/components/landingPage/landing-page.js";
import componentStylesLastPostsVerticalCaroussel from "assets/theme/components/landingPage/last-posts-vertical-caroussel.js";
import { PAGINATION } from "utils/const";
import { getInformationTypeChipColor } from "utils/getChipColors";

function LastPostsVerticalCaroussel(props) {
  const useStylesGeneric = makeStyles(componentStylesGeneric);
  const useStylesLandingPage = makeStyles(componentStylesLandingPage);
  const useStylesLastPostsVerticalCaroussel = makeStyles(componentStylesLastPostsVerticalCaroussel);
  
  const history = useHistory();
  const classes = { ...useStylesGeneric(), ...useStylesLandingPage(), ...useStylesLastPostsVerticalCaroussel() };
  const theme = useTheme()

  const [shortInformationList, setShortInformationList] = useState([])
  const [selectedInformation, setSelectedInformation] = useState(props.informationList[0])
  const [selectedInformationIndex, setSelectedInformationIndex] = useState(0)
  const [selectedInformationImage, setSelectedInformationImage] = useState("/images/white_background.png")

  useEffect(() => {
    setShortInformationList(props.informationList?.slice(0, PAGINATION.PAGE_SIZE_LAST_POSTS))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedInformationIndex(prev => prev + 1 === PAGINATION.PAGE_SIZE_LAST_POSTS ? 0 : prev + 1)
    }, 7000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(shortInformationList){
      setSelectedInformation(shortInformationList[selectedInformationIndex])
      setSelectedInformationImage((shortInformationList[selectedInformationIndex])?.photo)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInformationIndex, shortInformationList])

  const handleImageError = () => {
    setSelectedInformationImage("/images/white_background.png")
  }

  return (
    <Container maxWidth={false} className={classes.homepageSectionContainer} style={{marginBottom: "50px"}}>
      <Grid container className={classes.componentContainer}>
        <Grid item md={6} className={classes.selectedPostContainer}>
          <div
            data-testid="last-posts-vertical-caroussel-selected-post"
            style={{width: "100%", height: "100%", cursor: "pointer"}}
            onClick={() => {history.push((props.authState?.loggedIn ? "/admin" : "/auth") + "/informationDissemination-details/" +  selectedInformation?.alternateKey)}}
          >
            <img
              data-testid={"last-posts-vertical-caroussel-selected-post-image"}
              src={selectedInformationImage ? selectedInformationImage : "/images/group-afro-americans-working-together.png"}
              alt={selectedInformation?.title}
              className={classes.selectedPostImage}
              onError={handleImageError}
            />

            <div className={classes.selectedPostDetailsContainer}>
              <Box component={Grid} container>
                <Grid item sm={6}>
                  <Chip
                      data-testid={"last-posts-vertical-caroussel-selected-post-type-chip"}
                      classes={{
                          root: classes.informationTypeChipRoot,
                      }}
                      style={{ 
                        backgroundColor: getInformationTypeChipColor(selectedInformation?.informationDisseminationTypeKeyValue), 
                        borderColor: getInformationTypeChipColor(selectedInformation?.informationDisseminationTypeKeyValue),
                      }}
                      label={selectedInformation?.informationDisseminationTypeDescription}
                      variant="outlined"
                  />
                </Grid>
                <Grid 
                  item sm={6} 
                  style={{textAlign: "right"}} 
                  data-testid={"last-posts-vertical-caroussel-selected-post-published-on"}
                >
                  {selectedInformation?.publishedOn}
                </Grid>
              </Box>
              <Tooltip title={selectedInformation ? selectedInformation.title : ""}>
                <div 
                  data-testid={"last-posts-vertical-caroussel-selected-post-title"}
                  className={classes.oneLineEllipsis + " " + classes.postTitle}
                >
                  {selectedInformation?.title}
                </div>
              </Tooltip>
              <div 
                data-testid={"last-posts-vertical-caroussel-selected-post-description"}
                className={classes.safariFriendlyDoubleLineEllipsis} style={{marginTop: "10px"}}
              >
                {ReactHtmlParser(selectedInformation?.description)}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={true} style={{marginRight: "-30px"}}>
            <div style={{width: "100%"}}>
              {shortInformationList.map((information, key) => {
                return ( 
                  <Box 
                    data-testid={"last-posts-vertical-caroussel-post-" + key}
                    component={Grid} 
                    container 
                    key={key} 
                    className={classes.normalPostContainer}
                    style={selectedInformation === information ? { backgroundColor: theme.palette.primary.lightGreyBlue} : {}}
                    onClick={() => {setSelectedInformationIndex(key)}}
                  >
                    <img
                      data-testid={"last-posts-vertical-caroussel-post-" + key + "-image"}
                      src={information.photo ? information.photo : "/images/group-afro-americans-working-together.png"}
                      alt={information.title}
                      className={classes.normalPostImage}
                    />

                    <Grid item lg={true} style={{marginTop: "20px"}}>
                      <Box component={Grid} container>
                        <Grid item sm={6}>
                          <Chip
                              data-testid={"last-posts-vertical-caroussel-post-" + key + "-type-chip"}
                              classes={{
                                  root: classes.informationTypeChipRoot,
                              }}
                              style={{
                                backgroundColor: getInformationTypeChipColor(information.informationDisseminationTypeKeyValue), 
                                borderColor: getInformationTypeChipColor(information.informationDisseminationTypeKeyValue)
                              }}
                              label={information.informationDisseminationTypeDescription}
                              variant="outlined"
                          />
                        </Grid>
                        <Grid 
                          item sm={6} 
                          className={classes.postPublishedOn}
                          data-testid={"last-posts-vertical-caroussel-post-" + key + "-published-on"}
                        >
                          {information.publishedOn}
                        </Grid>
                      </Box>
                      <Tooltip title={information ? information.title : ""}>
                        <div 
                          data-testid={"last-posts-vertical-caroussel-post-" + key + "-title"}
                          className={classes.safariFriendlyDoubleLineEllipsis + " " + classes.postTitle}
                        >
                          {information.title}
                        </div>
                      </Tooltip>
                      <div 
                        data-testid={"last-posts-vertical-caroussel-post-" + key + "-description"}
                        className={classes.safariFriendlyDoubleLineEllipsis} 
                        style={{marginTop: "10px"}}
                      >
                        {ReactHtmlParser(information.description)}
                      </div>
                    </Grid>
                  </Box>
                )
              })}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(LastPostsVerticalCaroussel);