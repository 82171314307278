import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const GET_USER_RECENT_APPLICATIONS_DASHBOARD = "Applications/get-fo-recent-applications-dashboard";
export const GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_DASHBOARD = "Opportunity/get-fouc-recent-opportunity-candidate-dashboard";
export const GET_USER_SINGULAR_RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD = "Opportunity/get-fous-recent-opportunity-candidate-dashboard";

export function getUserRecentApplicationsDashboard() {
  return axios.get(
    EXTERNAL_API_URL + GET_USER_RECENT_APPLICATIONS_DASHBOARD
  );
}

export function getUserSingularRecentOpportunityCandidateDashboard() {
  return axios.get(
    EXTERNAL_API_URL + GET_USER_SINGULAR_RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD
  );
}

export function getUserCollectiveRecentOpportunityApplicationsDashboard(_opportunityTypeKeyValue) {
  return axios.get(
    EXTERNAL_API_URL + GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_DASHBOARD,{
      params: {"OpportunityTypeKeyValue" : _opportunityTypeKeyValue},
    }
  );
}