import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import "react-quill/dist/quill.snow.css";
//Header
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import { createOpportunityAction } from "redux/actions/opportunitiesAction";
import OpportunityCreation from "./Sections/OpportunityCreation";
import { labels, titles } from "resources/resources";
import { opportunityMap } from "mappers/OpportunityMap";
import { DATASET } from "utils/const";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Create(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const [opportunityQuestionsState, setOpportunityQuestionsState] = useState(
    []
  );

  const methods = useForm({
    mode: "onChange",
  });

  useEffect(() => { }, []);
  const cancelOnClick = () => {
    history.goBack();
  };

  const onSubmit = (data) => {
    props.createOpportunityAction(
      opportunityMap(data, opportunityQuestionsState, props.datasetsState[DATASET.OPPORTUNITY_DOCUMENT_TYPE])
    );
  };
  
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
          <LocationHeader
            section={"Criar Oferta"}
            subsection={"Emprego e Estágio Profissional"}
            subsectionLink={"/admin/opportunities"}
          />

          <PageTitleHeader
            title={intl.formatMessage(titles.Title_Opportunity_Creation)}
          />

          <OpportunityCreation
            setOpportunityQuestionsState={setOpportunityQuestionsState}
            opportunityQuestionsState={opportunityQuestionsState}
          />

          <Grid container classes={{ root: classes.containerButtons }}>
            <Grid item xs={12} sm={1} md={6}></Grid>
            <Grid item xs={12} sm={5} md={3}>
              <FormGroup>
                <GenericButton
                  typeSubmit={false}
                  color="secondary"
                  onClick={() => cancelOnClick()}
                >
                  {intl.formatMessage(labels.Label_Button_Cancel)}
                </GenericButton>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormGroup>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  disabled={props.opportunitiesState?.opportunitySubmitLoading}
                  loading={props.opportunitiesState?.opportunitySubmitLoading}
                >
                  {intl.formatMessage(labels.Label_Button_Submit)}
                </GenericButton>
              </FormGroup>
            </Grid>
          </Grid>
        </Container>
      </form>
    </FormProvider>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  createOpportunityAction: (data) => dispatch(createOpportunityAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
