import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import { useIntl } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import LinkMaterialUi from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";

//Icons
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStyles from "assets/theme/views/admin/generic-card.js";
import { getInformationTypeChipColor } from "utils/getChipColors";
import { SOCIAL_MEDIA_TYPES_KEYVALUES } from "utils/const";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStyles = makeStyles(componentStyles);

function InformationDisseminationCard(props) {
  const classes = { ...useStylesGeneric(), ...useStyles() };
  const intl = useIntl();

  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

  useEffect(() => {
    if (props.authState?.loggedIn !== undefined && props.authState.loggedIn) {
      setUserIsAuthenticated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfSocialMediaListHasShareLinks = (list) => {
    if (list !== undefined && list !== null && list.length > 0) {
      return list.some((item) => item.hasOwnProperty("shareLink"));
    }
    return false;
  };

  return (
    <>
      <Card className={classes.cardRoot}>
        <img
          src={
            props.photo !== null
              ? props.photo
              : "/images/group-afro-americans-working-together.png"
          }
          className={classes.cardImgTop}
          alt={props.title}
          style={{ objectFit: "cover" }}
        />

        <CardContent>
          <Box className={classes.cardChipContainer}>
            <Box className={classes.cardChipBox}>
              <Chip
                classes={{
                  root: classes.cardChipRoot,
                }}
                style={{
                  backgroundColor: getInformationTypeChipColor(
                    props.informationDisseminationTypeKeyValue
                  ),
                  borderColor: getInformationTypeChipColor(
                    props.informationDisseminationTypeKeyValue
                  ),
                }}
                label={props.informationDisseminationTypeDescription}
                variant="outlined"
              />
            </Box>
            <Box className={classes.cardTextAlignedOnEnd}>
              {"Publicado a " + props.publishedOn}
            </Box>
          </Box>
          <Tooltip
            title={props.title}
            placement="bottom"
            arrow
            interactive
            enterDelay={300}
            enterNextDelay={300}
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <Typography
              variant="h3"
              className={classes.doubleLineTitleEllipsis}
            >
              {props.title}
            </Typography>
          </Tooltip>

          <ReactQuill
            readOnly={true}
            className={
              classes.descriptionSize + " " + classes.quillOverflowHidden
            }
            theme="bubble"
            value={props.description}
          />

          <Grid container>
            <Grid
              item
              xs={12}
              sm={7}
              md={12}
              style={{ margin: "10px 0px 20px 0px" }}
            >
              {checkIfSocialMediaListHasShareLinks(
                props?.socialMediaState?.socialMediaLinks_result
              ) && (
                <div>
                  <div className={classes.sideBySideDiv}>
                    {intl.formatMessage(labels.Label_Share)}
                  </div>
                  <div>
                    {props?.socialMediaState?.socialMediaLinks_result?.map(
                      (socialMedia, key) => (
                        <Fragment key={key}>
                          {socialMedia.keyValue ===
                            SOCIAL_MEDIA_TYPES_KEYVALUES.FACEBOOK &&
                            socialMedia.shareLink && (
                              <FacebookIcon
                                className={
                                  classes.iconButtonOutlined +
                                  " " +
                                  classes.socialMediaIcon
                                }
                                onClick={() =>
                                  window.open(
                                    socialMedia.shareLink +
                                      window.location.origin +
                                      `/auth/informationDissemination-details/` +
                                      props.alternateKey,
                                    "_blank"
                                  )
                                }
                              />
                            )}
                          {socialMedia.keyValue ===
                            SOCIAL_MEDIA_TYPES_KEYVALUES.TWITTER &&
                            socialMedia.shareLink && (
                              <TwitterIcon
                                className={
                                  classes.iconButtonOutlined +
                                  " " +
                                  classes.socialMediaIcon
                                }
                                onClick={() =>
                                  window.open(
                                    socialMedia.shareLink +
                                      window.location.origin +
                                      `/auth/informationDissemination-details/` +
                                      props.alternateKey,
                                    "_blank"
                                  )
                                }
                              />
                            )}
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={7} md={7}></Grid>
            <Grid item xs={12} sm={5} md={5} className={classes.alignEnd}>
              <LinkMaterialUi
                component={Link}
                variant="h5"
                to={
                  userIsAuthenticated
                    ? `/admin/informationDissemination-details/${props.alternateKey}`
                    : `/auth/informationDissemination-details/${props.alternateKey}`
                }
                className={classes.containedGhostButton}
              >
                {intl.formatMessage(labels.Label_See_More)}
              </LinkMaterialUi>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(InformationDisseminationCard);
