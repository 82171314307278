import React, { Fragment } from "react";
import { useIntl } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Box, Grid, Container } from "@material-ui/core";

import componentStyles from "assets/theme/views/admin/static-page.js";
import DisplayData from "components/FormFields/DisplayData";
import { labels } from "resources/resources";
import LocationHeader from "components/Headers/LocationHeader";

const useStyles = makeStyles(componentStyles);

function InformationPage(props) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
      <Box marginLeft="-45px">
        <LocationHeader
          section={props.section ?? props.title}
          subsection={props.subsection}
          subsectionLink={props.subsectionLink}
        />
      </Box>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box data-testid="information-page-title" classes={{ root: classes.pageTitle }}>
            {props.title}
          </Box>
        </Grid>

        <Card className={classes.cardRoot}>
          <img
            src={props.headerPhoto ? props.headerPhoto : "/images/group-afro-americans-working-together.png"}
            alt={props.title}
            className={classes.headerImage}
          />
          <CardContent>
            <Grid item xs={12} md={12}>
              <Box classes={{ root: classes.descriptionRoot }}>
                {props.data && props.data?.length > 0 ? (
                  props.data?.map((data, dataKey) => {
                    return (
                      <Fragment key={dataKey}>
                        <DisplayData data={data} dataKey={dataKey} />
                      </Fragment>
                    );
                  })
                ) : (
                  <div className={classes.noResults}>{intl.formatMessage(labels.Label_No_Information_To_Present)}</div>
                )}
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
}

export default InformationPage;
