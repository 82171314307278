import { MONETARY_DECIMAL_SEPARATOR, FORM_FIELDS_PREFIX, PAGINATION } from "utils/const";

export function opportunityMap(formData, questionsData, documentTypes) {
  //Set FormData
  const data = new FormData();
  if (formData !== null && formData !== undefined) {
    const opportunity = {
      numVacancies: formData.numVacancies ?? "",
      provinceId: formData.province?.code ?? "",
      provinceKey: formData.province?.keyValue ?? "",
      municipalityId: formData.county?.code ?? "",
      reference: formData.reference ?? "",
      opportunityTypeId: formData.opportunityType?.code ?? "",
      opportunityTypeKey: formData.opportunityType?.keyValue ?? "",
      title: formData.title ?? "",
      description: formData.description ?? "",
      timetableTypeId: formData.timetableType?.code ?? "",
      timetableTypeKey: formData.timetableType?.keyValue ?? "",
      amount: formData.amount?.replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) ?? "",
      contractTypeId: formData.contractType?.code ?? "",
      contractTypeKey: formData.contractType?.keyValue ?? "",
      internshipTypeId: formData.internshipType?.code ?? "",
      internshipTypeKey: formData.internshipType?.keyValue ?? "",
      validFrom: formData.validFrom !== "" && formData.validFrom ? formData.validFrom.format("YYYY-MM-DD") : "",
      validTo: formData.validTo !== "" && formData.validTo ? formData.validTo.format("YYYY-MM-DD") : "",
      mentorName: formData.mentorName ?? "",
      mentorMobilePhone:
        formData.mentorMobilePhone !== "" && formData.mentorMobilePhone ? formData.mentorMobilePhone : "",
      opportunityProfessionalCategories: formData.opportunityProfessionalCategories
        ? [formData.opportunityProfessionalCategories?.code]
        : [],
      reportingRequired: formData.reportingRequired,
      reportingPeriod: formData.reportingPeriod ?? "",
      startDate: formData.startDate !== "" && formData.startDate ? formData.startDate.format("YYYY-MM-DD") : "",
      endDate: formData.endDate !== "" && formData.endDate ? formData.endDate.format("YYYY-MM-DD") : "",
      employmentCenterId: formData.employmentCenter ? formData.employmentCenter?.code : null,
      socialPerks: formData.socialPerks ?? "",
      otherPerks: formData.otherPerks ?? "",
      academicLevelId: formData.academicLevel ? formData.academicLevel?.code : null,
      workplace: formData.workplace ?? "",
      commune: formData.commune ?? "",
      requiredKnowledge: formData.requiredKnowledge ?? "",
      activeMonths: formData.activeMonths ?? "",
      observations: formData.opportunity_observations ?? "",
      acceptWorkersFromOtherRegions: formData.acceptWorkersFromOtherRegions
        ? formData.acceptWorkersFromOtherRegions.code
        : false,
    };

    //Append data
    for (const key in opportunity) {
      data.append("opportunity." + key, opportunity[key]);
    }

    let countQuestion = 0;
    for (const q in questionsData) {
      data.append("opportunity.OpportunityQuestions[" + countQuestion + "]", formData[questionsData[q].name]);
      countQuestion++;
    }

    //Append documents
    let countDoc = 0;
    documentTypes?.forEach((dt) => {
      if (
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()] &&
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()][0]
      ) {
        data.append("opportunity.OpportunityDocuments[" + countDoc + "].documentTypeId", dt.code);
        data.append("opportunity.OpportunityDocuments[" + countDoc + "].documentId", countDoc);
        data.append("OpportunityDocuments", formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()][0]);
        countDoc++;
      }
    });

    formData.requiredLanguages?.forEach((element) => {
      data.append("opportunity.RequiredLanguagesIds", element.code);
    });

    return data;
  }
  return formData;
}

export function createOpportunityCandidateMap(formData, opportunityId) {
  if (formData !== null && formData !== undefined) {
    const formKeys = Object.keys(formData);
    return {
      opportunityCandidate: {
        opportunityId: opportunityId,
        answers: formKeys?.map((q) => {
          return {
            questionId: q,
            answer: formData[q],
          };
        }),
      },
    };
  }
  return formData;
}

export function updateOpportunityMap(formData, questionsData, documentTypes) {
  //Set FormData
  const data = new FormData();
  if (formData !== null && formData !== undefined) {
    const opportunity = {
      alternateKey: formData.alternateKey ?? "",
      numVacancies: formData.numVacancies ?? "",
      provinceId: formData.province?.code ?? "",
      provinceKey: formData.province?.keyValue ?? "",
      municipalityId: formData.county?.code ?? "",
      reference: formData.reference ?? "",
      opportunityTypeId: formData.opportunityTypeId ?? "",
      opportunityTypeKey: formData.opportunityTypeKey ?? "",
      title: formData.title ?? "",
      description: formData.description ?? "",
      timetableTypeId: formData.timetableType?.code ?? "",
      timetableTypeKey: formData.timetableType?.keyValue ?? "",
      amount: formData.amount ?? "",
      contractTypeId: formData.contractType?.code ?? "",
      contractTypeKey: formData.contractType?.keyValue ?? "",
      internshipTypeId: formData.internshipType?.code ?? "",
      internshipTypeKey: formData.internshipType?.keyValue ?? "",
      validFrom: formData.validFrom !== "" && formData.validFrom ? formData.validFrom.format("YYYY-MM-DD") : "",
      validTo: formData.validTo !== "" && formData.validTo ? formData.validTo.format("YYYY-MM-DD") : "",
      mentorName: formData.mentorName ?? "",
      mentorMobilePhone:
        formData.mentorMobilePhone !== "" && formData.mentorMobilePhone ? formData.mentorMobilePhone : "",
      opportunityProfessionalCategories: formData.opportunityProfessionalCategories
        ? [formData.opportunityProfessionalCategories.code]
        : [],
      startDate: formData.startDate !== "" && formData.startDate ? formData.startDate.format("YYYY-MM-DD") : "",
      endDate: formData.endDate !== "" && formData.endDate ? formData.endDate.format("YYYY-MM-DD") : "",
      employmentCenterId: formData.employmentCenter ? formData.employmentCenter?.code : null,
      socialPerks: formData.socialPerks ?? "",
      otherPerks: formData.otherPerks ?? "",
      academicLevelId: formData.academicLevel ? formData.academicLevel?.code : null,
      workplace: formData.workplace ?? "",
      commune: formData.commune ?? "",
      requiredKnowledge: formData.requiredKnowledge ?? "",
      activeMonths: formData.activeMonths ?? "",
      observations: formData.opportunity_observations ?? "",
      acceptWorkersFromOtherRegions: formData.acceptWorkersFromOtherRegions
        ? formData.acceptWorkersFromOtherRegions.code
        : false,
    };
    //Append data
    for (const key in opportunity) {
      data.append("opportunity." + key, opportunity[key]);
    }

    let countQuestion = 0;
    for (const q in questionsData) {
      data.append("opportunity.OpportunityQuestions[" + countQuestion + "]", formData[questionsData[q].name]);
      countQuestion++;
    }

    //Append documents
    let countDoc = 0;
    documentTypes?.forEach((dt) => {
      if (
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()] &&
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()][0]
      ) {
        data.append("opportunity.OpportunityDocuments[" + countDoc + "].documentTypeId", dt.code);
        data.append("opportunity.OpportunityDocuments[" + countDoc + "].documentId", countDoc);
        data.append("OpportunityDocuments", formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.code.toString()][0]);
        countDoc++;
      }
    });

    formData.requiredLanguages?.forEach((element) => {
      data.append("opportunity.RequiredLanguagesIds", element.code);
    });

    return data;
  }
  return formData;
}

export function updateOpportunityStateMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      opportunityId: formData.id,
      actionType: formData.actionType,
      opportunityStateMotiveKey: formData.opportunityStateMotiveKey,
      observations: formData.observations,
    };
  }
  return formData;
}

export function getFilteredOpportunitiesMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      searchText: formData.searchText,
      reference: formData.reference,
      provinceId: formData.province?.code,
      opportunityTypeId: formData.opportunityType?.code,
      opportunityStateId: formData.opportunityState?.code,
      opportunityProfessionalCategoryId: formData.opportunityProfessionalCategory?.code,
      taxIdNumber: formData.taxIdNumber,
      companyName: formData.companyName,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE,
    };
  }
  return formData;
}

export function getFilteredPossibleOpportunityCandidatesMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      opportunityId: formData.opportunityId,
      userNameOrIdentityCard: formData.userNameOrIdentityCard,
      provinceId: formData.province?.code,
      municipalityId: formData.municipality?.code,
      academicLevelId: formData.academicLevel?.code,
      professionalCategoryId: formData.professionalCategory?.code,
      studiedLanguagesId: formData.studiedLanguages?.code,
      isAvailableForOpportunities: true,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE_MODAL_RESULT_TABLE,
    };
  }
  return formData;
}

export function updateOpportunityFollowupPeriodMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      opportunityCandidateId: formData.candidateCode,
      newStartDate:
        formData.followUpStartDate !== "" && formData.followUpStartDate
          ? formData.followUpStartDate.format("YYYY-MM-DD")
          : "",
      newEndDate:
        formData.followUpEndDate !== "" && formData.followUpEndDate
          ? formData.followUpEndDate.format("YYYY-MM-DD")
          : "",
    };
  }
  return formData;
}

export function updateFeedbackReportMap(formData, pageInfo) {
  if (formData !== null && formData !== undefined) {
    const answers = [];

    pageInfo?.questions?.forEach((question) =>
      question?.questionInformation?.forEach((questionInformation) =>
        answers.push({
          questionId: questionInformation?.questionId?.replace(FORM_FIELDS_PREFIX.FOLLOWUP_FEEDBACK_REPORT_DATA, ""),
          answer: String(formData[questionInformation?.questionId]),
        })
      )
    );

    return {
      feedbackReportId: formData.feedbackReportId,
      feedbackReportCompletedHours: !formData.feedbackReportCompletedHours
        ? null
        : formData.feedbackReportCompletedHours,
      feedbackReportCompletedHoursJustification: formData.feedbackReportCompletedHoursJustification,
      observations: formData.observations,
      questionsInformation: answers,
    };
  }
  return formData;
}

export default getFilteredOpportunitiesMap;
