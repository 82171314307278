import { useIntl } from "react-intl";

import BiographyPage from "components/GenericPage/BiographyPage";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { labels, titles } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function InstituteHolderBiography() {
  const intl = useIntl();

  const directorData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyPosition),
    photo: "/images/institute-holders/biography-photos/Manuel_Mbangui_Biography.png",
    name: "Manuel Mbangui",
    content: [
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyName_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyName)),
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyBirthdate_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyBirthdate)),
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyNationality_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyNationality)),
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyStudies_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyStudies)),
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualifications_Title)),
      firstBullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_1)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_2)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_3)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_4)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_5)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyQualification_6)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperiences_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_1)
      ),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_2)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_3)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_4)),
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyLanguages_Title)),
      firstBullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_1)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_2)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_3)),
    ],
  };

  const supervisionData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Position),
    name: "Tito de Jesus",
    content: [
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Name_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Name)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Birthdate_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Birthdate)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Nationality_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Nationality)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Studies_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Studies)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualifications_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_1)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_2)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_3)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_4)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_5)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_6)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_7)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_8)
      ),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperiences_Title
        )
      ),
      firstBullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_1
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_2
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_3
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_4
        )
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Languages_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Language_1)
      ),
    ],
  };

  const formationDirectorData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Position),
    name: "Eduarda Cassange",
    content: [
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Name_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Name)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Birthdate_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Birthdate)),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Nationality_Title
        )
      ),
      text(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Nationality)
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Studies_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Studies)),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualifications_Title
        )
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_1)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_2)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_3)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_4)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_5)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_6)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_7)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_8)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_9)
      ),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperiences_Title
        )
      ),
      firstBullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_1
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_2
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_3
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_4
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_5
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_6
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_7
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_8
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_9
        )
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Languages_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Language_1)
      ),
    ],
  };

  const inovationChiefData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Position),
    name: "Felisbino Narciso",
    content: [
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Name_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Name)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Birthdate_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Birthdate)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Nationality_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Nationality)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Studies_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Studies)),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualifications_Title
        )
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_1)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_2)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_3)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_4)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_5)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_6)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_7)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_8)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_9)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_10)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_11)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_12)
      ),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperiences_Title
        )
      ),
      firstBullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_1
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_2
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_3
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_4
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_5
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_6
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_7
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_8
        )
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Languages_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Language_1)
      ),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Language_2)),
    ],
  };

  const generalChiefData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Position),
    name: "Áurea Janeth Gaspar Marreta",
    content: [
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Name_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Name)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Birthdate_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Birthdate)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Nationality_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Nationality)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Studies_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Studies)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualifications_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_1)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_2)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_3)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_4)
      ),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperiences_Title
        )
      ),
      firstBullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_1
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_2
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_3
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_4
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_5
        )
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Languages_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Language_1)
      ),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Language_2)),
    ],
  };

  const jobDirectorData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Position),
    name: "Agostinho Pereira",
    content: [
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Name_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Name)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Birthdate_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Birthdate)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Nationality_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Nationality)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Studies_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Studies)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualifications_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_1)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_2)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_3)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_4)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_5)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_6)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_7)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_8)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_9)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_10)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_11)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_12)
      ),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperiences_Title
        )
      ),
      firstBullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_1
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_2
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_3
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_4
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_5
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_6
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_7
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_8
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_9
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_10
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_11
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_12
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_13
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_14
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_15
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_16
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_17
        )
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Languages_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Language_1)
      ),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Language_2)),
    ],
  };

  const modernChiefData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Position),
    name: "Américo Souto",
    content: [
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Name_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Name)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Birthdate_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Birthdate)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Nationality_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Nationality)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Studies_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Studies)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualifications_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_1)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_2)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_3)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_4)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_5)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_6)
      ),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperiences_Title
        )
      ),
      firstBullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_1
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_2
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_3
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_4
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_5
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_6
        )
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_1)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_2)
      ),
      bullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_3)
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Languages_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Language_1)
      ),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Language_2)),
    ],
  };

  const certChiefData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Position),
    name: "Belchior de Almeida",
    content: [
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Name_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Name)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Birthdate_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Birthdate)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Nationality_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Nationality)),
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Studies_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Studies)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualifications_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualification_1)
      ),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualification_2)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualification_3)),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperiences_Title
        )
      ),
      firstBullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_1
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_2
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_3
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_4
        )
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Languages_Title)
      ),
      firstBullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Language_1)),
    ],
  };

  const helpChiefData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Position),
    name: "Henriqueta de Assis",
    content: [
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Name_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Name)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Birthdate_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Birthdate)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Nationality_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Nationality)),
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Studies_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Studies)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualifications_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_1)
      ),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_2)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_3)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_4)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_5)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_6)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_7)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_8)),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperiences_Title
        )
      ),
      firstBullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperience_1
        )
      ),
      bullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperience_2
        )
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_OtherReferences_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_OtherReferences_1)
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Languages_Title)
      ),
      firstBullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Language_1)),
      bullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Language_2)),
    ],
  };

  const jobServiceManagerData = {
    title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Position),
    name: "Fragoso Carlos",
    content: [
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Name_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Name)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Birthdate_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Birthdate)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Nationality_Title)
      ),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Nationality)),
      subtitle(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Studies_Title)),
      text(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Studies)),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Qualifications_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Qualification_1)
      ),
      subtitle(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_ProfissionalExperiences_Title
        )
      ),
      firstBullet(
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_ProfissionalExperience_1
        )
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_OtherReferences_Title)
      ),
      firstBullet(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_OtherReferences_1)
      ),
      subtitle(
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Languages_Title)
      ),
      firstBullet(intl.formatMessage(labels.Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Language_1)),
    ],
  };

  const location = useLocation();

  const biographyLocation = location.pathname.split("/")[3];

  let data;
  switch (biographyLocation) {
    case "manuel-mbangui":
      data = directorData;
      break;
    case "agostinho-pereira":
      data = jobDirectorData;
      break;
    case "eduarda-cassange":
      data = formationDirectorData;
      break;
    case "henriqueta-de-assis":
      data = helpChiefData;
      break;
    case "aurea-janeth-gaspar-marreta":
      data = generalChiefData;
      break;
    case "americo-souto":
      data = modernChiefData;
      break;
    case "tito-de-jesus":
      data = supervisionData;
      break;
    case "felisbino-narciso":
      data = inovationChiefData;
      break;
    case "belchior-de-almeida":
      data = certChiefData;
      break;
    case "fragoso-carlos":
        data = jobServiceManagerData;
        break;
    default:
      data = null;
  }

  return (
    <BiographyPage
      title={intl.formatMessage(titles.Title_Menu_InstitutionalInformation_InstituteChiefs)}
      subtitle={intl.formatMessage(titles.Title_Menu_InstitutionalInformation_InstituteChiefs_Subtitle)}
      data={data}
    />
  );
}

const subtitle = (label) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
    subtitle: label,
  };
};

const bullet = (label) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
    description: label,
  };
};

const firstBullet = (label) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
    description: label,
  };
};

const text = (label) => {
  return label + "\n\n";
};

export default InstituteHolderBiography;
