import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { getStatisticalDataAction } from "redux/actions/statisticalDataAction";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function StatisticsDetails(props) {
  const intl = useIntl();
  const [statisticsData, setStatisticsData] = useState([]);

  useEffect(() => {
    if (!props.statisticalDataState?.getStatisticalData_result) {
      props.getStatisticalData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const statisticsByArea = separateStatisticsByArea(
      props.statisticalDataState?.getStatisticalData_result
    );
    const data = [];

    Object.keys(statisticsByArea).forEach((key) => {
      data.push({
        title: key,
        content: statisticsByArea[key].map((item) => {
          return item.description + ": " + item.metric?.toLocaleString("pt");
        }),
      });
    });

    data.push({
      title: "",
      content: [
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BUTTON,
          isDocument: true,
          path: "/DADOS_CICLO FORMATIVO -INEFOP 360.pdf", //TODO: Adicionar o link para o download
          title: intl.formatMessage(labels.Label_Download_Document),
        },
      ],
    });

    setStatisticsData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.statisticalDataState?.getStatisticalData_result]);

  const separateStatisticsByArea = (statsList) => {
    const statisticsGroups = [];

    statsList?.forEach((stat) => {
      if (!statisticsGroups[stat.statisticalDataAreaDescription]) {
        statisticsGroups[stat.statisticalDataAreaDescription] = [
          {
            description: stat.description,
            metric: stat.metric,
          },
        ];
      } else {
        statisticsGroups[stat.statisticalDataAreaDescription]?.push({
          description: stat.description,
          metric: stat.metric,
        });
      }
    });

    return statisticsGroups;
  };

  return (
    <InformationPage
      title={intl.formatMessage(titles.Title_Menu_Statistics)}
      data={statisticsData}
    />
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getStatisticalData: () => dispatch(getStatisticalDataAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsDetails);
