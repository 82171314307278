import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const CREATE_APPLICATION_URL = "applications/create";
export const GET_SUBPROGRAM_COMPLEMENTARY_DATA_URL = "SubPrograms/getcomplementarydata";
export const GET_MY_APPLICATIONS_URL = "applications/get-user-applications";
export const GET_SUBPROGRAM_INFORMATION_URL = "SubPrograms/get-subprogram";
export const GET_APPLICATION_DETAILS_GENERAL_DATA_URL =
  "Applications/get-application-details-general-data?applicationNumber=";
export const UPDATE_APPLICATION_DETAILS_GENERAL_DATA_URL = "Applications/update";

export const GET_APPLICATION_DOCUMENT_URL = "Applications/get-application-document-by-id?docId=";

export function getApplicationDetailsGeneralData(data) {
  return axios.get(EXTERNAL_API_URL + GET_APPLICATION_DETAILS_GENERAL_DATA_URL + data);
}

export function createApplication(application) {
  return axios.put(EXTERNAL_API_URL + CREATE_APPLICATION_URL, application);
}

export function getSubprogramComplementaryData(_subprogramId) {
  return axios.get(EXTERNAL_API_URL + GET_SUBPROGRAM_COMPLEMENTARY_DATA_URL, {
    params: {
      subProgramId: _subprogramId,
    },
  });
}

export function getSubprogramInformation(_subprogramId) {
  return axios.get(EXTERNAL_API_URL + GET_SUBPROGRAM_INFORMATION_URL, {
    params: {
      subProgramId: _subprogramId,
    },
  });
}

export function getMyApplications(data) {
  return axios.post(EXTERNAL_API_URL + GET_MY_APPLICATIONS_URL, data);
}

export function updateApplicationDetailGeneralData(data) {
  return axios.post(EXTERNAL_API_URL + UPDATE_APPLICATION_DETAILS_GENERAL_DATA_URL, data);
}

export function getApplicationDocument(data) {
  return axios.get(EXTERNAL_API_URL + GET_APPLICATION_DOCUMENT_URL + data, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
}
