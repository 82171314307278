import React from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
//style
import { makeStyles } from "@material-ui/core/styles";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function GenericButton(props) {
  const classes = { ...useStylesGeneric() };

  return (
    <>
      <Button
        data-testid={"generic-button-" + props.id}
        type={props.typeSubmit ? "submit" : "button"}
        size={props.size}
        variant="contained"
        color={
          props.color === "primary" || props.color === "secondary"
            ? props.color
            : "default"
        }
        className={clsx({
          [classes.containedTertiaryButton]: props.color === "tertiary",
          [classes.containedGhostButton]: props.color === "ghost",
          [classes.orangeButton]: props.color === "orange",
          [props.classes]: true,
        })}
        disabled={props.loading || props.disabled}
        style={props.style}
        onClick={props.onClick}
        component={props.component}
        to={props.to}
        target={props.target}
      >
        {props.children}
        {props.loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Button>
    </>
  );
}
