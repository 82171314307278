import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import { useIntl } from "react-intl";
// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormSelect from "components/FormFields/FormSelect";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

import SearchIcon from "@material-ui/icons/Search";
// src components
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import {
  getFilteredPossibleOpportunityCandidatesAction,
  addOpportunityCandidatesListAction,
} from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getFilteredPossibleOpportunityCandidatesMap } from "mappers/OpportunityMap.js";
import { PAGINATION, DATASET } from "utils/const";
import { ArrayUtils } from "utils/array_utils";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesGenericModal from "assets/theme/views/admin/generic-modal.js";
import { getDatasetStateByName } from "utils/datasets";
import GenericButton from "components/Buttons/GenericButton";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesGenericModal = makeStyles(componentStylesGenericModal);

const tableHead = [
  "Nome",
  "BI",
  "Local Trabalho Pretendido",
  "Província",
  "Município",
  "Áreas",
  "Nível Académico",
  "Linguagens Estudadas",
];

function AddCandidates(props) {
  const classes = { ...useStylesGeneric(), ...useStylesGenericModal() };
  const intl = useIntl();
  const methods = useForm({
    mode: "onChange",
  });

  const [filter, setFilter] = useState({
    opportunityId: props.opportunityDetailsData?.id,
    userNameOrIdentityCard: null,
    provinceId: props.opportunityDetailsData?.provinceId,
    municipalityId: props.opportunityDetailsData?.municipalityId,
    professionalCategoryId: props.opportunityDetailsData?.opportunityProfessionalCategories[0],
    isAvailableForOpportunities: true,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE_MODAL_RESULT_TABLE,
  });

  const [possibleCandidatesList, setPossibleCandidatesList] = useState([]);
  const [page, setPage] = useState(PAGINATION.FIRST_PAGE);
  const [candidatesListToAddToOpportunity, setCandidatesListToAddToOpportunity] = useState([]);
  const [clearBlockedFilters, setClearBlockedFilters] = useState(false);

  const clearFilter = () => {
    setClearBlockedFilters(true);

    methods.setValue("userNameOrIdentityCard", "");

    var provinceList = props.datasetsState[DATASET.PROVINCES] ? props.datasetsState[DATASET.PROVINCES] : [];
    var selectedProvince = getSelectOptionsElementByCode(provinceList, props.opportunityDetailsData?.provinceId);
    methods.setValue("province", selectedProvince);
    props.getDataSetByName(DATASET.MUNICIPALITIES, selectedProvince.keyValue);

    methods.setValue(
      "municipality",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.MUNICIPALITIES],
        props.opportunityDetailsData?.municipalityId
      )
    );

    methods.setValue(
      "professionalCategory",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.PROFESSIONAL_CATEGORY],
        props.opportunityDetailsData?.opportunityProfessionalCategories[0]
      )
    );
    methods.setValue("isAvailableForOpportunities", true);
  };

  useEffect(() => {
    if (methods.getValues("province") && clearBlockedFilters) {
      methods.setValue(
        "municipality",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.MUNICIPALITIES],
          props.opportunityDetailsData?.municipalityId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.getValues("province")]);

  useEffect(() => {
    var provinceList = props.datasetsState[DATASET.PROVINCES] ? props.datasetsState[DATASET.PROVINCES] : [];
    var selectedProvince = getSelectOptionsElementByCode(provinceList, props.opportunityDetailsData?.provinceId);
    methods.setValue("province", selectedProvince);
    props.getDataSetByName(DATASET.MUNICIPALITIES, selectedProvince.keyValue);

    var municipalitiesList = props.datasetsState[DATASET.MUNICIPALITIES]
      ? props.datasetsState[DATASET.MUNICIPALITIES]
      : [];
    var selectedMunicipality = getSelectOptionsElementByCode(
      municipalitiesList,
      props.opportunityDetailsData?.municipalityId
    );
    methods.setValue("municipality", selectedMunicipality);

    var professionalCategoryList = props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]
      ? props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]
      : [];
    methods.setValue(
      "professionalCategory",
      getSelectOptionsElementByCode(
        professionalCategoryList,
        props.opportunityDetailsData?.opportunityProfessionalCategories[0]
      )
    );

    props.getFilteredPossibleOpportunityCandidates(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.opportunitiesState.possibleCandidates_result)) {
      setPossibleCandidatesList(props.opportunitiesState.possibleCandidates_result.data);
    } else {
      setPossibleCandidatesList([]);
    }
  }, [props.opportunitiesState.possibleCandidates_result]);

  const onSubmitSearch = (data) => {
    data = {
      ...data,
      opportunityId: props.opportunityDetailsData?.id,
    };
    let filter = getFilteredPossibleOpportunityCandidatesMap(data);
    setFilter(filter);
    if (page === PAGINATION.FIRST_PAGE) {
      props.getFilteredPossibleOpportunityCandidates(filter);
    } else {
      handleChangePage(undefined, PAGINATION.FIRST_PAGE);
    }
  };

  const handleChangePage = (event, value) => {
    if (page !== value) {
      setPage(value);
      let temp_filter = { ...filter, pageIndex: value };
      setFilter(temp_filter);
      props.getFilteredPossibleOpportunityCandidates(temp_filter);
    }
  };

  const checkIfCandidateInOpportunityList = (userID) => {
    if (candidatesListToAddToOpportunity.includes(userID)) {
      return true;
    }
    return false;
  };

  const addCandidateToOpportunityList = (userID) => {
    setCandidatesListToAddToOpportunity((prevState) => {
      if (checkIfCandidateInOpportunityList(userID)) {
        return [...prevState];
      }
      return [...prevState, userID];
    });
  };

  const removeCandidateFromOpportunityList = (userID) => {
    setCandidatesListToAddToOpportunity((prevState) => {
      let elements = prevState.filter((element) => element !== userID);

      return elements;
    });
  };

  let handleCandidateInOpportunityList = (userID, userState) => {
    if (userState) {
      addCandidateToOpportunityList(userID);
    } else {
      removeCandidateFromOpportunityList(userID);
    }
  };

  const checkIfAllCandidatesChecked = (userIDList) => {
    const getListOfStates = userIDList?.map((prop) => {
      if (!checkIfCandidateInOpportunityList(prop.userId)) {
        return false;
      }
      return true;
    });

    if (getListOfStates.includes(false)) {
      return false;
    }
    return true;
  };

  const handleAddOpportunityCandidatesList = () => {
    const data = {
      opportunityId: props.opportunityDetailsData?.id,
      usersId: candidatesListToAddToOpportunity,
    };

    props.addOpportunityCandidatesList(data, () => {
      props.handleClose();
      props.onSuccess();
    });
  };

  return (
    <div style={{ margin: "0px 30px" }}>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            title: classes.cardHeader,
          }}
          title={"Adicionar Candidatos"}
        ></CardHeader>
        <CardContent>
          <form onSubmit={methods.handleSubmit(onSubmitSearch)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormLabel>Nome ou BI</FormLabel>
                  <Controller
                    name="userNameOrIdentityCard"
                    control={methods.control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: methods.formState.errors["userNameOrIdentityCard"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="province"
                    selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
                    loadChildrenDataSet={props.getDataSetByName}
                    childrenDataSet={DATASET.MUNICIPALITIES}
                    childrenSelect="municipality"
                    getValues={methods.getValues}
                    setValue={methods.setValue}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormLabel>Município</FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="municipality"
                    selectOptions={getDatasetStateByName(props, DATASET.MUNICIPALITIES)}
                    disabled={!methods.getValues("province")}
                    getValues={methods.getValues}
                    setValue={methods.setValue}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormLabel>Área Profissional</FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="professionalCategory"
                    selectOptions={getDatasetStateByName(props, DATASET.PROFESSIONAL_CATEGORY)}
                    getValues={methods.getValues}
                    setValue={methods.setValue}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormLabel>Nível Académico</FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="academicLevel"
                    selectOptions={getDatasetStateByName(props, DATASET.ACADEMIC_LEVEL)}
                    getValues={methods.getValues}
                    setValue={methods.setValue}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormGroup>
                  <FormLabel>Línguas Estudadas</FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="studiedLanguages"
                    selectOptions={getDatasetStateByName(props, DATASET.LANGUAGES)}
                    getValues={methods.getValues}
                    setValue={methods.setValue}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  Limpar Filtros
                </GenericButton>

                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.opportunitiesState.possibleCandidates_loading}
                >
                  Pesquisar
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      {/* Possible candidates table result */}
      <div style={{ marginTop: "30px" }}>
        <CardTableContainer title={"Candidatos"} count={possibleCandidatesList.length}>
          {props.opportunitiesState.possibleCandidates_loading ||
          (possibleCandidatesList !== undefined && possibleCandidatesList.length > 0) ? (
            <>
              <TableContainer>
                <Box component={Table} alignItems="center" marginBottom="0!important">
                  <TableHead>
                    <TableRow>
                      {tableHead.map((prop, key) => (
                        <TableCell
                          key={key}
                          classes={{
                            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                          }}
                        >
                          {prop}
                        </TableCell>
                      ))}
                      <TableCell
                        key="selectCol"
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead +
                            " " +
                            classes.tableCellHeadStickyRight,
                        }}
                        styles="width: '200px'"
                      >
                        <Grid container>
                          <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                            <>
                              <FormGroup className={classes.boxNameCategory}>
                                <FormControlLabel
                                  onChange={(e) => {
                                    possibleCandidatesList?.map((prop) =>
                                      handleCandidateInOpportunityList(prop.userId, e.target.checked)
                                    );
                                  }}
                                  value="end"
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={checkIfAllCandidatesChecked(possibleCandidatesList)}
                                    />
                                  }
                                  label={
                                    <Box component="div" className={classes.formControlLabelCheckboxInTableHeader}>
                                      Seleccionar
                                    </Box>
                                  }
                                  labelPlacement="start"
                                  className={classes.formControlLabel}
                                />
                              </FormGroup>
                            </>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {possibleCandidatesList?.map((prop, key) => (
                      <TableRow key={key}>
                        <TableCell
                          classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                          title={prop.userName}
                        >
                          {prop.userName}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>{prop.userIdentityCard}</TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {prop.intendedWorkplaceProvince}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>{prop.province}</TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>{prop.municipality}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                          title={prop.professionalCategories?.join(", ") ?? ""}
                        >
                          {prop.professionalCategories?.join(", ") ?? ""}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>{prop.academicLevel}</TableCell>
                        <TableCell
                          classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                          title={prop.studiedLanguages?.join(", ") ?? ""}
                        >
                          {prop.studiedLanguages?.join(", ") ?? ""}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                          <Box alignItems="center" justifyContent="center" display="flex">
                            <FormGroup className={classes.boxNameCategory}>
                              <FormControlLabel
                                onChange={(e) => {
                                  handleCandidateInOpportunityList(prop.userId, e.target.checked);
                                }}
                                control={
                                  <Checkbox color="primary" checked={checkIfCandidateInOpportunityList(prop.userId)} />
                                }
                                style={{ margin: 0 }}
                              />
                            </FormGroup>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Box>
                <TableBackdrop open={props.opportunitiesState.possibleCandidates_loading} />
              </TableContainer>

              <Pagination
                className={classes.alignPagination}
                count={
                  props.opportunitiesState.possibleCandidates_loading
                    ? 1
                    : props.opportunitiesState?.possibleCandidates_total_pages
                }
                page={page}
                onChange={handleChangePage}
                color="primary"
              ></Pagination>
            </>
          ) : (
            <Box classes={{ root: classes.noResultsInfo }} style={{ marginTop: "20px" }}>
              {intl.formatMessage(labels.Label_No_Results_Found)}
            </Box>
          )}
        </CardTableContainer>
      </div>

      <Grid container style={{ marginTop: "30px" }}>
        <Grid item xs={12} sm={4} md={6}></Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormGroup>
            <GenericButton typeSubmit={false} color="secondary" onClick={props.handleClose}>
              Cancelar
            </GenericButton>
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormGroup>
            <GenericButton
              typeSubmit={true}
              color="primary"
              onClick={handleAddOpportunityCandidatesList}
              disabled={
                props.opportunitiesState.addOpportunityCandidatesList_loading ||
                candidatesListToAddToOpportunity.length === 0
              }
              loading={props.opportunitiesState.addOpportunityCandidatesList_loading}
            >
              Adicionar
            </GenericButton>
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getFilteredPossibleOpportunityCandidates: (data) => dispatch(getFilteredPossibleOpportunityCandidatesAction(data)),
  addOpportunityCandidatesList: (data, onSuccess) => dispatch(addOpportunityCandidatesListAction(data, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCandidates);
