import React from "react";
import { connect } from "react-redux";
import { useIntl } from 'react-intl';

//material
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
//icons
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

import componentStyles from "assets/theme/components/dialogs/generic-dialog.js";

//custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import { messages, labels } from "resources/resources"
import { USER_TYPE } from "utils/const"

const useStyles = makeStyles(componentStyles);

export function RegisterDialog(props) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <GenericDialog open={props.open} onClose={props.onClose}>
      <Grid className={classes.dialogContent}>
        {props.authState.registrationResult ? (
          <>
            <CheckCircleOutlinedIcon
              classes={{ root: classes.modalResultIcon }}
            />
            <Typography classes={{ root: classes.modalResultHeader }}>
              <div>{intl.formatMessage(messages.Message_Auth_Register_Success)}</div>
              <div>
                {props.authState?.register_userType === USER_TYPE.INDIVIDUAL && (
                  intl.formatMessage(messages.Message_Auth_Register_Success_IndividualUser)
                )}
                {props.authState?.register_userType === USER_TYPE.COLLECTIVE && (
                  intl.formatMessage(messages.Message_Auth_Register_Success_CollectiveUser)
                )}
              </div>
            </Typography>
          </>
        ) : (
          <>
            <CancelOutlinedIcon classes={{ root: classes.modalResultIcon }} />
            <Typography classes={{ root: classes.modalResultHeader }}>
              {intl.formatMessage(messages.Message_Auth_Register_Error)}
            </Typography>
          </>
        )}
      </Grid>
      {props.authState.registrationResult && (
        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
          <GenericButton
            typeSubmit={false}
            color="primary"
            onClick={props.onClick}
          >
            {intl.formatMessage(labels.Label_General_Enter_Button)}
          </GenericButton>
        </Box>
      )}
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps, null)(RegisterDialog);
