import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTheme } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import { clearDataSetByNameAction } from "redux/actions/datasetsAction";

function FormSelect({
  control,
  fieldName,
  selectOptions,
  initialLabel,
  getValues,
  setValue,
  required,
  requiredMessage,
  loadChildrenDataSet,
  childrenDataSet,
  childrenSelect,
  grandchildrenSelect,
  childrenList,
  classes,
  disabled,
  onOptionChange,
  trigger,
  handleSetFilter,
  filterListKey,
  ...props
}) {
  const theme = useTheme();

  const fieldVal = (name) => {
    const value = getValues(name);
    return value ? value : null;
  };

  return (
    <Controller
      render={({ field: { onChange, name }, fieldState: { error } }) => (
        <Autocomplete
          noOptionsText={"Sem opções"}
          name={name}
          options={selectOptions ? selectOptions : []}
          value={fieldVal(name)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.code === value.code}
          disabled={disabled !== undefined ? disabled : false}
          data-testid={"form-select-" + name}
          classes={{
            inputRoot: clsx({
              [classes.selectBorderWarningInputRoot]: !!error,
            }),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={!initialLabel ? "Seleccione opção" : initialLabel}
              variant="outlined"
              InputLabelProps={{
                style: {
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: theme.palette.gray[600],
                },
              }}
            />
          )}
          onChange={(_, data) => {
            onChange(data);
            if (loadChildrenDataSet !== undefined) {
              if (data != null) loadChildrenDataSet(childrenDataSet, data.keyValue ?? data.code, true);
              else props.clearDataSetByName(childrenDataSet);
            }
            if (onOptionChange !== undefined) {
              onOptionChange(data);
            }
            if (childrenDataSet !== undefined) {
              setValue(childrenSelect, null);
              if (trigger !== undefined) trigger(childrenSelect);
            }
            if (handleSetFilter) {
              handleSetFilter(filterListKey, data ? data.code : null, [childrenSelect, grandchildrenSelect]);
            }

            if (childrenList?.length > 0 && loadChildrenDataSet !== undefined) {
              childrenList?.forEach((child) => {
                if (data != null) loadChildrenDataSet(child.dataset, data.keyValue ?? data.code, true);
                else props.clearDataSetByName(child.dataset);

                if (child.dataset !== undefined) {
                  setValue(child.name, null);
                  if (trigger !== undefined) trigger(child.name);
                }
                if (handleSetFilter) {
                  handleSetFilter(filterListKey, data ? data.code : null, [child.name]);
                }
              });
            }
          }}
        />
      )}
      name={fieldName}
      rules={{
        required: {
          value: required,
          message: requiredMessage,
        },
      }}
      control={control}
    />
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  clearDataSetByName: (name) => dispatch(clearDataSetByNameAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSelect);
