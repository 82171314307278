import { INFORMATION_DISSEMINATION_TYPE_KEYVALUE } from "utils/const";
import themeColors from "assets/theme/colors";

export const getInformationTypeChipColor = (informationType) => {
    switch (informationType) {
      case INFORMATION_DISSEMINATION_TYPE_KEYVALUE.NEWS:
        return themeColors.secondary.skyBlue;
      case INFORMATION_DISSEMINATION_TYPE_KEYVALUE.LEGISLATION:
        return themeColors.primary.mediumBlue;
      case INFORMATION_DISSEMINATION_TYPE_KEYVALUE.SCHEDULE:
        return themeColors.primary.darkBlue;
      case INFORMATION_DISSEMINATION_TYPE_KEYVALUE.INFORMATION:
        return themeColors.primary.main;
      case INFORMATION_DISSEMINATION_TYPE_KEYVALUE.STRUCTURING_PROJECT:
        return themeColors.orange[300];
      default:
        return themeColors.primary.main;
    }
  }