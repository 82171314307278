import { logout } from "../../../crud/auth.crud";
import { createCookie } from "utils/auth"
import { AUTH_COOKIE_NAME } from "utils/const";
import { AUTH } from "utils/actionsConsts";

const logoutAction = (email) => async (dispatch) => {
  logout(email)
    .then((res) => {
      dispatch({
        type: AUTH.LOGOUT,
        payload: res.data.succeeded,
      });
      //Remove cookie on logout
      createCookie(AUTH_COOKIE_NAME, "", -1);
    })
    .catch((error) => {
      dispatch({
        type: AUTH.LOGOUT,
        payload: false,
      });
      //Remove cookie on logout
      createCookie(AUTH_COOKIE_NAME, "", -1);
      console.log("error", error);
    });
};

export default logoutAction;
