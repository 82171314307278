const componentStyles = (theme) => ({ 
  buttonLabel: {
    fontSize: ".875rem",
    fontWeight: "600",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.greyBlue,
    [theme.breakpoints.down("md")]: {
      padding: "0!important",
    },
  },
  listItemRoot: {
    padding: "1rem",
    borderBottom: "1px solid " + theme.palette.gray[200],
    textDecoration: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
      "& .MuiAvatar-root": {
        backgroundColor: theme.palette.white.main
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.greyBlue
      },
    },
  },
  boxAnchor: {
    textDecoration: "none",
    padding: ".5rem 1rem",
    fontSize: ".875rem",
    fontWeight: "600",
    textAlign: "center",
    paddingBottom: "1rem",
    paddingTop: "1rem",
    display: "block",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
    },
  },
  menuList: {
    padding: "0!important",
  },
  menuPaper: {
    padding: "0!important",
    minWidth: "420px",
  },
  typographyH6: {
    fontSize: ".875rem!important",
    margin: "0!important",
    color: theme.palette.gray[600],
  },
  typographyH4: {
    fontSize: ".875rem!important",
    margin: "0!important",
    color: "inherit"
  },
  notificationsLargeBell: {
    width: "34px",
    height: "34px",
  },
  notificationBadge:{
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    right: "-2px",
    minWidth: "22px",
    height: "22px",
    borderRadius: "44px"
  },
});

export default componentStyles;
