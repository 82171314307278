import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import componentStylesGeneric from "assets/theme/views/admin/generic";

import { getOpportunityApplicationsFollowUpDetailsAction } from "redux/actions/opportunitiesAction";

import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import FollowUpDetailsTab from "./Tabs/FollowUpDetailsTab";
import FollowUpHistoryTab from "./Tabs/FollowUpHistoryTab";
import FollowUpFeedbackReportTab from "./Tabs/FollowUpFeedbackReportsTab";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityApplicationFollowUpDetails(props) {
  const classes = useStylesGeneric();
  const [tabStep, setTabStep] = useState("detailsTab");
  const [refreshPage, setRefreshPage] = useState(false);
 
  useEffect(() => {
    props.getOpportunityApplicationsFollowUpDetails(props.match?.params?.candidateCode);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);
    }
  };

  const handleRefreshPage = () => {
    setRefreshPage(true);
  };

  useEffect(() => {
    refreshResults();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const refreshResults = () => {
    props.getOpportunityApplicationsFollowUpDetails(props.match?.params?.candidateCode);
    setRefreshPage(false);
  };

  function loadTabContent() {
    switch (tabStep) {
        case "detailsTab":
            return (
                <FollowUpDetailsTab
                    opportunityApplicationFollowUpDetails={props.opportunitiesState.opportunityApplicationsFollowUpDetails_result}
                    handleRefreshPage={handleRefreshPage}
                />
            );
        case "historyTab":
            return (
                <FollowUpHistoryTab
                    opportunityApplicationFollowUpDetails={props.opportunitiesState.opportunityApplicationsFollowUpDetails_result}
                />
            );
        case "formsTab":
            return (
                <FollowUpFeedbackReportTab
                    opportunityApplicationFollowUpDetails={props.opportunitiesState.opportunityApplicationsFollowUpDetails_result}
                    handleRefreshPage={handleRefreshPage}
                />
            );
        default:
            return (
                <FollowUpDetailsTab
                    opportunityApplicationFollowUpDetails={props.opportunitiesState.opportunityApplicationsFollowUpDetails_result}
                    handleRefreshPage={handleRefreshPage}
                />
            );
    }
  }

  return (
    <>
      <GenericBackdrop open={props.opportunitiesState.getOpportunityApplicationsFollowUpDetails_loading} />

      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
        <LocationHeader
          subsection={"Lista de Acompanhamentos"}
          section={"Detalhes do Estágio"}
          subsectionLink={"/admin/my-followups/"}
        />

        <PageTitleHeader title={props.opportunitiesState.opportunityApplicationsFollowUpDetails_result?.opportunityTitle} />

        <Box component={Grid} container justifyContent="center">
          <ToggleButtonGroup
            value={tabStep}
            exclusive
            onChange={handleTabStep}
            aria-label="tab Step"
            className={classes.changeTabsGroup}
            style={{ marginBottom: "25px" }}
          >
            <ToggleButton value="detailsTab" className={classes.changeTabs}>
                Dados Gerais
            </ToggleButton>
            <ToggleButton value="historyTab" className={classes.changeTabs}>
                Histórico
            </ToggleButton>
            <ToggleButton value="formsTab" className={classes.changeTabs}>
                Formulários
            </ToggleButton>
          </ToggleButtonGroup>

          <Grid item xs={12} lg={12} md={12}>
            {loadTabContent()}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getOpportunityApplicationsFollowUpDetails: (opportunityCandidateId) => dispatch(getOpportunityApplicationsFollowUpDetailsAction(opportunityCandidateId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityApplicationFollowUpDetails);