import { Box, Container, FormGroup, FormHelperText, Grid, InputAdornment, useTheme } from "@material-ui/core";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { labels, errorMessages } from "resources/resources";
import GenericButton from "components/Buttons/GenericButton";
import FormSelect from "components/FormFields/FormSelect";
import { getDatasetStateByName } from "utils/datasets";
import { useDispatch, useSelector } from "react-redux";
import { DATASET } from "utils/const";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FormDatePicker from "components/Forms/FormDatePicker";
import { UNITS } from "utils/const";
import { getDatasetDataByCode } from "utils/datasets";
import { createUserEmploymentHistoryAction } from "redux/actions/auth/userAction";
import { updateUserEmploymentHistoryAction } from "redux/actions/auth/userAction";
import FormInput from "components/FormFields/FormInput";
import getDataSetByNameAction from "redux/actions/datasetsAction";
import useTokenHelper from "hooks/useTokenHelper";
import formatDate from "utils/date_utils";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function AddEmploymentHistoryModal({ data, editing = false, onCloseModal, onSuccess }) {
  const intl = useIntl();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStylesGeneric();
  const theme = useTheme();
  const tokenHelper = useTokenHelper();

  const defaultValues = useMemo(() => {
    if (editing) {
      const { datasetsState } = state;

      return {
        ...data,
        professionalCategory: getDatasetDataByCode(
          datasetsState[DATASET.PROFESSIONAL_CATEGORY],
          data.professionalCategoryId
        ),
        opportunityType: getDatasetDataByCode(datasetsState[DATASET.OPPORTUNITY_TYPE], data.opportunityTypeId),
        workedSince: moment(data.workedSince),
        workedUntil: data.workedUntil ? moment(data.workedUntil) : "",
      };
    }

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const isInefopOpportunity = useMemo(
    () => editing && !!data.opportunityReference,
    [data.opportunityReference, editing]
  );

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const title = useMemo(() => {
    const titleSufix = intl.formatMessage(
      labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentRecord
    );

    let formatedTitle = "";

    if (editing) {
      formatedTitle = `${intl.formatMessage(
        labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Edit
      )} ${titleSufix}`;
    } else {
      formatedTitle = `${intl.formatMessage(
        labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Add
      )} ${titleSufix}`;
    }

    return formatedTitle;
  }, [editing, intl]);

  async function onSubmit(data) {
    const dataToSubmit = {
      ...data,
      FO_UserSingularId: tokenHelper.getUserId(),
      professionalCategoryId: data.professionalCategory.code,
      opportunityTypeId: data.opportunityType.code,
      workedSince: formatDate(data.workedSince),
      workedUntil: formatDate(data.workedUntil),
    };

    if (!editing) {
      await dispatch(
        createUserEmploymentHistoryAction(dataToSubmit, () => {
          onCloseModal();
          onSuccess();
        })
      );
    } else {
      await dispatch(
        updateUserEmploymentHistoryAction(dataToSubmit, () => {
          onCloseModal();
          onSuccess();
        })
      );
    }
  }

  useEffect(() => {
    if (getDatasetStateByName(state, DATASET.OPPORTUNITY_TYPE).length !== 0)
      setValue(
        "opportunityType",
        getDatasetDataByCode(state.datasetsState[DATASET.OPPORTUNITY_TYPE], data.opportunityTypeId)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.datasetsState[DATASET.OPPORTUNITY_TYPE]]);

  useEffect(() => {
    if (getDatasetStateByName(state, DATASET.PROFESSIONAL_CATEGORY).length === 0)
      dispatch(getDataSetByNameAction(DATASET.PROFESSIONAL_CATEGORY));

    if (getDatasetStateByName(state, DATASET.OPPORTUNITY_TYPE).length === 0)
      dispatch(getDataSetByNameAction(DATASET.OPPORTUNITY_TYPE));
  }, [dispatch, state]);

  return (
    <Container>
      <Box>
        <h2 style={{ textAlign: "center" }}>{title}</h2>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "20px" }}>
          <FormInput
            name="opportunityReference"
            control={control}
            label={intl.formatMessage(
              labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityNumber
            )}
            disabled
          />
          <Grid container>
            <Grid item xs={12} md={8}>
              <FormInput
                name="companyName"
                control={control}
                label={intl.formatMessage(
                  labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Company
                )}
                disabled={isSubmitting || isInefopOpportunity}
                isRequired
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                name="salary"
                control={control}
                label={intl.formatMessage(
                  labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Salary
                )}
                isRequired
                disabled={isSubmitting || isInefopOpportunity}
                endAdornment={
                  <InputAdornment disableTypography={true} position="end">
                    {UNITS.MONETARY}
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormSelect
                  fieldName="professionalCategory"
                  control={control}
                  initialLabel={intl.formatMessage(
                    labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Profession
                  )}
                  selectOptions={getDatasetStateByName(state, DATASET.PROFESSIONAL_CATEGORY)}
                  getValues={getValues}
                  setValue={setValue}
                  classes={classes}
                  disabled={isSubmitting || isInefopOpportunity}
                  required
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                />

                <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                  {errors?.professionalCategory?.message}
                </FormHelperText>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormSelect
                  fieldName="opportunityType"
                  control={control}
                  initialLabel={intl.formatMessage(
                    labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityType
                  )}
                  selectOptions={getDatasetStateByName(state, DATASET.OPPORTUNITY_TYPE)}
                  getValues={getValues}
                  setValue={setValue}
                  classes={classes}
                  disabled={isSubmitting || isInefopOpportunity}
                  required
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                />

                <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                  {errors?.opportunityType?.message}
                </FormHelperText>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                name="workedSince"
                label={intl.formatMessage(
                  labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_StartDate
                )}
                control={control}
                getValues={getValues}
                trigger={trigger}
                endDateFieldName="workedUntil"
                maxDateToday
                isRequired
                disabled={isSubmitting || isInefopOpportunity}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                name="workedUntil"
                control={control}
                errors={errors}
                label={intl.formatMessage(
                  labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EndDate
                )}
                getValues={getValues}
                trigger={trigger}
                startDateFieldName="workedSince"
                maxDateToday
                disabled={isSubmitting || isInefopOpportunity}
              />
            </Grid>
          </Grid>
          <FormInput
            name="observations"
            control={control}
            label={intl.formatMessage(
              labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Observations
            )}
            multiline
            rows={3}
            disabled={isSubmitting}
          />
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <GenericButton typeSubmit={false} color="secondary" onClick={onCloseModal}>
                  {intl.formatMessage(labels.Label_Button_Cancel)}
                </GenericButton>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <GenericButton typeSubmit color="primary" loading={isSubmitting}>
                  {intl.formatMessage(labels.Label_Button_Submit)}
                </GenericButton>
              </FormGroup>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}

AddEmploymentHistoryModal.propTypes = {
  data: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
};
