import { AUTH } from "utils/actionsConsts";

const resetSessionExpiredAction = () => async (dispatch) => {
  dispatch({
    type: AUTH.RESET_SESSION_EXPIRED,
    payload: false,
  });
};

export default resetSessionExpiredAction;
