import {
  createApplication,
  getSubprogramComplementaryData,
  getMyApplications,
  getSubprogramInformation,
  getApplicationDetailsGeneralData,
  updateApplicationDetailGeneralData,
  getApplicationDocument,
} from "crud/applications.crud";

import { APPLICATIONS_ACTIONS, DIALOG } from "utils/actionsConsts";
import { convertByteArrayToBlobUrl } from "utils/converters";
import moment from "moment";

export const createApplicationAction = (data) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS_ACTIONS.GET_SUBMIT_LOADING,
    payload: true,
  });
  createApplication(data)
    .then((res) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.CREATE,
        payload: true,
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_SUBMIT_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: true,
            goBack: true,
            buttonLabel: "ACOMPANHAR CANDIDATURA",
            onClose: "/admin/my-applications/government-program-application",
            message: "O seu pedido de candidatura foi enviado com sucesso. Nº de Candidatura - " + res.data,
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: APPLICATIONS_ACTIONS.CREATE,
        payload: false,
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_SUBMIT_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
    });
};

export const getSubprogramComplementaryDataAction = (subprogramId) => async (dispatch) => {
  getSubprogramComplementaryData(subprogramId)
    .then((res) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_COMPLEMENTARY_DATA,
        payload: {
          complementaryData: res.data,
          subprogramId: subprogramId,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_COMPLEMENTARY_DATA,
        payload: {
          complementaryData: [],
          subprogramId: subprogramId,
        },
      });
      console.log("error", error);
    });
};

export const getSubprogramInformationAction = (subprogramId) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS_ACTIONS.GET_SUBPROGRAM_INFORMATION_LOADING,
    payload: true,
  });
  getSubprogramInformation(subprogramId)
    .then((res) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_SUBPROGRAM_INFORMATION,
        payload: {
          info: res.data,
          subprogramId: subprogramId,
        },
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_SUBPROGRAM_INFORMATION_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_SUBPROGRAM_INFORMATION,
        payload: {
          info: undefined,
          subprogramId: subprogramId,
        },
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_SUBPROGRAM_INFORMATION_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};
export const getMyApplicationsAction = (data) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS_ACTIONS.GET_USER_APPLICATIONS_LOADING,
    payload: true,
  });
  getMyApplications(data)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.applicationCode,
          governmentProgram: record.governmentProgramName,
          program: record.programName,
          subprogram: record.subProgramName,
          applicationState: record.applicationState,
          state: record.state,
        };
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_MY_APPLICATIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_USER_APPLICATIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_USER_APPLICATIONS_LOADING,
        payload: false,
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_MY_APPLICATIONS,
        result: false,
        payload: [],
      });

      console.log("error", error);
    });
};

export const getApplicationDetailsGeneralDataAction = (formData) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS_ACTIONS.GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING,
    payload: true,
  });
  dispatch({
    type: APPLICATIONS_ACTIONS.GET_APPLICATION_DETAILS_GENERAL_DATA,
    payload: {},
  });
  getApplicationDetailsGeneralData(formData)
    .then((res) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_APPLICATION_DETAILS_GENERAL_DATA,
        result: true,
        payload: {
          applicationNumber: res.data.applicationNumber,
          applicationStateId: res.data.applicationStateId,
          applicationStateHistory: res.data.applicationStateHistory,

          userSingularGeneralDataSection:
            res.data.userSingularGeneralDataSection != null
              ? {
                  nameOfUser: res.data.userSingularGeneralDataSection.nameOfUser,
                  userId: res.data.userSingularGeneralDataSection.userId,
                  numBI: res.data.userSingularGeneralDataSection.numBI,
                  gender: res.data.userSingularGeneralDataSection.gender,
                  maritalStatus: res.data.userSingularGeneralDataSection.maritalStatus,
                  otherApplicationsOfUser: res.data.userSingularGeneralDataSection.otherApplicationsOfUser,
                  age: res.data.userSingularGeneralDataSection.age,
                  mobilePhone: res.data.userSingularGeneralDataSection.mobilePhone,
                  birthDate: res.data.userSingularGeneralDataSection.birthDate
                    ? moment(res.data.userSingularGeneralDataSection.birthDate).format("DD/MM/YYYY")
                    : "",
                  specialNeeds: res.data.userSingularGeneralDataSection.specialNeeds,
                  specialNeedsPercentage: res.data.userSingularGeneralDataSection.specialNeedsPercentage,
                }
              : null,
          userColectiveGeneralDataSection:
            res.data.userColectiveGeneralDataSection !== null
              ? {
                  userId: res.data.userColectiveGeneralDataSection.userId,
                  userCode: res.data.userColectiveGeneralDataSection.userCode,
                  otherApplicationsOfUser: res.data.userColectiveGeneralDataSection.otherApplicationsOfUser,
                  responsibleName: res.data.userColectiveGeneralDataSection.responsibleName,
                  responsibeMobilePhone: res.data.userColectiveGeneralDataSection.responsibeMobilePhone,
                  responsibleEmail: res.data.userColectiveGeneralDataSection.responsibleEmail,
                  companyName: res.data.userColectiveGeneralDataSection.companyName,
                  companyTaxIdNumber: res.data.userColectiveGeneralDataSection.companyTaxIdNumber,
                  companyPhone: res.data.userColectiveGeneralDataSection.companyPhone,
                  companyEmail: res.data.userColectiveGeneralDataSection.companyEmail,
                }
              : null,

          provinceName: res.data.programSubprogramDataSection.provinceName,
          provinceId: res.data.programSubprogramDataSection.provinceId,
          provinceExternalId: res.data.programSubprogramDataSection.provinceExternalId,

          governmentProgramId: res.data.programSubprogramDataSection.governmentProgramId,
          governmentProgramName: res.data.programSubprogramDataSection.governmentProgramName,
          programId: res.data.programSubprogramDataSection.programId,
          programName: res.data.programSubprogramDataSection.programName,
          subprogramId: res.data.programSubprogramDataSection.subprogramId,
          subprogramName: res.data.programSubprogramDataSection.subprogramName,

          benefitId: res.data.programSubprogramDataSection.benefitId,
          benefitKeyValue: res.data.programSubprogramDataSection.benefitKeyValue,
          benefitName: res.data.programSubprogramDataSection.benefitName,

          bankPreferenceId: res.data.programSubprogramDataSection.bankPreferenceId,
          microcreditAmount: res.data.programSubprogramDataSection.microcreditAmount,
          concededMicrocreditAmount: res.data.programSubprogramDataSection.concededMicrocreditAmount,
          professionalKitsNumber: res.data.programSubprogramDataSection.professionalKitsNumber,
          concededProfessionalKitsNumber: res.data.programSubprogramDataSection.concededProfessionalKitsNumber,
          intendedCourseCode: res.data.programSubprogramDataSection.intendedCourseCode,
          intendedCourseName: res.data.programSubprogramDataSection.intendedCourseName,
          trainingCenterId: res.data.programSubprogramDataSection.trainingCenterId,
          trainingCenterName: res.data.programSubprogramDataSection.trainingCenterName,
          internshipAreaId: res.data.programSubprogramDataSection.internshipAreaId,
          internshipAreaDescription: res.data.programSubprogramDataSection.internshipAreaDescription,
          kitUnitPrice: res.data.programSubprogramDataSection.kitUnitPrice,
          parentApplicationId: res.data.programSubprogramDataSection.parentApplicationId,
          parentApplicationCode: res.data.programSubprogramDataSection.parentApplicationCode,
          generatedWorkPosts: res.data.programSubprogramDataSection.generatedWorkPosts,

          complementaryData: res.data.complementaryDataSection,
          complementaryDataId: res.data.complementaryDataId,
          documents: res.data.documentsSection,
        },
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_APPLICATION_DETAILS_GENERAL_DATA,
        result: false,
        payload: [],
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateApplicationDetailGeneralDataAction = (formData, filterData) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS_ACTIONS.GET_USER_APPLICATIONS_LOADING,
    payload: true,
  });
  updateApplicationDetailGeneralData(formData)
    .then((res) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_USER_APPLICATIONS_LOADING,
        payload: false,
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.UPDATE_APPLICATION_DETAILS_GENERAL_DATA,
        payload: res.data,
      });
      dispatch(getMyApplicationsAction(filterData));
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data !== null,
            goBack: true,
            onClose: "/admin/my-applications/government-program-application",
            message:
              res.data !== null
                ? "Dados da candidatura editados com sucesso."
                : "Ocorreu um erro ao editar os dados da candidatura.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.UPDATE_APPLICATION_DETAILS_GENERAL_DATA,
        payload: false,
      });
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_USER_APPLICATIONS_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const getApplicationDocumentAction = (docId, docName) => async (dispatch) => {
  getApplicationDocument(docId)
    .then((res) => {
      const link = document.createElement("a");
      link.href = convertByteArrayToBlobUrl(res.data);
      link.setAttribute("download", docName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      dispatch({
        type: APPLICATIONS_ACTIONS.GET_APPLICATION_DOCUMENT_DATA_DOWNLOAD,
        result: false,
        payload: null,
      });

      console.log("error", error);
    });
};

export const dataForApplicationCreationAction = (data) => async (dispatch) => {
  dispatch({
    type: APPLICATIONS_ACTIONS.DATA_FOR_APPLICATION_CREATION,
    payload: {
      governmentProgramId: data.governmentProgramId,
      programId: data.programId ?? null,
    },
  });
};

export default createApplicationAction;
