import { recover_password } from "crud/auth.crud";
import { AUTH } from "utils/actionsConsts";

export const recoverPasswordAction = (mobilePhone) => async(dispatch) => {
    recover_password(mobilePhone)
     .then(() => {
        dispatch({
          type: AUTH.RECOVER_PASSWORD,
          payload: true,
        });
      })
      .catch(() => {
        dispatch({ 
          type: AUTH.RECOVER_PASSWORD,
          payload: false,
        });
      });
  }

export const hideRecoverPasswordModalAction = () => async (dispatch) => {
  dispatch({ type: AUTH.HIDE_RECOVER_PASSWORD_MODAL, payload: false });
};

export default recoverPasswordAction;