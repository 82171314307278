import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
//MaterialUI
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import {
  TABLE_STATE_CHIP_COLOR,
  PAGINATION,
  APPLICATION_STATES_KEY,
  APPLICATION_STATES_CANCELED,
  APPLICATION_STATES_IN_PROGRESS,
  APPLICATION_STATES_APPROVED,
} from "utils/const";
import { getMyApplicationsAction } from "redux/actions/applicationsAction";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import CancelApplicationModal from "views/admin/GovernmentProgram/Application/Modals/CancelApplicationModal";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import GenericButton from "components/Buttons/GenericButton";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import { titles } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function MyGovernmentProgramsApplicationsTab(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const [cancelRequest, setCancelRequest] = useState(false);
  const [applicationNumber, setApplicationNumber] = useState("");
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const tableHead = ["Programa do Governo", "Programa", "Subprograma", "Nº Candidatura", "Estado", "", ""];

  const mapRecentApplicationsDashboardChipColors = (stateKey) => {
    if (APPLICATION_STATES_APPROVED.includes(stateKey)) {
      return TABLE_STATE_CHIP_COLOR.GREEN;
    }
    if (APPLICATION_STATES_CANCELED.includes(stateKey)) {
      return TABLE_STATE_CHIP_COLOR.RED;
    }
    if (APPLICATION_STATES_IN_PROGRESS.includes(stateKey)) {
      return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
    }
  };

  useEffect(() => {
    const filter = {
      pageIndex: page <= 0 ? 1 : page,
      pageSize: PAGINATION.PAGE_SIZE,
    };

    props.getMyApplications(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function handleOpenCancelApplicationModalEvent(applicationNumber) {
    setApplicationNumber(applicationNumber);
    setCancelRequest(true);
  }

  function handleCloseCancelApplicationModalEvent() {
    setCancelRequest(false);
  }

  return (
    <>
      <CardTableContainer
        title={intl.formatMessage(titles.Title_Table_UserSingular_ProgramApplications)}
        count={props?.applicationsState?.result?.total_records}
      >

        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
                <TableCell
                  key="detailsCol"
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                  }}
                >
                  Detalhes
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.applicationsState?.result?.data?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box fontWeight={"bold"}>{prop?.governmentProgram}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop?.program}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop?.subprogram}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop?.id}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <TableStateChip
                      state={mapRecentApplicationsDashboardChipColors(prop?.state?.key)}
                      message={prop?.state?.description}
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {prop.state.key === APPLICATION_STATES_KEY.AGUARDA_INFORMACAO && (
                      <GenericButton
                        typeSubmit={false}
                        color="tertiary"
                        size="small"
                        onClick={() => {
                          history.push(`/admin/edit-application/${prop.id}`);
                        }}
                      >
                        <Brightness1RoundedIcon classes={{ root: classes.pendingIcon }} />
                        Adicionar Dados Em Falta
                      </GenericButton>
                    )}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <GenericButton
                      typeSubmit={false}
                      color="tertiary"
                      size="small"
                      disabled={
                        prop.state.key === APPLICATION_STATES_KEY.CANCELADA ||
                          prop.state.key === APPLICATION_STATES_KEY.REJEITADA
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleOpenCancelApplicationModalEvent(prop.id);
                      }}
                    >
                      Cancelar
                    </GenericButton>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                    <Tooltip title="Detalhes">
                      <IconButton aria-label="details" component={Link} to={`/admin/application-details/${prop.id}`}>
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
          <TableBackdrop open={props?.applicationsState?.loading} />
        </TableContainer>

        <Pagination
          className={classes.alignPagination}
          count={props?.applicationsState?.result?.total_pages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        ></Pagination>
      </CardTableContainer>

      <GenericDialog maxWidth={"sm"} open={cancelRequest} onClose={handleCloseCancelApplicationModalEvent}>
        <CancelApplicationModal
          applicationNumber={applicationNumber}
          inProgress={false}
          onClose={handleCloseCancelApplicationModalEvent}
        />
      </GenericDialog>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getMyApplications: (data) => dispatch(getMyApplicationsAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyGovernmentProgramsApplicationsTab);
