import {
  getStatisticalData,
} from "crud/statisticalData.crud";
import { STATISTICAL_DATA_ACTIONS } from "utils/actionsConsts";

export const getStatisticalDataAction = () => async (dispatch) => {
  dispatch({
    type: STATISTICAL_DATA_ACTIONS.GET_STATISTICAL_DATA_LOADING,
    payload: true,
  });
  getStatisticalData()
    .then((res) => {
      dispatch({
        type: STATISTICAL_DATA_ACTIONS.GET_STATISTICAL_DATA,
        payload: res.data,
      });
      dispatch({
        type: STATISTICAL_DATA_ACTIONS.GET_STATISTICAL_DATA_LOADING,
        payload: false,
      });
    })
    .catch(() => {
      dispatch({
        type: STATISTICAL_DATA_ACTIONS.GET_STATISTICAL_DATA_LOADING,
        payload: false,
      });
    });
};