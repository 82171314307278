import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const VALIDATE_SSO_ACCESS = "SSOAccessConfiguration/validate-sso-access";

export function validateSSOAccess(_token,_url) {
    return axios.post(
        EXTERNAL_API_URL + VALIDATE_SSO_ACCESS,
        {
            validateSSOAccessModel: {
                Url: _url,
                Token: _token,
            }
        }
    );
}