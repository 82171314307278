import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function ProfessionalWallet() {
  const intl = useIntl();

  const professionalWalletData = [
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLawTitle
      ),
      content: [
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw1
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw2
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw3
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw4
          )
        ),
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatIsItTitle
      ),
      content: [
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatIsIt1
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatIsItExamples
          )
        ),
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatForTitle
      ),
      content: [
        firstBullet(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor1
          )
        ),
        bullet(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor2
          )
        ),
        bullet(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor3
          )
        ),
        bullet(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor4
          )
        ),
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhereToSignUpTitle
      ),
      content: [
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhereToSignUp1
          )
        ),
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisitesTitle
      ),
      content: [
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisitesLeading
          )
        ),
        bullet(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisites1
          )
        ),
        bullet(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisites2
          )
        ),
        bullet(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisites3
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletDocumentationLeading
          )
        ),
        bullet(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletDocumentation1
          )
        ),
        bullet(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletDocumentation2
          )
        ),
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletHowManyJobsTitle
      ),
      content: [
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_ProfessionalWalletHowManyJobs1
          )
        ),
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(
        titles.Title_Menu_Information_ProfessionalTraining_ProfessionalWallet
      )}
      data={professionalWalletData}
    />
  );
}

const bullet = (label) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
    description: label,
  };
};

const firstBullet = (label) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
    description: label,
  };
};

const text = (label) => {
  return label + "\n\n";
};

export default ProfessionalWallet;
