import {
  getUserRecentApplicationsDashboard, getUserSingularRecentOpportunityCandidateDashboard, getUserCollectiveRecentOpportunityApplicationsDashboard
} from "crud/dashboard.crud";

import { DASHBOARD_ACTIONS } from "utils/actionsConsts";

export const getUserRecentApplicationsDashboardAction = () => async (dispatch) => {
  dispatch({
    type: DASHBOARD_ACTIONS.GET_USER_RECENT_APPLICATIONS_DASHBOARD_LOADING,
    payload: true,
  });
  getUserRecentApplicationsDashboard()
    .then((res) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_USER_RECENT_APPLICATIONS_DASHBOARD,
        payload: res.data,
      });
      dispatch({
        type: DASHBOARD_ACTIONS.GET_USER_RECENT_APPLICATIONS_DASHBOARD_LOADING,
        payload: false,
      });
    })
    .catch(() => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_USER_RECENT_APPLICATIONS_DASHBOARD_LOADING,
        payload: false,
      });
    });
};

export const getUserSingularRecentOpportunityCandidateDashboardAction = () => async (dispatch) => {
  dispatch({
    type: DASHBOARD_ACTIONS.GET_USER_SINGULAR_RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD_LOADING,
    payload: true,
  });
  getUserSingularRecentOpportunityCandidateDashboard()
    .then((res) => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_USER_SINGULAR_RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD,
        payload: res.data,
      });
      dispatch({
        type: DASHBOARD_ACTIONS.GET_USER_SINGULAR_RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD_LOADING,
        payload: false,
      });
    })
    .catch(() => {
      dispatch({
        type: DASHBOARD_ACTIONS.GET_USER_SINGULAR_RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD_LOADING,
        payload: false,
      });
    });
};

export const getUserCollectiveRecentOpportunityApplicationsEmploymentDashboardAction =
  (opportunityTypeKeyValue) => async (dispatch) => {
    dispatch({
      type: DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_EMPLOYMENT_DASHBOARD_LOADING,
      payload: true,
    });
    getUserCollectiveRecentOpportunityApplicationsDashboard(opportunityTypeKeyValue)
      .then((res) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_EMPLOYMENT_DASHBOARD,
          payload: {
            info: res.data,
            opportunityTypeKeyValue: opportunityTypeKeyValue,
          },
        });
        dispatch({
          type: DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_EMPLOYMENT_DASHBOARD_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_EMPLOYMENT_DASHBOARD_LOADING,
          payload: {
            info: undefined,
            opportunityTypeKeyValue: opportunityTypeKeyValue,
          },
        });
        console.log("error", error);
      });
  };

  export const getUserCollectiveRecentOpportunityApplicationsInternshipDashboardAction =
  (opportunityTypeKeyValue) => async (dispatch) => {
    dispatch({
      type: DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_INTERNSHIP_DASHBOARD_LOADING,
      payload: true,
    });
    getUserCollectiveRecentOpportunityApplicationsDashboard(opportunityTypeKeyValue)
      .then((res) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_INTERNSHIP_DASHBOARD,
          payload: {
            info: res.data,
            opportunityTypeKeyValue: opportunityTypeKeyValue,
          },
        });
        dispatch({
          type: DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_INTERNSHIP_DASHBOARD_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: DASHBOARD_ACTIONS.GET_USER_COLLECTIVE_OPPORTUNITY_APPLICATIONS_INTERNSHIP_DASHBOARD_LOADING,
          payload: {
            info: undefined,
            opportunityTypeKeyValue: opportunityTypeKeyValue,
          },
        });
        console.log("error", error);
      });
  };