import { frontOfficePersonalProfileUpdate } from "crud/updatepersonalprofile.crud";
import { UPDATE_PROFILE, DIALOG } from "utils/actionsConsts";
import { b64ToImage } from "utils/converters";

export const updatePersonalProfileAction = (formData) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE.PERSONAL_LOADING });
  frontOfficePersonalProfileUpdate(formData)
    .then((res) => {
      dispatch({
        type: UPDATE_PROFILE.PERSONAL,
        payload: res.data,
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            goBack: res.data,
            onClose: res.data ? "\\" : undefined,
            message: res.data ? "Utilizador actualizado com sucesso" : "Erro a efetuar o seu pedido, por favor tente mais tarde",
          },
        },
      });
      dispatch({
        type: "updateProfile",
        payload: {
          userType: formData.get('user.userType'),
          name: formData.get('user.Name'),
          lastName: formData.get('user.userSingular.LastName'),
          photo: b64ToImage(formData.get('user.Photo')),
        },
      });
      dispatch({
        type: "getPersonalInformation",
        payload: {},
      });
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_PROFILE.PERSONAL,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Erro a efetuar o seu pedido, por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export default updatePersonalProfileAction;
