import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function EntrepreneurshipCenters() {
  const intl = useIntl();

  const entrepeneurshipData = [
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_EntrepreneurshipServicesTitle
      ),
      content: [
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesText1
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesText2
          )
        ),
        subtitle(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesCompanyIncubator_Title
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesCompanyIncubator_Text1
          )
        ),
        subtitle(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesLocalCenters_Title
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesLocalCenters_Text1
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesLocalCenters_Text2
          )
        ),
        subtitle(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesAfterWork1_Title
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesAfterWork1_Text1
          )
        ),
        subtitle(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesAfterWork2_Title
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesAfterWork2_Text1
          )
        ),
        subtitle(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesProgram_Title
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesProgram_Text1
          )
        ),
        subtitle(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Title
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Text1
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Text2
          )
        ),
        text(
          intl.formatMessage(
            labels.Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Text3
          )
        ),
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(
        titles.Title_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCenters
      )}
      data={entrepeneurshipData}
    />
  );
}

const subtitle = (label) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
    subtitle: label,
  };
};

const text = (label) => {
  return label + "\n\n";
};

export default EntrepreneurshipCenters;
