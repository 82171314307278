import { React } from "react";
import { connect } from "react-redux";

//import custom components
import InformationPage from "components/GenericPage/InformationPage";

function PrivacyPolicyPage() {
  const privacyPolicyData = [
    {
      title: "1. OBJETO",
      content:[
        "O presente documento visa estabelecer as condições de privacidade do portal INEFOP360 (adiante designado por Portal), acessível na versão web e em aplicação mobile, composto por uma área pública e outra reservada.",
      ]
    },
    {
      title: "2. POLÍTICA DE PRIVACIDADE DE DADOS",
      content:[
        "No âmbito da sua atividade, o Instituto Nacional do Emprego e Formação Profissional (adiante designado por INEFOP) realiza a recolha de dados pessoais neste Portal e garante que o seu tratamento é feito de acordo com as regras de Proteção da Privacidade de dados decorrentes da Lei de Proteção de Dados Pessoais (Lei nº 22/11 de 17 de junho), adiante designada por LPDP, e demais legislação nacional aplicável, garantindo por esta via todos os direitos e garantias relativos à privacidade de dados pessoais dos Titulares.",
      ]
    },
    {
      title: "3. DADOS PESSOAIS",
      content:[
        "Encontram-se aqui incluídos os dados pessoais transmitidos pelos seus titulares, registados neste Portal, bem como os recolhidos pelo INEFOP relativos aos visitantes do mesmo.",
      ]
    },
    {
      title: "4. RESPONSÁVEL PELO TRATAMENTO DE DADOS",
      content:[
        "O responsável pelo tratamento de dados recolhidos através deste Portal é o INEFOP. \n\nAvenida do 1º Congresso do MPLA N.5, \nLuanda, Angola \nTel: +244 222 709 560 | +244 222 709 561 \n\nPara eventuais esclarecimentos deverá contactar contacto.inefop@maptss.gov.ao",
      ]
    },
    {
      title:
        "5. FINALIDADE DA RECOLHA E TRATAMENTO E BASE JURÍDICA PARA O SEU PROCESSAMENTO",
      content:[
        "Os dados pessoais recolhidos pelo INEFOP, têm como finalidade o cumprimento das atribuições cometidas pela sua Lei Orgânica e demais legislação aplicável ao emprego e formação profissional, com a garantia dos direitos dos Titulares dos dados incluídos nos serviços prestados pela Instituição",
      ]
    },
    {
      title: "6. DIREITOS DOS TITULARES DOS DADOS",
      content:[ 
        "O Titular dos dados tem os direitos previstos na LPDP.",
      ]
    },
    {
      title: "7. MEDIDAS DE SEGURANÇA",
      content:[
        "O INEFOP, dispõe de medidas de segurança técnicas e organizativas com vista à proteção dos dados pessoais dos seus Titulares, nomeadamente no que respeita à utilização indevida, não autorizada, acesso ilegal ou divulgação, perda, alteração, danificação ou destruição. Essas medidas incluem: \n\nSegurança Física, garantida através do controlo de acessos às instalações do INEFOP e da destruição física dos suportes que contenham dados pessoais em formato digital. \n\nSegurança tecnológica, garantida mediante a aplicação de medidas tecnológicas de proteção da informação, como por exemplo a utilização de protocolos seguros e a monitorização dos nossos sistemas e data centers. \n\nSegurança Organizacional, garantida mediante o conhecimento da importância e das formas de proteção dos Dados Pessoais, de acordo com as políticas de segurança da informação implementadas na Instituição.",
      ]
    },
    {
      title: "8. SUBCONTRATAÇÃO",
      content:[
        "O INEFOP, como Responsável pelo Tratamento dos dados pessoais recolhidos no Portal, recorre a entidades externas contratadas no âmbito da criação, manutenção e gestão do Portal. Estas entidades podem ter acesso aos dados pessoais e atuam exclusivamente de acordo com as instruções e orientações do INEFOP, com a garantia de que adotam as medidas de segurança técnicas e organizativas adequadas exigidas na LPDP para assegurar a confidencialidade, integridade e disponibilidade da informação em causa.",
      ]
    },
    {
      title: "9. CONSERVAÇÃO DOS DADOS (PERÍODO DE RETENÇÃO DOS DADOS)",
      content:[
        "Sem prejuízo dos direitos que assistem ao Titular dos dados tratados no âmbito desta Política de Privacidade de Dados, os mesmos serão conservados enquanto a conta de utilizador permanecer ativa, estiver pendente, em execução ou se tiver existido no passado qualquer solicitação, ação ou serviço, efetuada, apresentada ou requerida através do Portal. \n\nA eliminação ou inativação da conta de utilizador não implica a eliminação de dados e informações relativas ao mesmo enquanto utente ou no âmbito da sua relação com o INEFOP, os quais serão mantidos de acordo com as obrigações legais de conservação dos dados em causa.",
      ]
    },
    {
      title:
        "10. ARMAZENAMENTO E TRANSFERÊNCIA DOS DADOS A NÍVEL TRANSFRONTEIRIÇO",
      content:[
        "O INEFOP pode transferir dados dos seus Titulares para países terceiros no âmbito das suas atribuições e com consentimento explicito dos mesmos.",
      ]
    },
    {
      title: "11. ALTERAÇÕES A ESTA DECLARAÇÃO",
      content:[
        "O INEFOP reserva-se no direito de revisão ou modificação desta Declaração, com observância da LPDP.",
      ]
    },
    {
      title: "12. POLÍTICA DE UTILIZAÇÃO DE COOKIES",
      content:[
        'O Portal recorre a etiquetas de Software, designadas por "Cookies", para identificar os visitantes das suas páginas por forma a formatar a informação apresentada com base nas preferências dos seus navegadores da internet e obter informação estatística relacionada com a utilização do Portal.',
      ]
    },
    {
      title: "13. UTILIZADORES REGISTADOS (DEVERES E RECOMENDAÇÕES)",
      content:[
        "O INEFOP, reserva-se no direito de suspender ou cancelar uma conta de utilizador caso verifique que a informação fornecida é falsa, incorreta, desatualizada, desadequada ou incompleta. A suspensão ou cancelamento de uma determinada conta apenas ocorrerá após notificação ao respetivo utilizador.",
      ]
    },
    {
      title:
        "14. RECOMENDAÇÕES RELATIVAS À CONTA DO UTILIZADOR, PALAVRA-PASSE E SEGURANÇA",
      content:[
        "No final do processo de registo, o utilizador definirá uma palavra-passe, passando, a partir desse momento, a ser responsável por todas as atividades que ocorram sob a sua utilização, bem como por manter a confidencialidade da mesma. Em caso de suspeita de uso não autorizado da palavra-passe ou de qualquer outra quebra de segurança, recomenda-se que seja informado imediatamente o INEFOP. Para que a conta pessoal não seja acedida por terceiros não autorizados, recomenda-se ainda que no final de cada sessão, o utilizador saia da sua conta e feche a janela do navegador (browser).",
      ]
    },
    {
      title: "15. HIPERLIGAÇÕES EXTERNAS",
      content:[
        "O INEFOP, não se responsabiliza nem pelos conteúdos presentes nas hiperligações feitas para o exterior do Portal e abertas numa nova janela, nem pela atualidade dos mesmos.",
      ]
    },
  ];

  return (
    <InformationPage
      title={"Política de Privacidade de Dados"}
      data={privacyPolicyData}
    />
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(PrivacyPolicyPage);
