import { SSO_ACTIONS } from "utils/actionsConsts";

const ssoReducer = (state =
  {
    redirectURL: "",
    token: "",
    validSSOAccess: true,
  }, action) => {
  switch (action.type) {
    case SSO_ACTIONS.SET_SSO_PARAMETERS:
      return {
        ...state,
        redirectURL: action.payload.redirectURL,
        token: action.payload.token,
      };
      case SSO_ACTIONS.VALIDATE_SSO_ACCESS:
        return { ...state, validSSOAccess: action.payload };
    default:
      return state;
  }
};

export default ssoReducer;