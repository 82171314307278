export const limitaString = (value, limitSize) => {
    if (!value || !limitSize)
        return value;
    if (value.length > limitSize)
        return value.substring(0, limitSize) + "..."
    return value
}

export const removeAccents = (value) => {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export default limitaString;