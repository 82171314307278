import { defineMessages } from "react-intl";

export const errorMessages = defineMessages({
  ErrorMessage_Birthdate_MaxDate: {
    id: "ErrorMessage_Birthdate",
    defaultMessage: "A data deve ser inferior à data atual",
    description: "Mensagem de erro quando um campo de data de nascimento tem uma data superior à atual",
  },
  ErrorMessage_Birthdate_MinDate: {
    id: "ErrorMessage_Birthdate_MinDate",
    defaultMessage: "A data deve ser superior a {minDate}",
    description: "Mensagem de erro quando um campo de data de nascimento tem uma data inferior à data mínima",
  },
  ErrorMessage_FieldRequired: {
    id: "ErrorMessage_FieldRequired",
    defaultMessage: "O campo é obrigatório",
    description: "Mensagem de erro quando um campo obrigatório do formulário não é preenchido",
  },
  ErrorMessage_FieldPercentage: {
    id: "ErrorMessage_FieldPercentage",
    defaultMessage: "O valor mínimo da % deve ser maior que 0 e menor que 100.",
    description: "Mensagem de erro quando ocorre um erro a % é incorrecta",
  },
  ErrorMessage_FieldValidation: {
    id: "ErrorMessage_FieldValidation",
    defaultMessage: "Confirme o valor do campo",
    description:
      "Mensagem de erro quando um campo obrigatório do formulário é pré-preenchido mas precisa de confirmação",
  },
  ErrorMessage_GenericError: {
    id: "ErrorMessage_GenericError",
    defaultMessage: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
    description: "Mensagem de erro quando ocorre um erro genérico na API",
  },
  ErrorMessage_InvalidFormat: {
    id: "ErrorMessage_InvalidFormat",
    defaultMessage: "Formato inválido",
    description: "Mensagem de erro quando um campo do formulário tem um formato inválido",
  },
  ErrorMessage_MinRegisterDate: {
    id: "ErrorMessage_MinRegisterDate",
    defaultMessage: "A idade mínima para registo é de 17 anos.",
    description: "Mensagem de erro quando a data de nascimento para registo não perfaz 17 anos.",
  },
  ErrorMessage_ID_Card_InvalidFormat: {
    id: "ErrorMessage_ID_Card_InvalidFormat",
    defaultMessage: "O número de BI tem tamanho máximo de 14 caracteres",
    description: "Mensagem de erro quando um campo do formulário tem um formato inválido",
  },
  ErrorMessage_MobilePhone_ConfirmationMobilePhone_Not_Match: {
    id: "ErrorMessage_MobilePhone_ConfirmationMobilePhone_Not_Match",
    defaultMessage: "O número inserido deve ser igual ao campo {phoneLabel}",
    description: "Mensagem de erro quando o valor do campo de confirmação de telemóvel não é igual ao telemóvel",
  },
  ErrorMessage_Invalid_Value: {
    id: "ErrorMessage_Invalid_Value",
    defaultMessage: "Valor inválido.",
    description: "Mensagem de erro quando o valor inserido é inválido.",
  },
  ErrorMessage_Email_ConfirmationEmail_Not_Match: {
    id: "ErrorMessage_Email_ConfirmationEmail_Not_Match",
    defaultMessage: "O e-mail inserido deve ser igual ao campo {emailLabel}",
    description: "Mensagem de erro quando o valor do campo de confirmação do e-mail não é igual ao e-mail",
  },
  ErrorMessage_Password_Not_Valid: {
    id: "ErrorMessage_Password_Not_Valid",
    defaultMessage: "Palavra-passe inválida",
    description: "Mensagem de erro quando a palavra-passe inserida no registo não cumpre os requisitos",
  },
  ErrorMessage_Invalid_ProfessionalKit_Value: {
    id: "ErrorMessage_Invalid_ProfessionalKit_Value",
    defaultMessage: "O número de kits não pode ser superior a {numMaxKits}.",
    description: "Mensagem de erro quando o valor inserido é inválido.",
  },
  ErrorMessage_Generic_InvalidField: {
    id: "ErrorMessage_Generic_InvalidField",
    defaultMessage: "Formato incorrecto",
    description: "Mensagem genérica para qualquer campo que não cumpra o formato indicado",
  },
  ErrorMessage_Generic_ValueGreaterThanZeroField: {
    id: "ErrorMessage_Generic_ValueGreaterThanZeroField",
    defaultMessage: "O valor tem de ser superior a zero",
    description: "Mensagem genérica para qualquer campo que o valor tenha de ser superior a zero",
  },
  ErrorMessage_Generic_ValueEqualOrGreaterThanZeroField: {
    id: "ErrorMessage_Generic_ValueEqualOrGreaterThanZeroField",
    defaultMessage: "O valor tem de ser maior ou igual a zero",
    description: "Mensagem genérica para qualquer campo que o valor tenha de ser superior a zero",
  },
  ErrorMessage_Generic_DateToGreaterThanDateFromField: {
    id: "ErrorMessage_Generic_DateToGreaterThanDateFromField",
    defaultMessage: "A data deve ser superior à data de inicio",
    description: "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser superior à data de fim",
  },
  ErrorMessage_Generic_DateFromLessThanDateToField: {
    id: "ErrorMessage_Generic_DateFromLessThanDateToField",
    defaultMessage: "A data deve ser inferior à data de fim",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser superior à data de inicio",
  },
  ErrorMessage_Generic_DateLessThanTodayField: {
    id: "ErrorMessage_Generic_DateLessThanTodayField",
    defaultMessage: "A data deve ser inferior à data atual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser inferior à data actual",
  },
});
export const labels = defineMessages({
  //General
  Label_BreadCrumb_Default: {
    id: "Label_BreadCrumb_Default",
    defaultMessage: "Voltar",
    description: "Label aplicada no breadcrumb por defeito",
  },
  Label_BreadCrumb_Individual_Registration: {
    id: "Label_BreadCrumb_Individual_Registration",
    defaultMessage: "Registo de Cidadão",
    description: "Label aplicada no breadcrumb da página de registo de cidadão",
  },
  Label_BreadCrumb_Colective_Registration: {
    id: "Label_BreadCrumb_Colective_Registration",
    defaultMessage: "Registo Colectivo",
    description: "Label aplicada no breadcrumb na página de registo colectivo",
  },
  Label_BreadCrumb_Registration: {
    id: "Label_BreadCrumb_Registration",
    defaultMessage: "Registo",
    description: "Label aplicada no breadcrumb na página geral de registo",
  },
  Label_Not_Applicable: {
    id: "Label_Not_Applicable",
    defaultMessage: "N/A",
    description: "Not Applicable",
  },
  Label_Download: {
    id: "Label_Download",
    defaultMessage: "Descarregar",
    description: "Descarregar",
  },
  Label_Download_Document: {
    id: "Label_Download_Document",
    defaultMessage: "Descarregar Documento",
    description: "Label_Download_Document",
  },
  Label_Upload_Document: {
    id: "Label_Upload_Document",
    defaultMessage: "Carregar documento",
    description: "Upload document",
  },
  Label_No_Information_To_Present: {
    id: "Label_No_Information_To_Present",
    defaultMessage: "Sem informação para apresentar.",
    description: "No Information to present",
  },
  Label_No_Results: {
    id: "Label_No_Results",
    defaultMessage: "Sem resultados",
    description: "No results",
  },
  Label_No_Results_Found: {
    id: "Label_No_Results_Found",
    defaultMessage: "Não foram encontrados resultados.",
    description: "No results found",
  },
  Label_Undefined: {
    id: "Label_Undefined",
    defaultMessage: "Indefinido",
    description: "Undefined",
  },
  Label_Search: {
    id: "Label_Search",
    defaultMessage: "Pesquisar",
    description: "Search",
  },
  Label_See_More: {
    id: "Label_See_More",
    defaultMessage: "Ver Mais",
    description: "See More",
  },
  Label_See_All_M: {
    id: "Label_See_All_M",
    defaultMessage: "Ver Todos",
    description: "See All",
  },
  Label_See_All_F: {
    id: "Label_See_All_F",
    defaultMessage: "Ver Todas",
    description: "See All",
  },
  Label_Know_More: {
    id: "Label_Know_More",
    defaultMessage: "Saber Mais",
    description: "Know More",
  },
  Label_Know_More_Ellipsis: {
    id: "Label_Know_More_Ellipsis",
    defaultMessage: "Saiba Mais...",
    description: "Know More",
  },
  Label_SelectMultipleOptions: {
    id: "Label_SelectMultipleOptions",
    defaultMessage: "Seleccione opções aplicáveis",
    description: "Label SelectMultipleOptions",
  },
  Label_See_Data: {
    id: "Label_See_Data",
    defaultMessage: "Ver Dados",
    description: "See Data",
  },
  Label_Welcome: {
    id: "Label_Welcome",
    defaultMessage: "Bem-vindo(a)",
    description: "Welcome",
  },
  Label_Share: {
    id: "Label_Share",
    defaultMessage: "Partilhar",
    description: "Share",
  },
  Label_Date: {
    id: "Label_Date",
    defaultMessage: "Data",
    description: "Date",
  },
  Label_Button_Next: {
    id: "Label_Button_Next",
    defaultMessage: "SEGUINTE",
    description: "Label do botão de navegação do formulário que permite navegar para a página seguinte",
  },
  Label_Button_Previous: {
    id: "Label_Button_Previous",
    defaultMessage: "ANTERIOR",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Label_Button_Register: {
    id: "Label_Button_Register",
    defaultMessage: "REGISTAR",
    description: "Label do botão de registo",
  },
  Label_Button_Edit: {
    id: "Label_Button_Edit",
    defaultMessage: "Editar",
    description: "Label do botão de edição",
  },
  Label_Generic_AddRecord: {
    id: "Label_Generic_AddRecord",
    defaultMessage: "Adicionar Registo",
    description: "AddRecord button label",
  },

  Label_Button_Cancel: {
    id: "Label_Button_Cancel",
    defaultMessage: "Cancelar",
    description: "Label de botºoes cancelar",
  },
  Label_Button_Submit: {
    id: "Label_Button_Submit",
    defaultMessage: "Submeter",
    description: "Label de botões submeter",
  },
  Label_Reject_Submit: {
    id: "Label_Reject_Submit",
    defaultMessage: "Rejeitar",
    description: "Label de botões Rejeitar",
  },
  Label_Recruit_Submit: {
    id: "Label_Recruit_Submit",
    defaultMessage: "Recrutar",
    description: "Label de botões Recrutar",
  },

  Label_Admin_Applications_Programs_Field_ParentApplication: {
    id: "Label_Admin_Applications_Programs_Field_ParentApplication",
    defaultMessage: "Candidatura relacionada (opcional)",
    description: "Label do campo da candidatura relacionada",
  },
  Label_Admin_Applications_Programs_Field_GeneratedWorkPosts: {
    id: "Label_Admin_Applications_Programs_Field_GeneratedWorkPosts",
    defaultMessage: "Postos de trabalho a gerar",
    description: "Label do campo que irá conter os postos de trabalho a gerar",
  },
  Label_Admin_Applications_Programs_Field_Province: {
    id: "Label_Admin_Applications_Programs_Field_Province",
    defaultMessage: "Onde pretende candidatar-se?",
    description: "Label do campo de provincia da candidatura",
  },
  Label_Admin_Applications_Programs_Field_Province_Placeholder: {
    id: "Label_Admin_Applications_Programs_Field_Province_Placeholder",
    defaultMessage: "Seleccione a província em que se pretende candidatar",
    description: "Label do campo de provincia da candidatura",
  },
  Label_Admin_Applications_Programs_Field_ProgramSubprogram: {
    id: "Label_Admin_Applications_Programs_Field_ProgramSubprogram",
    defaultMessage: "Qual o programa a que pretende candidatar-se?",
    description: "Label da tabela de seleção de programa e subprograma da candidatura",
  },
  Label_Admin_Applications_Programs_Field_ProgramSubprogram_Placeholder: {
    id: "Label_Admin_Applications_Programs_Field_ProgramSubprogram_Placeholder",
    defaultMessage: "Seleccione um programa",
    description: "Placeholder dos campos da tabela de seleção de programa e subprograma da candidatura",
  },
  Label_Admin_Applications_Programs_Field_Subprogram_Placeholder: {
    id: "Label_Admin_Applications_Programs_Field_Subprogram_Placeholder",
    defaultMessage: "Seleccione um subprograma",
    description: "Placeholder dos campos da tabela de seleção de programa e subprograma da candidatura",
  },
  Label_Admin_Applications_Programs_Field_BankPreference: {
    id: "Label_Admin_Applications_Programs_Field_BankPreference",
    defaultMessage: "Qual o banco que tem preferência?",
    description: "Label do campo de seleção de banco na criação de candidatura a um subprograma de microcrédito",
  },
  Label_Admin_Applications_Programs_Field_BankPreference_Placeholder: {
    id: "Label_Admin_Applications_Programs_Field_BankPreference_Placeholder",
    defaultMessage: "Seleccione o banco",
    description: "Label do campo de seleção de banco na criação de candidatura a um subprograma de microcrédito",
  },
  Label_Auth_WelcomeToINEFOP: {
    id: "Label_Auth_WelcomeToINEFOP",
    defaultMessage: "Bem-vindo ao novo portal INEFOP",
    description: "Label welcome to INEFOP",
  },
  Label_Auth_ColectiveRegistrationSteps_UserData_Field_MobilePhone: {
    id: "Label_Auth_ColectiveRegistrationSteps_UserData_Field_MobilePhone",
    defaultMessage: "Telemóvel",
    description: "Label do campo 'Telemóvel' do registo de entidades coletivas",
  },
  Label_Auth_ColectiveRegistrationSteps_UserData_Field_Email: {
    id: "Label_Auth_ColectiveRegistrationSteps_UserData_Field_Email",
    defaultMessage: "E-mail (opcional)",
    description: "Label do campo 'Email' do registo de entidades coletivas",
  },
  Label_Auth_ColectiveRegistrationSteps_Step_UserData: {
    id: "Label_Auth_ColectiveRegistrationSteps_Step_UserData",
    defaultMessage: "Dados Utilizador",
    description: "Label dos steps do registo de entidades coletivas",
  },
  Label_Auth_ColectiveRegistrationSteps_Step_CompanyData: {
    id: "Label_Auth_ColectiveRegistrationSteps_Step_CompanyData",
    defaultMessage: "Dados Empresa",
    description: "Label dos steps do registo de entidades coletivas",
  },
  Label_Auth_ColectiveRegistrationSteps_Step_Password: {
    id: "Label_Auth_ColectiveRegistrationSteps_Step_Password",
    defaultMessage: "Palavra-Passe",
    description: "Label dos steps do registo de entidades coletivas",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm_PersonalDataStep: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm_PersonalDataStep",
    defaultMessage: "Dados pessoais",
    description: "Label do step de dados pessoais no registo de entidades individuais",
  },
  Label_Auth_RegistrationSteps_MainForm_InformationDataStep: {
    id: "Label_Auth_RegistrationSteps_MainForm_InformationDataStep",
    defaultMessage: "Informações",
    description: "Label do step de dados informações no registo de entidades",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm_ProfessionalDataStep: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm_ProfessionalDataStep",
    defaultMessage: "Dados profissionais",
    description: "Label do step de dados profissionais no registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm__PasswordStep: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm__PasswordStep",
    defaultMessage: "Palavra-Passe",
    description: "Label do step de palavra-passe no registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm__DocumentsStep: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm__DocumentsStep",
    defaultMessage: "Documentos",
    description: "Label do step de documentos no registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Name: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Name",
    defaultMessage: "Nome",
    description: "Label campo 'Nome' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Surname: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Surname",
    defaultMessage: "Apelido",
    description: "Label campo 'Apelido' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_IdentityCard: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_IdentityCard",
    defaultMessage: "Nº do BI",
    description: "Label campo 'Nº do BI' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_MobilePhone: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_MobilePhone",
    defaultMessage: "Telemóvel",
    description: "Label do campo 'Telemóvel' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForInternshipOpportunities: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForInternshipOpportunities",
    defaultMessage: "Quero aparecer nas listas de candidatos a estágios",
    description: "Label do campo 'Disponível para Oportunidades de Estágio' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForEmploymentOpportunities: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForEmploymentOpportunities",
    defaultMessage: "Quero aparecer nas listas de candidatos a emprego",
    description: "Label do campo 'Disponível para Oportunidades de Emprego' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Email: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Email",
    defaultMessage: "E-mail (opcional)",
    description: "Label do campo 'Email' do registo de entidades individuais",
  },
  Label_Auth_Recover_Password_MobilePhone: {
    id: "Label_Auth_Recover_Password_MobilePhone",
    defaultMessage: "Telemóvel",
    description: "Label do campo 'Telemóvel' da modal de recuperação de password",
  },
  Label_General_OK_Button: {
    id: "Label_General_OK_Button",
    defaultMessage: "OK",
    description: "Label de botões OK",
  },
  Label_General_Enter_Button: {
    id: "Label_General_Enter_Button",
    defaultMessage: "Login",
    description: "Label de botões ENTRAR",
  },
  Label_Generic_Upload_Document: {
    id: "Label_Generic_Upload_Document",
    defaultMessage: "Carregar documento",
    description: "Label do campo",
  },

  //Registration
  Label_Auth_Register_TableHead_EntityTypeCompany: {
    id: "Label_Auth_Register_TableHead_EntityTypeCompany",
    defaultMessage: "Empresa",
    description: "Label do cabeçalho da tabela das funcionalidades do registo de entidades colectivas",
  },
  Label_Auth_Register_Button_EntityTypeCompany: {
    id: "Label_Auth_Register_Button_EntityTypeCompany",
    defaultMessage: "Registar-me como Empresa",
    description: "Label do botão que seleciona a área de registo de entidades colectivas",
  },
  Label_Auth_Register_TableHead_EntityTypeIndividual: {
    id: "Label_Auth_Register_TableHead_EntityTypeIndividual",
    defaultMessage: "Cidadão",
    description: "Label do cabeçalho da tabela das funcionalidades do registo de entidades individuais",
  },
  Label_Auth_Register_Button_EntityTypeIndividual: {
    id: "Label_Auth_Register_Button_EntityTypeIndividual",
    defaultMessage: "Registar-me como Cidadão",
    description: "Label do botão que seleciona a área de registo de entidades individuais",
  },
  Label_Auth_Register_Introduction_1: {
    id: "Label_Auth_Register_Introduction_1",
    defaultMessage:
      "O registo de entidade neste portal poderá ser do tipo Cidadão ou Empresa. O tipo de entidade escolhida determinará as funcionalidades a que terá acesso.",
    description: "Registration Label",
  },
  Label_Auth_Register_Introduction_2: {
    id: "Label_Auth_Register_Introduction_2",
    defaultMessage: "As funcionalidades de cada tipo de entidade podem ser consultadas na tabela abaixo:",
    description: "Registration Label",
  },
  Label_Auth_Register_Introduction_TableHead_Area: {
    id: "Label_Auth_Register_Introduction_TableHead_Area",
    defaultMessage: "Área",
    description: "Registration Label",
  },
  Label_Auth_Register_Introduction_TableHead_Service: {
    id: "Label_Auth_Register_Introduction_TableHead_Service",
    defaultMessage: "Serviço",
    description: "Registration Label",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_GovernmentSupports: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_GovernmentSupports",
    defaultMessage: "Apoios do Governo",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_GovernmentSupports_Service_ApplyToGovernmentSupports: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_GovernmentSupports_Service_ApplyToGovernmentSupports",
    defaultMessage: "Efetuar e acompanhar candidatura a apoios do governo",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_EmploymentAndInternship: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_EmploymentAndInternship",
    defaultMessage: "Emprego e Estágio",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_EmploymentAndInternship_Service_CreateOpportunities: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_EmploymentAndInternship_Service_CreateOpportunities",
    defaultMessage: "Criar e gerir ofertas de emprego e estágio",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_EmploymentAndInternship_Service_ApplyToOpportunities: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_EmploymentAndInternship_Service_ApplyToOpportunities",
    defaultMessage: "Efetuar e acompanhar candidatura a ofertas de emprego e estágio",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining",
    defaultMessage: "Formação Profissional",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckAvailableCourses: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckAvailableCourses",
    defaultMessage: "Consultar o catálogo de cursos existentes",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_ApplyToCourses: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_ApplyToCourses",
    defaultMessage: "Registo de intenção da inscrição em curso",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckMyCourses: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckMyCourses",
    defaultMessage: "Consultar os seus cursos",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckMyCoursesCalendar: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckMyCoursesCalendar",
    defaultMessage: "Consultar o calendário de cursos onde está inscrito",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckMyCourseCertificates: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckMyCourseCertificates",
    defaultMessage: "Consultar o comprovativo de realização dos seus cursos",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_Posts: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_Posts",
    defaultMessage: "Publicações",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_Posts_Service_CheckPosts: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_Posts_Service_CheckPosts",
    defaultMessage: "Consultar notícias, legislações e agenda do INEFOP",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_Contacts: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_Contacts",
    defaultMessage: "Contactos",
    description: "Registration Label - Services List",
  },
  Label_Auth_Register_Introduction_ServicesList_Area_Contacts_Service_CheckContacts: {
    id: "Label_Auth_Register_Introduction_ServicesList_Area_Contacts_Service_CheckContacts",
    defaultMessage: "Consultar morada e contactos do INEFOP",
    description: "Registration Label - Services List",
  },

  //InformationDissemination
  Label_Admin_InformationDissemination_InformationCategory: {
    id: "Label_Admin_InformationDissemination_InformationCategory",
    defaultMessage: "Categoria",
    description: "Information Category label",
  },
  Label_Admin_InformationDissemination_Authentication: {
    id: "Label_Admin_InformationDissemination_Authentication",
    defaultMessage: "Autenticação",
    description: "Authentication label",
  },
  Label_Admin_InformationDissemination_PublishedDate: {
    id: "Label_Admin_InformationDissemination_PublishedDate",
    defaultMessage: "Data Publicação",
    description: "Information details published date label",
  },

  //Menu Labels
  //INEFOP
  Label_Menu_INEFOP: {
    id: "Label_Menu_INEFOP",
    defaultMessage: "Institucional",
    description: "Menu Label",
  },
  Label_Menu_INEFOP_InstitutionOverview: {
    id: "Label_Menu_INEFOP_InstitutionOverview",
    defaultMessage: "Instituição",
    description: "Menu Label",
  },
  Label_Menu_INEFOP_InstitutionHistory: {
    id: "Label_Menu_INEFOP_InstitutionHistory",
    defaultMessage: "Historial",
    description: "Menu Label",
  },
  Label_Menu_INEFOP_InstitutionMissionVisionAndValues: {
    id: "Label_Menu_INEFOP_InstitutionMissionVisionAndValues",
    defaultMessage: "Missão, Visão e Valores",
    description: "Menu Label",
  },
  Label_Menu_INEFOP_InstitutionOrganicStatus: {
    id: "Label_Menu_INEFOP_InstitutionOrganicStatus",
    defaultMessage: "Estatuto Orgânico",
    description: "Menu Label",
  },
  Label_Menu_INEFOP_InstitutionOrganizationChart: {
    id: "Label_Menu_INEFOP_InstitutionOrganizationChart",
    defaultMessage: "Organograma",
    description: "Menu Label",
  },
  Label_Menu_INEFOP_DirectorBiography: {
    id: "Label_Menu_INEFOP_DirectorBiography",
    defaultMessage: "Biografia do Director",
    description: "Menu - Label_Menu_INEFOP_DirectorBiography",
  },
  Label_Menu_INEFOP_InstituteHolders: {
    id: "Label_Menu_INEFOP_InstituteHolders",
    defaultMessage: "Titulares do Instituto",
    description: "Menu - Label_Menu_INEFOP_InstituteHolders",
  },
  //Posts
  Label_Menu_Posts: {
    id: "Label_Menu_Posts",
    defaultMessage: "Publicações",
    description: "Menu Label",
  },
  Label_Menu_Posts_News: {
    id: "Label_Menu_Posts_News",
    defaultMessage: "Notícias",
    description: "Menu Label",
  },
  Label_Menu_Posts_Legislations: {
    id: "Label_Menu_Posts_Legislations",
    defaultMessage: "Legislações",
    description: "Menu Label",
  },
  Label_Menu_Posts_Schedule: {
    id: "Label_Menu_Posts_Schedule",
    defaultMessage: "Agenda",
    description: "Menu Label",
  },
  //Information
  Label_Menu_Information: {
    id: "Label_Menu_Information",
    defaultMessage: "Informação",
    description: "Menu Label",
  },
  Label_Menu_Information_EmploymentCenters: {
    id: "Label_Menu_Information_EmploymentCenters",
    defaultMessage: "Centros de Emprego",
    description: "Menu Label",
  },
  Label_Menu_Information_EmploymentCentersNetwork: {
    id: "Label_Menu_Information_EmploymentCentersNetwork",
    defaultMessage: "Rede de Centros de Emprego",
    description: "Menu Label",
  },
  Label_Menu_Information_ForeignWorkersContractRegistration: {
    id: "Label_Menu_Information_ForeignWorkersContractRegistration",
    defaultMessage: "Registo de Contrato de Trabalhadores Estrangeiros",
    description: "Menu Label",
  },
  Label_Menu_Information_EntrepreneurshipCenters: {
    id: "Label_Menu_Information_EntrepreneurshipCenters",
    defaultMessage: "Serviços de Empreendedorismo e Inovação",
    description: "Menu Label",
  },
  Label_Menu_Information_EntrepreneurshipCentersNetwork: {
    id: "Label_Menu_Information_EntrepreneurshipCentersNetwork",
    defaultMessage: "Rede de Centros de Empreendedorismo",
    description: "Menu Label",
  },
  Label_Menu_Information_ProfessionalWallet: {
    id: "Label_Menu_Information_ProfessionalWallet",
    defaultMessage: "Carteira Profissional",
    description: "Menu Label",
  },
  Label_Menu_Information_FormationOffer: {
    id: "Label_Menu_Information_FormationOffer",
    defaultMessage: "Oferta Formativa",
    description: "Menu Label",
  },
  Label_Menu_Information_TrainingCentersLicensing: {
    id: "Label_Menu_Information_TrainingCentersLicensing",
    defaultMessage: "Licenciamento de Centros de Formação Profissional",
    description: "Menu Label",
  },
  Label_Menu_Information_Entrepreneurship: {
    id: "Label_Menu_Information_Entrepreneurship",
    defaultMessage: "Empreendedorismo",
    description: "Menu Label",
  },
  Label_Menu_Information_Programs: {
    id: "Label_Menu_Information_Programs",
    defaultMessage: "Programas",
    description: "Menu Label",
  },
  Label_Menu_Information_CompanyServices: {
    id: "Label_Menu_Information_CompanyServices",
    defaultMessage: "Serviços para Empresa",
    description: "Menu Label",
  },
  Label_Menu_Information_CitizenServices: {
    id: "Label_Menu_Information_CitizenServices",
    defaultMessage: "Serviços para Cidadão",
    description: "Menu Label",
  },
  Label_Menu_Information_EmploymentServices: {
    id: "Label_Menu_Information_EmploymentServices",
    defaultMessage: "Serviços de Emprego",
    description: "Menu Label",
  },
  Label_Menu_Information_EntrepreneurshipServices: {
    id: "Label_Menu_Information_EntrepreneurshipServices",
    defaultMessage: "Serviços de Empreendedorismo",
    description: "Menu Label",
  },
  Label_Menu_Information_ProfessionalTraining: {
    id: "Label_Menu_Information_ProfessionalTraining",
    defaultMessage: "Formação Profissional",
    description: "Menu Label",
  },

  //Help
  Label_Menu_Help: {
    id: "Label_Menu_Help",
    defaultMessage: "Ajuda",
    description: "Menu Label",
  },
  Label_Menu_Help_FAQs: {
    id: "Label_Menu_Help_FAQs",
    defaultMessage: "Perguntas Frequentes",
    description: "Menu Label",
  },
  Label_Menu_Help_FAQs_HowToRegisterInInefop: {
    id: "Label_Menu_Help_FAQs_HowToRegisterInInefop",
    defaultMessage: "Como fazer o registo no portal INEFOP?",
    description: "Menu Label",
  },
  Label_Menu_Help_FAQs_HowToRecoverPassword: {
    id: "Label_Menu_Help_FAQs_HowToRecoverPassword",
    defaultMessage: "Como recuperar a palavra-passe?",
    description: "Menu Label",
  },
  Label_Menu_Help_FAQs_HowToUpdatePersonalData: {
    id: "Label_Menu_Help_FAQs_HowToUpdatePersonalData",
    defaultMessage: "Como alterar os meus dados pessoais?",
    description: "Menu Label",
  },
  Label_Menu_Help_FAQs_HowToApplyToAJob: {
    id: "Label_Menu_Help_FAQs_HowToApplyToAJob",
    defaultMessage: "Como posso candidatar-me a um emprego ou estágio através do INEFOP?",
    description: "Menu Label",
  },
  Label_Menu_Help_FAQs_HowToApplyToGovernmentSupports: {
    id: "Label_Menu_Help_FAQs_HowToApplyToGovernmentSupports",
    defaultMessage: "Como posso candidatar-me a um apoio do governo?",
    description: "Menu Label",
  },
  Label_Menu_Help_UsefulLinks: {
    id: "Label_Menu_Help_UsefulLinks",
    defaultMessage: "Links Úteis",
    description: "Menu Label",
  },
  Label_Menu_Help_NationalScholarshipTrainers: {
    id: "Label_Menu_Help_NationalScholarshipTrainers",
    defaultMessage: "Bolsa Nacional de Formadores",
    description: "Menu Label",
  },

  //StructuringProjects
  Label_Menu_StructuringProjects: {
    id: "Label_Menu_StructuringProjects",
    defaultMessage: "Programas",
    description: "Menu Label",
  },

  //Submenu labels
  //InstitutionalInformation - MissionVisionAndValues
  Label_Search_Menu_InstitutionalInformation_MissionVisionAndValues: {
    id: "Label_Search_Menu_InstitutionalInformation_MissionVisionAndValues",
    defaultMessage: "Menu_InstitutionalInformation_MissionVisionAndValues",
    description: "Prefix used to search InstitutionalInformation - MissionVisionAndValues infomration on resources",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_Mission: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_Mission",
    defaultMessage: "MISSÃO",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_MissionDescription: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_MissionDescription",
    defaultMessage:
      "Assegurar a execução das políticas sobre a organização do mercado emprego e da formação profissional a nível nacional e aplicação dos programas nos domínios da formação e reabilitação profissional definidas e aprovadas pelo executivo.",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_Vision: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_Vision",
    defaultMessage: "VISÃO",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_VisionDescription: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_VisionDescription",
    defaultMessage:
      "Ser referência na promoção do Emprego, Empregabilidade e Formação Profissional em Angola, com elevado padrão ético e de qualidade, e absoluto comprometimento em alinhamento com as estratégias vigentes de desenvolvimento socioeconómico do País.",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_Values: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_Values",
    defaultMessage: "VALORES",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_1: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_1",
    defaultMessage: "Profissionalismo",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_2: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_2",
    defaultMessage: "Responsabilidade",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_3: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_3",
    defaultMessage: "Zelo",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_4: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_4",
    defaultMessage: "Imparcialidade",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },
  Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_5: {
    id: "Label_Menu_InstitutionalInformation_MissionVisionAndValues_ValuesDescription_5",
    defaultMessage: "Excelência",
    description: "InstitutionalInformation - MissionVisionAndValues",
  },

  //InstitutionalInformation - Overview
  //InstitutionalInformation - Overview
  Label_Search_Menu_InstitutionalInformation_Overview: {
    id: "Label_Search_Menu_InstitutionalInformation_Overview",
    defaultMessage: "Menu_InstitutionalInformation_Overview",
    description: "Prefix used to search InstitutionalInformation - Overview infomration on resources",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_1: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_1",
    defaultMessage:
      "O INEFOP - Instituto Nacional de Emprego e Formação Profissional, é um Instituto Público do Sector Social, dotado de personalidade jurídica, autónoma administrativa, financeira e patrimonial que tem como missão assegurar a execução das políticas relativas a organização do mercado de emprego, bom como a direcção e coordenação do sistema de formação profissional e a aplicação dos programas nos domínios da formação e reabilitação profissional definidas e aprovadas pelo Governo.",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_2: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_2",
    defaultMessage:
      "Para promover a organização do mercado de trabalho, melhorar a oferta formativa e valorizar os recursos humanos no País, desenvolvem-se no âmbito dos Sistema Nacional de Emprego e Formação Profissional (SNEFP), um conjunto de acções inseridas na rede pública de centros e serviços tutelados pelo INEFOP.",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_3: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_3",
    defaultMessage:
      "Em termos de natureza dos serviços, existem 141 unidades formativas, 78 centros de emprego e empreendedorismo e 18 serviços provinciais.",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4",
    defaultMessage:
      "Actualmente o INEFOP conta com 241 Serviços Executivos Directos e Indirectos, estruturas descentralizadas em toda extensão do País, nomeadamente:",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_1: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_1",
    defaultMessage: "18 Serviços Provinciais;",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_2: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_2",
    defaultMessage: "2 Centros Integrados de Formação Tecnológica (CINFOTEC);",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_3: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_3",
    defaultMessage: "31 Centros de Formação Profissional;",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_4: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_4",
    defaultMessage: "16 Centros Integrados de Emprego e Formação Profissional;",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_5: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_5",
    defaultMessage: "35 Centros Móveis;",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_6: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_6",
    defaultMessage: "61 Pavilhões de Artes e Ofícios;",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_7: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_7",
    defaultMessage: "23 Centros de Emprego (C.E);",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_8: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_8",
    defaultMessage: "10 Centros Integrados de Emprego e Formação Profissional (CIEFP);",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_9: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_9",
    defaultMessage: "12 Unidades de Intermediação de Mão-de-obra (UIMO);",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_10: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_10",
    defaultMessage: "18 Centros Municipais de Empreendedorismo e Emprego (CMESE);",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_11: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_11",
    defaultMessage: "10 Centros Locais de Empreendedorismo e Serviços de Emprego (CLESE);",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_12: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_12",
    defaultMessage: "5 Unidades Móveis de Emprego;",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_13: {
    id: "Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_13",
    defaultMessage: "5 Escolas Rurais de Capacitação e Ofícios.",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_Contacts: {
    id: "Label_Menu_InstitutionalInformation_Overview_Contacts",
    defaultMessage: "CONTACTOS",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_Contacts_Adress: {
    id: "Label_Menu_InstitutionalInformation_Overview_Contacts_Adress",
    defaultMessage: "Av. do 1º Congresso do MPLA N.º 5, Luanda, Angola",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_Contacts_PhoneNumber: {
    id: "Label_Menu_InstitutionalInformation_Overview_Contacts_PhoneNumber",
    defaultMessage: "+244 222 709 560 | +244 222 709 561",
    description: "InstitutionalInformation - Overview",
  },
  Label_Menu_InstitutionalInformation_Overview_Contacts_Email: {
    id: "Label_Menu_InstitutionalInformation_Overview_Contacts_Email",
    defaultMessage: "contacto@inefop.gov.ao",
    description: "InstitutionalInformation - Overview",
  },

  //InstitutionalInformation - History
  Label_Search_Menu_InstitutionalInformation_History: {
    id: "Label_Search_Menu_InstitutionalInformation_History",
    defaultMessage: "Menu_InstitutionalInformation_History",
    description: "Prefix used to search InstitutionalInformation - History infomration on resources",
  },
  Label_Menu_InstitutionalInformation_History_description_1: {
    id: "Label_Menu_InstitutionalInformation_History_description_1",
    defaultMessage:
      "O Instituto Nacional do Emprego e Formação Profissional abreviadamente designado por (INEFOP) é um instituto Público do Sector Social, dotado de personalidade jurídica, autonomia administrativa, financeira e patrimonial que tem como missão assegurar a execução das políticas relativas à organização do mercado de emprego, bem como a direcção e coordenação do sistema de formação profissional e aplicação dos programas nos domínios da formação e reabilitação profissional definidas e aprovada pelo Governo.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_description_2: {
    id: "Label_Menu_InstitutionalInformation_History_description_2",
    defaultMessage:
      "O INEFOP foi criado pelo Decreto Lei n.º 34/98 de 2 de Outubro, O mesmo tem como seu órgão de Tutela o Ministério da Administração Pública, Trabalho e Segurança Social (MAPTSS).",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_description_3: {
    id: "Label_Menu_InstitutionalInformation_History_description_3",
    defaultMessage:
      "Porém, apesar do decreto da criação do INEFOP ter sido aprovado no ano de 1998, na verdade, o INEFOP desenvolve as suas actividades desde o ano de 1992, altura em que foi aprovado o estatuto orgânico do INAFOP, através do Decreto nº 39-D-92 de 28 de Agosto.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_description_4: {
    id: "Label_Menu_InstitutionalInformation_History_description_4",
    defaultMessage:
      "O INEFOP passa assim a integrar, para além da Direcção Geral, duas Direcções Adjuntas: uma para a área de Formação e outra para a área do Emprego.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1",
    defaultMessage: "A FORMAÇÃO PROFISSIONAL EM ANGOLA, DESDE OS PRIMÓRDIOS DA INDEPENDÊNCIA À NOVA REPÚBLICA",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_1",
    defaultMessage:
      "A Formação Profissional até a altura da Independência de Angola, a 11 de Novembro de 1975 estava enquadrada nos Serviços de Emprego, sendo posteriormente enquadrada no Ministério do Trabalho e Segurança Social.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_2",
    defaultMessage:
      "Pelo facto do processo de independência ter sido muito conturbado, originou a que, muitos nacionais e estrangeiros detentores de conhecimento tecnológico, técnico e científico capaz de tocar a economia do País, abandonassem, em busca de pontos mais seguros, deixando a Jovem República “dilacerada”, no concernente a quadros. Para fazer face a esta situação, Angola, teve de recorrer aos países amigos, em outras palavras, a cooperação internacional.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_3",
    defaultMessage:
      "Com objectivo de se ultrapassar rapidamente a carência de quadros que se sentia a todos os níveis e sectores da vida social, económica e cultural, a palavra de ordem era de que ao lado de cada técnico cooperante, um Angolano, e que cada sector da vida socio-económica do País, devesse criar condições para superar o mais rápido possível a carência de quadros que tinha. Em parte, isto originou o surgimento na altura, de vários Centros de Formação Profissional, desde a Indústria, Banca, Educação, Agricultura, Pescas, Administração Pública, Finanças, Cultura, Defesa e Serviços similares, Transportes, Petróleos, etc.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_4: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_4",
    defaultMessage:
      "É de realçar que nos primeiros anos de independência, a formação ficou sob tutela do então Ministério do Trabalho e Segurança Social (1975-1977). Dada a necessidade de qualificar novos quadros e superar os poucos trabalhadores com que o País contava, é criado à nível do Comité Central do MPLA, o Departamento de Quadros, que vigorou de 1976 à 1977.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_5: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_5",
    defaultMessage:
      "Com todos estes sectores a fazerem formação era necessário criar mecanismos para evitar duplicação de esforços e optimizar recursos. Em 1978, a formação passa a ser tutelada pelo Ministério da Educação, sendo enquadrada na Direcção Nacional do Ensino de Adultos, deixando de fazer parte dos Serviços de Emprego, do Ministério do Trabalho (MINTRAB). Com a integração da componente formação na Direcção Nacional de Ensino de Adultos, esta passa a designar-se Direcção Nacional de Formação de Adultos.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_6: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_6",
    defaultMessage:
      "Portanto, houve a necessidade de tornar a formação um instrumento de desenvolvimento sustentável do país, assim, com base no Decreto nº 110/83 de Dezembro do Conselho de Ministros, orienta as competências PEDAGÓGICAS E METODOLÓGICAS das acções de formação",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_7: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_7",
    defaultMessage:
      "Segundo o Decreto, os centros criados pelos sectores da vida económica e social do País, era da sua competência a nomeação do Director Geral do centro, cabendo ao Órgão Reitor da Formação, (Ministério da Educação) na altura, a nomeação do Director Adjunto pedagógico, no sentido de salvaguardar a difusão e aplicação das orientações metodológico-pedagógicas nos centros de formação.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_8: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_8",
    defaultMessage:
      "A Formação Profissional compreendia dois cíclos. O I ciclo destinava-se a jovens e adultos sem qualificação e o II ciclo para trabalhadores com qualificação do I ciclo. O grupo alvo participante dos dois ciclos podia ou não obter equivalência académica, dependente do perfil de entrada.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9",
    defaultMessage:
      "Com a necessidade de melhoria da qualidade da Formação Profissional, era preciso dar outro impulso ao processo formativo. Assim, é aprovado o Estatuto da Formação Profissional, através do Decreto nº 28/89, de 1 Julho,  do Conselho de Ministros e consequentemente, revogado o Decreto 110/83. O Estatuto da Formação Profissional, enquadrava e regulamentava as diferentes actividades inerentes a formação, tal como: a definição da formação, objectivos, formas, tipos e locais de formação, assim como, os órgãos responsáveis pelas políticas de formação, atribuindo-lhes funções específicas, concretamente:",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9_BulletPoint_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9_BulletPoint_1",
    defaultMessage:
      "Ministério da Educação – Coordenar, executar, controlar e orientar as acções de Formação Profissional;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9_BulletPoint_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9_BulletPoint_2",
    defaultMessage:
      "Ministério do Trabalho – Coordenar, controlar e orientar as acções de formação permanente dos trabalhadores, entre outras;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9_BulletPoint_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9_BulletPoint_3",
    defaultMessage:
      "Ministério do Plano – Coordenar e assegurar os dados necessários a planificação da força de trabalho, entre outras atribuições.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_10: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_10",
    defaultMessage:
      "Como órgão multisetorial, foi criado o Conselho Nacional da Formação Profissional, de assessoria ao Ministério da Educação em matéria de Formação Profissional.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_2",
    defaultMessage: "A LEI Nº 21-A/92, DE 28 DE AGOSTO",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_2_Description_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_2_Description_1",
    defaultMessage:
      "A Formação Profissional estava diluída no Subsistema de ensino de adultos, com um chefe com a categoria de chefe de Departamento dentro de uma Direcção Nacional de Ensino de Adultos, sem expressão, se considerar a dimensão que sempre se procurou atribuir a formação pela sua importância e pertinência.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_2_Description_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_2_Description_2",
    defaultMessage:
      "Esta Lei, aprovada pela Assembleia do Povo, cria o SISTEMA NACIONAL DA FORMAÇÃO PROFISSIONAL (SNFP), que enquadra toda a formação profissional inicial e contínua, seja em que sector económico, modalidade e tipo de formação.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3",
    defaultMessage: "DADOS DA FORMAÇÃO PROFISSIONAL, DESDE OS PRIMÓRDIOS DA INDEPENDÊNCIA À NOVA REPÚBLICA (1975-1992)",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_1",
    defaultMessage:
      "No período em referência teriam existido, cerca de 140 Centros de Formação Profissional, criados por iniciativa dos vários Ministérios e Empresas, por razões já evocadas nas páginas anteriores. Estes, não serviram cabalmente as necessidades da procura de mão-de-obra por várias razões, obrigando as unidades económicas a recorrerem sistematicamente a contratação de força de trabalho expatriada.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_2",
    defaultMessage:
      "No final da década de 80, com a nomeação de um Vice-Ministro para o Ensino Técnico Profissional e consequentemente a criação da Direcção Nacional de Formação Profissional, veio dar um contributo na realização de importantes reuniões, debates, elaboração, aprovação e publicação de um pacote legislativo de grande importância para a Formação Profissional.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_3",
    defaultMessage:
      "Assim, em 1989 foi criada a Unidade de Formação para Empresa (U.F.E.), no âmbito da Direcção Nacional de Formação Profissional do Ministério da Educação. No mesmo ano, foi criada a Unidade de Formação de Instrutores (U.F.I.), para responder as necessidades de formação dos Instrutores/Formadores da Formação Profissional. Neste período já existia cerca de 1.113 Instrutores da Formação Profissional, dos quais, 23% eram expatriados. Neste período, não existia nenhum atractivo para a carreira de Formador da Formação Profissional, para além do salário.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_4: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_4",
    defaultMessage:
      "O CEFOPESCAS, detinha a maior fasquia de formadores estrangeiros entre os Centros de formação. Isto teria a ver, precisamente com o tipo de formação ministrada naquele centro que requeria um nível de especialização para a qualidade e especificidade dos cursos que ali se ministravam. De realçar que na altura possuíam um nível de cooperação muito elevado, em vários domínios, com o Reino da Suécia. Neste período, cerca de 39 % dos instrutores dos centros de formação existentes.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5",
    defaultMessage:
      "Até ao ano de 1990, o Sistema de Formação Profissional contava com uma rede de cerca de 135 Centros de Formação Profissional. Estes centros detinham uma capacidade instalada potencial de cerca de 12.481, isto significa que os Centros não eram utilizados na sua capacidade máxima, por motivos vários designadamente: ",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_1",
    defaultMessage: "Situação Político-Militar que o país viveu;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_2",
    defaultMessage: "Grupo alvo sem as qualificações académicas exigidas como perfil de entrada;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_3",
    defaultMessage:
      "Centros de Formação Profissional de Empresas Públicas (Ministérios) com público alvo especifico principalmente;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_4: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_4",
    defaultMessage: "Número reduzido de Centros de Formação Profissional Públicos.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_6: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_6",
    defaultMessage: "Desta, apenas 10.040 era considerada a capacidade real que a cobertura era de apenas 5.515.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_7: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_7",
    defaultMessage:
      "Na tabela podemos verificar, conforme se referiu anteriormente, os centros eram maioritariamente tutelados por Ministérios e instituições públicas. Até 1989 o Ministério da Educação tutelava 17 centros de Formação em todo o país, isto é 14% das instituições formativas do país.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4",
    defaultMessage: "A FORMAÇÃO PROFISSIONAL NO PERÍODO DE 1992 À 2002",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_1",
    defaultMessage:
      "O ano de 1992 ficou marcado de forma indelével na memória dos cidadãos da República de Angola, porque dava início a uma nova República, num país jovem marcado inicialmente por uma guerra de libertação contra o colonialismo Português que culminou com proclamação da independência a 11 de Novembro de 1975. Assistiu-se posteriormente a uma feroz guerra civil e a ingerência de países estrangeiros no apoio a alguns grupos de Angolanos rebeldes.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_2",
    defaultMessage:
      "O povo Angolano, ciente da necessidade de paz, ensaiou várias tentativas de acordos de que culminaram com a assinatura dos Acordos de Paz de Bicesse, em Portugal. Aos acordos, seguiram-se as eleições de 1992, vencidas pelo MPLA e pelo Presidente José Eduardo dos Santos, nas eleições presidenciais. Neste caso, tendo em conta os resultados obtidos indicaram a necessidade de realização de uma segunda volta entre o presidente José Eduardo dos Santos, presidente do MPLA e Jonas Malheiro Savimbi, presidente da UNITA. Os resultados foram, entretanto, rejeitados pela UNITA, sob alegação de fraude eleitoral, apesar de terem sido consideradas pelas Nações Unidas geralmente justas e livres.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_3",
    defaultMessage:
      "Assistiu-se, então, ao retorno à guerra com todas as consequências daí provenientes em todos os sectores da vida do País, com a destruição das infra-estruturas existentes, entre as quais, as ligadas a Formação Profissional não foram poupadas. Neste período, assistiu-se ao encerramento e vandalização das estruturas formativas afectas ao Estado e a retracção e/ou mesmo o encerramento de outras tuteladas por instituições privadas (principalmente escolas e centros de artes e ofícios tuteladas maioritariamente pela igreja Católica).",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_4: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_4",
    defaultMessage:
      "Apesar da guerra, a actividade formativa continuou o seu percurso, limitada as capitais de províncias, estando a estrutura de Formação Profissional adstrita a Direcção Nacional de Formação Profissional e como já foi referido, sob tutela do Ministério da Educação.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_5: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_5",
    defaultMessage:
      "Dados relativos ao ano de 1992 apontavam a existência de cento e quarenta e dois (142) Centros de Formação Profissional, dos quais estariam totalmente disponíveis.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_6: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_6",
    defaultMessage:
      "O período de 1992 a 2002 tornou-se, portanto, num período marcado por grandes realizações, quer em termos de funcionamento, como de reorganização e enquadramento legal, foram criadas as bases para sustentação de todo um sistema para o processo de formação, ensino e aprendizagem. Em 1992, ao abrigo do Decreto n.º 21-A/92, de 28 de Agosto (DR n.º 34), da Assembleia Nacional, é criada a Lei de Bases do Sistema Nacional de Formação Profissional. No mesmo mês, pelo Decreto n.º 39-D/92, de 28 de Agosto é igualmente criado o Instituto Nacional de Formação Profissional, abreviadamente denominado por INAFOP, passando este Instituto a ser o órgão reitor do Sistema Nacional de Formação em Angola.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_7: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_7",
    defaultMessage:
      "Ao INAFOP, como orgão reitor Sistema Nacional de Formação Profissional, competia implementar, coordenar todo o processo de Formação Profissional no país. Nesta altura foi, também, instituído o Fundo de Financiamento da Formação Profissional.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_8: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_8",
    defaultMessage:
      "A revista Formação era editada e publicada pelo UIDOC da Direcção Nacional do Ensino Técnico Profissional. Esta iniciativa teve sequência timidamente nos anos subsequentes a Criação do INAFOP. Foram editados, publicados e divulgados vários Boletins Informativos.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_9: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_9",
    defaultMessage:
      "Foi igualmente criada uma Unidade de Formação de Instrutores de Formação de Profissional (U.F.I.) instalada no Centro Nacional de Formação Profissional do Cazenga, tendo este lançado um Curso Permanente para Instrutores.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10",
    defaultMessage:
      "A criação do INAFOP foi a mola impulsionadora para a transformação da U.F.I. num Centro de Formação de Formadores (CENFOR) com as actividades focalizadas em:",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10_BulletPoint_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10_BulletPoint_1",
    defaultMessage: "Formação pedagógica inicial;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10_BulletPoint_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10_BulletPoint_2",
    defaultMessage: "Capacitação pedagógica;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10_BulletPoint_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10_BulletPoint_3",
    defaultMessage: "Organização de seminários diversos.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_11: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_11",
    defaultMessage:
      "Em 1989/90 registava-se a existência de 1.113 instrutores de FP dos quais 23% eram estrangeiros. Estes números relativos aos estrangeiros subiam para o caso de uma análise sectorial chegando aos 40% nos Centros ligados aos sectores de Habitação, Pescas, Energia, Finanças e Cultura e inferior a 15% para os Centros de Saúde, Indústria, Educação, Indústria, Educação, Comércio e Construção.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_12: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_12",
    defaultMessage:
      "O Instituto Nacional de Formação Profissional em 1992 encontrava-se já implantado no país com representação a nível de Delegações Provinciais. Entretanto a rede de centros mantinha-se ainda assim incipiente.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_13: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_13",
    defaultMessage:
      "Mudanças se impunham na estruturação do INAFOP considerada a necessidade de se aliar o Emprego a Formação Profissional de modo a repor e criar o paralelismo entre estas duas componentes que se complementam.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_14: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_14",
    defaultMessage:
      "Em 1995, o Conselho de Ministros da República de Angola, aprova o Decreto 40/95, de 29 de Dezembro transferindo a tutela do INAFOP do Ministério da Educação para o Ministério da Administração Pública, Emprego e Segurança Social.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_15: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_15",
    defaultMessage:
      "Neste período são estabelecidas as bases para o relançamento e integração de acções entre o emprego e formação profissional visando a criação de uma dependência comum.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_16: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_16",
    defaultMessage:
      "O Decreto nº 34/98, 2 de Outubro; do Conselho de Ministros: repõe essa dependência comum com a criação do INEFOP – Instituto Nacional de Emprego e Formação Profissional, orgão que tem como competência a gestão e avaliação do Sistema Nacional do Emprego e da Formação Profissional, absorvendo as competências do INAFOP – Instituto Nacional de Formação Profissional, criado em 1992, através do Decreto nº 39-D-92 de 28 de Agosto.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_17: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_17",
    defaultMessage:
      "O INEFOP passa assim a integrar, para além da Direcção Geral, duas Direcções Adjuntas: uma para a área de Formação e outra para a área do Emprego.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_18: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_18",
    defaultMessage:
      "No ano de 2002, através do Decreto executivo n.º 18/02, de 17 de Maio; dos Ministros da Administração Pública, Emprego e Segurança Social e das finanças: aprova o estatuto orgânico do Instituto Nacional de Emprego e Formação Profissional (INEFOP) revogando todas as disposições contrariando o previsto no presente diploma.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_19: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_19",
    defaultMessage:
      "Entre 1992 a 2002, actividades ligadas à formação profissional no âmbito da formação profissional foram desenvolvidas quer no âmbito das atribuições internas do INEFOP. No domínio da legislação normativa e regulamentar, nomeadamente: Decreto n.º 16/98, de 3 de Julho: do Conselho de Ministros: Aprova o regulamento sobre o enquadramento dos Centros de Formação Profissional. – D.R. n.º 29.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_20: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_20",
    defaultMessage:
      "Um forte pendor começou a ser dado na reabilitação e introdução de programa formativos de formação inicial e contínua nos centros de formação tutelados, com inputs do orgão central do estado em programas de formação activos, na construção e apetrechamento de centros de formação, pavilhões de artes e ofícios e, também na instituição de centros e unidades móveis de formação.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_21: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_21",
    defaultMessage:
      "Os resultados dos esforços do executivo, reflectem-se na Tabela 8 e traduziu-se num incremento da oferta formativa e no acesso dos jovens às instituições de formação do INEFOP entre 1998 e 2002, com um índice de aproveitamento na ordem de 83.82%, relativamente ao número de formandos matriculados no período.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects",
    defaultMessage: "COOPERAÇÃO E PROJECTOS",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description",
    defaultMessage:
      "No domínio da Cooperação e reforço de Capacidades técnicas e institucionais, foram rubricados e implementados projectos e programas de formação tais como:",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_1",
      defaultMessage:
        "Projecto ANG89/001: Financiado pelo PNUD, OXFAM, ASDI e pela Embaixada de Portugal e que resultaram na implantação do Serviço Nacional de Formação para a Empresa;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_2",
      defaultMessage:
        "Projecto ANG/89/001: Programa Desenvolve o teu Negócio para os desmobilizados das Forças Armadas. Formação de Formadores;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_3",
      defaultMessage:
        "Projecto ANG/89/001: Programa Desenvolve o teu Negócio direcionado para 12 senhoras da Associação das Mulheres Empresárias de Luanda (ASSOMEL);",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_4",
      defaultMessage:
        "Projecto ANG/89/001: Desenvolvimento do programa de Descrição, análise ocupacional e diagnóstico de necessidades de formação para as empresas: Unicargas, Correios de Angola e Peskwanza;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_5",
      defaultMessage:
        "Projecto IED/Portugal: Visa a Formação inicial de formandos, a formação Pedagógica Inicial e contínua de Formadores em Luanda e Huambo, com início em 1995;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_6",
      defaultMessage:
        "Projecto de Formação de Quadros: Financiado pelo governo de Angola e executado pela TECNOFORMA em 1995-96, para formação de técnicos e responsáveis das 18 províncias do país, pertencentes a áreas de estudo e planeamento das estruturas de emprego e da formação profissional, nas províncias de Benguela, Huíla e Luanda;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_7",
      defaultMessage: "Projecto GTZ/Alemanha: Reabilitação /equipamento do Centro de Formação Profissional do Kikuxi;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_8",
      defaultMessage:
        "ITALCONSULT/Itália: Elaboração de Programas e Planos Curriculares para as especialidades do INEFOP;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_9:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_9",
      defaultMessage:
        "Cospe/Itália: Reabilitação do Centro da Cospe no Centro de Formação Profissional do Kuanza Sul e apresentação a união europeia de um Projecto de construção de um Centro de Formação Profissional na Província do Bié;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_10:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_10",
      defaultMessage:
        "IFESH/EUA: Projecto de Formação de Mão-de-obra. Reabilitação do Centro de Formação Profissional do Kuanza Norte, financiado pelo Banco Mundial;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_11:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_11",
      defaultMessage:
        "COPRAI/Portugal: Formação de Formadores e desenvolvimento do Programa de Formação de Gestores e Chefias intermédias em Gestão de Recursos Humanos, Chefias Intermédias, Gestão da Qualidade, Gestão de Stocks, Gestão da Formação e Gestão da Manutenção, organizado pelo SENAFOPE no âmbito de uma cooperação com a Associação Industrial Portuense;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_12:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_12",
      defaultMessage:
        "Replicação do Programa da COPRAI, na Empresa Pestômbwa no Namibe para formação de Gestores e chefias intermédias;",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_13:
    {
      id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_BulletPoint_13",
      defaultMessage:
        "GAMEK, formação de contabilistas e ENE: Gestão de Recursos Humanos, Chefias Intermédias e Gestão da Manutenção).",
      description: "InstitutionalInformation - History",
    },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_2",
    defaultMessage:
      "O estabelecimento de parcerias com instituições congéneres foram importantes e constituíram igualmente o foco de atenção do orgão de tutela da formação profissional do país.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5",
    defaultMessage: "O INEFOP NO PERÍODO DE 2002 A 2015",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_1",
    defaultMessage:
      "Neste período havia ainda a necessidade de classificar as profissões em Angola. Assim sendo, o decreto conjunto nº 31/03, de 24 de junho dos Ministérios da Administração Pública Emprego e Segurança Social e do Planeamento aprova o Classificador das Profissões em Angola.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_2",
    defaultMessage:
      "A profissão adquire-se por uma formação. Assim sendo, a eficiência da formação passa necessariamente pelo seu agente catalizador: O FORMADOR. Neste contexto, deveria haver um “Instrumento” para a superação continua deste agente. É assim que o decreto executivo n º 37/05, de 14 de Março, do MAPTSS cria o Centro de Formação de Formadores, abreviadamente designado por CENFFOR, para atender essa necessidade.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_3",
    defaultMessage:
      "Em 2008, o decreto executivo nº 68/08, de 20 de Maio, do MAPTSS, aprova o paradigma da orgânica dos serviços executivos indirectos do Instituto Nacional do Emprego e Formação Profissional, INEFOP.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_4: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_4",
    defaultMessage:
      "Por outro lado, para um atendimento mais personalizado às empresas em termos de Mão-de-Obra, o decreto executivo nº 237/08 de 6 de Outubro do MAPTSS cria o Centro Integrado de Formação Tecnológica, abreviadamente designado por CINFOTEC.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_5: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_5",
    defaultMessage:
      "A outra necessidade que surgiu neste período é definir os Centros de Formação por âmbito. Desta feita o decreto executivo nº 306/08 de 28 de Outubro do MAPTSS cria os Centros de Formação de âmbito Provincial e aprova o estatuto orgânico que rege os Centros de Formação.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_6: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_6",
    defaultMessage:
      "A formação por especialidade era outra necessidade. A começar pela área da Construção Civil. Então, a luz do decreto executivo nº 307/08 de Outubro cria o Centro de Formação de Construção Civil, abreviadamente designado por CENFOC, e aprova o seu estatuto orgânico.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_7: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_7",
    defaultMessage:
      "Ainda com a preocupação de definir o âmbito de atuação dos Centros de Formação, o decreto nº 308/08 de 29 de Outubro do MAPTSS cria os Centros de Formação do âmbito municipal e aprova seu estatuto orgânico.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_8: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_8",
    defaultMessage:
      "A rede das Unidades de Formação alargou-se em todo pais. Desta vez com a preocupação de dar formação até às pessoas sem a formação académica. Para a concretização deste processo, o decreto executivo nº 309/08 de 29 de Outubro, do MAPTSS, cria os Pavilhões de Artes e Ofícios e aprova o seu estatuto orgânico.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9",
    defaultMessage:
      "Ainda neste período, o Governo Angola teve cooperação com alguns países que resultaram em seguintes projectos:",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9_BulletPoint_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9_BulletPoint_1",
    defaultMessage:
      "FTD: Futuro – Trabalho e Desenvolvimento, que apoiou a implementação dos projectos ao nível do CINFOTEC;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9_BulletPoint_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9_BulletPoint_2",
    defaultMessage:
      "RKK: Rogaland Kurs Kompetensecenter (Noruega), Formação de Mão-de-obra, Formação de Formadores e apetrechamento dos centros de formação do CAZENGA, CINFOTEC, SOYO e CABINDA.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9_BulletPoint_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9_BulletPoint_3",
    defaultMessage:
      "GIZ: Formação de formadores e Elaboração de Matrizes e Estruturas curriculares para os cursos de formação profissional com e sem equivalência escolar.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6",
    defaultMessage: "O INEFOP 2015",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_1",
    defaultMessage:
      "Convindo assegurar, no plano institucional, a execução articulada e coordenada das políticas sectoriais de emprego e formação profissional e; havendo a necessidade de se adequar o Estatuto orgânico do Instituto Nacional de Emprego e Formação Profissional, às novas regras de Organização, Estruturação e Funcionamento dos Institutos Públicos estabelecidas pelo Decreto Legislativo Presidencial n.º 2/13, de 25 de Julho.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_2",
    defaultMessage:
      "É publicado aos 02 de Junho de 2015, o Decreto Presidencial n.º 128/15, que Aprova o Estatuto do Instituto Nacional de Emprego e Formação Profissional e revoga toda a legislação contrariando o presente Diploma.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3",
    defaultMessage:
      "Para execução das suas atribuições o Instituto Nacional do Emprego e Formação Profissional compreende os seguintes Serviços Executivos Externos:",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_1: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_1",
    defaultMessage: "Centros de Emprego;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_2: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_2",
    defaultMessage: "Centros de Formação Profissional;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_3: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_3",
    defaultMessage: "Centros Integrados de Emprego e Formação Profissional;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_4: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_4",
    defaultMessage: "Escolas Rurais de Capacitação e Ofícios;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_5: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_5",
    defaultMessage: "Unidades Móveis de Formação Profissional;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_6: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_6",
    defaultMessage: "Centros Locais de Empreendedorismo e Serviço de Emprego;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_7: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_7",
    defaultMessage: "Pavilhão de Formação Profissional de Artes e Ofícios;",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_8: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_8",
    defaultMessage: "Pavilhão Ocupacionais de Prestação de Serviços.",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_4: {
    id: "Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_4",
    defaultMessage:
      "Actualmente, o INEFOP apresenta uma capacidade formativa que ronda a volta de 33.239 formandos/ano, com um total de 1.228 formadores distribuídos em 174 Centros de Formação por todo país.",
    description: "InstitutionalInformation - History",
  },
  //Tables
  Label_Menu_InstitutionalInformation_History_Table_1: {
    id: "Label_Menu_InstitutionalInformation_History_Table_1",
    defaultMessage: "Tabela 1. Formadores nacionais e estrangeiros nos centros de formação em Angola (1987-89)",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Table_2: {
    id: "Label_Menu_InstitutionalInformation_History_Table_2",
    defaultMessage: "Tabela 2. Formadores dos centros tutelados pelo INEFOP e outros organismos em 1989",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Table_3: {
    id: "Label_Menu_InstitutionalInformation_History_Table_3",
    defaultMessage: "Tabela 3. Capacidade instalada dos centros até 1989 - Dados de um inquérito efetuado em 1989",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Table_4: {
    id: "Label_Menu_InstitutionalInformation_History_Table_4",
    defaultMessage:
      "Tabela 4. Evolução da capacidade instalada dos centros de formação profissional entre 1987/88 e 1989",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Table_5: {
    id: "Label_Menu_InstitutionalInformation_History_Table_5",
    defaultMessage: "Tabela 5. Capacidade instalada dos centros ministérios de tutela entre 1987 e 1989",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Table_6: {
    id: "Label_Menu_InstitutionalInformation_History_Table_6",
    defaultMessage:
      "Tabela 6. Centros de Formação Profissional por Tutela, Tipo de Formação e Capacidade Instalada – 1989",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Table_7: {
    id: "Label_Menu_InstitutionalInformation_History_Table_7",
    defaultMessage: "Tabela 7. Províncias por organismo - 2002",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Table_8: {
    id: "Label_Menu_InstitutionalInformation_History_Table_8",
    defaultMessage: "Tabela 8. Formandos por ano de formação (1998-2002)",
    description: "InstitutionalInformation - History",
  },
  //Images
  Label_Menu_InstitutionalInformation_History_Image_1: {
    id: "Label_Menu_InstitutionalInformation_History_Image_1",
    defaultMessage: "Imagem 1. Organograma do INAFOP - 1992",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Image_2_3: {
    id: "Label_Menu_InstitutionalInformation_History_Image_2_3",
    defaultMessage: "Imagens 2 e 3. Boletins informativos",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Image_4: {
    id: "Label_Menu_InstitutionalInformation_History_Image_4",
    defaultMessage: "Imagem 4. Organograma do INEFOP - 2002",
    description: "InstitutionalInformation - History",
  },
  Label_Menu_InstitutionalInformation_History_Image_5: {
    id: "Label_Menu_InstitutionalInformation_History_Image_5",
    defaultMessage: "Imagem 5. Organograma do INEFOP - 2015",
    description: "InstitutionalInformation - History",
  },

  //InstitutionalInformation - Director Biography
  Label_Search_Menu_InstitutionalInformation_DirectorBiography: {
    id: "Label_Search_Menu_InstitutionalInformation_DirectorBiography",
    defaultMessage: "Menu_InstitutionalInformation_DirectorBiography",
    description: "Prefix used to search InstitutionalInformation - Director Biography information on resources",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyPosition: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyPosition",
    defaultMessage: "Director Geral do Instituto Nacional de Emprego e Formação Profissional",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyName_Title: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyName_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyName: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyName",
    defaultMessage: "Manuel Mbungalanga Vianey Mbangui",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyBirthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyBirthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyBirthdate: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyBirthdate",
    defaultMessage: "30/10/1985",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyNationality_Title: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyNationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyNationality: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyNationality",
    defaultMessage: "Huambo",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyStudies_Title: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyStudies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyStudies: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyStudies",
    defaultMessage: "Licenciado em Contabilidade e Administração",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyQualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyQualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyQualification_1: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyQualification_1",
    defaultMessage: "Especialista em Empregos Verdes, Pelo Centro de Formação Internacional de Turim, da OIT",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyQualification_2: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyQualification_2",
    defaultMessage: "Coordenador Executivo do PAPE",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyQualification_3: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyQualification_3",
    defaultMessage: "Consultor",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyQualification_4: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyQualification_4",
    defaultMessage: "Especialista em gestão de projectos pela FGV- Brasil",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyQualification_5: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyQualification_5",
    defaultMessage: "Ponto Focal do Projecto de Apoio a Formação Profissional dos PALOP-TL",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyQualification_6: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyQualification_6",
    defaultMessage: "Membro fundador da cooperativa de crédito do FAJE -Cooperafaje",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_1",
    defaultMessage: "Auditor de stock - 03/04/2007 - 25/08/2007",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_2: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_2",
    defaultMessage: "Auxiliar de Contabilidade - 02/09/2007",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_3: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_3",
    defaultMessage: "Contabilista/Administrador - 03/10/2007",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_4: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyProfissionalExperience_4",
    defaultMessage: "Chefe de Departamento Nacional de Trabalho e Empreendedorismo - 2014-2018",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyLanguages_Title: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyLanguages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_1: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_1",
    defaultMessage: "Português (Falado e Escrito)",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_2: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_2",
    defaultMessage: "Inglês Básico",
    description: "Institutional Information - Director Biography",
  },
  Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_3: {
    id: "Label_Menu_InstitutionalInformation_DirectorBiographyLanguage_3",
    defaultMessage: "Francês Básico",
    description: "Institutional Information - Director Biography",
  },

  // Institutional Information - Institute Chiefs - Job Director
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Position: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Position",
    defaultMessage: "Director Geral Adjunto para o Emprego do INEFOP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Name_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Name_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Name: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Name",
    defaultMessage: "António Agostinho Pereira",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Birthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Birthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Birthdate: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Birthdate",
    defaultMessage: "20/10/1970",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Nationality_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Nationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Nationality: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Nationality",
    defaultMessage: "Luanda",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Studies_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Studies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Studies: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Studies",
    defaultMessage: "N/A",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_1",
    defaultMessage: "Formação em elaboração e implementação de programas produtivos para grupos vulneráveis",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_2",
    defaultMessage: "Formação em técnicas de emprego",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_3",
    defaultMessage: "Capacitação em estudos e analise do mercado de trabalho",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_4",
    defaultMessage: "Formação em estatisticas aplicadas do mercado de trabalho",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_5",
    defaultMessage: "Capacitação em Monotorização e Avaliação de Programas de Emprego",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_6",
    defaultMessage:
      "Formação em desenvolvimento de competências para uma maior produtividade, geração de emprego e desenvolvimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_7: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_7",
    defaultMessage: "Formação em gestão de serviços de emprego eficazes",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_8: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_8",
    defaultMessage: "Formação em gestão de Centros de Formação Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_9: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_9",
    defaultMessage: "Capacitação em Empreendedorismo e gestão de negócios",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_10: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_10",
    defaultMessage:
      "Formação de formadores do curso de sensibilização e começa o teu negócio para desmobilizados do exército",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_11: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_11",
    defaultMessage: "Formação em Conselheiro de Orientação Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_12: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Qualification_12",
    defaultMessage: "Capacitação sobre a elaboração e implementação de projectos de promoção de emprego jovem",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_1",
    defaultMessage:
      "Participação em reuniões de altos funcionários e peritos do sector do Trabalho da SADC - 2011 - 2023",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_2",
    defaultMessage: "Participação na preparação das reuniões dos Ministros do Trabalho da SADC - 2012 - 2021",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_3",
    defaultMessage: "Participação nas reuniões da SADC sobre Migração Laboral",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_4",
    defaultMessage: "Participação na elaboração de Políticas e Projectos de Emprego",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_5",
    defaultMessage:
      "Participação na preparação e realização dos encontros nacionais sobre emprego e formação profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_6",
    defaultMessage: "Coordenador da Unidade Técnica do Programa de Reconversão Profissional da Função Pública",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_7: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_7",
    defaultMessage: "Participação na elaboração da nova metodologia de oferta de serviços dos centros de emprego",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_8: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_8",
    defaultMessage:
      "Participação na elaboração do Programa de Revitalização e Modernização e Expansão dos Serviços Públicos de Emprego",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_9: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_9",
    defaultMessage:
      "Participação dos Estudos sobre Emprego Jovem em Angola, realizado entre o INEFOP e o Banco Mundial",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_10: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_10",
    defaultMessage: "Professor de alfabetização - INRP - Instituto Nacional de Reabilitação Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_11: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_11",
    defaultMessage: "Técnico de Integração Socioprofissional de pessoas com deficiência - INRP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_12: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_12",
    defaultMessage: "Formador de formadores do Programa CTN - Comece o Teu Negócio - Gov. Angola, OIT e PNUD",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_13: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_13",
    defaultMessage: "Chefe de Secção de Integração Socioprofissional - INRP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_14: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_14",
    defaultMessage: "Chefe de Secção de Colocação - INEFOP - Instituto Nacional de Emprego e Formação Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_15: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_15",
    defaultMessage: "Chefe de Divisão de Emprego - INEFOP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_16: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_16",
    defaultMessage: "Chefe de Departamento de Apoio ao Director Geral - INEFOP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_17: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_ProfissionalExperience_17",
    defaultMessage: "Chefe de Serviço Provincial do INEFOP de Luanda",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Languages_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Languages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Language_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Language_1",
    defaultMessage: "Português (falado e escrito)",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Language_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobDirector_Language_2",
    defaultMessage: "Inglês básico",
    description: "Institutional Information - Institute Chiefs",
  },

  //InstitutionalInformation - Institute Chiefs - FormationDirector
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Position: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Position",
    defaultMessage: "Directora Geral Adjunta da Área da Formação Profissional INEFOP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Name_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Name_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Name: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Name",
    defaultMessage: "Eduarda Ninõle Israel Cassangue",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Birthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Birthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Birthdate: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Birthdate",
    defaultMessage: "28/04/1965",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Nationality_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Nationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Nationality: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Nationality",
    defaultMessage: "Benguela",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Studies_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Studies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Studies: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Studies",
    defaultMessage: "Licenciada no Curso de Professores do Ensino Básico na Variante Matemática Ciências da Natureza",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_1",
    defaultMessage: "Formação de Gestão de Projetos",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_2",
    defaultMessage: "Formação para Formadores de Empreendedorismo",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_3",
    defaultMessage: "Formação Pedagógica dos Formadores com Base nas Tecnologias",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_4",
    defaultMessage: "Curso de Gestão Financeira",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_5",
    defaultMessage: "Curso de Língua Gestual",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_6",
    defaultMessage: "Curso de comércio electrónico",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_7: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_7",
    defaultMessage: "Curso da Microsof Office",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_8: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_8",
    defaultMessage: "Curso de Excel Avançado",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_9: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Qualification_9",
    defaultMessage: "Curso de Auditoria",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_1",
    defaultMessage:
      "Responsável pelo Pelouro de Emprego Empreendedorismo do PAPE e Secretaria Executiva do Plano de Acção para Promoção da Empregabilidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_2",
    defaultMessage:
      "Técnica Superior da Direcção Nacional das Condições e Rendimento do trabalho e Secretária Executiva do Plano de Ação para Promoção da Empregabilidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_3",
    defaultMessage: "Coordenadora do Observatório nacional de emprego e formação profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_4",
    defaultMessage: "Chefe do Departamento de Formação e Desenvolvimento Curricular do INEFOP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_5",
    defaultMessage:
      "Técnica Superior Responsável pelo Departamento de Certificação de certificação de Competências Profissionais do INEFOP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_6",
    defaultMessage: "Técnica Superior do Núcleo de Supervisão e Auditoria",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_7: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_7",
    defaultMessage: "Técnica Superior do Observatório de Emprego e Formação Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_8: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_8",
    defaultMessage:
      "Coordenadora Pedagógica/ Formadora do Centro Nacional de Formação de Formadores (CENNFFOR)-INEFOP-MAPTSS",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_9: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_ProfissionalExperience_9",
    defaultMessage: "Directora Pedagógica do Colégio Él-Oscar Benguela",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Languages_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Languages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Language_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_FormationDirector_Language_1",
    defaultMessage: "Português (falado e escrito)",
    description: "Institutional Information - Institute Chiefs",
  },

  //InstitutionalInformation - Institute Chiefs - Supervisor
  Label_Search_Menu_InstitutionalInformation_InstituteChiefs: {
    id: "Label_Search_Menu_InstitutionalInformation_InstituteChiefs",
    defaultMessage: "Menu_InstitutionalInformation_InstituteChiefs",
    description: "Prefix used to search InstitutionalInformation - Institute Chiefs information on resources",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Position: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Position",
    defaultMessage: "Chefe de Departamento de Supervisão e Qualidade da Formação Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Name_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Name_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Name: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Name",
    defaultMessage: "Tito Arnaldo Bravo de Jesus",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Birthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Birthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Birthdate: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Birthdate",
    defaultMessage: "15/12/1974",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Nationality_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Nationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Nationality: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Nationality",
    defaultMessage: "Luanda",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Studies_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Studies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Studies: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Studies",
    defaultMessage: "Licenciatura em Contabilidade e Gestão",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_1",
    defaultMessage: "Formação pedagógica inicial e contínua de formadores",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_2",
    defaultMessage: "Hardware",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_3",
    defaultMessage: "Língua portuguesa",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_4",
    defaultMessage: "Informática",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_5",
    defaultMessage: "Auditoria e supervisão pedagógica",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_6",
    defaultMessage: "Qualidade e normas ISO 9001",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_7: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_7",
    defaultMessage: "Metodologias de elaboração de Tecnicas para supervisão de CFP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_8: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Qualification_8",
    defaultMessage: "Auditoria interna",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_1",
    defaultMessage:
      "Instrutor de Alfabetização e assistente social dos militares internados no Centro Ortopédico de Viana - 1995 - 1998",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_2",
    defaultMessage: "Formador de contabilidade geral no CRPV - 1998 - 2013",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_3",
    defaultMessage: "Supervisor/Auditor do SNFP - 2013 - 2018",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_ProfissionalExperience_4",
    defaultMessage: "Actualmente Chefe deDep",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Languages_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Languages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Language_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_Supervision_Language_1",
    defaultMessage: "Português",
    description: "Institutional Information - Institute Chiefs",
  },

  //InstitutionalInformation - Institute Chiefs - InovationChief
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Position: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Position",
    defaultMessage: "Chefe de Departamento de Empreendedorismo e Inovação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Name_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Name_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Name: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Name",
    defaultMessage: "Felisbino Manuel Gaspar Narciso",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Birthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Birthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Birthdate: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Birthdate",
    defaultMessage: "30/04/1983",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Nationality_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Nationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Nationality: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Nationality",
    defaultMessage: "N/A",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Studies_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Studies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Studies: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Studies",
    defaultMessage: "Licenciatura em Gestão de Empresas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_1",
    defaultMessage: "Formação pedagógica inicial e contínua de formadores",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_2",
    defaultMessage: "Informática",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_3",
    defaultMessage: "Gestão de Projetos",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_4",
    defaultMessage: "Gestão de Indústrias Criativas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_5",
    defaultMessage: "Empreendorismo Verde",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_6",
    defaultMessage: "Técnicas de Empreendorismo e Inovação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_7: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_7",
    defaultMessage: "Análise e Gestão de Projetos",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_8: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_8",
    defaultMessage: "Planos de Negócios",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_9: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_9",
    defaultMessage: "Começa o seu negócio",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_10: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_10",
    defaultMessage: "Gestão do Negócio",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_11: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_11",
    defaultMessage: "Oratória",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_12: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Qualification_12",
    defaultMessage: "Gestão e chefia e outras formações fora da área de negócios actual",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_1",
    defaultMessage: "Gestão de pequeno negócio - 1999 - 2002",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_2",
    defaultMessage: "Técnico de electricidade em várias empresas do sector - 2002 - 2006",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_3",
    defaultMessage: "Trabalho em manutenção de geradores e AC - 2007 - 2008",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_4",
    defaultMessage: "Formador - 2008 - 2009",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_5",
    defaultMessage: "Técnico de produção - 2010 - 2011",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_6",
    defaultMessage: "Professor do ensino médio - 2011",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_7: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_7",
    defaultMessage: "Formador e técnico de incubação na incubadora de empresas do INEFOP - 2011 - 2018",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_8: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_ProfissionalExperience_8",
    defaultMessage: "Primeiro chefe de departamento de empreendedorismo e inovação do INEFOP - 2018 - 2023",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Languages_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Languages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Language_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Language_1",
    defaultMessage: "Português",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Language_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_InovationChief_Language_2",
    defaultMessage: "Inglês básico",
    description: "Institutional Information - Institute Chiefs",
  },

  //InstitutionalInformation - Institute Chiefs - GeneralChief
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Position: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Position",
    defaultMessage: "Chefe de Departamento da Administração e Serviços Gerais do INEFOP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Name_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Name_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Name: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Name",
    defaultMessage: "Áurea Janeth Gaspar Marreta Cassoma",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Birthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Birthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Birthdate: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Birthdate",
    defaultMessage: "02/01/1993",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Nationality_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Nationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Nationality: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Nationality",
    defaultMessage: "Luanda",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Studies_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Studies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Studies: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Studies",
    defaultMessage: "Licenciatura em Administração e Serviços Gerais",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_1",
    defaultMessage: "Organização e Gestão de Empresas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_2",
    defaultMessage: "Auditoria Financeira",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_3",
    defaultMessage: "Contabilidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Qualification_4",
    defaultMessage: "Formação do SIGFPE no INFORFIP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_1",
    defaultMessage: "Assistente Comercial do Banco Bic Angola - 2014 - 2015",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_2",
    defaultMessage:
      "Técnica de Departamento de Administração e Serviços Gerais do Ministério da Industria - 2015 - 2020",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_3",
    defaultMessage: "Contablista -AGDN Comercio e Master Câmbios - 2016 - 2020",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_4",
    defaultMessage: "Técnica de Departamento de Administração e Serviços Gerais do INEFOP - 2020 - 2021",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_ProfissionalExperience_5",
    defaultMessage: "Chefe de Departamento da Administração e Serviços Gerais do INEFOP - 2021 - Até à data atual",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Languages_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Languages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Language_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Language_1",
    defaultMessage: "Português",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Language_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_GeneralChief_Language_2",
    defaultMessage: "Inglês básico",
    description: "Institutional Information - Institute Chiefs",
  },

  //InstitutionalInformation - Institute Chiefs - ModernChief
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Position: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Position",
    defaultMessage: "Chefe De Departamento de Comunicação, Inovação Tecnologia e Modernização dos Serviços",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Name_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Name_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Name: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Name",
    defaultMessage: "Américo Guedes Pinto Souto",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Birthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Birthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Birthdate: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Birthdate",
    defaultMessage: "18/08/1983",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Nationality_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Nationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Nationality: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Nationality",
    defaultMessage: "Luanda",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Studies_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Studies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Studies: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Studies",
    defaultMessage:
      "Frequência em Informática de Gestão na Universidade Lusiadas de Angola (2001 - 2006) e Frequência em Engenharia de Contrução Civil na Universidade Jeant Peaget (2001 - 2004)",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_1",
    defaultMessage: "Especialidade em Criação de Startup e Inovação, Universidade de São Paulo no Brasil",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_2",
    defaultMessage: "Especialidade em Inbound Marketing, Universidade de São Paulo no Brasil",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_3",
    defaultMessage: "Certificado Internacional em Engenharia de Sistemas Microsoft 2008 (MCSE)",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_4",
    defaultMessage: "Certificado Internacional de Profissional em Redes Ciscos (CCNP)",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_5",
    defaultMessage: "Certificado Internacional de Instrutor para Academia de Redes Cisco Associado (CCNA)",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Qualification_6",
    defaultMessage: "Formação Pedagógica de Formadores",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_1",
    defaultMessage:
      "Iniciou funções como colaborador do INEFOP em Julho de 2006, tendo efectivamente ingressado no regime especial de formadores em 2010, participando do desenvolvimento de sistemas de informação para os centros e serviços de emprego de 2006 até 2009. Após termino do projecto, de 2009 a 2012, colaborou como formador no Centro de Formação Profissional de Construção Civil de Viana, tendo implementado naquela unidade a primeira Academia de Redes Cisco nas Unidades do INEFOP, em 2013, aos 30 anos, tornou-se o primeiro Director do Gabinete de Tecnologias de Informação do MAPTSS, no qual dirigiu projectos para a implementação de Sistemas de Informação para a Função Pública, como SINGERH, SIIGT, SIGEPA, tendo sessado funções em Março de 2018. Regressou para o quadro de pessoal do INEFOP em 2019, tendo integrado o pelouro de comunicação do PAPE, e posteriormente nomeado para a função de Chefe de Departamento de Comunicação, Inovação Tecnológica e Modernização dos Serviços em 2021.",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_2",
    defaultMessage:
      "Projecto de desenvolvimento de sistemas de informação para os centros e serviços de emprego - 2006 - 2009",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_3",
    defaultMessage: "Centro de Formação Profissional de Construção Civil de Viana - 2010 - 2012",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_4",
    defaultMessage: "Director do Gabinete de Tecnologias de Informação do MAPTSS - 2013 - 2018",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_5",
    defaultMessage: "Técnico de Comunicação do PAPE - 2019 - 2021",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_ProfissionalExperience_6",
    defaultMessage: "Chefe de Departamento de Comunicação, Inovação Tecnológica e Modernização dos Serviços - 2021",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_Title",
    defaultMessage: "Outras referências",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_1",
    defaultMessage:
      "2012 - Criou em sociedade a empresa Eventos Online, pioneira na transmissão de eventos em streaming na internet, tendo trabalhado com a UNITEL e Nova Energia e Ghassist até 2020",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_2",
    defaultMessage: "2014 - Criou em sociedade o Restaurante Bar Plano B, sito na via expressa",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_OtherReferences_3",
    defaultMessage: "2016 - Criou em sociedade a empresa de Formação e Desenvolvimento pessoal “Valor Acrescido”",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Languages_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Languages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Language_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Language_1",
    defaultMessage: "Português (falado e escrito)",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Language_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_ModernChief_Language_2",
    defaultMessage: "Inglês básico",
    description: "Institutional Information - Institute Chiefs",
  },

  //InstitutionalInformation - Institute Chiefs - CertChief
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Position: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Position",
    defaultMessage: "Chefe De Departamento de Formação e Certificação Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Name_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Name_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Name: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Name",
    defaultMessage: "Belchior Ferreira De Almeida",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Birthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Birthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Birthdate: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Birthdate",
    defaultMessage: "24/04/1984",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Nationality_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Nationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Nationality: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Nationality",
    defaultMessage: "Luanda",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Studies_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Studies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Studies: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Studies",
    defaultMessage: "Arquitectura e urbanismo, e frequência do curso superior de Economia e gestão pela Unipiaget",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualification_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualification_1",
    defaultMessage: "Electrotecnia",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualification_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualification_2",
    defaultMessage: "Experiência profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualification_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Qualification_3",
    defaultMessage: "Mentoria em electrotecnia",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_1",
    defaultMessage: "Técnico de electricidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_2",
    defaultMessage: "Electrónica",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_3",
    defaultMessage: "Formador de electricidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_ProfissionalExperience_4",
    defaultMessage: "Técnico de obra c. Civil",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Languages_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Languages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Language_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_CertChief_Language_1",
    defaultMessage: "Português",
    description: "Institutional Information - Institute Chiefs",
  },

  //InstitutionalInformation - Institute Chiefs - HelpChief
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Position: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Position",
    defaultMessage: "Chefe De Departamento de Apoio ao Director Geral",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Name_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Name_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Name: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Name",
    defaultMessage: "Henriqueta Manuel Faustino Domingos",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Birthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Birthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Birthdate: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Birthdate",
    defaultMessage: "30/12/1968",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Nationality_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Nationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Nationality: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Nationality",
    defaultMessage: "Luanda",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Studies_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Studies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Studies: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Studies",
    defaultMessage: "Licenciada no Curso de Secretariado Executivo e Comunicação Empresarial",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_1",
    defaultMessage: "Curso de Datilografia e Mecanografia",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_2",
    defaultMessage: "Técnicas de Secretariado curso de chefias administrativas: Eficácia Pessoal",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_3: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_3",
    defaultMessage: "Técnicas Administrativas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_4: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_4",
    defaultMessage: "Curso de Informática",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_5: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_5",
    defaultMessage: "Curso de Microsoft Office",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_6: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_6",
    defaultMessage: "Curso de Gestão Documental",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_7: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_7",
    defaultMessage: "Curso de Secretariado Executivo",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_8: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Qualification_8",
    defaultMessage: "Formação da Pauta Deontológica",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperience_1",
    defaultMessage: "Escriturária Datilógrafa - 1989 - 2018",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperience_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperience_2",
    defaultMessage: "Chefe do Departamento de Apoio ao Gabinete do Director Geral do INEFOP - 2018 - Atualidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_OtherReferences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_ProfissionalExperience_6",
    defaultMessage: "Outras referências",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_OtherReferences_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_OtherReferences_1",
    defaultMessage: "Chefe do Departamento de Apoio ao Gabinete do Director do INEFOP",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Languages_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Languages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Language_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Language_1",
    defaultMessage: "Português (falado e escrito)",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Language_2: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_HelpChief_Language_2",
    defaultMessage: "Inglês básico",
    description: "Institutional Information - Institute Chiefs",
  },

  //InstitutionalInformation - Institute Chiefs - Job Service Manager
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Position: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Position",
    defaultMessage: "Chefe De Departamento de Gestão e Serviços de Emprego",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Name_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Name_Title",
    defaultMessage: "Nome",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Name: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Name",
    defaultMessage: "Fragoso Carlos",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Birthdate_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Birthdate_Title",
    defaultMessage: "Data de Nascimento",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Birthdate: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Birthdate",
    defaultMessage: "N/A",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Nationality_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Nationality_Title",
    defaultMessage: "Naturalidade",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Nationality: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Nationality",
    defaultMessage: "N/A",

    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Studies_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Studies_Title",
    defaultMessage: "Formação",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Studies: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Studies",
    defaultMessage: "N/A",

    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Qualifications_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Qualifications_Title",
    defaultMessage: "Qualificações",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Qualification_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Qualification_1",
    defaultMessage: "N/A",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_ProfissionalExperiences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_ProfissionalExperiences_Title",
    defaultMessage: "Experiência Profissional",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_ProfissionalExperience_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_ProfissionalExperience_1",
    defaultMessage: "N/A",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_OtherReferences_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_ProfissionalExperience_6",
    defaultMessage: "Outras referências",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_OtherReferences_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_OtherReferences_1",
    defaultMessage: "N/A",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Languages_Title: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Languages_Title",
    defaultMessage: "Idiomas",
    description: "Institutional Information - Institute Chiefs",
  },
  Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Language_1: {
    id: "Label_Menu_InstitutionalInformation_InstituteChiefs_JobServiceManager_Language_1",
    defaultMessage: "N/A",
    description: "Institutional Information - Institute Chiefs",
  },

  // Entrepreneurship Services
  Label_Search_Menu_Information_EntrepreneurshipServices: {
    id: "Label_Search_Menu_Information_EntrepreneurshipServices",
    defaultMessage: "Menu_Information_EntrepreneurshipServices",
    description: "Prefix used to search EntrepreneurshipServices - EntrepreneurshipServices information on resources",
  },
  Label_Menu_Information_EntrepreneurshipServicesTitle: {
    id: "Label_Menu_Information_EntrepreneurshipServicesTitle",
    defaultMessage: "Serviços de Empreendedorismo e Inovação",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesText1: {
    id: "Label_Menu_Information_EntrepreneurshipServicesText1",
    defaultMessage:
      "Estando o país a postar fortemente na diversificação da económia e, consequentemente, no combate ao desemprego, o fomento da cultura do empreendedorismo, assume-se como uma ferramenta eficaz para a criação de oportunidades geradoras de negócios e rendimentos susceptíveis de proporcionar a oferta de bens e serviço às comunidades e permitir o desenvolvimento de MPME e das famílias.",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesText2: {
    id: "Label_Menu_Information_EntrepreneurshipServicesText2",
    defaultMessage:
      "Deste modo, e com vista a contribuir para os esforços do executivos, serviços de empreendedorismo do INEFOP, apoia-se através das unidades de empreendedorismo, as Incubadoras de Empresas nos Centros Locais de Empreendedorismo e Serviços de Emprego, Centros Municipais de Empreendedorismo e Serviços de Emprego, Pró-Trabalhos e empreendedorismo na comunidade, estão criadas as condições necessárias para criação dos próprio negócio que se ajustarem ao contexto actual sócio-económico, assim como melhorando, permanentemente os seus serviços, assim como alinharem os conteúdos formativos às diferentes realidades .",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesCompanyIncubator_Title: {
    id: "Label_Menu_Information_EntrepreneurshipServicesCompanyIncubator_Title",
    defaultMessage: "Incubadora de Empresas (IEMP):",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesCompanyIncubator_Text1: {
    id: "Label_Menu_Information_EntrepreneurshipServicesCompanyIncubator_Text1",
    defaultMessage:
      "A Incubadora de Empresas (IEMP), são serviços integrados do Instituto Nacional de Emprego e Formação Profissional (INEFOP), é uma unidade de serviço, cujo objecto social, está voltado para estimular a criação e o desenvolvimento de micro e pequenas empresas industriais ou de prestação de serviços, por meio da formação complementar do empreendedor nos seus aspectos técnicos, de gestão para facilitar e agilizar o processo de inovação tecnológica nas empresas. Para tanto, conta com um espaço físico especialmente adaptado para alojar temporariamente micro e pequenas empresas de prestação de serviços/produtos, dispõe de uma série de serviços e facilidades.",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesLocalCenters_Title: {
    id: "Label_Menu_Information_EntrepreneurshipServicesLocalCenters_Title",
    defaultMessage: "Centro Locais de empreendedorismo e serviços de emprego (CLESE:",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesLocalCenters_Text1: {
    id: "Label_Menu_Information_EntrepreneurshipServicesLocalCenters_Text1",
    defaultMessage:
      "São serviços executivos indirectos do INEFOP, de âmbito provincial, vocacionado para a formação profissional especializada no domínio de gestão empresárial e empreendedorismo, apoio à criação e desenvolvimento de micro, pequenas e médias empresas (MPME), bem como para a intermediação da procura e oferta de mão-de-obra, criado ao abrigo do Decreto Executivo nº 248/13, de 9 de setembro.",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesLocalCenters_Text2: {
    id: "Label_Menu_Information_EntrepreneurshipServicesLocalCenters_Text2",
    defaultMessage:
      "A par do processo formativo, os mesmos têm a missão de prestar serviço de pré-incubação, incubação física e incubação virtual, no sentido de proporcionar aos empreendedores um ambiente adequado para o funcionamento das empresas, com serviços especializados de orientação empresarial, espaço físico comum e infra-estruturas técnicas e administrativas.",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesAfterWork1_Title: {
    id: "Label_Menu_Information_EntrepreneurshipServicesAfterWork1_Title",
    defaultMessage: "Pró-Trabalho.",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesAfterWork1_Text1: {
    id: "Label_Menu_Information_EntrepreneurshipServicesAfterWork1_Text1",
    defaultMessage:
      "Considerando a necessidade organizar no seio das comunidades o exercício de ocupações laborais por profissões com reconhecida competência, bem como de proporcionar condições adequadas para que novos agentes profissionais possam exercer as suas actividades laborais com eficiência e qualidades surgem os Pró - Trabalhos;.",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesAfterWork2_Title: {
    id: "Label_Menu_Information_EntrepreneurshipServicesAfterWork2_Title",
    defaultMessage: "Pró–Trabalho (pavilhões ocupacionais de prestação de serviços):",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesAfterWork2_Text1: {
    id: "Label_Menu_Information_EntrepreneurshipServicesAfterWork2_Text1",
    defaultMessage:
      "São serviços executivos Indirectos dos serviços provinciais do INEFOP, vocacionados incentivar e auxiliar a realização de actividades profissionais especializadas nas comunidades em condições de higêne e segurança no trabalho, bem como a participar no processo de reconhecimento, validação e certificação de competências em contexto real de trabalho.",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesProgram_Title: {
    id: "Label_Menu_Information_EntrepreneurshipServicesProgram_Title",
    defaultMessage: "Programa de Empreendedorismo na Comunidade:",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesProgram_Text1: {
    id: "Label_Menu_Information_EntrepreneurshipServicesProgram_Text1",
    defaultMessage:
      "São Programas integrados de empreendedorismo, e para disseminar cultura de criação de seu próprio negócio nas zonas rurais e suburbanas, através de acções decapacitação na gestão de pequenos negócios, de modo a permitir que os cidadãos possam identificar e implementar oportunidades geradoras de rendimentos e susceptíveis de propiciar a oferta de bens e serviços às comunidades. Ministrar acções de capacitação, visando o incremento das capacidades de Gestão Empresarial de micro e pequenas empresas familiares ou etc…;",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Title: {
    id: "Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Title",
    defaultMessage: "Programa de Apoio ao Micro- Crédito",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Text1: {
    id: "Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Text1",
    defaultMessage:
      "Em parceria com os Bancos comerciais, conceder micro-créditos de forma bonificada e sem a necessidade de apresentar nenhum tipo de garantias reais por parte do micro ou pequeno empresário;",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Text2: {
    id: "Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Text2",
    defaultMessage: "Programa de Fomento de Auto-Emprego.",
    description: "Information - EntrepreneurshipServices",
  },
  Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Text3: {
    id: "Label_Menu_Information_EntrepreneurshipServicesMicrocredit_Text3",
    defaultMessage:
      "É Programa de Concessão de ferramentas de trabalho - equipamentos (kits) profissionais de caracter oficinal e/ou individual, por forma a aumentar a capacidade dos pequenos empresários, na oferta de bens e serviços às comunidades.",
    description: "Information - EntrepreneurshipServices",
  },

  //InstitutionalInformation - OrganizationChart
  Label_Search_Menu_InstitutionalInformation_OrganizationChart: {
    id: "Label_Search_Menu_InstitutionalInformation_OrganizationChart",
    defaultMessage: "Menu_InstitutionalInformation_OrganizationChart",
    description: "Prefix used to search OrganizationChart - OrganicStatus information on resources",
  },

  //InstitutionalInformation - OrganicStatus
  Label_Search_Menu_InstitutionalInformation_OrganicStatus: {
    id: "Label_Search_Menu_InstitutionalInformation_OrganicStatus",
    defaultMessage: "Menu_InstitutionalInformation_OrganicStatus",
    description: "Prefix used to search InstitutionalInformation - OrganicStatus infomration on resources",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree",
    defaultMessage: "DECRETO PRESIDENCIAL N.º 128/15, DE 2 DE JUNHO",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_1",
    defaultMessage:
      "Convindo assegurar, no plano institucional, a execução articulada e coordenada das políticas sectoriais de emprego e formação profissional;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_2",
    defaultMessage:
      "Havendo necessidade de se adequar o Estatuto Orgânico do Instituto Nacional de Emprego e Formação Profissional, às novas Regras de Organização, Estruturação e Funcionamento dos Institutos Públicos estabelecidas pelo Decreto Legislativo Presidencial n.º 2/13, de 25 de Junho;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_3",
    defaultMessage:
      "O Presidente da República decreta, nos termos da alínea d) do artigo 120.º e do n.º 1 do artigo 125.º, ambos da Constituição da República de Angola, o seguinte:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_1",
    defaultMessage: "ARTIGO 1.º (Aprovação)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_1_Description: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_1_Description",
    defaultMessage:
      "É aprovado o Estatuto Orgânico do Instituto Nacional de Emprego e Formação Profissional, anexo ao presente Decreto Presidencial e que dele é parte integrante.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_2",
    defaultMessage: "ARTIGO 2.º (Revogação)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_2_Description: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_2_Description",
    defaultMessage:
      "É revogada toda a legislação que contrarie o disposto no presente Diploma, nomeadamente o Decreto Executivo Conjunto n.º 18/02, de 17 de Maio.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_3",
    defaultMessage: "ARTIGO 3.º (Dúvidas e omissões)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_3_Description: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_3_Description",
    defaultMessage:
      "As dúvidas e omissões resultantes da interpretação e aplicação do presente Diploma são resolvidas pelo Presidente da República.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_4: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_4",
    defaultMessage: "ARTIGO 4.º (Entrada em vigor)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_4_Description: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_4_Description",
    defaultMessage:
      "O presente Decreto Presidencial entra em vigor na data da sua publicação. Apreciado em Conselho de Ministros, em Luanda, aos 2 de Abril de 2015. Publique-se.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_4: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_4",
    defaultMessage: "Luanda, aos 21 de Maio de 2015.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_5: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_5",
    defaultMessage: "O Presidente da República, José Eduardo dos Santos.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1",
    defaultMessage: "ESTATUTO ORGÂNICO DO INSTITUTO NACIONAL DE EMPREGO E FORMAÇÃO PROFISSIONAL - CAPÍTULO I",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Description_1",
    defaultMessage: "DISPOSIÇÕES GERAIS",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_1",
    defaultMessage: "ARTIGO 1.º (Natureza e objecto)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_1_Description: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_1_Description",
    defaultMessage:
      "O Instituto Nacional de Emprego e Formação Profissional, abreviadamente designado por «INEFOP», é um Instituto Público do Sector Social, dotado de personalidade jurídica, autonomia administrativa, financeira e patrimonial que tem como missão assegurar a execução das políticas relativas à organização do mercado de emprego, bem como a direcção e coordenação do sistema de formação profissional e a aplicação dos programas nos domínios da formação e reabilitação profissional definidas e aprovadas pelo Governo.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_2",
    defaultMessage: "ARTIGO 2.º (Regime jurídico)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_2_Description: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_2_Description",
    defaultMessage:
      "O INEFOP rege-se pelo presente Estatuto, demais regulamentos que o venham complementar e subsidiariamente, pela legislação que lhe seja aplicável.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_3",
    defaultMessage: "ARTIGO 3.º (Sede e âmbito)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_3_Description: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_3_Description",
    defaultMessage: "O INEFOP tem a sua sede em Luanda e serviços locais em todas as províncias.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_4: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_4",
    defaultMessage: "ARTIGO 4.º (Superintendência)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_4_Description: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_4_Description",
    defaultMessage:
      "O INEFOP funciona sob superintendência do titular do Órgão Responsável pela Administração do Trabalho.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5",
    defaultMessage: "ARTIGO 5.º (Atribuições)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description",
    defaultMessage: "O INEFOP tem as seguintes atribuições:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_1",
    defaultMessage:
      "Assegurar a execução das políticas sobre a organização do mercado de emprego e da formação profissional;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_2",
    defaultMessage:
      "Assegurar a coordenação dos sistemas nacionais de emprego e formação profissional e sua articulação com o sistema de educação, relativamente aos perfis de emprego de competências profissionais, por forma a acompanhar e potenciar o desenvolvimento económico e social do País;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_3",
    defaultMessage: "Promover a divulgação e o conhecimento dos programas públicos de emprego e formação profissional;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_4: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_4",
    defaultMessage:
      "Participar na organização do mercado de emprego com vista a valorização sócio profissional dos recursos humanos a nível nacional;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_5: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_5",
    defaultMessage:
      "Promover as actividades que visam a certificação de competências profissionais em cooperação com as entidades empregadoras, representações profissionais e outras;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_6: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_6",
    defaultMessage:
      "Promover e assegurar a igualdade de oportunidades no acesso à informação, orientação, formação profissional e ao emprego, especialmente para jovens e outros grupos sociais mais vulneráveis;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_7: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_7",
    defaultMessage:
      "Contribuir para a melhoria da produtividade nas empresas mediante o apoio à realização de programas e acções de formação profissional inicial e contínua da mão-de-obra nacional nas diversas modalidades e nos diferentes níveis de qualificação;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_8: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_8",
    defaultMessage:
      "Colaborar na definição e avaliação das políticas sobre a organização do mercado de emprego e a formação profissional, em articulação com os órgãos competentes;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_9: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_9",
    defaultMessage:
      "Apoiar e executar programas e acções no domínio da reabilitação profissional e prestar colaboração com as entidades que realizam projectos e actividades neste âmbito;",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_10:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_10",
      defaultMessage:
        "Propor, orientar e acompanhar a execução de programas conducentes à preparação de formadores e gestores de instituições no âmbito do sistema de formação profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_11:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_11",
      defaultMessage:
        "Conceber, aplicar e desenvolver programas e acções de formação profissional em coordenação com as organizações empresariais e demais parceiros económicos e sociais;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_12:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_12",
      defaultMessage:
        "Propor, orientar e acompanhar a execução de programas no âmbito dos sistemas de emprego e de formação profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_13:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_13",
      defaultMessage:
        "Estabelecer relações de cooperação e de intercâmbio, com entidades congéneres de outros países, com vista a adopção de medidas para a melhoria do desempenho dos sistemas nacionais de emprego e de formação profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_14:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_14",
      defaultMessage: "Exercer as demais atribuições estabelecidas por lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2",
    defaultMessage: "ESTATUTO ORGÂNICO DO INSTITUTO NACIONAL DE EMPREGO E FORMAÇÃO PROFISSIONAL - CAPÍTULO II",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Description_1",
    defaultMessage: "ORGANIZAÇÃO EM GERAL",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6",
    defaultMessage: "ARTIGO 6.º (Órgãos e serviços)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1",
    defaultMessage: "O INEFOP compreende os seguintes órgãos:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_1",
      defaultMessage: "Conselho de Administração;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_2",
      defaultMessage: "Director Geral;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_3",
      defaultMessage: "Conselho Consultivo Alargado;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_4",
      defaultMessage: "Conselho Fiscal.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2",
    defaultMessage: "O INEFOP compreende os seguintes Serviços de Apoio Agrupados:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2_BulletPoint_1",
      defaultMessage: "Departamento de Apoio ao Director Geral;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2_BulletPoint_2",
      defaultMessage: "Departamento de Administração e Serviços Gerais;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2_BulletPoint_3",
      defaultMessage: "Departamento dos Recursos Humanos e das Tecnologias de Informação.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3",
    defaultMessage: "O INEFOP compreende os seguintes Serviços Executivos:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_1",
      defaultMessage: "Departamento de Gestão e Serviços de Emprego;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_2",
      defaultMessage: "Departamento de Formação Profissional e de Desenvolvimento Curricular;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_3",
      defaultMessage: "Departamento  de Empreendedorismo;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_4",
      defaultMessage: "Departamento de Supervisão e Qualidade;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_5",
      defaultMessage: "Departamento de Certificação Profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_6",
      defaultMessage: "Serviços Provinciais e Serviços Executivos Externos.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3",
    defaultMessage: "ESTATUTO ORGÂNICO DO INSTITUTO NACIONAL DE EMPREGO E FORMAÇÃO PROFISSIONAL - CAPÍTULO III",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_1",
    defaultMessage: "ORGANIZAÇÃO EM ESPECIAL - SECÇÃO I",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_2",
    defaultMessage: "ÓRGÃOS DE GESTÃO",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7",
    defaultMessage: "ARTIGO 7.º (Conselho de Administração)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_1",
    defaultMessage:
      "O Conselho de Administração é o órgão colegial de gestão que delibera sobre aspectos fundamentais relativos ao funcionamento e actividade do INEFOP.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2",
    defaultMessage: "O Conselho de Administração tem a seguinte composição:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_1",
      defaultMessage: "Director Geral que o preside;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_2",
      defaultMessage: "Directores  Gerais-Adjuntos;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_3",
      defaultMessage: "Chefes de Departamento;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_4",
      defaultMessage: "Dois vogais, designados pelo titular do Órgão de Superintendência.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3",
    defaultMessage: "O Conselho de Administração tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3_BulletPoint_1",
      defaultMessage: "Aprovar os instrumentos de gestão previsional e os documentos de prestação de contas do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3_BulletPoint_2",
      defaultMessage: "Aprovar a organização técnica e administrativa, bem como os regulamentos internos;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3_BulletPoint_3",
      defaultMessage: "Proceder ao acompanhamento regular da actividade do INEFOP.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_4: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_4",
    defaultMessage:
      "O Conselho de Administração reúne-se mensalmente, e a título extraordinário, sempre que convocado pelo seu presidente.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_5: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_5",
    defaultMessage:
      "As deliberações do Conselho de Administração são aprovadas por maioria, e o presidente tem voto de qualidade em caso de empate.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_6: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_6",
    defaultMessage:
      "O Director Geral do INEFOP convoca anualmente um Conselho Consultivo Alargado, com o objectivo de realizar o balanço geral das actividades do Instituto, devendo a reunião ocorrer no primeiro trimestre de cada ano civil.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7",
    defaultMessage: "O Conselho Consultivo tem a seguinte composição:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7_BulletPoint_1",
      defaultMessage: "Director Geral que o preside;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7_BulletPoint_2",
      defaultMessage: "Directores  Gerais-Adjuntos;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7_BulletPoint_3",
      defaultMessage: "Chefes de Departamento.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8",
    defaultMessage: "ARTIGO 8.º (Director Geral)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_1",
    defaultMessage:
      "O Director Geral é o órgão singular de gestão do INEFOP, nomeado pelo titular do Departamento Ministerial que exerce a superintendência sobre o referido Instituto.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2",
    defaultMessage: "O Director Geral tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_1",
      defaultMessage: "Exercer os poderes gerais de gestão técnica, administrativa e patrimonial;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_2",
      defaultMessage: "Propor a nomeação e exoneração dos responsáveis do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_3",
      defaultMessage: "Controlar directamente ou através dos órgãos e serviços competentes toda actividade do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_4",
      defaultMessage: "Assegurar a correcta gestão administrativa, financeira e patrimonial do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_5",
      defaultMessage:
        "Submeter ao Órgão de Superintendência e ao Tribunal de Contas os instrumentos de gestão, após parecer do Conselho Fiscal;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_6",
      defaultMessage: "Exarar ordens de serviço e instruções necessárias ao bom funcionamento do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_7",
      defaultMessage: "Representar o INEFOP em juízo ou fora dele;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_8",
      defaultMessage: " Exercer as demais competências estabelecidas por lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_3",
    defaultMessage:
      "O Director Geral é coadjuvado por 2 (dois) Directores Gerais-Adjuntos, nomeados pelo titular do Departamento Ministerial responsável pelo INEFOP, sendo um responsável pela área do emprego e outro pela área da formação profissional, de entre os quais designa sempre um que o substitui nas suas ausências e impedimentos.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9",
    defaultMessage: "ARTIGO 9.º (Conselho Consultivo Alargado)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_1",
    defaultMessage:
      "O Conselho Consultivo Alargado é o órgão de consulta encarregue de analisar e pronunciar-se sobre os princípios gerais a que deve obedecer a actividade do INEFOP.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2",
    defaultMessage: "O Conselho Consultivo tem a seguinte composição:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_1",
      defaultMessage: "Director Geral que o preside;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_2",
      defaultMessage: "Directores  Gerais-Adjuntos;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_3",
      defaultMessage: "Chefes de Departamentos;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_4",
      defaultMessage: "Chefes dos Serviços Provinciais;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_5",
      defaultMessage: "Técnicos do INEFOP quando convocados pelo Director Geral;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_6",
      defaultMessage:
        "Entidades especializadas, a convite expresso do INEFOP com anuência do Órgão de Superintendência.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3",
    defaultMessage: "O Conselho Consultivo alargado tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_1",
      defaultMessage: "Analisar a política, a estratégia, os planos e orçamentos plurianuais do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_2",
      defaultMessage: "Analisar os relatórios de actividades e de execução do orçamento do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_3",
      defaultMessage:
        "Analisar as necessidades de pessoal do INEFOP e a política de recursos humanos do emprego e da formação profissional a adoptar;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_4",
      defaultMessage:
        "Pronunciar-se sobre as acções de reestruturação ou dinamização do Instituto, assegurando a necessária coordenação entre as áreas envolvidas e os restantes órgãos do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_5",
      defaultMessage: "Apreciar e pronunciar-se sobre questões de carácter social dos funcionários do INEFOP.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_4: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_4",
    defaultMessage: "O Conselho Consultivo Alargado rege-se por um Regulamento Interno a aprovar pelo INEFOP.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10",
    defaultMessage: "ARTIGO 10.º (Conselho Fiscal)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_1",
    defaultMessage:
      "O Conselho Fiscal é o órgão de controlo e fiscalização interna ao qual cabe analisar e emitir parecer sobre todas as matérias de índole económico-financeira e patrimonial sobre a actividade do INEFOP.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_2",
    defaultMessage:
      "O Conselho Fiscal é composto por 3 (três) membros, sendo o Presidente indicado pelo titular do Órgão responsável pelas Finanças Públicas e por 2 (dois) vogais indicados pelo titular do Órgão que superintende o Instituto, devendo um deles ser especialista em contabilidade pública.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3",
    defaultMessage: "O Conselho Fiscal tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_1",
      defaultMessage:
        "Analisar e emitir parecer de índole económico- -financeira e patrimonial sobre a actividade do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_2",
      defaultMessage:
        "Emitir nos prazos legais, parecer sobre as contas anuais, relatório de actividades e a proposta de orçamento privativo do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_3",
      defaultMessage: "Emitir parecer sobre o cumprimento das normas reguladoras do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_4",
      defaultMessage:
        "Proceder à verificação regular dos fundos existentes e fiscalizar a escrituração da contabilidade.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_4: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_4",
    defaultMessage:
      "O Conselho Fiscal é nomeado pelo titular do Órgão de Superintendência do INEFOP, reúne-se trimestralmente, e a título extraordinário, sempre que convocado pelo seu Presidente.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_3",
    defaultMessage: "ORGANIZAÇÃO EM ESPECIAL - SECÇÃO II",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_4: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_4",
    defaultMessage: "SERVIÇOS DE APOIO AGRUPADOS",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11",
    defaultMessage: "ARTIGO 11.º (Departamento de Apoio ao Director Geral)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_1",
    defaultMessage:
      "O Departamento de Apoio ao Director Geral é o serviço encarregue das funções de secretariado de direcção, assessoria jurídica, intercâmbio, documentação e informação.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2",
    defaultMessage: "O Departamento de Apoio ao Director Geral tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_1",
      defaultMessage:
        "Proceder à análise global dos programas realizados pelo INHFOP e apoiar na elaboração dos respectivos relatórios;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_2",
      defaultMessage: "Promover a difusão de publicações e a boa imagem do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_3",
      defaultMessage:
        "Propor a realização de acções tendentes a promover o relacionamento e a cooperação com organismos congéneres;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_4",
      defaultMessage:
        "Participar na elaboração e no asseguramento do cumprimento de acordos e protocolos tanto no plano interno como no plano internacional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_5",
      defaultMessage:
        "Prestar assessoria jurídica sobre questões que, com tal natureza, lhe sejam submetidas pelo órgão de direcção do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_6",
      defaultMessage: "Emitir pareceres e elaborar estudos jurídicos;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_7",
      defaultMessage: "Representar o INEFOP em actos jurídicos para o qual seja designado;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_8",
      defaultMessage: "Velar pela organização, distribuição e circulação do expediente administrativo;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_9:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_9",
      defaultMessage:
        "Registar, arquivar e manter em bom estado de conservação, toda a documentação recepcionada e expedida;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_10:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_10",
      defaultMessage: "Executar todas as tarefas inerentes à função de secretariado;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_11:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_11",
      defaultMessage: "Exercer as demais competências estabelecidas por lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_3",
    defaultMessage: "O Departamento de Apoio ao Director Geral é dirigido por um Chefe de Departamento.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12",
    defaultMessage: "ARTIGO 12.º (Departamento de Administração e Serviços Gerais)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_1",
    defaultMessage:
      "O Departamento de Administração e Serviços Gerais é o serviço encarregue pela gestão orçamental, finanças, património, transporte, relações públicas e protocolo.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2",
    defaultMessage: "O Departamento de Administração e Serviços Gerais tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_1",
      defaultMessage: "Conceber, propor, implementar o sistema administrativo e de gestão financeira do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_2",
      defaultMessage: "Elaborar, propor, executar e controlar o orçamento do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_3",
      defaultMessage:
        "Assegurar a coordenação e o controlo financeiro dos projectos desenvolvidos no âmbito do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_4",
      defaultMessage:
        "Analisar, propor, difundir normas e procedimentos de trabalho para as distintas áreas do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_5",
      defaultMessage:
        "Apresentar o relatório e contas do INEFOP com a periodicidade requerida pelos órgãos superiores;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_6",
      defaultMessage: "Elaborar e manter o registo contabilístico de acordo com as normas legais vigentes;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_7",
      defaultMessage: "Elaborar, actualizar o inventário do património do INEFOP e velar pela sua conservação;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_8",
      defaultMessage: "Proceder a aquisição de bens e equipamentos necessários para as diferentes áreas do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_9:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_9",
      defaultMessage: "Exercer as demais competências estabelecidas por lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_3",
    defaultMessage: "O Departamento de Administração e Serviços Gerais é dirigido por um Chefe de Departamento.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13",
    defaultMessage: "ARTIGO 13.º (Departamento de Recursos Humanos e das Tecnologias de Informação)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_1",
    defaultMessage:
      "O Departamento de Recursos Humanos e das Tecnologias de Informação é o serviço encarregue da gestão de pessoal, modernização e inovação.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2",
    defaultMessage: "O Departamento de Recursos Humanos e das Tecnologias de informação tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_1",
      defaultMessage:
        "Garantir a gestão previsional dos recursos humanos do INEFOP, através dos instrumentos adequados de planeamento e gestão;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_2",
      defaultMessage:
        "Definir, executar as estratégias de desenvolvimento integral dos recursos humanos do INEFOP, bem como os critérios de avaliação e o respectivo impacto;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_3",
      defaultMessage:
        "Proceder ao recrutamento, selecção, treinamento do pessoal a nível central e acompanhar o processo a nível local em articulação com os Serviços Provinciais;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_4",
      defaultMessage: "Organizar, controlar e distribuir os recursos humanos mediante o planeamento de efectivos;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_5",
      defaultMessage:
        "Definir estratégias de remuneração suplementar, através de estudos, análise de enquadramento, bem como controlar e garantir a administração de salários nos termos da lei;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_6",
      defaultMessage:
        "Analisar, propor e difundir as normas de trabalho no domínio de recursos humanos para as demais áreas do INEFOP, através de recurso às tecnologias de informação;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_7",
      defaultMessage:
        "Coordenar o processo de avaliação de desempenho do pessoal, identificar as suas necessidades de formação em colaboração com os demais órgãos e serviços do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_8",
      defaultMessage:
        "Organizar, manter actualizados os processos individuais que permitam o conhecimento completo, permanente da situação e evolução profissional do pessoal do INEFOP, bem como a confidencialidade dos dados registados;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_9:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_9",
      defaultMessage:
        "Projectar, avaliar e propor a adopção de soluções tecnológicas eficientes de acordo com as exigências de funcionamento do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_10:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_10",
      defaultMessage: "Coordenar a execução, a gestão das tecnologias de informação e de comunicação do INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_11:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_11",
      defaultMessage: "Gerir os parâmetros de qualidade dos serviços de tecnologia de informação e de comunicação;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_12:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_12",
      defaultMessage:
        "Apoiar os Serviços Provinciais na aplicação da estratégia dos recursos humanos do INEFOP, por forma a garantir a sua uniformização, bem como prestar a assistência técnica necessária e divulgar de forma sistemática a informação pertinente;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_13:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_13",
      defaultMessage: "Exercer as demais competências estabelecidas por lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_3",
    defaultMessage:
      "O Departamento de Recursos Humanos e das Tecnologias de Informação é dirigido por um Chefe de Departamento.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_5: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_5",
    defaultMessage: "ORGANIZAÇÃO EM ESPECIAL - SECÇÃO III",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_6: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_6",
    defaultMessage: "SERVIÇOS EXECUTIVOS",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14",
    defaultMessage: "ARTIGO 14.º (Departamento de Gestão e Serviços de Emprego)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_1",
    defaultMessage:
      "O Departamento de Gestão e Serviços de Emprego é o serviço encarregue de acompanhar a organização do mercado de emprego através dos centros de emprego, e demais serviços afins na implementação das políticas activas de emprego.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2",
    defaultMessage: "O Departamento de Gestão e Serviços de Emprego tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_1",
      defaultMessage:
        "Estudar, apoiar, implementar medidas pertinentes no âmbito da mobilidade geográfica de trabalhadores, em função da situação e das tendências do mercado de trabalho;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_2",
      defaultMessage:
        "Conceber, elaborar, actualizar os mecanismos de informação, orientação profissional e avaliar a sua implementação;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_3",
      defaultMessage:
        "Propor a regulamentação da actividade de colocação por outras entidades, de modo a inseri-la nos objectivos das políticas de emprego;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_4",
      defaultMessage:
        "Perspectivar a evolução do mercado de trabalho, em relação as qualificações, carreiras profissionais e divulgar as informações  pertinentes;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_5",
      defaultMessage:
        "Executar em cooperação com as entidades competentes, acções de apoio técnico, material no âmbito das micro, pequenas empresas e do fomento do auto-emprego;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_6",
      defaultMessage:
        "Articular com a área de formação profissional, medidas tendentes a satisfazer as necessidades das empresas em mão-de-obra  qualificada;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_7",
      defaultMessage: "Orientar, apoiar e avaliar a actividade dos centros de emprego;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_8",
      defaultMessage:
        "Propor e apoiar a execução de programas de auxílio à inserção laboral de técnicos no mercado de emprego;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_9:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_9",
      defaultMessage: "Exercer as demais competências estabelecidas por lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_3",
    defaultMessage: "O Departamento de Gestão e Serviços de Emprego é dirigido por um Chefe de Departamento.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15",
    defaultMessage: "ARTIGO 15.º (Departamento de Empreendedorismo)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_1",
    defaultMessage:
      "O Departamento de Empreendedorismo é o serviço encarregue de realizar estudos de caracterização do mercado, ambiente de negócios, a fim de determinar as potencialidades económicas locais e facilitar o desenvolvimento de actividades produtivas ou de prestação de serviço.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2",
    defaultMessage: "O Departamento de Empreendedorismo tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_1",
      defaultMessage:
        "Orientar a actividade dos serviços executivos indirectos relacionados a área de emprego, na implementação dos programas sobre empreendedorismo;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_2",
      defaultMessage:
        "Adoptar programas de apoio técnico à empreendedores, na escolha de novos negócios, criação de micro e pequenas Empresas;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_3",
      defaultMessage:
        "Cooperar com as instituições de ensino médio, e superior, na elaboração de programas de formação em empreendedorismo e gestão de negócios;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_4",
      defaultMessage:
        "Propor, em coordenação com instituições bancárias, estratégias de facilitação de acesso ao crédito aos beneficiários de programas locais de empreendedorismo;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_5",
      defaultMessage:
        "Incentivar a busca de informações, soluções e inovações de novos negócios, visando o crescimento económico das comunidades;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_6",
      defaultMessage:
        "Identificar potencialidades para a criação e implementação de actividades económicas geradoras de rendimento;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_7",
      defaultMessage: "Conceber, elaborar e avaliar a aplicação de normas sobre o fomento de emprego;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_8",
      defaultMessage:
        "Propor a criação de mecanismos de apoio e aperfeiçoamento às iniciativas conducentes à geração de emprego e renda;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_9:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_9",
      defaultMessage: "Exercer as demais competências estabelecidas lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_3",
    defaultMessage: "O Departamento de Empreendedorismo é dirigido por um Chefe de Departamento.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16",
    defaultMessage: "ARTIGO 16.º (Departamento de Formação Profissional e de Desenvolvimento Curricular)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_1",
    defaultMessage:
      "O Departamento de Formação Profissional e de Desenvolvimento Curricular é o serviço encarregue de elaborar as normas que estabelecem as modalidades de formação inicial de jovens, adultos, dos planos curriculares dos diferentes cursos, em conformidade com os perfis de competências, e os níveis de qualificação profissional.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2",
    defaultMessage:
      "O Departamento de Formação Profissional e de Desenvolvimento Curricular tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_1",
      defaultMessage:
        "Estabelecer a lista de profissões consideradas prioritárias para a formação em função do estudo de mercado de emprego e da capacidade formativa disponível;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_2",
      defaultMessage: "Promover a colaboração entre instituições de formação;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_3",
      defaultMessage:
        "Incentivar e apoiar a realização de acções que contribuam para a identificação das necessidades de formação das empresas;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_4",
      defaultMessage: "Acompanhar, avaliar os programas de formação e requalificação profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_5",
      defaultMessage: "Estabelecer as normas e mecanismos de avaliação dos participantes às acções de formação;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_6",
      defaultMessage:
        "Acompanhar, monitorar do ponto de vista metodológico a actividade das empresas e serviços de formação profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_7",
      defaultMessage:
        "Apoiar e orientar metodologicamente a actividade dos serviços locais no domínio da formação profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_8",
      defaultMessage:
        "Executar a política adoptada para a requalificação profissional de pessoas portadoras de deficiência, visando a sua integração na vida activa;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_9:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_9",
      defaultMessage:
        "Emitir parecer sobre o processo de licenciamento de centros de formação profissional superintendidos pelo INEFOP;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_10:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_10",
      defaultMessage:
        "Conceber, actualizar os modelos, práticas de intervenção nos domínios da informação, orientação profissional, assegurar o desenvolvimento das respectivas normas e procedimentos;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_11:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_11",
      defaultMessage:
        "Elaborar os instrumentos de apoio à formação profissional, nomeadamente, planos curriculares, manuais, itens de testes e outros;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_12:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_12",
      defaultMessage:
        "Elaborar a listagem de equipamentos, ferramentas e materiais para os cursos do Sistema Nacional de Formação Profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_13:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_13",
      defaultMessage:
        "Propor a formação e superação técnica e pedagógica dos formadores e gestores das unidades formativas;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_14:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_14",
      defaultMessage: "Elaborar perfis profissionais de acordo com a demanda do mercado de trabalho;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_15:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_15",
      defaultMessage: "Exercer as demais competências estabelecidas por lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_3",
    defaultMessage:
      "O Departamento de Formação Profissional e de Desenvolvimento Curricular é dirigido por um Chefe de Departamento.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17",
    defaultMessage: "ARTIGO 17.º (Departamento de Supervisão e Qualidade)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_1",
    defaultMessage:
      "O Departamento de Supervisão e Qualidade é o serviço encarregue de garantir o cumprimento das normas de qualidade, deontologia no processo de ensino e aprendizagem.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2",
    defaultMessage: "O Departamento de Supervisão e Qualidade tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_1",
      defaultMessage:
        "Verificar a compatibilidade entre o perfil dos formadores do sistema nacional de formação profissional, e o perfil exigido para ocupação da função, promovendo, se necessário o reforço de competências;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_2",
      defaultMessage:
        "Assegurar através de visitas de apoio, e controlo a execução das metodologias de formação profissional, a utilização correcta dos planos curriculares, e outros instrumentos de apoio à formação profissional, pelos centros de formação sob superintendência do INEFOP, bem como os superintendidos por outros organismos públicos, instituições privadas e de gestão participada;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_3",
      defaultMessage: "Garantir o cumprimento das normas de avaliação do processo de ensino e aprendizagem;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_4",
      defaultMessage:
        "Apoiar, controlar a aplicação correcta dos instrumentos metodológicos do processo de ensino e aprendizagem, em estreita colaboração com o Centro Nacional de Formação de Formadores;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_5",
      defaultMessage: "Integrar equipas multidisciplinares para avaliação das competências dos formadores;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_6",
      defaultMessage:
        "Avaliar em colaboração com o Centro Nacional de Formação de Formadores, a conformidade dos equipamentos de formação, em função dos cursos ministrados, a ministrar e, sempre que necessário, propor medidas correctivas;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_7",
      defaultMessage:
        "Propor medidas correctivas para melhorar o desempenho do processo de ensino e aprendizagem e de gestão dos centros de formação profissional sob superintendência pelo Instituto, assim como os superintendidos por outros organismos públicos, instituições privadas ou de gestão participada;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_8",
      defaultMessage: "Exercer as demais competências estabelecidas por lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_3",
    defaultMessage: "O Departamento de Supervisão e Qualidade é dirigido por um Chefe de Departamento.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18",
    defaultMessage: "ARTIGO 18.º (Departamento de Certificação Profissional)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_1",
    defaultMessage:
      "O Departamento de Certificação Profissional é o serviço encarregue de avaliar as competências dos profissionais.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2",
    defaultMessage: "O Departamento de Certificação Profissional tem as seguintes competências:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_1",
      defaultMessage: "Emitir pareceres sobre a certificação profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_2",
      defaultMessage: "Elaborar os instrumentos de avaliação para a Certificação Profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_3",
      defaultMessage: "Elaborar programas, projectos de avaliação e certificação de competências profissionais;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_4",
      defaultMessage:
        "Efectuar em colaboração com o Departamento de Formação Profissional de Desenvolvimento Curricular o levantamento dos perfis profissionais relevantes para o mercado de trabalho e proceder os ajustamentos necessários nos termos da lei;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_5",
      defaultMessage:
        "Elaborar e propor normas técnicas de funcionamento das instituições de certificação de competências profissionais;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_6",
      defaultMessage: "Criar e gerir a base de dados dos profissionais certificados;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_7",
      defaultMessage: "Exercer as demais competências estabelecidas por lei ou determinadas superiormente.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_3",
    defaultMessage: "O Departamento de Certificação Profissional é dirigido por um Chefe de Departamento.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_7: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_7",
    defaultMessage: "ORGANIZAÇÃO EM ESPECIAL - SECÇÃO IV",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_8: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_8",
    defaultMessage: "SERVIÇOS LOCAIS E SERVIÇOS EXECUTIVOS INDIRECTOS",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_19: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_19",
    defaultMessage: "ARTIGO 19.º (Serviços Provinciais)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_19_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_19_Description_1",
    defaultMessage:
      "Os Serviços Provinciais do INEFOP compreendem a Secção de Emprego e a Secção de Formação Profissional.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_19_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_19_Description_2",
    defaultMessage:
      "Os Serviços referidos no número anterior são dirigidos por um Chefe de Serviço equiparado a Chefe de Departamento Provincial e é nomeado pelo titular do Órgão de superintendência sob proposta do Director Geral do INEFOP.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20",
    defaultMessage: "ARTIGO 20.º (Serviços Executivos Externos)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1",
    defaultMessage:
      "Para a execução das suas atribuições o Instituto Nacional de Emprego e Formação Profissional compreende os seguintes Serviços Executivos Externos:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_1",
      defaultMessage: "Centros de Emprego;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_2",
      defaultMessage: "Centros de Formação Profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_3",
      defaultMessage: "Centros Integrados de Emprego e Formação Profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_4:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_4",
      defaultMessage: "Escolas Rurais de Capacitação e Ofícios;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_5:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_5",
      defaultMessage: "Unidades Móveis de Formação Profissional;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_6:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_6",
      defaultMessage: "Centros Locais de Empreendedorismo e Serviços de Emprego;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_7:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_7",
      defaultMessage: "Pavilhões de Formação Profissional de Artes e Ofícios;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_8:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_8",
      defaultMessage: "Pavilhões Ocupacionais de Prestações de Serviços.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_2",
    defaultMessage:
      "A orgânica e o funcionamento dos serviços referidos no número anterior são aprovados por Decreto Executivo do Ministro responsável pela área do Trabalho e da Formação Profissional.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4",
    defaultMessage: "ESTATUTO ORGÂNICO DO INSTITUTO NACIONAL DE EMPREGO E FORMAÇÃO PROFISSIONAL - CAPÍTULO IV",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Description_1",
    defaultMessage: "GESTÃO FINANCEIRA E PATRIMONIAL",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21",
    defaultMessage: "ARTIGO 21.º (Receitas)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1",
    defaultMessage: "Constituem receitas do INEFOP as seguintes:",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1_BulletPoint_1:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1_BulletPoint_1",
      defaultMessage: "As dotações do Orçamento Geral do Estado;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1_BulletPoint_2:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1_BulletPoint_2",
      defaultMessage: "As receitas provenientes do fundo de financiamento;",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1_BulletPoint_3:
    {
      id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1_BulletPoint_3",
      defaultMessage:
        "Outras dotações, donativos e subsídios, bem como quaisquer outros rendimentos e valores que lhe sejam atribuídos ou provenham da sua actividade.",
      description: "InstitutionalInformation - OrganicStatus",
    },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_22: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_22",
    defaultMessage: "ARTIGO 22.º (Património)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_22_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_22_Description_1",
    defaultMessage:
      "O INEFOP pode ter sob sua administração bens do património do Estado que sejam afectos por lei, para o exercício da sua actividade.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_23: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_23",
    defaultMessage: "ARTIGO 23.º (Despesas)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_23_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_23_Description_1",
    defaultMessage:
      "Constituem despesas do INEFOP as que resultam de encargos decorrentes da prossecução das suas atribuições.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5",
    defaultMessage: "ESTATUTO ORGÂNICO DO INSTITUTO NACIONAL DE EMPREGO E FORMAÇÃO PROFISSIONAL - CAPÍTULO V",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Description_1",
    defaultMessage: "DISPOSIÇÕES FINAIS",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24",
    defaultMessage: "ARTIGO 24.º (Quadro de pessoal e organograma)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24_Description_1",
    defaultMessage:
      "O quadro de pessoal e organograma do INEFOP são os constantes dos Anexos I, II, III, IV e V respectivamente, anexos ao presente Estatuto orgânico e do qual são parte integrante.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24_Description_2",
    defaultMessage:
      "O pessoal do INEFOP está sujeito ao regime jurídico da Função Pública e do contrato de trabalho a termo certo nos termos da Lei Geral de Trabalho para o exercício de actividades de duração temporária.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24_Description_3: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24_Description_3",
    defaultMessage: "O pessoal do INEFOP está sujeito ao regime jurídico.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_25: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_25",
    defaultMessage: "ARTIGO 25.º (Remuneração suplementar)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_25_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_25_Description_1",
    defaultMessage:
      "Os funcionários do INEFOP podem beneficiar de uma remuneração suplementar, nos termos previstos no artigo 38.º do Decreto Legislativo Presidencial n.º 2/13, de 25 de Junho.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_26: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_26",
    defaultMessage: "ARTIGO 26.º (Regulamentos internos)",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_26_Description_1: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_26_Description_1",
    defaultMessage:
      "O INEFOP deve elaborar regulamentos para o funcionamento dos seus órgãos e serviços e propor à aprovação do titular do Órgão de Superintendência.",
    description: "InstitutionalInformation - OrganicStatus",
  },
  Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Description_2: {
    id: "Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Description_2",
    defaultMessage: "O Presidente da República, José Eduardo dos Santos.",
    description: "InstitutionalInformation - OrganicStatus",
  },

  //Information - EmploymentServices - EmploymentCenters
  Label_Search_Menu_Information_EmploymentServices_EmploymentCenters: {
    id: "Label_Search_Menu_Information_EmploymentServices_EmploymentCenters",
    defaultMessage: "Menu_Information_EmploymentServices_EmploymentCenters",
    description: "Prefix used to search Information - EmploymentServices - EmploymentCenters information on resources",
  },
  Label_Search_Menu_Information_EmploymentServices_Employment_Centers_Network: {
    id: "Label_Search_Menu_Information_EmploymentServices_Employment_Centers_Network",
    defaultMessage: "Menu_Information_EmploymentServices_Employment_Centers_Network",
    description:
      "Prefix used to search Information - EmploymentServices - EmploymentCentersNetwork information on resources",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_1: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_1",
    defaultMessage:
      "Os Centros de Emprego são órgãos locais de execução das políticas de emprego, dependentes dos Serviços Provinciais e podem ser permanentes ou móveis de acordo com as condições e as realidades do mercado de emprego das áreas onde se encontram instalados.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_2: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_2",
    defaultMessage:
      "Os Centros de Emprego permanentes têm a sede em determinada localidade e abrangem uma zona definida em que se desenvolva uma actividade contínua/ permanente.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_3: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_3",
    defaultMessage:
      "O Centro de Emprego Móveis é um serviço que actua a partir do Centro permanente utilizam instalações ocasionais, funcionando na base de um horário estabelecido para o efeito e divulgado para conhecimento do público.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_4: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_4",
    defaultMessage:
      "Os Centros de Emprego dispõem de técnicos necessários e com competências para a prossecução dos objectivos previstos nos estatutos",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_5: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_5",
    defaultMessage:
      "Os Centros de Emprego deverão divulgar a finalidade de suas atribuições a todos os serviços, associações de trabalhadores e de empregadores e ao público em geral do meio envolvente.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions",
    defaultMessage: "QUAIS SÃO AS FUNÇÕES DE UM CENTROS DE EMPREGO",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_1: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_1",
    defaultMessage:
      "Informar e orientar profissionalmente os trabalhadores de modos a ajudá-los a encontrar um emprego digno e ajustado às suas capacidades e adaptação profissionais;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_2: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_2",
    defaultMessage: "Promover o ajustamento entre a procura e a oferta de emprego e formação profissional;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_3: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_3",
    defaultMessage: "Proporcionar serviços de apoio à procura de emprego;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_4: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_4",
    defaultMessage: "Promover o emprego e a formação profissional de grupos específicos de candidatos a emprego;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_5: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_5",
    defaultMessage:
      "Apoiar as empresas no recrutamento dos trabalhadores que necessitam para o desenvolvimento de sua actividade;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_6: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_6",
    defaultMessage: "Colaborar na detenção de necessidades locais de formação e reabilitação profissional;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_7: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_7",
    defaultMessage:
      "Apoiar e dinamizar a realização de programas de formação profissional e de criação de postos de trabalho;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_8: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_8",
    defaultMessage: "Implementar mecanismos adequados à efectivação da mobilidade geográfica dos trabalhadores;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_9: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_9",
    defaultMessage:
      "Recolher e difundir informações actualizadas, sobre a situação e perspectivas do mercado de emprego e proceder às análises necessárias;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_10: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_10",
    defaultMessage: "Participar na implementação de políticas e medidas de protecção no desemprego.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing",
    defaultMessage: "QUAIS SÃO OS SERVIÇOS PRESTADOS NUM CENTRO DE EMPREGO?",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing2: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing2",
    defaultMessage: "COLOCAÇÃO",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_1: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_1",
    defaultMessage:
      "Inscrever os candidatos a emprego residentes na sua área de jurisdição, anotando as suas qualificações profissionais, experiencias, desejos e preferências;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_2: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_2",
    defaultMessage:
      "Verificar as suas aptidões físicas e profissionais e encaminhá-los, se for caso disso, para obter conselhos;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_3: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_3",
    defaultMessage:
      "Obter das entidades empregadoras informações precisas sobre as suas necessidades, postos de trabalho vagos e condições a satisfazer para o seu preenchimento;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_4: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_4",
    defaultMessage:
      "Registar as ofertas de emprego comunicadas pelos empregadores por via escrita, pessoal, telefónica ou qualquer outra;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_5: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_5",
    defaultMessage:
      "Apresentar às empresas que fizerem ofertas de emprego, os candidatos que reúnam as condições exigidas;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_6: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_6",
    defaultMessage:
      "Abster-se de aceitar ofertas de emprego que comportem salários ou outras condições de trabalho inferiores às normas fixadas pela legislação, pelas convenções colectivas ou pelas normas de regulamentação administrativa do trabalho;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_7: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_7",
    defaultMessage:
      "Assegurar e articular com os parceiros sociais/ outras entidades, a informação, inscrição e selecção dos trabalhadores candidatos à emigração;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_8: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_8",
    defaultMessage:
      "Contactar as empresas com vista a recolha de ofertas de emprego e divulgação da mão-de-obra inscrita no ficheiro e recolha de informações sobre a situação das profissões no mercado de emprego e suas tendências evolutivas;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_9: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_9",
    defaultMessage:
      "Obter conhecimentos actualizados de postos de trabalho, suas características e conhecimentos profissionais necessários para o exercer.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation",
    defaultMessage: "INFORMAÇÃO E ORIENTAÇÃO PROFISSIONAL",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_1:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_1",
      defaultMessage:
        "Proporcionar acções de informação e orientação profissional de modo a melhorar a capacidade do individuo para compreender o mercado de emprego onde se integra e facilitar as opções profissionais;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_2:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_2",
      defaultMessage:
        "Assegurar apoio especializado aos jovens ou adultos candidatos a emprego, que por iniciativa própria ou de outrem apresentam problemas relativos à escolha de uma profissão;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_3:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_3",
      defaultMessage:
        "Relacionar e interpretar as informações sobre o individuo, obtidas na entrevista que ressaltam a personalidade, capacidade, eficiência escolar e profissional, quer por aplicação de provas de aptidão que conduzam a uma avaliação das suas potencialidades em termos de ajustamento profissional;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_4:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_4",
      defaultMessage:
        "Controlar os resultados da Orientação Profissional por via de inquéritos pessoais e transmitir aos Serviços Centrais;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_5:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_5",
      defaultMessage:
        "Obter dos Estabelecimentos de Ensino e de Formação, de todos os níveis, informações sobre as condições de admissão e frequência, disciplinas, níveis e tipos de cursos ministrados;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_6:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_6",
      defaultMessage: "Colaborar na divulgação dos objectivos da Orientação Profissional;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_7:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_7",
      defaultMessage:
        "Apoiar e participar nas acções de capacitação a levar cabo pelo serviço, em cooperação com outros Serviços ou Instituições;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_8:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_8",
      defaultMessage: "Elaborar relatórios e fichas profissionais e enviá-los mensalmente aos Serviços Provinciais.",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining",
    defaultMessage: "FORMAÇÃO PROFISSIONAL",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_1: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_1",
    defaultMessage:
      "Os Centros de Emprego asseguram neste âmbito, a qualidade profissional, o aperfeiçoamento, a aprendizagem e a readaptação, podendo efectuar estas acções directamente ou através das empresas e em cooperação outros organismos.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_2: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_2",
    defaultMessage: "Para que a Formação Profissional corresponda:",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_2_BulletPoint_1: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_2_BulletPoint_1",
    defaultMessage: "Às necessidades urgentes de mão-de-obra e do progresso técnico;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_2_BulletPoint_2: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_2_BulletPoint_2",
    defaultMessage:
      "A rápida qualificação profissional de trabalhadores que careçam de obter um emprego compatível com a sua idade e aptidões.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion",
    defaultMessage: "EMPREENDEDORISMO E PROMOÇÃO DO EMPREGO",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_1:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_1",
      defaultMessage:
        "Os Centros de Emprego estimulam e desenvolver actividades ajustadas à promoção do emprego e criação de postos de trabalho de acordo com as orientações resultantes de medidas legislativas específicas;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_2:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_2",
      defaultMessage:
        "Realizam estudos de caracterização do mercado, ambiente de negócios a fim de determinar as potencialidades económicas locais facilitar o desenvolvimento de actividades produtivas ou de prestação de serviços;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_3:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_3",
      defaultMessage:
        "Apoiam à criação de pequena e micro empresas, através do crédito com garantia de bonificação das taxas de juro;",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_4:
    {
      id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_4",
      defaultMessage:
        "Executam as medidas e políticas ajustadas á requalificação profissional de pessoas com deficiência, visando a sua integração na vida activa.",
      description: "Information - EmploymentServices - EmploymentCenters",
    },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies",
    defaultMessage: "ESTUDOS DO MERCADO DE EMPREGO",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_1: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_1",
    defaultMessage: "Cadastrar as entidades empregadoras implantadas na área de actuação;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_2: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_2",
    defaultMessage:
      "Organizar e realizar inquéritos destinados a recolha de dados sobre a situação do mercado de emprego;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_3: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_3",
    defaultMessage: "Actualizar o registo das acções formativas;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_4: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_4",
    defaultMessage:
      "Inventariar as actividades artesanais e de pequena indústria bem como o número de trabalhadores ocupados nas mesmas;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_5: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_5",
    defaultMessage:
      "Conhecer a situação do emprego, estudar e analisar as causas e incidências dos fenómenos do sub-emprego e desemprego;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_6: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_6",
    defaultMessage:
      "Colaborar com os demais Serviços Públicos, Entidades Empregadoras e Associações de profissionais, na recolha de dados para elaborar/actualizar o Classificador Nacional de Profissões;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_7: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_7",
    defaultMessage:
      "Receber dentro do prazo fixado na Lei, a cópia do Quadro de Pessoal que as Entidades Empregadoras estão obrigadas a remeter aos Centros de Emprego;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_8: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_8",
    defaultMessage:
      "Instruir as Entidades Empregadoras a apresentarem anualmente, mapa de evolução dos seus efectivos e a necessidade de formação profissional;",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_9: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_9",
    defaultMessage:
      "Elaborar e remeter ao Serviço Provincial do Instituto Nacional do Emprego e Formação Profissional os elementos estatísticos e relatórios sobre o mercado de emprego.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_RENT: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_RENT",
    defaultMessage: "REGISTO NOMINAL DE TRABALHADORES – RENT",
    description: "Information - EmploymentServices - EmploymentCenters",
  },
  Label_Menu_Information_EmploymentServices_EmploymentCenters_RENT_Description: {
    id: "Label_Menu_Information_EmploymentServices_EmploymentCenters_RENT_Description",
    defaultMessage:
      "O registo nominal de trabalhadores, é um instrumento administrativo de recolha de informação no sector empresarial público, privado, misto e cooperativo, assim como nas instituições sociais, religiosas e organizações não-governamentais que tenham ao seu serviço trabalhadores assalariados, seja qual for a natureza do vínculo laboral, cujo procedimento está regulado em diploma próprio pelo Decreto Executivo Nº 155/14 de 31 de Dezembro.",
    description: "Information - EmploymentServices - EmploymentCenters",
  },

  //Information - EmploymentServices - EmploymentCentersNetwork
  Label_Menu_Information_EmploymentServices_Employment_Centers_Network_Table1: {
    id: "Label_Menu_Information_EmploymentServices_Employment_Centers_Network_Table1",
    defaultMessage: "TABELA 1. MAPEAMENTO DOS SERVIÇOS PÚBLICOS DA REDE CENTROS DE EMPREGO DE ANGOLA",
    description: "Information - EmploymentServices - EmploymentCentersNetwork",
  },

  // Company Services - Ratify Agreement
  Label_Search_Menu_CompanyServices_RatifyAgreement: {
    id: "Label_Search_Menu_CompanyServices_RatifyAgreement",
    defaultMessage: "Menu_CompanyServices_RatifyAgreement",
    description: "Prefix used to search Company Services - Ratify Agreement information on resources",
  },
  Label_Menu_CompanyServices_RatifyAgreementTitle: {
    id: "Label_Menu_CompanyServices_RatifyAgreementTitle",
    defaultMessage: "Instrutivo para homologação de contratos",
    description: "Company Services - Ratify Agreement",
  },
  Label_Menu_CompanyServices_RatifyAgreement1: {
    id: "Label_Menu_CompanyServices_RatifyAgreement1",
    defaultMessage:
      "Serviços de homologação de contratos são processos nos quais uma parte neutra e imparcial, assim sendo o INEFOP verifica a conformidade e legalidade de um contrato antes que ele entre em vigor.",
    description: "Company Services - Ratify Agreement",
  },
  Label_Menu_CompanyServices_RatifyAgreement2: {
    id: "Label_Menu_CompanyServices_RatifyAgreement2",
    defaultMessage:
      "A homologação tem como objetivo garantir que as cláusulas e condições do contrato estejam em conformidade com as leis e regulamentos aplicáveis e que ambas as partes tenham tido a oportunidade de revisar e concordar com os termos do contrato de forma justa.",
    description: "Company Services - Ratify Agreement",
  },
  Label_Menu_CompanyServices_RatifyAgreement3: {
    id: "Label_Menu_CompanyServices_RatifyAgreement3",
    defaultMessage:
      "Durante o processo de homologação, o contrato é analisado quanto à sua validade legal, clareza das cláusulas, equilíbrio de direitos e obrigações, e possíveis inconsistências ou ambiguidades que possam prejudicar uma das partes. O INEFOP pode solicitar revisões ou reajustes no contrato para garantir que ele esteja em conformidade com as leis e que os direitos e interesses de ambas as partes sejam devidamente protegidos.",
    description: "Company Services - Ratify Agreement",
  },
  Label_Menu_CompanyServices_RatifyAgreement4: {
    id: "Label_Menu_CompanyServices_RatifyAgreement4",
    defaultMessage:
      "Uma vez que o contrato tenha sido homologado, ele geralmente recebe um selo ou certificado para indicar que passou pelo processo de análise e foi considerado válido e legal. Isso pode fornecer uma maior segurança e confiança às partes envolvidas no contrato, pois demonstra que ele foi submetido a uma avaliação imparcial e que cumpre os requisitos legais.",
    description: "Company Services - Ratify Agreement",
  },

  //Information - EmploymentServices - ForeignWorkersContractRegistration
  Label_Search_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration: {
    id: "Label_Search_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration",
    defaultMessage: "Menu_Information_EmploymentServices_ForeignWorkersContractRegistration",
    description:
      "Prefix used to search Information - EmploymentServices - ForeignWorkersContractRegistration information on resources",
  },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions: {
    id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions",
    defaultMessage: "INSTRUTIVO PARA REGISTO DOS CONTRATOS DE TRABALHADORES ESTRANGEIROS",
    description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
  },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_1: {
    id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_1",
    defaultMessage:
      "O contrato deve ser remetido ao Centro de Emprego, por meio de um oficio em duplicado a solicitar homologação de contratos.",
    description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
  },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_2: {
    id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_2",
    defaultMessage: "Devera fazer o preenchimento dos contratos de trabalho por tempo Determinado em triplicado.",
    description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
  },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3: {
    id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3",
    defaultMessage: "Deve anexar ao contrato:",
    description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
  },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3_BulletPoint_1:
    {
      id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3_BulletPoint_1",
      defaultMessage: "Cópia do passaporte com o visto de trabalho actualizado;",
      description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
    },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3_BulletPoint_2:
    {
      id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3_BulletPoint_2",
      defaultMessage:
        "Salário devera estar expresso e pago em moeda acordada entre o empregador e o empregado, deverão anexar a copia da folha do qualificador de profissões, onde consta a categoria, grupo e função que o trabalhador exerce;",
      description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
    },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3_BulletPoint_3:
    {
      id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3_BulletPoint_3",
      defaultMessage:
        "Os contratos de trabalho podem ser prorrogáveis ate 36 meses, o que implica o pagamento dos 5% do sálario base, (Decreto Presidencial n.º 43/17, de 6 de Março).",
      description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
    },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract: {
    id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract",
    defaultMessage: "CONTA PROMOÇÃO DO EMPREGO, Nº 0006-142256-011 KZ (BPC)",
    description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
  },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract_Description_1: {
    id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract_Description_1",
    defaultMessage:
      "No verso do contrato, onde consta (outras Cláusulas), devera constar o compromisso de honra, que diz: o trabalhador compromete-se a regressar no seu pais de origem, após cessação do contrato.",
    description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
  },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract_Description_2: {
    id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract_Description_2",
    defaultMessage:
      "Em caso de transferência, temos o seguinte IBAN da Conta Promoção do Emprego: Nº AO06.0010.0006.0014.2256.0117.0",
    description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
  },
  Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract_Description_3: {
    id: "Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract_Description_3",
    defaultMessage:
      "Os contratos de trabalho devem ser preenchidos (elaborados), com base o modelo padrão, isto é, no modelo da Impresa Nacional.",
    description: "Information - EmploymentServices - ForeignWorkersContractRegistration",
  },

  //Information - EntrepreneurshipServices - EntrepreneurshipCenters
  Label_Search_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCenters: {
    id: "Label_Search_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCenters",
    defaultMessage: "Menu_Information_EntrepreneurshipServices_EntrepreneurshipCenters",
    description:
      "Prefix used to search Information - EntrepreneurshipServices - EntrepreneurshipCenters information on resources",
  },

  //Information - EntrepreneurshipServices - EntrepreneurshipCentersNetwork
  Label_Search_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCentersNetwork: {
    id: "Label_Search_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCentersNetwork",
    defaultMessage: "Menu_Information_EntrepreneurshipServices_EntrepreneurshipCentersNetwork",
    description:
      "Prefix used to search Information - EntrepreneurshipServices - EntrepreneurshipCentersNetwork information on resources",
  },

  Label_Search_Menu_Information_ProfessionalTraining_ProfessionalWallet: {
    id: "Label_Search_Menu_Information_ProfessionalTraining_ProfessionalWallet",
    defaultMessage: "Menu_Information_ProfessionalTraining_ProfessionalWallet",
    description: "Prefix used to search InstitutionalInformation - ProfessionalWallet information on resources",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLawTitle: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWallet",
    defaultMessage: "Informação Legal",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw1: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw1",
    defaultMessage:
      "O Decreto n.o 120/05 de 19 de Dezembro estabelece as normas gerais para atribuição de Carteira Profissionais.",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw2: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw2",
    defaultMessage:
      "O artigo 4, na alínea b) do ponto 1 do referido decreto, atribui ao Instituto Nacional de Emprego e Formação Profissional (INEFOP), a responsabilidade de regular o processo para as profissões de Artes e Ofícios.",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw3: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw3",
    defaultMessage:
      "O mesmo decreto refere que: Profissões de Artes e Oficios são aquelas actividades que são desenvolvidas com maior abrangência nas comunidades.",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw4: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletLaw4",
    defaultMessage:
      "Em Julho de 2019 o MAPTSS aprovou em decreto executivo o regulamento do processo de atribuição da Carteira Profissional de artes e ofícios (Decreto Executivo n.o 179/19 de 9 de Julho).",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatIsItTitle: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatIsItTitle",
    defaultMessage: "O que é a carteira profissional?",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatIsIt1: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatIsIt1",
    defaultMessage:
      "É um documento oficial, emitido pelo INEFOP, que comprova que o seu titular possui as habilidades, atitudes, competências e demais requisitos exigidos para o exercício de uma profissão no domínio das artes e ofícios.",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatIsItExamples: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatIsItExamples",
    defaultMessage: "Exemplo: Carpintaria, Electricidade BT, Mecanica Auto, Corte e costura, etc.",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatForTitle: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatForTitle",
    defaultMessage: "Para que serve a carteira?",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor1: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor1",
    defaultMessage:
      "Certifica que o cidadão é detentor de habilidades, capacidades e competências técnico-profissionais duma área específica;",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor2: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor2",
    defaultMessage: "Valoriza e dignifica os profissionais ligados as áreas de arte se ofícios;",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor3: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor3",
    defaultMessage:
      "Contribui para organização e regulamentação do exercício da actividade laboral dos profissionais no que diz respeito ao enquadramento na respectiva categoria/classe;",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor4: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhatFor4",
    defaultMessage:
      "A carteira profissional identifica o profissional e mostra a categoria em que o mesmo pode ser enquadrado no quadro de pessoal de uma empresa.",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhereToSignUpTitle: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhereToSignUpTitle",
    defaultMessage: "Onde posso inscrever-me?",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhereToSignUp1: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletWhereToSignUp1",
    defaultMessage: "No portal, bem como nos centros de formação profissional e nos centros de empregos do INEFOP.",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisitesTitle: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisitesTitle",
    defaultMessage: "Quais são os requisitos e os documentos necessários?",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisitesLeading: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisitesLeading",
    defaultMessage: "Os requisitos de candidatura são os seguintes:",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisites1: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisites1",
    defaultMessage: "Ser cidadão Nacional ou estrangeiro residente;",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisites2: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisites2",
    defaultMessage: "Ser maior de 18 anos e em idade activa;",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisites3: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletRequisites3",
    defaultMessage: "Ter uma profissão adquirida em contexto formal ou informal;",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletDocumentationLeading: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletDocumentationLeading",
    defaultMessage: "O candidato deve apresentar a seguinte documentação:",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletDocumentation1: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletDocumentation1",
    defaultMessage: "Fotocópia do BI ou autorização de residência;",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletDocumentation2: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletDocumentation2",
    defaultMessage: "Uma foto tipo passe;",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletHowManyJobsTitle: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletHowManyJobsTitle",
    defaultMessage: "Posso candidatar-me para mais de uma profissão?",
    description: "Information - Professional Wallet",
  },
  Label_Menu_Information_ProfessionalTraining_ProfessionalWalletHowManyJobs1: {
    id: "Label_Menu_Information_ProfessionalTraining_ProfessionalWalletHowManyJobs1",
    defaultMessage: "Pode inscrever-se em duas profissões.",
    description: "Information - Professional Wallet",
  },

  //Information - ProfessionalTraining - FormationOffer
  Label_Search_Menu_Information_ProfessionalTraining_FormationOffer: {
    id: "Label_Search_Menu_Information_ProfessionalTraining_FormationOffer",
    defaultMessage: "Menu_Information_ProfessionalTraining_FormationOffer",
    description: "Prefix used to search Information - ProfessionalTraining - FormationOffer information on resources",
  },

  //Information - ProfessionalTraining - TrainingCentersLicensing
  Label_Search_Menu_Information_ProfessionalTraining_TrainingCentersLicensing: {
    id: "Label_Search_Menu_Information_ProfessionalTraining_TrainingCentersLicensing",
    defaultMessage: "Menu_Information_ProfessionalTraining_TrainingCentersLicensing",
    description:
      "Prefix used to search Information - ProfessionalTraining - TrainingCentersLicensing information on resources",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments",
    defaultMessage: "Documentos Necessários",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1",
    defaultMessage: "Os documentos necessários para o licenciamento de centro de formação profissional são:",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_1: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_1",
    defaultMessage: "Requerimento;",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_2: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_2",
    defaultMessage: "Título legal de aquisição ou usufruto das instalações;",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_3: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_3",
    defaultMessage:
      "Planta ou simples desenho à escala de 1:100, sua respectiva memória descritiva e croquis de localização;",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_4: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_4",
    defaultMessage: "Cópia do Estatuto Orgânico*;",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_5: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_5",
    defaultMessage: "Copia do Regulamento Interno*;",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_6: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_6",
    defaultMessage: "Cópia do cartão de contribuinte fiscal;",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_7: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_7",
    defaultMessage: "Relação dos equipamentos e materiais didácticos em conformidade com os cursos a ministrar;",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_8: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_8",
    defaultMessage: "Plano(s) curricular(es) do(s) curso(s) a ministrar de acordo com a matriz em anexo;",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_9: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_9",
    defaultMessage: "Guia de deposito do emolumento;",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_2: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_2",
    defaultMessage:
      "(*) Deve-se elaborar o estatuto orgânico e o regulamento interno em nome do centro de formação e não da empresa, se existir.",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },
  Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_DownloadDocument: {
    id: "Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_DownloadDocument",
    defaultMessage: "Instrutivo e Formulários",
    description: "Information - ProfessionalTraining TrainingCentersLicensing",
  },

  //Homepage
  Label_HomePage_InefopServices_Description_1: {
    id: "Label_HomePage_InefopServices_Description_1",
    defaultMessage:
      "Assegurar a expressão das políticas sobre a organização do mercado de emprego e da Formação Profissional;",
    description: "Homepage - INEFOP Services",
  },
  Label_HomePage_InefopServices_Description_2: {
    id: "Label_HomePage_InefopServices_Description_2",
    defaultMessage:
      "Assegurar a coordenação dos sistemas nacionais de emprego e formação profissional e a sua articulação com o sistema de educação, relativamente aos perfis de emprego de competências profissionais por forma a acompanhar e potenciar o desenvolvimento económico e social do país;",
    description: "Homepage - INEFOP Services",
  },
  Label_HomePage_InefopServices_Description_3: {
    id: "Label_HomePage_InefopServices_Description_3",
    defaultMessage: "Promover a divulgação e o conhecimento dos programas públicos de emprego e formação profissional.",
    description: "Homepage - INEFOP Services",
  },
  Label_HomePage_InefopServices_Service_1_Title: {
    id: "Label_HomePage_InefopServices_Service_1_Title",
    defaultMessage: "Homologação de Contratos",
    description: "Homepage - INEFOP Services",
  },
  Label_HomePage_InefopServices_Service_1_Description: {
    id: "Label_HomePage_InefopServices_Service_1_Description",
    defaultMessage: "Instrutivo para Registo dos Contratos de Trabalhadores Estrangeiros",
    description: "Homepage - INEFOP Services",
  },
  Label_HomePage_InefopServices_Service_2_Title: {
    id: "Label_HomePage_InefopServices_Service_2_Title",
    defaultMessage: "Contrato de Trabalho",
    description: "Homepage - INEFOP Services",
  },
  Label_HomePage_InefopServices_Service_2_Description: {
    id: "Label_HomePage_InefopServices_Service_2_Description",
    defaultMessage: "Acção para promoção da empregabilidade e apoio com contratos de trabalho",
    description: "Homepage - INEFOP Services",
  },
  Label_HomePage_InefopServices_Service_3_Title: {
    id: "Label_HomePage_InefopServices_Service_3_Title",
    defaultMessage: "Carteira Profissional",
    description: "Homepage - INEFOP Services",
  },
  Label_HomePage_InefopServices_Service_3_Description: {
    id: "Label_HomePage_InefopServices_Service_3_Description",
    defaultMessage: "Acção para promoção da empregabilidade e apoio com contratos de trabalho",
    description: "Homepage - INEFOP Services",
  },
  Label_HomePage_LastPosts: {
    id: "Label_HomePage_LastPosts",
    defaultMessage: "Notícias",
    description: "Homepage - Last Posts",
  },
  Label_HomePage_WhoWeAre_ImageAlt: {
    id: "Label_HomePage_WhoWeAre_ImageAlt",
    defaultMessage: "Logotipo do INEFOP",
    description: "Homepage - WhoWeAre_ImageAlt",
  },
  Label_HomePage_DirectorMessage_SeeBiography: {
    id: "Label_HomePage_DirectorMessage_SeeBiography",
    defaultMessage: "Ver a Biografia",
    description: "Homepage - DirectorMessage_SeeBiography",
  },
  Label_HomePage_DirectorMessage_InstituteHolders: {
    id: "Label_HomePage_DirectorMessage_InstituteHolders",
    defaultMessage: "Titulares do Instituto",
    description: "Homepage - DirectorMessage_InstituteHolders",
  },
  Label_HomePage_DirectorMessage_ImageAlt: {
    id: "Label_HomePage_DirectorMessage_ImageAlt",
    defaultMessage: "Director Geral do INEFOP",
    description: "Homepage - DirectorMessage_ImageAlt",
  },
  Label_HomePage_DirectorMessage_DirectorName: {
    id: "Label_HomePage_DirectorMessage_DirectorName",
    defaultMessage: "Manuel Mbangui",
    description: "Homepage - DirectorMessage_DirectorName",
  },
  Label_HomePage_DirectorMessage_DirectorFullJobDescription: {
    id: "Label_HomePage_DirectorMessage_DirectorFullJobDescription",
    defaultMessage: "Director Geral do INEFOP",
    description: "Homepage - DirectorMessage_DirectorFullJobDescription",
  },
  Label_HomePage_Services_ImACompanyAndIWant: {
    id: "Label_HomePage_Services_ImACompanyAndIWant",
    defaultMessage: "Sou empresa e quero...",
    description: "Label_HomePage_Services",
  },
  Label_HomePage_Services_ImACitizenAndIWant: {
    id: "Label_HomePage_Services_ImACitizenAndIWant",
    defaultMessage: "Sou cidadão e quero...",
    description: "Label_HomePage_Services",
  },
  Label_HomePage_Services_Description: {
    id: "Label_HomePage_Services_Description",
    defaultMessage: "Serviços INEFOP disponíveis para si!",
    description: "Label_HomePage_Services_Description",
  },
  Label_HomePage_Services_Citizen_SeeJobAndInternshipOffers: {
    id: "Label_HomePage_Services_Citizen_SeeJobAndInternshipOffers",
    defaultMessage: "Emprego ou Estágio",
    description: "Label_HomePage_Services_Citizen_SeeJobAndInternshipOffers",
  },
  Label_HomePage_Services_Citizen_SeeProfessionalFormation: {
    id: "Label_HomePage_Services_Citizen_SeeProfessionalFormation",
    defaultMessage: "Formação Profissional",
    description: "Label_HomePage_Services_Citizen_SeeInternshipOffers",
  },
  Label_HomePage_Services_Citizen_SeeProfessionalWallet: {
    id: "Label_HomePage_Services_Citizen_SeeProfessionalWallet",
    defaultMessage: "Carteira Profissional",
    description: "Label_HomePage_Services_Citizen_SeeApplications",
  },
  Label_HomePage_Services_Company_CreateJobAndInternshipOffers: {
    id: "Label_HomePage_Services_Company_CreateJobAndInternshipOffers",
    defaultMessage: "Criar Emprego ou Estágio",
    description: "Label_HomePage_Services_Company_CreateJobOffers",
  },
  Label_HomePage_Services_Company_RatifyAgreement: {
    id: "Label_HomePage_Services_Company_RatifyAgreement",
    defaultMessage: "Homologar Contrato",
    description: "Label_HomePage_Services_Company_CreateInternshipOffers",
  },
  Label_HomePage_Services_Company_LicensingCenters: {
    id: "Label_HomePage_Services_Company_LicensingCenters",
    defaultMessage: "Licenciar Centro de Formação",
    description: "Label_HomePage_Services_Company_AnaliseApplications",
  },
  Label_HomePage_StructuringProjects_Description: {
    id: "Label_HomePage_StructuringProjects_Description",
    defaultMessage:
      "Os projectos estruturantes constituem um conjunto de iniciativas do INEFOP para a expansão, modernização e disponibilização de serviços ao cidadão e à empresa.",
    description: "Label_HomePage_StructuringProjects_Description",
  },
  Label_HomePage_Statistics_Description_1: {
    id: "Label_HomePage_Statistics_Description_1",
    defaultMessage: "Por uma formação profissional moderna e inclusiva,",
    description: "Label_HomePage_Statistics_Description",
  },
  Label_HomePage_Statistics_Description_2: {
    id: "Label_HomePage_Statistics_Description_2",
    defaultMessage: "a chave para o desenvolvimento.",
    description: "Label_HomePage_Statistics_Description",
  },
  Label_HomePage_MyProcesses: {
    id: "Label_HomePage_MyProcesses",
    defaultMessage: "Os meus processos",
    description: "Homepage - My Processes",
  },

  //Information - CompanyServices - CreateEmploymentOpportunity
  Label_Search_Menu_Information_CompanyServices_CreateEmploymentOpportunity: {
    id: "Label_Search_Menu_Information_CompanyServices_CreateEmploymentOpportunity",
    defaultMessage: "Menu_Information_CompanyServices_CreateEmploymentOpportunity",
    description: "Prefix used to search Information",
  },

  //Information - CompanyServices - CreateEmploymentOpportunity
  Label_Search_Menu_Information_CompanyServices_RatifyAgreement: {
    id: "Label_Search_Menu_Information_CompanyServices_RatifyAgreement",
    defaultMessage: "Menu_Information_CompanyServices_RatifyAgreement",
    description: "Prefix used to search Information",
  },

  //Information - CompanyServices - CreateInternshipOpportunity
  Label_Search_Menu_Information_CompanyServices_CreateInternshipOpportunity: {
    id: "Label_Search_Menu_Information_CompanyServices_CreateInternshipOpportunity",
    defaultMessage: "Menu_Information_CompanyServices_CreateInternshipOpportunity",
    description: "Prefix used to search Information",
  },

  //Information - CitizenServices - SeeEmploymentOpportunities
  Label_Search_Menu_Information_CitizenServices_SeeEmploymentOpportunities: {
    id: "Label_Search_Menu_Information_CitizenServices_SeeEmploymentOpportunities",
    defaultMessage: "Menu_Information_CitizenServices_SeeEmploymentOpportunities",
    description: "Prefix used to search Information",
  },

  //Information - CitizenServices - SeeInternshipOpportunities
  Label_Search_Menu_Information_CitizenServices_SeeInternshipOpportunities: {
    id: "Label_Search_Menu_Information_CitizenServices_SeeInternshipOpportunities",
    defaultMessage: "Menu_Information_CitizenServices_SeeInternshipOpportunities",
    description: "Prefix used to search Information",
  },

  //Information - CitizenServices - SeeOpportunityApplications
  Label_Search_Menu_Information_CitizenServices_SeeOpportunityApplications: {
    id: "Label_Search_Menu_Information_CitizenServices_SeeOpportunityApplications",
    defaultMessage: "Menu_Information_CitizenServices_SeeOpportunityApplications",
    description: "Prefix used to search Information",
  },

  //Statistics Page
  Label_Search_Menu_Statistics: {
    id: "Label_Search_Menu_Statistics",
    defaultMessage: "Menu_Statistics",
    description: "Prefix used to search Information",
  },

  //SearchBox
  Label_SearchBox_Title: {
    id: "Label_SearchBox_Title",
    defaultMessage: "Olá, o que pretende pesquisar?",
    description: "Search information title",
  },
  Label_SearchBox_Placeholder_WriteWhatYoureLookingFor: {
    id: "Label_SearchBox_Placeholder_WriteWhatYoureLookingFor",
    defaultMessage: "Escreva o que procura",
    description: "Prefix used to search Information",
  },

  //UserProfile - UserSingular
  Label_UserProfile_UserSingular_MyProfile: {
    id: "Label_UserProfile_UserSingular_MyProfile",
    defaultMessage: "Meu Perfil",
    description: "UserProfile - UserSingular",
  },
  Label_UserProfile_UserSingular_Tabs_PersonalData: {
    id: "Label_UserProfile_UserSingular_Tabs_PersonalData",
    defaultMessage: "Dados Pessoais",
    description: "UserProfile - UserSingular - Tabs",
  },
  Label_UserProfile_UserSingular_Tabs_ProfessionalDataAndPassword: {
    id: "Label_UserProfile_UserSingular_Tabs_ProfessionalDataAndPassword",
    defaultMessage: "Dados Profissionais e Palavra-Passe",
    description: "UserProfile - UserSingular - Tabs",
  },
  Label_UserProfile_UserSingular_Tabs_EmploymentAndUnemploymentHistory: {
    id: "Label_UserProfile_UserSingular_Tabs_EmploymentAndUnemploymentHistory",
    defaultMessage: "Histórico de Emprego e Desemprego",
    description: "UserProfile - UserSingular - Tabs",
  },

  //UserProfile - UserSingular - ProfessionalData
  Label_UserProfile_UserSingular_ProfessionalData_MainProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_MainProfessionalExperience",
    defaultMessage: "Profissão Principal",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_SecondaryProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_SecondaryProfessionalExperience",
    defaultMessage: "Profissão Subsidiária (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_YearsOfMainProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_YearsOfMainProfessionalExperience",
    defaultMessage: "Experiência Profissional Principal",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_YearsOfSecondaryProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_YearsOfSecondaryProfessionalExperience",
    defaultMessage: "Experiência Profissional Subsidiária (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_ProfessionalQualification: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_ProfessionalQualification",
    defaultMessage: "Qualificação Profissional (conhecimentos máquinas, etc.) (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_OtherProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_OtherProfessionalExperience",
    defaultMessage: "Outras Experiências Profissionais (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_Household: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_Household",
    defaultMessage: "Pessoas a Cargo (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_FamilyIncome: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_FamilyIncome",
    defaultMessage: "Rendimento Familiar (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_StudiedLanguages: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_StudiedLanguages",
    defaultMessage: "Línguas Estudadas (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId",
    defaultMessage: "Local Trabalho Pretendido (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId_Details: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId_Details",
    defaultMessage: "Preencher caso esteja à procura de emprego",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_OccupationalCategory: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_OccupationalCategory",
    defaultMessage: "Categoria Ocupacional (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },

  //UserProfile - UserSingular - EmploymentAndUnemploymentHistory
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_StartDate: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_StartDate",
    defaultMessage: "Data Início",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EndDate: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EndDate",
    defaultMessage: "Data Fim",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentHistory: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentHistory",
    defaultMessage: "Histórico de Emprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityNumber: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityNumber",
    defaultMessage: "Nº Oferta de Emprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Company: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Company",
    defaultMessage: "Empresa/Filial",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Profession: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Profession",
    defaultMessage: "Profissão",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityType: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityType",
    defaultMessage: "Tipo",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_WorkTimeFrame: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_WorkTimeFrame",
    defaultMessage: "Período de Trabalho",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Salary: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Salary",
    defaultMessage: "Salário (Kz)",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Observations: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Observations",
    defaultMessage: "Observações",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentHistory: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentHistory",
    defaultMessage: "Histórico de Desemprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_AddRecord: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_AddRecord",
    defaultMessage: "Adicionar Registo",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Edit: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Edit",
    defaultMessage: "Editar",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Add: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Add",
    defaultMessage: "Adicionar",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentRecord: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentRecord",
    defaultMessage: "Registo de Desemprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentRecord: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentRecord",
    defaultMessage: "Registo de Emprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },

  //UserProfile - UserCollective - CompanyData
  Label_UserProfile_UserCollective_CompanyData_CompanyEACId: {
    id: "Label_UserProfile_UserCollective_CompanyData_CompanyEACId",
    defaultMessage: "CAE da Empresa",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_PropertyType: {
    id: "Label_UserProfile_UserCollective_CompanyData_PropertyType",
    defaultMessage: "Tipo Propriedade",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompany: {
    id: "Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompany",
    defaultMessage: "Filial (opcional)",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyPostalCode: {
    id: "Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyPostalCode",
    defaultMessage: "Caixa Postal da Filial (opcional)",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyActivity: {
    id: "Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyActivity",
    defaultMessage: "Actividade da Filial (opcional)",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyEAC: {
    id: "Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyEAC",
    defaultMessage: "CAE da Filial (opcional)",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_MaleWorkersNumber: {
    id: "Label_UserProfile_UserCollective_CompanyData_MaleWorkersNumber",
    defaultMessage: "Nº de Trabalhadores Homens (opcional)",
    description: "UserProfile - UserCollective - Number of male workers in the company",
  },
  Label_UserProfile_UserCollective_CompanyData_FemaleWorkersNumber: {
    id: "Label_UserProfile_UserCollective_CompanyData_FemaleWorkersNumber",
    defaultMessage: "Nº de Trabalhadores Mulheres (opcional)",
    description: "UserProfile - UserCollective - Number of female workers in the company",
  },

  //Opportunity - Opportunity Data
  Label_Opportunity_OpportunityData_CandidateData: {
    id: "Label_Opportunity_OpportunityData_CandidateData",
    defaultMessage: "Dados do Candidato",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_EmploymentCenter: {
    id: "Label_Opportunity_OpportunityData_EmploymentCenter",
    defaultMessage: "Centro de Emprego",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_SocialPerks: {
    id: "Label_Opportunity_OpportunityData_SocialPerks",
    defaultMessage: "Regalias Sociais Oferecidas",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_SocialPerks_Examples: {
    id: "Label_Opportunity_OpportunityData_SocialPerks_Examples",
    defaultMessage: "Transportes, casa, alimentação, etc.",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_OtherPerks: {
    id: "Label_Opportunity_OpportunityData_OtherPerks",
    defaultMessage: "Outras Regalias",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_RequiredAcademicLevel: {
    id: "Label_Opportunity_OpportunityData_RequiredAcademicLevel",
    defaultMessage: "Nível de Escolaridade",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_Workplace: {
    id: "Label_Opportunity_OpportunityData_Workplace",
    defaultMessage: "Local de Trabalho",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_Commune: {
    id: "Label_Opportunity_OpportunityData_Commune",
    defaultMessage: "Comuna",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_RequiredKnowledge: {
    id: "Label_Opportunity_OpportunityData_RequiredKnowledge",
    defaultMessage: "Conhecimentos Profissionais",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_RequiredKnowledge_Examples: {
    id: "Label_Opportunity_OpportunityData_RequiredKnowledge_Examples",
    defaultMessage: "Máquinas, ferramentas, etc.",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_ActiveMonths: {
    id: "Label_Opportunity_OpportunityData_RequiredKnowledge_ActiveMonths",
    defaultMessage: "Tempo de Prática (anos)",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_Observations: {
    id: "Label_Opportunity_OpportunityData_RequiredKnowledge_Observations",
    defaultMessage: "Observações (opcional)",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_AcceptWorkersFromOtherRegions: {
    id: "Label_Opportunity_OpportunityData_AcceptWorkersFromOtherRegions",
    defaultMessage: "Trabalhadores Doutras Regiões",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_RequiredLanguages: {
    id: "Label_Opportunity_OpportunityData_RequiredLanguages",
    defaultMessage: "Conhecimentos de Línguas",
    description: "Opportunity_OpportunityData",
  },

  //Opportunity - UserSingular Opportunity Data
  Label_Opportunity_UserSingular_OpportunityData_RequiredKnowledge: {
    id: "Label_Opportunity_UserSingular_OpportunityData_RequiredKnowledge",
    defaultMessage: "Conhecimentos Profissionais",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_UserSingular_OpportunityData_ActiveMonths: {
    id: "Label_Opportunity_UserSingular_OpportunityData_ActiveMonths",
    defaultMessage: "Tempo de Prática (anos)",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_UserSingular_OpportunityData_RequiredLanguages: {
    id: "Label_Opportunity_UserSingular_OpportunityData_RequiredLanguages",
    defaultMessage: "Conhecimentos de Línguas",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_UserSingular_OpportunityData_RequiredAcademicLevel: {
    id: "Label_Opportunity_UserSingular_OpportunityData_RequiredAcademicLevel",
    defaultMessage: "Nível de Escolaridade",
    description: "Opportunity_OpportunityData",
  },

  // Other Areas Intervetion
  Label_InterventionOtherArea: {
    id: "Label_InterventionOtherArea",
    defaultMessage: "Intervenção de outras áreas",
    description: "InterventionOtherArea section title",
  },
  Label_InterventionOtherArea_Area: {
    id: "Label_InterventionOtherArea_Area",
    defaultMessage: "Área",
    description: "InterventionOtherArea area field",
  },
  Label_InterventionOtherArea_ReasonForIntervention: {
    id: "Label_InterventionOtherArea_ReasonForIntervention",
    defaultMessage: "Motivo da Intervenção",
    description: "InterventionOtherArea intervention motive",
  },
  Label_InterventionOtherArea_Results: {
    id: "Label_InterventionOtherArea_Results",
    defaultMessage: "Resultados",
    description: "InterventionOtherArea intervention results",
  },
});

export const titles = defineMessages({
  //Register Pages
  Title_Auth_Register: {
    id: "Title_Auth_Register",
    defaultMessage: "Registo",
    description: "Título do ecrã de registo",
  },
  Title_Register_Card_Title: {
    id: "Title_Register_Card_Title",
    defaultMessage: "Funcionalidades para Cidadão e Empresas",
    description: "Título do tabela do ecrã de registo com a comparação de funcionalidades",
  },
  Title_Auth_Register_Collective_User: {
    id: "Title_Auth_Register_Collective_User",
    defaultMessage: "Registo de Empresa",
    description: "Título do ecrã de registo",
  },
  Title_Auth_Register_Individual_User: {
    id: "Title_Auth_Register_Individual_User",
    defaultMessage: "Registo de Cidadão",
    description: "Título do ecrã de registo",
  },
  Title_Auth_Recover_Password_Modal: {
    id: "Title_Auth_Recover_Password_Modal",
    defaultMessage: "Recuperar palavra-passe",
    description: "Título da modal de recuperação de palavra-passe",
  },
  Title_Auth_Confirmation_Modal: {
    id: "Title_Auth_Confirmation_Modal",
    defaultMessage: "Autentique a sua conta",
    description: "Título da modal de confirmação do registo",
  },
  Title_Admin_Applications_MainForm: {
    id: "Title_Admin_Applications_MainForm",
    defaultMessage: "Candidatura",
    description: "Título do ecrã de candidatura",
  },
  Title_Admin_Applications_MainForm_ProgramSubprograms: {
    id: "Title_Admin_Applications_MainForm_ProgramSubprograms",
    defaultMessage: "Programas e subprogramas",
    description: "Título da área de definição do programa e subprograma da candidatura",
  },
  Title_Admin_Applications_MainForm_ComplementaryData: {
    id: "Title_Admin_Applications_MainForm_ComplementaryData",
    defaultMessage: "Dados complementares",
    description: "Título da área de definição dos dados complementares da candidatura",
  },
  Title_Admin_Applications_MainForm_Confirmation: {
    id: "Title_Admin_Applications_MainForm_Confirmation",
    defaultMessage: "Confirmação",
    description: "Título da área de confirmação da candidatura",
  },
  Title_Applications_MainPage: {
    id: "Title_Applications_MainPage",
    defaultMessage: "Candidaturas",
    description: "Título do ecrã de consulta de candidaturas",
  },
  Title_Applications_InProgress: {
    id: "Title_Applications_InProgress",
    defaultMessage: "Candidaturas em progresso",
    description: "Título do ecrã de consulta de candidaturas em progresso",
  },
  Title_Applications_All: {
    id: "Title_Applications_All",
    defaultMessage: "As minhas candidaturas",
    description: "Título do ecrã de consulta das minhas candidaturas",
  },
  Title_Opportunity_Creation: {
    id: "Title_Opportunity_Creation",
    defaultMessage: "Criar Oferta",
    description: "Título do ecrã de criação de ofertas",
  },
  Title_Opportunity_Creation_OpportunityType_Card: {
    id: "Title_Opportunity_Creation_OpportunityType_Card",
    defaultMessage: "Tipo de Oferta",
    description: "Título do cartão de definição do tipo da oferta",
  },
  Title_Opportunity_Employment_Data_Card: {
    id: "Title_Opportunity_Employment_Data_Card",
    defaultMessage: "Dados da oferta de Emprego",
    description: "Título do cartão de definição dos dados da oferta de emprego",
  },
  Title_Opportunity_Internship_Data_Card: {
    id: "Title_Opportunity_Internship_Data_Card",
    defaultMessage: "Dados da oferta de Estágio Profissional",
    description: "Título do cartão de definição dos dados da oferta de estágio",
  },
  Title_Opportunity_Employment_RejectMotive_Card: {
    id: "Title_Opportunity_Employment_RejectMotive_Card",
    defaultMessage: "Motivo de rejeição",
    description: "Título do cartão com o motivo da rejeição da oferta",
  },
  Title_Opportunity_Employment_CloseMotive_Card: {
    id: "Title_Opportunity_Employment_CloseMotive_Card",
    defaultMessage: "Motivo de fecho",
    description: "Título do cartão com o motivo do fecho da oferta",
  },
  Title_Opportunity_Application_Internship: {
    id: "Title_Opportunity_Application_Internship",
    defaultMessage: "Candidato a Estágio Profissional",
    description: "Título do ecrã de detalhe de candidato a Estágio Profissional",
  },
  Title_Opportunity_Internship_Details_Card: {
    id: "Title_Opportunity_Internship_Details_Card",
    defaultMessage: "Detalhes da oferta de Estágio Profissional",
    description: "Título do cartão de definição dos dados da oferta de estágio",
  },
  Title_Opportunity_Employment_Details_Card: {
    id: "Title_Opportunity_Employment_Details_Card",
    defaultMessage: "Detalhes da oferta de Emprego",
    description: "Título do cartão de definição dos dados da oferta de emprego",
  },
  Title_Menu_InstitutionalInformation_Overview: {
    id: "Title_Menu_InstitutionalInformation_Overview",
    defaultMessage: "Instituição",
    description: "Institutional Information - Institution page title",
  },
  Title_Menu_InstitutionalInformation_History: {
    id: "Title_Menu_InstitutionalInformation_History",
    defaultMessage: "Historial",
    description: "Institutional Information - History page title",
  },
  Title_Menu_InstitutionalInformation_MissionVisionAndValues: {
    id: "Title_Menu_InstitutionalInformation_MissionVisionAndValues",
    defaultMessage: "Missão, Visão e Valores",
    description: "Institutional Information - Mission Vision and Values page title",
  },
  Title_Menu_InstitutionalInformation_DirectorBiography: {
    id: "Title_Menu_InstitutionalInformation_DirectorBiography",
    defaultMessage: "Biografia do Director",
    description: "Institutional Information - Title_Menu_InstitutionalInformation_DirectorBiography",
  },
  Title_Menu_InstitutionalInformation_InstituteChiefs: {
    id: "Title_Menu_InstitutionalInformation_InstituteChiefs",
    defaultMessage: "Titulares do Instituto",
    description: "Institutional Information - Title_Menu_InstitutionalInformation_InstituteChiefs",
  },
  Title_Menu_InstitutionalInformation_InstituteChiefs_Subtitle: {
    id: "Title_Menu_InstitutionalInformation_InstituteChiefs_Subtitle",
    defaultMessage: "Instituto Nacional de Emprego e Formação Profissional",
    description: "Institutional Information - Title_Menu_InstitutionalInformation_InstituteChiefs_Subtitle",
  },
  Title_Menu_InstitutionalInformation_OrganicStatus: {
    id: "Title_Menu_InstitutionalInformation_OrganicStatus",
    defaultMessage: "Estatuto Orgânico",
    description: "Institutional Information - Organic Status page title",
  },
  Title_Menu_InstitutionalInformation_OrganizationChart: {
    id: "Title_Menu_InstitutionalInformation_OrganizationChart",
    defaultMessage: "Organograma",
    description: "Institutional Information - Organization Chart page title",
  },
  Title_Posts: {
    id: "Title_Posts",
    defaultMessage: "Publicações",
    description: "Posts",
  },
  Title_StructuringProjects: {
    id: "Title_StructuringProjects",
    defaultMessage: "Programas",
    description: "Title_StructuringProjects",
  },
  Title_Menu_Information_EmploymentServices_EmploymentCenters: {
    id: "Title_Menu_Information_EmploymentServices_EmploymentCenters",
    defaultMessage: "O que são Centros de Emprego?",
    description: "Information - EmploymentServices - EmploymentCenters page title",
  },
  Title_Menu_Information_EmploymentServices_Employment_Centers_Network: {
    id: "Title_Menu_Information_EmploymentServices_Employment_Centers_Network",
    defaultMessage: "Rede de Centros de Emprego",
    description: "Information - EmploymentServices - EmploymentCentersNetwork page title",
  },
  Title_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration: {
    id: "Title_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration",
    defaultMessage: "Registo de Contrato de Trabalhadores Estrangeiros",
    description: "Information - EmploymentServices - ForeignWorkersContractRegistration page title",
  },
  Title_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCenters: {
    id: "Title_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCenters",
    defaultMessage: "Serviços de Empreendedorismo e Inovação",
    description: "Information - EntrepreneurshipServices - EntrepreneurshipCenters page title",
  },
  Title_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCentersNetwork: {
    id: "Title_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCentersNetwork",
    defaultMessage: "Rede de Centros de Empreendedorismo",
    description: "Information - EntrepreneurshipServices - EntrepreneurshipCentersNetwork page title",
  },
  Title_Menu_Information_ProfessionalTraining_ProfessionalWallet: {
    id: "Title_Menu_Information_ProfessionalTraining_ProfessionalWallet",
    defaultMessage: "Carteira Profissional",
    description: "Information - ProfessionalTraining - ProfessionalWallet page title",
  },
  Title_Menu_Information_ProfessionalTraining_FormationOffer: {
    id: "Title_Menu_Information_ProfessionalTraining_FormationOffer",
    defaultMessage: "Oferta Formativa",
    description: "Information - ProfessionalTraining - FormationOffer page title",
  },
  Title_Menu_Information_ProfessionalTraining_TrainingCentersLicensing: {
    id: "Title_Menu_Information_ProfessionalTraining_TrainingCentersLicensing",
    defaultMessage: "Licenciamento de Centros de Formação Profissional",
    description: "Information - ProfessionalTraining - TrainingCentersLicensing page title",
  },
  Title_Menu_Information_CompanyServices_LicensingCenters: {
    id: "Title_Menu_Information_CompanyServices_LicensingCenters",
    defaultMessage: "Licenciar Centro de Formação",
    description: "Information - CurrentServices",
  },
  Title_Menu_Information_CompanyServices_CreateEmploymentOrInternshipOpportunities: {
    id: "Title_Menu_Information_CompanyServices_CreateEmploymentOrInternshipOpportunities",
    defaultMessage: "Criar Emprego ou Estágio",
    description: "Information - CurrentServices",
  },
  Title_Menu_Information_CompanyServices_RatifyAgreement: {
    id: "Title_Menu_Information_CompanyServices_RatifyAgreement",
    defaultMessage: "Homologar Contrato",
    description: "Information - CurrentServices",
  },
  Title_Menu_Information_CitizenServices_SeeEmploymentOpportunities: {
    id: "Title_Menu_Information_CitizenServices_SeeEmploymentOpportunities",
    defaultMessage: "Emprego ou Estágio",
    description: "Information - CurrentServices",
  },
  Title_Menu_Information_CitizenServices_SeeProfessionalTraining: {
    id: "Title_Menu_Information_CitizenServices_SeeProfessionalTraining",
    defaultMessage: "Formação Profissional",
    description: "Information - CurrentServices",
  },
  Title_Menu_Information_CitizenServices_SeeProfessionalWallet: {
    id: "Title_Menu_Information_CitizenServices_SeeProfessionalWallet",
    defaultMessage: "Carteira Profissional",
    description: "Information - CurrentServices",
  },
  Title_HomePage_InefopServices: {
    id: "Title_HomePage_InefopServices",
    defaultMessage: "Serviços",
    description: "Homepage - INEFOP Services",
  },
  Title_HomePage_Parthnerships: {
    id: "Title_HomePage_Parthnerships",
    defaultMessage: "Apoios & Parceiros",
    description: "Homepage - Title_HomePage_Parthnerships",
  },
  Title_HomePage_Help: {
    id: "Title_HomePage_Help",
    defaultMessage: "Precisa de Apoio?",
    description: "Homepage - Title_HomePage_Help",
  },
  Title_HomePage_WhoWeAre: {
    id: "Title_HomePage_WhoWeAre",
    defaultMessage: "Quem Somos",
    description: "Homepage - Title_HomePage_WhoWeAre",
  },
  Label_HomePage_TelephoneHelp: {
    id: "Label_HomePage_TelephoneHelp",
    defaultMessage: "Telefónico",
    description: "HomePage - Label_HomePage_TelephoneHelp",
  },
  Label_HomePage_ChatOnlineHelp: {
    id: "Label_HomePage_ChatOnlineHelp",
    defaultMessage: "Chat Online",
    description: "HomePage - Label_HomePage_ChatOnlineHelp",
  },
  Label_HomePage_WhatsappHelp: {
    id: "Label_HomePage_WhatsappHelp",
    defaultMessage: "Whatsapp",
    description: "HomePage - Label_HomePage_WhatsappHelp",
  },
  Label_HomePage_EmailHelp: {
    id: "Label_HomePage_EmailHelp",
    defaultMessage: "Email",
    description: "HomePage - Label_HomePage_EmailHelp",
  },
  Title_HomePage_StructuringProjects: {
    id: "Title_HomePage_StructuringProjects",
    defaultMessage: "Programas",
    description: "Homepage - Title_HomePage_WhoWeAre",
  },
  Title_SearchResults: {
    id: "Title_SearchResults",
    defaultMessage: "Resultados da Pesquisa",
    description: "Search Results",
  },
  Title_Menu_Statistics: {
    id: "Title_Menu_Statistics",
    defaultMessage: "Estatísticas",
    description: "Title_Menu_Statistics",
  },
  // ======Tables====
  // Minhas Candidaturas a Apoios
  Title_Table_UserSingular_ProgramApplications: {
    id: "Title_Table_UserSingular_ProgramApplications",
    defaultMessage: "Minhas Candidaturas a Apoios",
    description: "Title_Table_UserSingular_ProgramApplications",
  },
  // Minhas Candidaturas a Ofertas Emprego/Estágio
  Title_Table_UserSingular_OpportunityApplications: {
    id: "Title_Table_UserSingular_OpportunityApplications",
    defaultMessage: "Minhas Candidaturas a {opportunityType}",
    description: "Title_Table_UserSingular_OpportunityApplications",
  },
  // Meus acompanhamentos em curso/concluídos
  Title_Table_UserSingular_OpportunityApplicationsFollowup: {
    id: "Title_Table_UserSingular_OpportunityApplicationsFollowup",
    defaultMessage: "Meus acompanhamentos {opportunityApplicationsFollowupState}",
    description: "Title_Table_UserSingular_OpportunityApplicationsFollowup",
  },
  // Meus acompanhamentos em curso/concluídos - Utilizador Colectivo
  Title_Table_UserColective_OpportunityApplicationsFollowup: {
    id: "Title_Table_UserColective_OpportunityApplicationsFollowup",
    defaultMessage: "Acompanhamentos {opportunityApplicationsFollowupState}",
    description: "Title_Table_UserColective_OpportunityApplicationsFollowup",
  },
  // =====Footer=====
  // Sede Administrativa
  Title_Footer_SedeAdministrativa: {
    id: "Title_Footer_SedeAdministrativa",
    defaultMessage: "Sede Administrativa",
    description: "Footer Title_Footer_SedeAdministrativa",
  },
  Label_Footer_SedeAdministrativaLocation: {
    id: "Label_Footer_SedeAdministrativaLocation",
    defaultMessage: "Largo do Ambiente | Rua dos Municipios Portugueses",
    description: "Footer - Label_Footer_SedeAdministrativaLocation",
  },
  Label_Footer_SedeAdministrativaEmail: {
    id: "Label_Footer_SedeAdministrativaEmail",
    defaultMessage: "contacto@inefop.gov.ao",
    description: "Footer - Label_Footer_SedeAdministrativaEmail",
  },
  Label_Footer_SedeAdministrativaTelephone: {
    id: "Label_Footer_SedeAdministrativaTelephone",
    defaultMessage: "+244 223 345 567",
    description: "Footer - Label_Footer_SedeAdministrativaTelephone",
  },
  // Âmbito Institucional
  Title_Footer_AmbitoInstitucional: {
    id: "Title_Footer_AmbitoInstitucional",
    defaultMessage: "Âmbito Institucional",
    description: "Footer - Title_Footer_AmbitoInstitucional",
  },
  Label_Footer_AmbitoInstitucionalMission: {
    id: "Label_Footer_AmbitoInstitucionalMission",
    defaultMessage: "Missão, Visão e Valores",
    description: "Footer - Label_Footer_AmbitoInstitucionalMission",
  },
  Label_Footer_AmbitoInstitucionalHistory: {
    id: "Label_Footer_AmbitoInstitucionalHistory",
    defaultMessage: "História",
    description: "Footer - Label_Footer_AmbitoInstitucionalHistory",
  },
  Label_Footer_AmbitoInstitucionalOrganic: {
    id: "Label_Footer_AmbitoInstitucionalOrganic",
    defaultMessage: "Estatuto Orgânico",
    description: "Footer - Label_Footer_AmbitoInstitucionalOrganic",
  },
  Label_Footer_AmbitoInstitucionalLegislation: {
    id: "Label_Footer_AmbitoInstitucionalLegislation",
    defaultMessage: "Legislação",
    description: "Footer - Label_Footer_AmbitoInstitucionalLegislation",
  },
  Label_Footer_AmbitoInstitucional_TermsAndConditions: {
    id: "Label_Footer_AmbitoInstitucional_TermsAndConditions",
    defaultMessage: "Termos e Condições",
    description: "Footer - Label_Footer_AmbitoInstitucional_TermsAndConditions",
  },
  Label_Footer_AmbitoInstitucional_PrivacyPolicies: {
    id: "Label_Footer_AmbitoInstitucional_PrivacyPolicies",
    defaultMessage: "Políticas de Privacidade",
    description: "Footer - Label_Footer_AmbitoInstitucional_PrivacyPolicies",
  },
  // Services
  Title_Footer_Services: {
    id: "Title_Footer_Services",
    defaultMessage: "Serviços",
    description: "Footer - Title_Footer_Services",
  },
  Label_Footer_ServicesJobWeb: {
    id: "Label_Footer_ServicesJobWeb",
    defaultMessage: "Portal do Emprego",
    description: "Footer - Label_Footer_ServicesJobWeb",
  },
  Label_Footer_ServicesTrainingWeb: {
    id: "Label_Footer_ServicesTrainingWeb",
    defaultMessage: "Portal da Formação",
    description: "Footer - Label_Footer_ServicesTrainingWeb",
  },
  // Newsletter
  Title_Footer_Newsletter: {
    id: "Title_Footer_Newsletter",
    defaultMessage: "Newsletter",
    description: "Footer - Title_Footer_Newsletter",
  },
  Text_Footer_Newsletter: {
    id: "Message_Footer_Newsletter",
    defaultMessage:
      "Acompanhe diariamente a nossa oferta de serviços e mantenha-se informado sobre os nossos eventos subscrevendo a nossa newsletter...",
    description: "Footer - Message_Footer_Newsletter",
  },
  Text_Footer_NewsletterField: {
    id: "Text_Footer_NewsletterField",
    defaultMessage: "Digite o seu email",
    description: "Footer - Text_Footer_NewsletterField",
  },
  Text_Footer_NewsletterButton: {
    id: "Text_Footer_NewsletterButton",
    defaultMessage: "Subscrever",
    description: "Footer - Text_Footer_NewsletterButton",
  },
  // Socials
  Text_Footer_FollowSocials: {
    id: "Message_Footer_FollowSocials",
    defaultMessage: "Siga nossas redes sociais",
    description: "Footer - Message_Footer_FollowSocials",
  },
  // Copyright
  Label_Footer_Copyright: {
    id: "Label_Footer_Copyright",
    defaultMessage: "Instituto Nacional do Emprego e Formação Profissional - INEFOP",
    description: "Footer - Label_Footer_Copyright",
  },
  // Login Modal
  Label_LoginModal_EmailInputTitle: {
    id: "Label_LoginModal_EmailInputTitle",
    defaultMessage: "Telemóvel ou E-mail",
    description: "LoginModal - Label_LoginModal_EmailInputTitle",
  },
  Label_LoginModal_EmailInputPlaceholder: {
    id: "Label_LoginModal_EmailInputPlaceholder",
    defaultMessage: "Insira telemóvel ou E-mail",
    description: "LoginModal - Label_LoginModal_EmailInputPlaceholder",
  },
  Label_LoginModal_PasswordInputTitle: {
    id: "Label_LoginModal_PasswordInputTitle",
    defaultMessage: "Palavra-passe",
    description: "LoginModal - Label_LoginModal_PasswordInputTitle",
  },
  Label_LoginModal_PasswordInputPlaceholder: {
    id: "Label_LoginModal_PasswordInputPlaceholder",
    defaultMessage: "Insira palavra-passe",
    description: "LoginModal - Label_LoginModal_PasswordInputPlaceholder",
  },
  // Recover Password Modal
  Label_RecoverPasswordModal_MobilePhoneInputPlaceholder: {
    id: "Label_RecoverPasswordModal_MobilePhoneInputPlaceholder",
    defaultMessage: "Insira telemóvel",
    description: "RecoverPasswordModal - Label_RecoverPasswordModal_MobilePhoneInputPlaceholder",
  },
});
export const messages = defineMessages({
  Message_Footer_Newsletter: {
    id: "Message_Footer_Newsletter",
    defaultMessage:
      "Acompanhe diariamente a nossa oferta de serviços e mantenha-se informado sobre os nossos eventos subscrevendo a nossa newsletter...",
    description: "Footer - Message_Footer_Newsletter",
  },
  Message_Footer_FollowSocials: {
    id: "Message_Footer_FollowSocials",
    defaultMessage: "Siga nossas redes sociais",
    description: "Footer - Message_Footer_FollowSocials",
  },
  Message_Generic_MandatoryField: {
    id: "Message_Generic_MandatoryField",
    defaultMessage: "O campo é obrigatório",
    description: "Mensagem genérica para qualquer campo que seja obrigatório",
  },
  Message_Generic_InvalidField: {
    id: "Message_Generic_InvalidField",
    defaultMessage: "Formato incorrecto",
    description: "Mensagem genérica para qualquer campo que não cumpra o formato indicado",
  },
  Message_Auth_Recover_Password_Modal_1: {
    id: "Message_Auth_Recover_Password_Modal_1",
    defaultMessage: "Irá ser enviado um SMS para o número indicado, para definir uma nova palavra-passe.",
    description: "1º mensagem a aparecer na modal de recuperação de password",
  },
  Message_Auth_Recover_Password_Modal_2: {
    id: "Message_Auth_Recover_Password_Modal_2",
    defaultMessage: "Após autenticação, e por motivos de segurança, deverá proceder à substituição da palavra-passe.",
    description: "2º mensagem a aparecer na modal de recuperação de password",
  },
  Message_Auth_Recover_Password_Success: {
    id: "Message_Auth_Recover_Password_Success",
    defaultMessage: "Pedido de recuperação de palavra passe-enviado.",
    description: "Mensagem a aparecer após sucesso no pedido de recuperação de password",
  },
  Message_Auth_Confirmation_Modal: {
    id: "Message_Auth_Confirmation_Modal",
    defaultMessage:
      "Proteger a sua conta é a nossa prioridade. Confirme a sua conta inserindo o código de autorização enviado para *** *** {lastDigits}",
    description: "Mensagem a aparecer na modal de confirmação do registo",
  },
  Message_Auth_ConfirmationFooter_Modal: {
    id: "Message_Auth_ConfirmationFooter_Modal",
    defaultMessage: "Não feche esta janela enquanto não receber o sms de autenticação da sua conta.",
    description: "Mensagem a aparecer na modal de confirmação do código telefone",
  },
  Message_Auth_Password_Rules_1: {
    id: "Message_Auth_Password_Rules_1",
    defaultMessage: "A palavra-passe tem de conter:",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_2: {
    id: "Message_Auth_Password_Rules_2",
    defaultMessage: "Pelo menos 10 caracteres",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_3: {
    id: "Message_Auth_Password_Rules_3",
    defaultMessage: "Pelo menos 1 letra maiúscula",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_4: {
    id: "Message_Auth_Password_Rules_4",
    defaultMessage: "Pelo menos 1 letra minúscula",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_5: {
    id: "Message_Auth_Password_Rules_5",
    defaultMessage: "Pelo menos 1 número",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_6: {
    id: "Message_Auth_Password_Rules_6",
    defaultMessage: "Pelo menos 1 caracter especial",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Admin_Opportunity_Details_Missing_DocumentsMessage: {
    id: "Message_Admin_Opportunity_Details_Missing_DocumentsMessage",
    defaultMessage:
      "Para efectuar a candidatura tem de concluir o preenchimento do seu perfil e carregar os seguintes documentos: ",
    description:
      "Aviso que aparece quando o utilizador não tem os documentos necessários para se candidatar a uma oferta.",
  },
  Message_Admin_Applications_Create_Must_Complete_ProfileMessage: {
    id: "Message_Admin_Applications_Create_Must_Complete_ProfileMessage",
    defaultMessage: "Para efectuar a candidatura tem de concluir o preenchimento do seu perfil",
    description:
      "Aviso que aparece quando o utilizador não tem os dados do perfil necessários para efetuar candidatura PAPE.",
  },
  Message_Admin_User_Profile_User_Documents_Message: {
    id: "Message_Admin_User_Profile_User_Documents_Message",
    defaultMessage: "Para se candidatar a Empregos e Estágios profissionais deve submeter os seguintes documentos: ",
    description: "Aviso que aparece no perfil do utilizador na área de documentos.",
  },
  Message_Admin_HomePage_App_New_Version: {
    id: "Message_Admin_HomePage_App_New_Version",
    defaultMessage: "Nova Versão do INEFOP 360!",
    description: "New version of the app",
  },
  Message_Admin_HomePage_No_Access_To_Page: {
    id: "Message_Admin_HomePage_No_Access_To_Page",
    defaultMessage: "Sem Acesso",
    description: "No access to page",
  },
  Message_Admin_HomePage_No_Access_To_Page_Description_1: {
    id: "Message_Admin_HomePage_No_Access_To_Page_Description_1",
    defaultMessage: "Não tem permissão para aceder à área selecionada por ter iniciado sessão como utilizador do tipo",
    description: "No access to page",
  },
  Message_Admin_HomePage_No_Access_To_Page_Description_2: {
    id: "Message_Admin_HomePage_No_Access_To_Page_Description_2",
    defaultMessage: "A página só se encontra disponível para utilizadores do tipo",
    description: "No access to page",
  },
  Message_Generic_DateToGreaterThanOrEqualToTodayField: {
    id: "Message_Generic_DateToGreaterThanOrEqualToTodayField",
    defaultMessage: "A data deve ser igual ou superior à data actual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser igual ou superior à data actual",
  },
  Message_Generic_DateLessThanOrEqualToTodayField: {
    id: "Message_Generic_DateLessThanOrEqualToTodayField",
    defaultMessage: "A data deve ser igual ou inferior à data actual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser igual ou inferior à data actual",
  },
  Message_Generic_DateFromLessThanOrEqualToDateToField: {
    id: "Message_Generic_DateFromLessThanOrEqualToDateToField",
    defaultMessage: "A data deve ser igual ou inferior à data de fim",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser igual ou inferior à data de fim",
  },
  Message_Generic_DateToGreaterThanOrEqualToDateFromField: {
    id: "Message_Generic_DateToGreaterThanOrEqualToDateFromField",
    defaultMessage: "A data deve ser igual ou superior à data de início",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser igual ou superior à data de início",
  },

  //Registration
  Message_Auth_Register_Success: {
    id: "Message_Auth_Register_Success",
    defaultMessage: "O seu registo foi realizado com sucesso.",
    description: "Registration Label - Services List",
  },
  Message_Auth_Register_Success_IndividualUser: {
    id: "Message_Auth_Register_Success_IndividualUser",
    defaultMessage: "Agora já pode entrar e candidatar-se a apoios ou ofertas de emprego e estágio.",
    description: "Registration Label - Services List",
  },
  Message_Auth_Register_Success_CollectiveUser: {
    id: "Message_Auth_Register_Success_CollectiveUser",
    defaultMessage:
      "Por favor aguarde validação por parte do INEFOP para que possa criar ofertas de emprego e estágio.",
    description: "Registration Label - Services List",
  },
  Message_Auth_Register_Error: {
    id: "Message_Auth_Register_Error",
    defaultMessage: "O registo falhou, por favor tente mais tarde.",
    description: "Registration Label - Services List",
  },
});
export default errorMessages;
