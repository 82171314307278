import {
    postNewsletterAddContact,
} from "crud/newsletter.crud";
import { DIALOG, NEWSLETTER_ACTIONS } from "utils/actionsConsts";

export const postNewsletterAddContactAction = (email) => async (dispatch) => {
    dispatch({
        type: NEWSLETTER_ACTIONS.POST_NEWSLETTER_ADD_CONTACT_LOADING,
        payload: true,
    });
    postNewsletterAddContact(email)
        .then((res) => {
            dispatch({
                type: NEWSLETTER_ACTIONS.POST_NEWSLETTER_ADD_CONTACT_LOADING,
                payload: false,
            });
            dispatch({
                type: DIALOG.SHOW,
                payload: {
                    showGenericDialog: true,
                    genericDialogData: {
                        type: DIALOG.ONE_BUTTON_TYPE,
                        isSuccess: res.data,
                        buttonLabel: "Ok",
                        message: res.data ? "Subscrição confirmada!" : "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
                    },
                },
            });
        })
        .catch(() => {
            dispatch({
                type: NEWSLETTER_ACTIONS.POST_NEWSLETTER_ADD_CONTACT_LOADING,
                payload: false,
            });
            dispatch({
                type: DIALOG.SHOW,
                payload: {
                    showGenericDialog: true,
                    genericDialogData: {
                        type: DIALOG.ONE_BUTTON_TYPE,
                        isSuccess: false,
                        message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
                    },
                },
            });
        });
};