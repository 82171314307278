import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import componentStylesLandingPage from "assets/theme/components/landingPage/landing-page.js";
import { titles, labels } from "resources/resources";
import GenericButton from "components/Buttons/GenericButton";
import useWindowSize from "hooks/useWindowSize";

function WhoWeAre(props) {
  const useStylesLandingPage = makeStyles(componentStylesLandingPage);
  const classes = useStylesLandingPage();
  const intl = useIntl();
  const history = useHistory();
  const windowSize = useWindowSize();

  return (
    <Container
      maxWidth={false}
      className={
        classes.homepageSectionContainer +
        " " +
        classes.centerContent +
        " " +
        classes.homepageSectionContainerGrey
      }
    >
      <Typography
        component="h5"
        variant="h5"
        className={classes.homepageSectionMainHeader}
      >
        {intl.formatMessage(titles.Title_HomePage_WhoWeAre)}
      </Typography>

      <Grid container style={{ maxWidth: "1525px" }}>
        <Grid item xs={true}>
          <div style={{ textAlign: "justify" }}>{props.inefopSummary}</div>
          <GenericButton
            typeSubmit={false}
            color={"orange"}
            onClick={() =>
              history.push(
                (props.authState?.loggedIn ? "/admin" : "/auth") +
                  "/institution-overview"
              )
            }
            style={{
              marginTop: "30px",
            }}
          >
            {intl.formatMessage(labels.Label_Know_More_Ellipsis)}
          </GenericButton>
        </Grid>
        {windowSize.width > 1000 && (
          <img
            src={require("assets/img/brand/INEFOP_logo.png").default}
            alt={intl.formatMessage(labels.Label_HomePage_WhoWeAre_ImageAlt)}
            className={classes.whoWeAreSectionImage}
          />
        )}
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(WhoWeAre);
