import { getListFromFormData } from "utils/formSelectMultiple";

export function individualRegistrationMap(formData) {
  if (formData != null && formData !== undefined) {
    return {
      user: {
        id: "",
        userType: 1,
        name: formData.name ? formData.name : "",
        mobilePhone: formData.mobilePhone ? formData.mobilePhone : "",
        alternativeMobilePhone: formData.alternativeMobilePhone
          ? formData.alternativeMobilePhone
          : "",
        email: formData.email ? formData.email : "",
        password: formData.password ? formData.password : "",
        userCode: "",
        userSingular: {
          lastName: formData.lastname ? formData.lastname : "",
          dateOfBirth: formData.birthdate ? formData.birthdate : "",
          identityCard: formData.identityCard ? formData.identityCard : "",
          specialNeedsId: formData.specialNeeds
            ? formData.specialNeeds.code
            : null,
          specialNeedsPercent: formData.specialNeedsPercent
            ? formData.specialNeedsPercent
            : null,
          genderId: formData.gender ? formData.gender.code : null,
          maritalStatusId: formData.maritalStatus
            ? formData.maritalStatus.code
            : null,
          nationalityId: formData.nationality ? formData.nationality.code : null,
          niss: formData.niss ? formData.niss : "",
          hasNISS: formData.hasNISS ? formData.hasNISS : null,
          compositeAddress: {
            provinceId: formData.province ? formData.province.code : null,
            provinceKey: formData.province ? formData.province.keyValue : "",
            municipalityId: formData.municipality
              ? formData.municipality.code
              : null,
            district: formData.district ? formData.district : "",
            address: formData.address ? formData.address : "",
          },
          academicLevelId: formData.academicLevel
            ? formData.academicLevel.code
            : null,
          degreeId:
            formData.degree && formData.degree.code !== ""
              ? formData.degree.code
              : null,
          professionalCategoryId:
            formData.professionalCategory &&
              formData.professionalCategory.code !== ""
              ? formData.professionalCategory.code
              : null,
          professionalTrainingId: formData.professionalTraining
            ? formData.professionalTraining.code.toString()
            : null, // .label?
          entrepreneurshipTrainingId: formData.entrepreneurshipTraining
            ? formData.entrepreneurshipTraining.code.toString()
            : null, // .label?
          smallBusinessMgmtTrainingId: formData.smallBusinessMgmtTraining
            ? formData.smallBusinessMgmtTraining.code.toString()
            : null, // .label?
          professionalExperienceList: getListFromFormData(formData.professionalExperience) ?? [],
          experience: formData.experience ? formData.experience : null,
          isAvailableForInternshipOpportunities: formData.isAvailableForInternshipOpportunities ?? false,
          isAvailableForEmploymentOpportunities: formData.isAvailableForEmploymentOpportunities ?? false,
        },
      },
    };
  }
  return formData;
}

export default individualRegistrationMap;
