import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

// @material-ui/core components
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// core components
import CardMyGovernmentProgramsApplications from "components/Cards/Dashboard/CardMyGovernmentProgramsApplications";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import componentStylesLandingPage from "assets/theme/components/landingPage/landing-page.js";
import { checkOperationPermission } from "utils/permission";
import { OPERATIONS } from "utils/const";
import CardMyOpportunityApplications from "components/Cards/Dashboard/CardMyOpportunityApplications";
import CardMyOpportunityApplicationsEmployment from "components/Cards/Dashboard/CardMyOpportunityApplicationsEmployment";
import CardMyOpportunityApplicationsInternship from "components/Cards/Dashboard/CardMyOpportunityApplicationsInternship";
import { labels } from "resources/resources";

const useStyles = makeStyles(componentStyles);
const useStylesLandingPage = makeStyles(componentStylesLandingPage);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

function Dashboard(props) {
  const classes = { ...useStyles(), ...useStylesLandingPage(), ...useStylesCardDeck() };
  const intl = useIntl();

  return (
    <Container maxWidth={false} className={classes.homepageSectionContainer}>
      <Typography component="h5" variant="h5" className={classes.homepageSectionMainHeader}>
        {intl.formatMessage(labels.Label_HomePage_MyProcesses)}
      </Typography>

      <Grid container style={{ marginBottom: "-40px" }}>
        {checkOperationPermission(
          props.authState.operationsList,
          OPERATIONS.RECENT_OPPORTUNITY_APPLICATIONS_USER_COLLECTIVE
        ) && (
          <Grid item xs={12} xl={6}>
            <CardMyOpportunityApplicationsEmployment />
          </Grid>
        )}
        {checkOperationPermission(
          props.authState.operationsList,
          OPERATIONS.RECENT_OPPORTUNITY_APPLICATIONS_USER_COLLECTIVE
        ) && (
          <Grid item xs={12} xl={6}>
            <CardMyOpportunityApplicationsInternship />
          </Grid>
        )}
        {checkOperationPermission(props.authState.operationsList, OPERATIONS.RECENT_APPLICATIONS_DASHBOARD) && (
          <Grid item xs={12} xl={6}>
            <CardMyGovernmentProgramsApplications />
          </Grid>
        )}
        {checkOperationPermission(
          props.authState.operationsList,
          OPERATIONS.RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD_USER_SINGULAR
        ) && (
          <Grid item xs={12} xl={6}>
            <CardMyOpportunityApplications />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(Dashboard);
