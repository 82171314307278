import { PAGINATION } from "utils/const";

export function getFilteredOpportunitiesMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      searchText: formData.searchText,
      reference: formData.reference,
      provinceId: formData.province?.code,
      municipalityId: formData.county?.code,
      opportunityTypeId: formData.opportunityType?.code,
      opportunityStateId: formData.opportunityState?.code,
      professionalCategoryId: formData.professionalCategory?.code,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE,
    };
  }
  return formData;
}

export function getFilteredAvailableOpportunitiesMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      provinceId: formData.province?.code,
      municipalityId: formData.county?.code,
      opportunityTypeId: formData.opportunityType?.code,
      professionalCategoryId: formData.opportunityProfessionalCategory?.code,
      contractTypeId: formData.contractType?.code,
      internshipTypeId: formData.internshipType?.code,
      timeTableTypeId: formData.timeTableType?.code,
      companyName: formData.companyName,
      searchText: formData.searchText,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE_AVAILABLE_OPPORTUNITIES,
    };
  }
  return formData;
}

export function getUserColectiveOpportunityApplicationsMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      opportunityTitleOrDescription: formData.searchText,
      opportunityTypeId: formData.opportunityType?.code,
      candidateCode: formData.applicationCode,
      candidateStateId: formData.applicationState?.code,
      opportunityReference: formData.opportunityReference,
      userNameOrIdentityCard: formData.userNameOrIdentityCard,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE,
    };
  }
  return formData;
}

export default getFilteredOpportunitiesMap;
