import React from "react";
import moment from "moment";

import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import updatePersonalProfileAction from "redux/actions/updatePersonalProfileAction";

import { getPersonalInformationAction } from "redux/actions/auth/getPersonalInformationAction";
import { getSelectOptionsElementByCode, getMultipleSelectOptionsElementByCode } from "utils/formSelect";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import PasswordForm from "../Components/PasswordForm";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

//custom components
import UserForm from "./UserForm";
import CompanyForm from "./CompanyForm";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";

import { FetchDatasetsOnMount } from "utils/datasets";
import { dataSetsNeededColectiveRegistration } from "constants/componentsDatasets";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesUserProfile from "assets/theme/views/admin/user-profile.js";
import UserPicture from "../Components/UserPicture";
import { DATASET } from "utils/const";
import userProfileEnterpriseUpdateMap from "mappers/UserProfileEnterpriseUpdateMap";
import LocationHeader from "components/Headers/LocationHeader";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesUserProfile = makeStyles(componentStylesUserProfile);

function UserProfile(props) {
  FetchDatasetsOnMount(props.getDataSetByName, props.datasetsState, dataSetsNeededColectiveRegistration);

  const classes = { ...useStylesGeneric(), ...useStylesUserProfile() };
  const [file, setFile] = React.useState();
  const [tabType, settabType] = React.useState("user");
  const [changePassword, setChangePassword] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  React.useEffect(() => {
    props.getPersonalInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #region SETVALUES
  React.useEffect(() => {
    if (!props.authState.personalInformation_loading) {
      methods.setValue("companyName", props.authState.personalInformation?.name);
      methods.setValue("contactName", props.authState.personalInformation?.userColective?.contactName);
      methods.setValue("alternativeMobilePhone", props.authState.personalInformation?.alternativeMobilePhone);
      methods.setValue("mobilePhone", props.authState.personalInformation?.mobilePhone);
      methods.setValue("mobilePhoneConfirmation", props.authState.personalInformation?.mobilePhone);
      methods.setValue("email", props.authState.personalInformation?.email);
      methods.setValue("emailConfirmation", props.authState.personalInformation?.email);
      methods.setValue("taxIdNumber", props.authState.personalInformation?.userColective?.taxIdNumber);
      methods.setValue("permitCode", props.authState.personalInformation?.userColective?.permitCode);
      methods.setValue(
        "permitCodeValidUntil",
        props.authState.personalInformation?.userColective?.permitCodeValidUntil
          ? moment(props.authState.personalInformation.userColective.permitCodeValidUntil)
          : ""
      );
      methods.setValue("district", props.authState.personalInformation?.userColective?.compositeAddress?.district);
      methods.setValue("address", props.authState.personalInformation?.userColective?.compositeAddress?.address);
      methods.setValue("niss", props.authState.personalInformation?.userColective?.niss);
      methods.setValue("companyEmail", props.authState.personalInformation?.userColective?.companyEmail);
      methods.setValue("companyPhone", props.authState.personalInformation?.userColective?.companyPhone);
      methods.setValue("experience", props.authState.personalInformation?.userColective?.experience);
      methods.setValue("propertyType", props.authState.personalInformation?.userColective?.propertyType);
      methods.setValue("subsidiaryCompany", props.authState.personalInformation?.userColective?.subsidiaryCompany);
      methods.setValue(
        "subsidiaryCompanyPostalCode",
        props.authState.personalInformation?.userColective?.subsidiaryCompanyPostalCode
      );
      methods.setValue(
        "subsidiaryCompanyActivity",
        props.authState.personalInformation?.userColective?.subsidiaryCompanyActivity
      );
      methods.setValue("maleWorkersNumber", props.authState.personalInformation?.userColective?.maleWorkersNumber);
      methods.setValue("femaleWorkersNumber", props.authState.personalInformation?.userColective?.femaleWorkersNumber);
    }

    setupDropdowns(props, methods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authState.personalInformation_loading]);
  // #endregion

  //#region PROVINCES
  React.useEffect(() => {
    if (props.datasetsState && props.datasetsState[DATASET.PROVINCES]) {
      methods.setValue(
        "province",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.PROVINCES],
          props.authState.personalInformation?.userColective?.compositeAddress?.provinceId
        )
      );

      const provinceKey = props.datasetsState[DATASET.PROVINCES].find(
        (el) => el.code === props.authState.personalInformation?.userColective?.compositeAddress?.provinceId
      )?.keyValue;
      if (provinceKey) props.getDataSetByName(DATASET.MUNICIPALITIES, provinceKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROVINCES]]);
  //#endregion

  //#region MUNICIPALITIES
  React.useEffect(() => {
    if (props.datasetsState) {
      methods.setValue(
        "municipality",
        // getDatasetStateByName(props, DATASET.MUNICIPALITIES)
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.MUNICIPALITIES],
          props.authState.personalInformation?.userColective?.compositeAddress?.municipalityId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.MUNICIPALITIES]]);
  //#endregion

  //#region BUSINESS_PURPOSE
  React.useEffect(() => {
    if (props.datasetsState) {
      methods.setValue(
        "businessPurpose",
        getMultipleSelectOptionsElementByCode(
          props.datasetsState[DATASET.BUSINESS_PURPOSE],
          props.authState.personalInformation?.userColective?.businessPurposeList
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.BUSINESS_PURPOSE]]);
  //#endregion

  //#region ECONOMIC_ACTIVITIES_CLASSIFICATION
  React.useEffect(() => {
    if (props.datasetsState) {
      methods.setValue(
        "companyEACId",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION],
          props.authState.personalInformation?.userColective?.companyEACId
        )
      );

      methods.setValue(
        "subsidiaryCompanyEACId",
        getSelectOptionsElementByCode(
          props.datasetsState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION],
          props.authState.personalInformation?.userColective?.subsidiaryCompanyEACId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION]]);
  //#endregion

  const handletabType = (event, value) => {
    if (value !== null) {
      settabType(value);
    }
  };

  function handleChangePassword() {
    setChangePassword(true);
  }

  function handleCloseModal() {
    setChangePassword(false);
  }

  const handleRegister = (data) => {
    const mappedData = userProfileEnterpriseUpdateMap(data, file, props.authState?.personalInformation?.id);
    props.updatePersonalProfile(mappedData);
  };

  return (
    <>
      <GenericBackdrop open={props.authState.personalInformation_loading} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleRegister)}>
          <Container maxWidth={false} component={Box} marginTop="2.5rem" classes={{ root: classes.containerRoot }}>
            <LocationHeader section="Meu Perfil" />
            <Grid container>
              <Grid item xs={12} md={3} className={classes.userProfileTitle}>
                Meu perfil
              </Grid>
              <Grid item xs={12} md={9}>
                <ToggleButtonGroup
                  value={tabType}
                  exclusive
                  onChange={handletabType}
                  aria-label="entity type"
                  className={classes.changeTabsGroupUserProfile}
                >
                  <ToggleButton
                    value="user"
                    aria-label="user"
                    className={classes.changeTabs + " " + classes.changeTabsUserProfile}
                  >
                    Dados Utilizador
                  </ToggleButton>
                  <ToggleButton
                    value="professional"
                    aria-label="empresa"
                    className={classes.changeTabs + " " + classes.changeTabsUserProfile}
                  >
                    Dados Empresa e Palavra-Passe
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} md={3}>
                {tabType === "user" ? <UserPicture file={file} setFile={setFile} /> : null}
              </Grid>
              {tabType === "user" ? (
                <Grid item xs={12} md={9}>
                  <UserForm />
                </Grid>
              ) : (
                <Grid item xs={12} md={12}>
                  <CompanyForm handleChangePassword={handleChangePassword} />
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box className={classes.boxButtonSaveProfile}>
                  <GenericButton
                    typeSubmit={true}
                    color="primary"
                    loading={props.personalProfileState.update_profile_loading}
                  >
                    Guardar
                  </GenericButton>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </form>
      </FormProvider>

      <GenericDialog maxWidth={"sm"} open={changePassword} onClose={handleCloseModal}>
        <PasswordForm handleCloseModal={handleCloseModal} />
      </GenericDialog>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = (dispatch) => ({
  updatePersonalProfile: (data) => dispatch(updatePersonalProfileAction(data)),
  getPersonalInformation: () => dispatch(getPersonalInformationAction()),
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

function setupDropdowns(props, methods) {
  /* #region  Province */
  if (props.datasetsState && props.datasetsState[DATASET.PROVINCES]) {
    const provinceId = props.authState.personalInformation?.userColective?.compositeAddress?.provinceId;

    methods.setValue("province", getSelectOptionsElementByCode(props.datasetsState[DATASET.PROVINCES], provinceId));

    const provinceKey = props.datasetsState[DATASET.PROVINCES].find(
      (el) => el.code === props.authState.personalInformation?.userColective?.compositeAddress?.provinceId
    )?.keyValue;
    if (provinceKey) props.getDataSetByName(DATASET.MUNICIPALITIES, provinceKey);
  }
  /* #endregion */

  /* #region  Municipality */
  if (props.datasetsState && props.datasetsState[DATASET.MUNICIPALITIES]) {
    methods.setValue(
      "municipality",
      // getDatasetStateByName(props, DATASET.MUNICIPALITIES)
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.MUNICIPALITIES],
        props.authState.personalInformation?.userColective?.compositeAddress?.municipalityId
      )
    );
  }

  /* #endregion */

  /* #region Business Purpose */
  if (props.datasetsState && props.datasetsState[DATASET.BUSINESS_PURPOSE]) {
    methods.setValue(
      "businessPurpose",
      getMultipleSelectOptionsElementByCode(
        props.datasetsState[DATASET.BUSINESS_PURPOSE],
        props.authState.personalInformation?.userColective?.businessPurposeList
      )
    );
  }
  /* #endregion */

  /* #region Business Purpose */
  if (props.datasetsState && props.datasetsState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION]) {
    methods.setValue(
      "companyEACId",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION],
        props.authState.personalInformation?.userColective?.companyEACId
      )
    );

    methods.setValue(
      "subsidiaryCompanyEACId",
      getSelectOptionsElementByCode(
        props.datasetsState[DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION],
        props.authState.personalInformation?.userColective?.subsidiaryCompanyEACId
      )
    );
  }
  /* #endregion */
}
