import { React, useState, useEffect } from "react";
// redux
import { connect } from "react-redux";
//redux actions
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
// @material-ui
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
// @material-ui/icons components
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
// styles
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
//utils
import { groupBy } from "utils/array_utils";
import { DATASET } from "utils/const";

import moment from "moment";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ApplicationStepper(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(2);
  const [states, setStates] = useState([]);

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 20,
    },
    active: {
      "& $line": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    completed: {
      "& $line": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    line: {
      height: 4,
      marginBottom: "10px",
      border: 0,
      backgroundColor: theme.palette.gray[100],
      borderRadius: 1,
    },
  })(StepConnector);

  //Application State
  useEffect(() => {
    if (props.applicationState !== undefined) updateActiveStep(props.applicationState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applicationState, states]);

  //Stepper States
  useEffect(() => {
    if (props.datasetsState[DATASET.APPLICATION_STATES] !== undefined) {
      const states = props.datasetsState[DATASET.APPLICATION_STATES].map((state) => {
        let date = props.history?.find((element) => element.group === state.keyValue)?.date; //state.KeyValue is the StateGroup
        let data_string = date ? moment(date).format("DD/MM/YYYY") : "--";

        return { id: state.code, label: state.keyValue, date: data_string }; //state.KeyValue is the StateGroup
      });

      const steps = groupBy(states, (x) => x.label);
      setStates(Array.from(steps));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.APPLICATION_STATES], props.history]);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.APPLICATION_STATES] === undefined ||
      props.datasetsState[DATASET.APPLICATION_STATES]?.length === 0
    ) {
      props.getDataSetByName(DATASET.APPLICATION_STATES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateActiveStep = (applicationState) => {
    if (applicationState !== undefined && states !== []) {
      let found = states.findIndex((state) => {
        return state[1].some((item) => {
          return item.id === applicationState;
        });
      });
      let step = found === states.length - 1 ? states.length : found;
      setActiveStep(step);
    }
  };

  return (
    <Stepper className={classes.stepperBox} activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
      {states.map((state, index) => {
        return (
          <Step key={index}>
            <StepLabel
              classes={{
                root: classes.stepperText,
              }}
              StepIconComponent={ColorlibStepIcon}
            >
              <StepLabel>{state[1][0].date}</StepLabel>
              {state[0]}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationStepper);

function ColorlibStepIcon(props) {
  const classes = useStylesGeneric();

  return (
    <div>
      <Fab size="small" className={classes.stepperCircle}>
        {props.completed ? <CheckCircleIcon className={classes.stepperCheck}></CheckCircleIcon> : ""}
        {props.active ? <Brightness1RoundedIcon className={classes.stepperFilledCircle}></Brightness1RoundedIcon> : ""}
      </Fab>
    </div>
  );
}
