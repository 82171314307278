import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import OpportunityApplicationsFilterSection from "./Sections/OpportunityApplicationsFilterSection";
import OpportunityApplicationsResultSection from "./Sections/OpportunityApplicationsResultSection";
import { getIsOpportunityApplicationAction } from "redux/actions/opportunitiesAction";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityApplicationsList(props) {
  const classes = useStylesGeneric();

  const [page, setPage] = useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const setIsOpportunityApplication = () => {
    props.getIsOpportunityApplication(true);
  };
  
  useEffect(() => {
    setIsOpportunityApplication();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Grid container classes={{ root: classes.containerRoot }}>
      <Grid item xs={12} md={12}>
        <OpportunityApplicationsFilterSection
          page={page}
          handleChangePage={(value) => { handleChangePage(undefined, value); }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Box classes={{ root: classes.tableResultBoxRoot }}>
          <OpportunityApplicationsResultSection 
            page={page}
            handleChangePage={handleChangePage}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getIsOpportunityApplication: (isOpportunityApplication) => dispatch(getIsOpportunityApplicationAction(isOpportunityApplication)),
});


export default connect(mapStateToProps, mapDispatchToProps)(OpportunityApplicationsList);