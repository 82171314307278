import React from "react";

import EmploymentHistorySection from "views/admin/UserArea/UserSingular/Sections/EmploymentHistorySection";
import UnemploymentHistorySection from "views/admin/UserArea/UserSingular/Sections/UnemploymentHistorySection";

function EmploymentAndUnemploymentHistory() {
  return (
    <>
      <EmploymentHistorySection />
      <UnemploymentHistorySection />
    </>
  );
}

export default EmploymentAndUnemploymentHistory;
