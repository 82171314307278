import { React, useState } from "react";
import clsx from "clsx";
import { labels, errorMessages } from "resources/resources";
import { Controller } from "react-hook-form";
import { connect } from "react-redux";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import ReactDatetime from "react-datetime";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import DateRange from "@material-ui/icons/DateRange";
//import custom components
import "moment/locale/pt";
import moment from "moment";
import { REGISTRATION_CONSTS, REGEX_PATTERNS} from "utils/const";

function PersonalData({ getValues, errors, classes, theme, trigger, control, ...props }) {
  // const { control } = useFormContext();

  const [stateInternship, setStateInternship] = useState(false);
  const [stateEmployment, setStateEmployment] = useState(false);


  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE_SIMPLE.test(date)) {
      onChange(date);
    }
  };

  const handleChangeInternship = (event) => {
    setStateInternship(event.target.checked);
  };

  const handleChangeEmployment = (event) => {
    setStateEmployment(event.target.checked);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>
              {props.intl.formatMessage(labels.Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Name)}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              component={Box}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["name"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["name"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["name"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>
              {props.intl.formatMessage(labels.Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Surname)}
            </FormLabel>
            <Controller
              name="lastname"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
              }}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["lastname"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["lastname"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["lastname"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>Data de Nascimento</FormLabel>
            <Controller
              name="birthdate"
              control={control}
              component={Box}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  maxDate: (value) => {
                    return (
                      value < moment().startOf("day") ||
                      props.intl.formatMessage(errorMessages.ErrorMessage_Birthdate_MaxDate)
                    );
                  },
                  minDate: (value) => {
                    return (
                      value >
                      moment(REGISTRATION_CONSTS.MIN_BIRTHDATE, REGISTRATION_CONSTS.DATE_TIME_FORMAT).startOf(
                        "day"
                      ) ||
                      props.intl.formatMessage(errorMessages.ErrorMessage_Birthdate_MinDate, {
                        minDate: REGISTRATION_CONSTS.MIN_BIRTHDATE,
                      })
                    );
                  },
                  dateHasSlash: (value) => {
                    if (value._isAMomentObject !== true) {
                      if (!value.includes("/")) {
                        return props.intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                      }
                    }
                  },
                  isAdult: (value) => {
                    if (moment().diff(value, "years") < 17) {
                      return props.intl.formatMessage(errorMessages.ErrorMessage_MinRegisterDate);
                    }
                  },
                },
              }}
              render={({ field }) => (
                <ReactDatetime
                  {...field}
                  locale="pt"
                  dateFormat={REGISTRATION_CONSTS.DATE_TIME_FORMAT}
                  timeFormat={false}
                  isValidDate={(currentDate, _) => {
                    return (
                      currentDate < moment().startOf("day") &&
                      currentDate >
                      moment(REGISTRATION_CONSTS.MIN_BIRTHDATE, REGISTRATION_CONSTS.DATE_TIME_FORMAT).startOf("day")
                    );
                  }}
                  inputProps={{
                    placeholder: REGISTRATION_CONSTS.DATE_TIME_PLACEHOLDER,
                    className: "",
                  }}
                  renderInput={(dateInputProps, open) => (
                    <OutlinedInput
                      onFocus={open}
                      {...dateInputProps}
                      onChange={(e) => {
                        handleValidDate(e.target.value, dateInputProps.onChange);
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box component={DateRange} marginRight=".5rem" />
                        </InputAdornment>
                      }
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: errors["birthdate"] !== undefined,
                        }),
                      }}
                      style={{ width: "100%" }}
                    />
                  )}
                />
              )}
            />
            {errors["birthdate"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["birthdate"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>
              {props.intl.formatMessage(labels.Label_Auth_IndividualRegistrationSteps_PersonalData_Field_IdentityCard)}
            </FormLabel>
            <Controller
              name="identityCard"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  maxLength: (value) => {
                    return (
                      value.length <= REGISTRATION_CONSTS.MAX_LENGTH_ID_CARD ||
                      props.intl.formatMessage(errorMessages.ErrorMessage_ID_Card_InvalidFormat)
                    );
                  },
                  //IDisUnique: async () => await fetch()
                },
              }}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  inputProps={{ maxLength: 14 }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["identityCard"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["identityCard"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["identityCard"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>
              {props.intl.formatMessage(labels.Label_Auth_IndividualRegistrationSteps_PersonalData_Field_MobilePhone)}
            </FormLabel>
            <Controller
              name="mobilePhone"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  validPhoneNumber: (value) => {
                    if (
                      value &&
                      (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                        parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                    )
                      return props.intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                    return true;
                  },
                  validLength: () => { },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  onChange={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
                    onChange(e);
                    trigger("mobilePhoneConfirmation");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["mobilePhone"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["mobilePhone"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["mobilePhone"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>Confirmação telemóvel</FormLabel>
            <Controller
              name="mobilePhoneConfirmation"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  validPhoneNumber: (value) => {
                    if (
                      value &&
                      (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                        parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                    )
                      return props.intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                    return true;
                  },
                  phoneNumberMatch: (value) => {
                    return (
                      value === getValues("mobilePhone") ||
                      props.intl.formatMessage(
                        errorMessages.ErrorMessage_MobilePhone_ConfirmationMobilePhone_Not_Match,
                        {
                          phoneLabel: props.intl.formatMessage(
                            labels.Label_Auth_IndividualRegistrationSteps_PersonalData_Field_MobilePhone
                          ),
                        }
                      )
                    );
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  disabled={!getValues("mobilePhone") || errors["mobilePhone"] !== undefined}
                  onChange={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
                    onChange(e);
                    trigger("mobilePhone");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["mobilePhoneConfirmation"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["mobilePhoneConfirmation"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["mobilePhoneConfirmation"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup>

            <Typography component="div" variant="body2">
              <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Controller
                    name="isAvailableForInternshipOpportunities"
                    control={control}
                    rules={{
                      validate: {
                        oneIsChecked: (value) => {
                          return value || getValues("isAvailableForEmploymentOpportunities") || "Seleccione pelo menos um dos campos";
                        },
                      },
                    }}
                    render={({ field: { onChange, name } }) => (
                      <Switch
                        name={name}
                        checked={stateInternship}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          handleChangeInternship(e);
                          trigger("isAvailableForEmploymentOpportunities");
                        }}
                        color="primary"
                      />
                    )}
                  />
                </Grid>
                <Grid item><FormLabel>{props.intl.formatMessage(labels.Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForInternshipOpportunities)}</FormLabel></Grid>
              </Grid>
              {errors["isAvailableForInternshipOpportunities"] &&  (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["isAvailableForInternshipOpportunities"].message}
                    </FormHelperText>
                  )}
            </Typography>
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup>

            <Typography component="div" variant="body2">
              <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Controller
                    name="isAvailableForEmploymentOpportunities"
                    control={control}
                    rules={{
                      validate: {
                        oneIsChecked: (value) => {
                          return value || getValues("isAvailableForInternshipOpportunities") || "Seleccione pelo menos um dos campos";
                        },
                      },
                    }}
                    render={({ field: { onChange, name } }) => (
                      <Switch
                        name={name}
                        checked={stateEmployment}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          handleChangeEmployment(e);
                          trigger("isAvailableForInternshipOpportunities");
                        }}
                        color="primary"
                      />
                    )}
                  />
                </Grid>
                <Grid item><FormLabel>{props.intl.formatMessage(labels.Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForEmploymentOpportunities)}</FormLabel></Grid>
                {errors["isAvailableForEmploymentOpportunities"] && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["isAvailableForEmploymentOpportunities"].message}
                    </FormHelperText>
                  )}
              </Grid>
            </Typography>
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
