import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useIntl } from "react-intl";

// @material-ui
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";

// components
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import { PAGINATION } from "utils/const";
import { getUserEmploymentHistoryAction } from "redux/actions/auth/getPersonalInformationAction";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import { labels } from "resources/resources";
import AddEmploymentHistoryModal from "../Modals/AddEmploymentHistoryModal";
import { useCallback } from "react";
import useTokenHelper from "hooks/useTokenHelper";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function EmploymentHistorySection(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const tokenHelper = useTokenHelper();
  const [page, setPage] = useState(1);

  const [showAddEmploymentHistoryModal, setShowAddEmploymentHistoryModal] = useState({
    open: false,
    editing: false,
    data: {},
  });

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const tableHead = [
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityNumber),
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Company),
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Profession),
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityType),
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_WorkTimeFrame),
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Salary),
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Observations),
    "",
  ];

  const getUserEmploymentHistory = useCallback(() => {
    const filter = {
      pageIndex: page <= 0 ? 1 : page,
      pageSize: PAGINATION.PAGE_SIZE,
      userId: tokenHelper.getUserId(),
    };

    props.getUserEmploymentHistory(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getUserEmploymentHistory();
  }, [getUserEmploymentHistory]);

  function handleToggleAddEmploymentHistoryModal(data = {}, editing = false) {
    setShowAddEmploymentHistoryModal((prevState) => ({
      open: !prevState.open,
      editing,
      data,
    }));
  }

  function handleOnSuccessAction() {
    setPage(1);
    getUserEmploymentHistory();
  }

  return (
    <>
      <CardTableContainer
        title={intl.formatMessage(
          labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentHistory
        )}
        count={props.authState?.getUserEmploymentHistory_result?.totalCount}
        actionButtonLabel={intl.formatMessage(
          labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_AddRecord
        )}
        actionButtonOnClick={() => handleToggleAddEmploymentHistoryModal()}
      >
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.authState?.getUserEmploymentHistory_result?.data?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {prop?.opportunityReference && (
                      <GenericButton
                        color="ghost"
                        typeSubmit={false}
                        size="small"
                        onClick={() => {
                          history.push("/admin/available-opportunity-details/" + prop?.opportunityAlternateKey);
                        }}
                      >
                        {prop?.opportunityReference}
                      </GenericButton>
                    )}

                    {!prop?.opportunityReference && <Box>N/A</Box>}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop?.companyName}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop?.professionalCategoryDescription}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop?.opportunityTypeDescription}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>
                      {!prop?.workedSince && !prop?.workedUntil
                        ? intl.formatMessage(labels.Label_Undefined)
                        : (prop?.workedSince
                            ? moment(prop?.workedSince).format("DD/MM/YYYY")
                            : intl.formatMessage(labels.Label_Undefined)) +
                          " - " +
                          (prop?.workedUntil
                            ? moment(prop?.workedUntil).format("DD/MM/YYYY")
                            : intl.formatMessage(labels.Label_Undefined))}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop?.salary}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop?.observations}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <GenericButton
                      typeSubmit={false}
                      color="tertiary"
                      size="small"
                      onClick={() => {
                        handleToggleAddEmploymentHistoryModal(prop, true);
                      }}
                    >
                      {intl.formatMessage(labels.Label_Button_Edit)}
                    </GenericButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
          <TableBackdrop open={props?.authState?.getUserEmploymentHistory_loading} />
        </TableContainer>

        <Pagination
          className={classes.alignPagination}
          count={props.authState?.getUserEmploymentHistory_result?.totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        ></Pagination>
      </CardTableContainer>

      <GenericDialog
        maxWidth={"md"}
        open={showAddEmploymentHistoryModal.open}
        onClose={handleToggleAddEmploymentHistoryModal}
      >
        <AddEmploymentHistoryModal
          data={showAddEmploymentHistoryModal.data}
          onCloseModal={handleToggleAddEmploymentHistoryModal}
          editing={showAddEmploymentHistoryModal.editing}
          onSuccess={handleOnSuccessAction}
        />
      </GenericDialog>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUserEmploymentHistory: (data) => dispatch(getUserEmploymentHistoryAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentHistorySection);
