import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function OrganizationChart() {
  const intl = useIntl();

  const organizationChartData = [
    {
      title: "",
      content: [
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/INEFOP_OrganizationChart_current.png",
          title: "",
        },
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(titles.Title_Menu_InstitutionalInformation_OrganizationChart)}
      data={organizationChartData}
    />
  );
}

export default OrganizationChart;
