import { frontOfficeEnterpriseProfileUpdate } from "crud/updateenterpriseprofile.crud";
import { UPDATE_PROFILE, DIALOG } from "utils/actionsConsts";

export const updateEnterpriseProfileAction = (formData) => async (dispatch) => {
    frontOfficeEnterpriseProfileUpdate(formData)
    .then((res) => {
      dispatch({
        type: UPDATE_PROFILE.ENTERPRISE,
        payload: res.data.succeeded,
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data.succeeded,
            message: "Utilizador actualizado com sucesso",
          }
        }
      });
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_PROFILE.ENTERPRISE,
        payload: false,
      });

      console.log("error", error);
    });
};

export default updateEnterpriseProfileAction;