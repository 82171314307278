import { register } from "crud/auth.crud";
import { DIALOG, REGISTER } from "utils/actionsConsts";

export const registerAction = (user, userType) => async (dispatch) => {
  dispatch({ type: REGISTER.REGISTER_LOADING, payload: true });
  register(user)
    .then((res) => {
      dispatch({ type: REGISTER.REGISTER, payload: res.data != null, userType: userType });
    })
    .catch((error) => {
      dispatch({ type: REGISTER.REGISTER, payload: false });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            noRedirect: true,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
    });
};

export const hideRegistrationModalAction = () => async (dispatch) => {
  dispatch({ type: REGISTER.HIDE_REGISTRATION_MODAL, payload: false });
};
export default registerAction;
