import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import AuthFooter from "components/Footers/AuthFooter";
import GradientHeader from "components/Headers/GradientHeader.js";

import routes from "routes.js";

import componentStyles from "assets/theme/layouts/admin.js";

const useStyles = makeStyles(componentStyles);

const AuthSearch = (props) => {
  const classes = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth-search") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (
    props.authState.loggedIn !== undefined &&
    props.authState.loggedIn &&
    !props.authState.mustConfirmPhone 
  ) {
    return <Redirect to="/admin/home" />;
  } else {
    return (
      <>
        <Box>
          <Box position="relative" flex="1" className={classes.mainContent}>
            <AuthNavbar/>
            
            <GradientHeader />

            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/home" />
            </Switch>
          </Box>

          <AuthFooter />
        </Box>
      </>
    );
  }
};

// export default Auth;
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, {})(AuthSearch);
