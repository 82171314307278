import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { FetchDatasetsOnMount } from "../../../utils/datasets";
import { dataSetsNeededColectiveRegistration } from "../../../constants/componentsDatasets";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import colectiveRegistrationMap from "../../../mappers/ColectiveRegistrationMap";
import registerAction from "redux/actions/registerAction";
// @material-ui/core components
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import LocationHeader from "components/Headers/LocationHeader";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";

// core components
import componentStylesRegister from "assets/theme/views/auth/register.js";
import componentStylesGeneric from "assets/theme/views/admin/generic";

//custom components
import GenericButton from "components/Buttons/GenericButton";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import { titles, labels } from "resources/resources";
import { USER_TYPE } from "utils/const";
//Steps
import UserData from "./UserData";
import Password from "./Password";
import CompanyData from "./CompanyData";

const useStylesRegister = makeStyles(componentStylesRegister);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function MainForm(props) {
  FetchDatasetsOnMount(props.getDataSetByName, props.datasetsState, dataSetsNeededColectiveRegistration);
  const classes = { ...useStylesGeneric(), ...useStylesRegister() };
  const theme = useTheme();
  const intl = useIntl();

  const [activeStep, setActiveStep] = useState(0);
  const [goNext, setGoNext] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (goNext) {
      if (activeStep === steps.length - 1) {
        props.registerAction(colectiveRegistrationMap(data), USER_TYPE.COLLECTIVE);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  function getSteps() {
    return [
      intl.formatMessage(labels.Label_Auth_ColectiveRegistrationSteps_Step_UserData),
      intl.formatMessage(labels.Label_Auth_ColectiveRegistrationSteps_Step_CompanyData),
      intl.formatMessage(labels.Label_Auth_ColectiveRegistrationSteps_Step_Password),
    ];
  }

  const steps = getSteps();

  const handlePrevious = () => {
    trigger();
    setGoNext(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <UserData
            control={control}
            getValues={getValues}
            errors={errors}
            classes={classes}
            theme={theme}
            trigger={trigger}
            intl={intl}
          />
        );
      case 1:
        return (
          <CompanyData
            control={control}
            getValues={getValues}
            errors={errors}
            classes={classes}
            setValue={setValue}
            theme={theme}
            intl={intl}
          />
        );
      case 2:
      default:
        return (
          <Password
            control={control}
            getValues={getValues}
            classes={classes}
            theme={theme}
            errors={errors}
            trigger={trigger}
            intl={intl}
          />
        );
    }
  }
  return (
    <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
      <LocationHeader
        section={intl.formatMessage(labels.Label_BreadCrumb_Colective_Registration)}
        subsection={intl.formatMessage(labels.Label_BreadCrumb_Registration)}
        subsectionLink={"/auth/register"}
      />

      <PageTitleHeader title={intl.formatMessage(titles.Title_Auth_Register_Collective_User)} />

      <Box component={Grid} container justifyContent="center">
        <Grid item xs={12} lg={12} md={12}>
          <Container maxWidth={false}>
            <Stepper className={classes.stepperRoot} activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <Card classes={{ root: classes.cardRoot }}>
                <CardHeader title={steps[activeStep]} classes={{ title: classes.cardHeader }}></CardHeader>
                <CardContent classes={{ root: classes.cardContent }}>{getStepsContent(activeStep)}</CardContent>
              </Card>
              <Grid container>
                <Grid item xs={6}>
                  <Box textAlign="left" marginTop="1.5rem" marginBottom="1.5rem">
                    {activeStep > 0 && (
                      <GenericButton typeSubmit={true} color="secondary" onClick={handlePrevious}>
                        <Box
                          component={NavigateBefore}
                          position="relative"
                          width="1.25rem!important"
                          height="1.25rem!important"
                          marginRight=".25rem!important"
                        />
                        {intl.formatMessage(labels.Label_Button_Previous)}
                      </GenericButton>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="right" marginTop="1.5rem" marginBottom="1.5rem">
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      loading={props.authState.register_loading}
                      onClick={() => setGoNext(true)}
                    >
                      {activeStep === steps.length - 1
                        ? intl.formatMessage(labels.Label_Button_Register)
                        : intl.formatMessage(labels.Label_Button_Next)}
                      <Box
                        component={NavigateNext}
                        position="relative"
                        width="1.25rem!important"
                        height="1.25rem!important"
                        marginRight=".25rem!important"
                      />
                    </GenericButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Grid>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  registerAction: (user, userType) => dispatch(registerAction(user, userType)),
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainForm);
