import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getDataSetByNameAction, getGeographicDataByProvinceAction } from "redux/actions/datasetsAction";
import { useIntl } from "react-intl";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { labels } from "resources/resources";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import { getApplicationDocumentAction } from "redux/actions/applicationsAction";
import ProgramTable from "../../Components/ProgramTable";
import { COMPLEMENTARY_DATA_ANSWER_TYPE, UNITS, BENEFIT, FORM_FIELDS_PREFIX } from "utils/const";
import componentStyles from "assets/theme/views/admin/applications.js";
const useStyles = makeStyles(componentStyles);

function Confirmation(props) {
  const classes = { ...useStyles() };
  const intl = useIntl();
  const [filesState, setFiles] = useState([]);

  useEffect(() => {
    if (props.applicationData?.documents !== undefined && props.applicationData?.documents?.length > 0)
      setFiles(props.applicationData?.documents);
    else {
      const docs = [];
      props.pageState?.docFields?.forEach((d) => {
        docs.push({
          name: props.getValues(d.id)[0]?.name,
          type: d.name,
        });
      });
      setFiles(docs ? docs : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const files = filesState.map((file, i) => (
    <Grid item xs={12} md={4} className={classes.paddingRight0px} key={i}>
      <FormGroup>
        <FormLabel className={classes.boxNameCategory}>{file.type}</FormLabel>
        <Box component="span" display="flex" className={classes.documentBadge}>
          <Box className={classes.documentNameRoot}>{file.name}</Box>
        </Box>
      </FormGroup>
    </Grid>
  ));

  const getDocumentFieldSize = (numDocs) => {
    if (12 % numDocs === 0) return 12 / numDocs;
    return 4;
  };

  const getSelectedGovernmentProgram = () => {
    return props?.governmentProgramsState?.getGovernmentPrograms_result?.find((p) => p.id === props.applicationsState.dataForApplicationCreation.governmentProgramId)?.value;
  };

  const getSelectedProgram = () => {
    return props.getValues(props.pageState.programsFieldName)?.label;
  };

  const getSelectedSubProgram = () => {
    return props.getValues(props.pageState.subprogramsFieldName)?.label;
  };

  const getCardHeaderTitle = () => {
    return (
      "Confirmação dos dados da candidatura: " +
      props.getValues(props.pageState.programsFieldName)?.label +
      " / " +
      props.getValues(props.pageState.subprogramsFieldName)?.label
    );
  };

  const getValuesToShow = (field) => {
    const value = props.getValues(field.id);
    switch (field.answerTypeId) {
      case COMPLEMENTARY_DATA_ANSWER_TYPE.TEXT:
        return value;
      case COMPLEMENTARY_DATA_ANSWER_TYPE.DATASET:
      case COMPLEMENTARY_DATA_ANSWER_TYPE.BOOLEAN:
        return value ? value.label : "";
      default:
        return;
    }
  };

  return (
    <Card classes={{ root: props.classes.cardRoot }}>
      <CardHeader
        classes={{ title: props.classes.cardHeader }}
        title={getCardHeaderTitle()}
      ></CardHeader>
      <CardContent classes={{ root: props.classes.cardContent }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormLabel>{intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_Province)}</FormLabel>
              <Box className={props.classes.confirmationFieldsBox}>
                {props.getValues(props.pageState.provinceFieldName)?.label}
              </Box>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormLabel>
                {intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_ProgramSubprogram)}
              </FormLabel>
              <ProgramTable
                governmentProgram={getSelectedGovernmentProgram()}
                program={getSelectedProgram()}
                subprogram={getSelectedSubProgram()}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormLabel>
                {intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_ParentApplication)}
              </FormLabel>
              <Box className={props.classes.confirmationFieldsBox}>
                {props.getValues(props.pageState.parentApplciationFieldName)?.label ?? "N/A"}
              </Box>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormLabel>Tipo de benefício</FormLabel>
              <Box className={props.classes.confirmationFieldsBox}>{props.pageState.info.benefitName}</Box>
            </FormGroup>
          </Grid>
          {(props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT ||
            props.pageState.info.benefitKeyValue === BENEFIT.MICROCREDIT ||
            props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT) && (
              <>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_GeneratedWorkPosts)}
                    </FormLabel>
                    <Box className={props.classes.confirmationFieldsBox}>
                      {props.getValues(props.pageState.generatedWorkPostsName)}
                    </Box>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Banco</FormLabel>
                    <Box className={props.classes.confirmationFieldsBox}>
                      {props.getValues(props.pageState.bankPreferenceFieldName)?.label}
                    </Box>
                  </FormGroup>
                </Grid>
              </>
            )}
          {(props.pageState.info.benefitKeyValue === BENEFIT.MICROCREDIT ||
            props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>Valor pretendido</FormLabel>
                  <Box className={props.classes.confirmationFieldsBox}>
                    {(props.getValues(props.pageState.microcreditBenefitTypeName)
                      ? props.getValues(props.pageState.microcreditBenefitTypeName)
                      : 0) +
                      " " +
                      UNITS.MONETARY}
                  </Box>
                </FormGroup>
              </Grid>
            )}
          {(props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
            props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>Número de kits</FormLabel>
                  <Box className={props.classes.confirmationFieldsBox}>
                    {props.getValues(props.pageState.professionalKitBenefitTypeName)}
                  </Box>
                </FormGroup>
              </Grid>
            )}
          {props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP && (
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Área onde pretende fazer o estágio</FormLabel>
                <Box className={props.classes.confirmationFieldsBox}>
                  {props.getValues(props.pageState.internshipFieldName)?.label}
                </Box>
              </FormGroup>
            </Grid>
          )}
          {(props.pageState.info.benefitKeyValue === BENEFIT.AVANCO ||
            props.pageState.info.benefitKeyValue === BENEFIT.CAPACITA ||
            props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET) && (
              <>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>
                      {props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET
                        ? "Escolha da carteira pretendida"
                        : "Curso pretendido"}
                    </FormLabel>
                    <Box className={props.classes.confirmationFieldsBox}>
                      {props.getValues(props.pageState.shapeProgramsFieldName)?.label}
                    </Box>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>Centro de formação</FormLabel>
                    <Box className={props.classes.confirmationFieldsBox}>
                      {props.getValues(props.pageState.shapeSubsiteFieldName)?.label}
                    </Box>
                  </FormGroup>
                </Grid>
              </>
            )}
        </Grid>
        {props.pageState?.complementaryData.length > 0 && (
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <Box className={props.classes.cardHeader}>Dados Complementares</Box>
              </FormGroup>
            </Grid>
            {props.pageState.complementaryData.map(
              (cd, key) =>
                cd.show && (
                  <Grid item xs={12} md={4} key={key}>
                    <FormGroup>
                      <FormLabel>{cd.question}</FormLabel>
                      <Box className={props.classes.confirmationFieldsBox}>{getValuesToShow(cd)}</Box>
                    </FormGroup>
                  </Grid>
                )
            )}
          </Grid>
        )}
        {props.pageState.info.subProgramDocument?.length > 0 && (
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <Box className={props.classes.cardHeader}>Documentos submetidos</Box>
              </FormGroup>
            </Grid>
            {props.isEditPage && (
              <>
                {props.applicationData?.documents.map((file, i) => (
                  <Grid
                    item
                    xs={12}
                    md={getDocumentFieldSize(props.pageState.info.subProgramDocument.length)}
                    className={classes.paddingRight0px}
                    key={i}
                  >
                    <FormGroup>
                      <FormLabel className={classes.boxNameCategory}>{file.documentTypeName}</FormLabel>
                      {!props.getValues(FORM_FIELDS_PREFIX.DOCUMENT + file.documentTypeId) ||
                        !props.getValues(FORM_FIELDS_PREFIX.DOCUMENT + file.documentTypeId)[0]?.name ? (
                        <SubmitedDocumentsSection files={[file]} getDocument={props.getApplicationDocument} />
                      ) : (
                        <Box component="span" display="flex" className={classes.documentBadge}>
                          <Box className={classes.documentNameRoot}>
                            {props.getValues(FORM_FIELDS_PREFIX.DOCUMENT + file.documentTypeId)[0]?.name}
                          </Box>
                        </Box>
                      )}
                    </FormGroup>
                  </Grid>
                ))}
              </>
            )}
            {!props.isEditPage && (
              <Grid container className={classes.docsContainer}>
                {files}
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  getGeographicDataByProvince: (name, province) => dispatch(getGeographicDataByProvinceAction(name, province)),
  getApplicationDocument: (docId, docName) => dispatch(getApplicationDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
