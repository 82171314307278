import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import CandidateGeneralDetails from "./Sections/CandidateGeneralDetails";
import CandidateProfessionalDetails from "./Sections/CandidateProfessionalDetails";
import CandidateAnswers from "./Sections/CandidateAnswers";
import ApproveCandidateDialog from "./Dialogs/ApproveCandidateDialog";
import RejectCandidateDialog from "./Dialogs/RejectCandidateDialog";
import CandidateRejectedMotive from "./Sections/CandidateRejectedMotive";
import { OPPORTUNITY_TYPE_KEY } from "utils/const";
import _ from "lodash";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import "react-quill/dist/quill.snow.css";
//Header
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import { getCandidateInformationAction } from "redux/actions/opportunitiesAction";

import { labels } from "resources/resources";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidateDetails(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const [candidateInfo, setCandidateInfo] = useState();
  const [candidateId, setCandidateId] = useState();

  const methods = useForm({
    mode: "onChange",
  });

  //Dialogs Logic
  const DIALOG = { SHOW: true, HIDE: false };
  const [dialogs, setDialogs] = useState({
    approveModal: false,
    rejectModal: false,
    updateModal: false,
  });

  const showHideDialog = (dialog, op) => {
    let dialogs_temp = _.mapValues(dialogs, () => false);

    dialogs_temp[dialog] = op;

    setDialogs(dialogs_temp);
  };

  useEffect(() => {
    console.log(props.match.params);

    props.getCandidateInformation(props.match.params.alternateKey);
    if (props.opportunitiesState.candidateInfo !== null) {
      setCandidateInfo(props.opportunitiesState.candidateInfo);
      setCandidateId(props.match.params.alternateKey);
    }
    if (props.opportunitiesState.candidateInfo == null) {
      setCandidateInfo([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.alternateKey]);

  const getTabTitle = () => {
    if (props.opportunitiesState?.candidateInfo?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO)
      return "Candidato a oferta de Emprego";
    if (props.opportunitiesState?.candidateInfo?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL)
      return "Candidato a Estágio Profissional";
    return "";
  };

  function handleApproveModal() {
    showHideDialog("approveModal", DIALOG.SHOW);
  }
  
  function handleRejectModal() {
    showHideDialog("rejectModal", DIALOG.SHOW);
  }

  const onSubmit = () => {};

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <GenericBackdrop open={props.opportunitiesState.candidateInfo_loading} />

        <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
          {props.opportunitiesState.isOpportunityApplication ? (
            <LocationHeader
              section={"Detalhes do Candidato"}
              subsection={"Lista de Candidaturas"}
              subsectionLink={"/admin/user-colective-opportunity-applications"}
            />
          ) : (
            <LocationHeader
              section={"Detalhes do Candidato"}
              subsection={"Emprego e Estágio Profissional"}
              subsectionLink={"/admin/opportunities"}
            />
          )}

          <PageTitleHeader title={getTabTitle()} />

          <CandidateGeneralDetails candidateInfo={candidateInfo} />
          <CandidateProfessionalDetails candidateInfo={candidateInfo} />

          {props.opportunitiesState?.candidateInfo?.opportunityCandidateAnswers?.length > 0 && (
            <CandidateAnswers candidateInfo={candidateInfo} />
          )}

          {props.opportunitiesState?.candidateInfo?.opportunityCandidateStateId === 3 && (
            <CandidateRejectedMotive candidateInfo={candidateInfo} />
          )}

          {props.opportunitiesState?.candidateInfo?.opportunityCandidateStateId === 1 && (
            <Grid container classes={{ root: classes.containerButtons }}>
              <Grid item xs={12} sm={1} md={6}></Grid>
              <Grid item xs={12} sm={5} md={3}>
                <FormGroup classes={{ root: classes.cancelButtonFormGroup }}>
                  <GenericButton 
                    typeSubmit={false}
                    color="secondary"
                    onClick={() => handleRejectModal()}
                  >
                    {intl.formatMessage(labels.Label_Reject_Submit)}
                  </GenericButton>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup classes={{ root: classes.saveButtonFormGroup }}>
                  <GenericButton 
                    typeSubmit={true}
                    color="primary"
                    onClick={() => handleApproveModal()}
                    loading={props.opportunitiesState.opportunitySubmitLoading}
                  >
                    {intl.formatMessage(labels.Label_Recruit_Submit)}
                  </GenericButton>
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </Container>

        <ApproveCandidateDialog
          name="approve-dialog"
          maxWidth={"xl"}
          open={dialogs.approveModal}
          candidateId={candidateId}
          opportunityId={props.opportunitiesState?.candidateInfo?.opportunityId}
          overlappingInternship={props.opportunitiesState?.candidateInfo?.papeInternshipOverlapsOthersOfUser}
          history={history}
          onClose={() => showHideDialog("approveModal", DIALOG.HIDE)}
        />

        <RejectCandidateDialog
          name="reject-dialog"
          maxWidth={"xl"}
          open={dialogs.rejectModal}
          candidateId={candidateId}
          opportunityId={props.opportunitiesState?.candidateInfo?.opportunityId}
          history={history}
          onClose={() => showHideDialog("rejectModal", DIALOG.HIDE)}
        />
      </form>
    </FormProvider>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getCandidateInformation: (userId) => dispatch(getCandidateInformationAction(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetails);
