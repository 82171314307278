const componentStyles = (theme) => ({
    header: {
      background: "linear-gradient(90deg, " + theme.palette.primary.greyBlue + " 0%, " + theme.palette.primary.mediumBlue + " 100%)",
      paddingTop: "22rem",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "25rem",
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: "40rem",
      },
    },
  
    loginHeader: {
      position: "relative",
      borderRadius: "0px",
      // fontSize:"40px",
      // fontWeight:"600",
      
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "40px",
      lineHeight: "47px",
      alignItems: "center",
      textAlign: "center",
      color: theme.palette.white.main,
    },
  });
  
  export default componentStyles;
  