import { useState, useEffect } from "react";

export const usePasswordValidation = ({
  firstPassword = "",
  secondPassword = "",
  requiredLength = 10,
}) => {
  const [passwordValidation, setPasswordValidation] = useState({
    validLength: false,
    hasNumber: false,
    upperCase: false,
    lowerCase: false,
    specialChar: false,
    match: false,
    isValid: null,
  });

  useEffect(() => {
    let states = {
      validLength: firstPassword.length >= requiredLength ? true : false,
      upperCase: firstPassword.toLowerCase() !== firstPassword,
      lowerCase: firstPassword.toUpperCase() !== firstPassword,
      hasNumber: /\d/.test(firstPassword),
      specialChar: /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(firstPassword),
      match: firstPassword && firstPassword === secondPassword,
      isValid: null,
    };
    var count = 0;
    if (states.upperCase) count++;
    if (states.lowerCase) count++;
    if (states.hasNumber) count++;
    if (states.specialChar) count++;
    if (!(count === 0 && states.isValid == null))
      //Não validar no Onload
      states.isValid = count >= 4 && states.validLength;

    setPasswordValidation(states);
  }, [firstPassword, secondPassword, requiredLength]);

  return [passwordValidation];
};
