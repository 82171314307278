import axios from "axios";
import { EXTERNAL_API_URL } from "../index";
export const GET_DATASET_BY_NAME_URL = "DataSets/GetDataSet";
export const GET_DATASET_BY_INDEX_URL = "dataSets/getdatasetbyindex";
export const GET_CHILDREN_BY_PROPERTYID_URL = "datasets/getchildrenbypropertyid";
export const GET_NATIONALITIES_URL = "Nationalities";
export const GET_GEOGRAPHIC_DATA_URL = "GeographicData/GetProvinces";
export const GET_GEOGRAPHIC_DATA_BY_PROVINCE_URL = "GeographicData/GetMunicipalities?provinceId=";
export const GET_ALL_PROGRAMS_URL = "Programs/get-all-programs";
export const GET_SUBPROGRAMS_BY_PROGRAM_URL = "SubPrograms/get-current-subprograms";
// export const GET_SHAPE_PROGRAMS_URL = "External/Training/TrainingOffers/Filter"
// export const GET_SHAPE_SUBSITES_URL = "External/Training/SubSites/Filter"
export const GET_SHAPE_PROGRAMS_URL = "ExternalServices/get-shape-programs";
export const GET_SHAPE_SUBSITES_URL = "ExternalServices/get-shape-subsites";
export const GET_USER_APPLICATION_AWAITING_TRAINING_URL = "Applications/get-awating-training-applications";

export function getShapePrograms(target, province) {
  return axios.get(EXTERNAL_API_URL + GET_SHAPE_PROGRAMS_URL, {
    params: {
      SearchTarget: target,
      IdProvincia: province,
    },
  });
}

export function getShapeSubsites(offer, province) {
  return axios.get(EXTERNAL_API_URL + GET_SHAPE_SUBSITES_URL, {
    params: {
      OfferId: offer,
      ProvinceId: province,
    },
  });
}

export function getGeographicData() {
  return axios.get(EXTERNAL_API_URL + GET_GEOGRAPHIC_DATA_URL);
}

export function getGeographicDataByProvince(province) {
  return axios.get(EXTERNAL_API_URL + GET_GEOGRAPHIC_DATA_BY_PROVINCE_URL + province);
}

export function getNationalities() {
  return axios.get(EXTERNAL_API_URL + GET_NATIONALITIES_URL);
}

export function getDataSetByName(name, parentKeyValue) {
  return axios.get(EXTERNAL_API_URL + GET_DATASET_BY_NAME_URL, {
    params: {
      name: name,
      parentKeyValue: parentKeyValue,
    },
  });
}
export function getDataSetByIndex(id) {
  return axios.get(EXTERNAL_API_URL + GET_DATASET_BY_INDEX_URL, {
    params: {
      id: id,
    },
  });
}

export function getChildrenByPropertyId(id) {
  return axios.get(EXTERNAL_API_URL + GET_CHILDREN_BY_PROPERTYID_URL, {
    params: {
      id: id,
    },
  });
}
export function getAllPrograms(governmentProgramId) {
  return axios.get(EXTERNAL_API_URL + GET_ALL_PROGRAMS_URL, {
    params: {
      governmentProgramId: governmentProgramId,
    },
  });
}
export function getSubprogramByProgramId(programId, onlyActive) {
  return axios.get(EXTERNAL_API_URL + GET_SUBPROGRAMS_BY_PROGRAM_URL, {
    params: {
      programId: programId,
      onlyActive: onlyActive
    },
  });
}

export function getUserApplicationsAwaitingTraining() {
  return axios.get(EXTERNAL_API_URL + GET_USER_APPLICATION_AWAITING_TRAINING_URL);
}
