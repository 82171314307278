import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { updateApplicationDetailGeneralDataAction } from "redux/actions/applicationsAction";
import { updateGeneralDataMapCards } from "mappers/ApplicationMap";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { APPLICATION_STATES_KEY } from "utils/const.js";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CancelApplicationModal(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const [filter] = useState({
    userId: "",
    subprogramId: undefined,
    ApplicationState: undefined,
    ApplicationStateGroup: props.inProgress ? ["1", "2", "3"] : [],
    pageIndex: 1,
    pageSize: 15,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const cancelApplication = (data) => {
    let auxData = {
      applicationNumber: props.applicationNumber,
      applicationStateId: APPLICATION_STATES_KEY.CANCELADA,
      cancelingMotiveObservations: data.motive,
    };
    props.updateApplicationDetails(updateGeneralDataMapCards(auxData), filter);
    props.onClose();
  };

  return (
    <form onSubmit={handleSubmit(cancelApplication)}>
      <Box>
        <HelpOutlineIcon className={classes.modalHelpIcon} />
        <Box className={classes.modalHeader}>Pretende continuar?</Box>
        <Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box className={classes.modalSubHeader}>
                Ao cancelar a candidatura esta ficará concluída e sem efeito. Poderá encontrá-la na àrea 'Minhas
                candidaturas'.
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <br></br>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel className={classes.modalTitles}>Motivo de cancelamento</FormLabel>
                <Controller
                  name="motive"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "O campo é obrigatório",
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      fullWidth
                      autoComplete="off"
                      // type="text"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: errors[name] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {errors["motive"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {errors["motive"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
        <Box textAlign="center">
          <GenericButton 
            typeSubmit={true}
            color="primary"
          >
            Submeter
          </GenericButton>
        </Box>
      </Box>
    </form>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateApplicationDetails: (data, filter) => dispatch(updateApplicationDetailGeneralDataAction(data, filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelApplicationModal);
