import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import CardContent from "@material-ui/core/CardContent";
import ListItem from "@material-ui/core/ListItem";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

// @material-ui/icons components
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

// core components
import { errorMessages, labels, messages, titles } from "resources/resources";
import { INFORMATION_DISSEMINATION_TYPE_KEYVALUE } from "utils/const";
import { AUTH } from "utils/actionsConsts";
import { loginAction, setTargetPathAfterLoginAction } from "redux/actions/auth/loginAction";
import { recoverPasswordAction, hideRecoverPasswordModalAction } from "redux/actions/auth/recoverPasswordAction";
import { showAuthenticationModalAction } from "redux/actions/auth/authenticationAction";
import { hideRegistrationModalAction } from "redux/actions/registerAction";
import AuthenticationModal from "components/Modals/Auth/AuthenticationModal";
import AuthNavbarMenuDropdown from "components/Dropdowns/AuthNavbarMenuDropdown.js";
import ResetPassword from "components/Modals/Auth/ResetPassword";
import GenericDialog from "components/Dialogs/GenericDialog";
import RegisterDialog from "views/auth/Dialogs/RegisterDialog";
import LoginModal from "components/Modals/Auth/LoginModal";
import RecoverModal from "components/Modals/Auth/RecoverModal";
import { useOnHoverOutside } from "hooks/useOnHoverOutside";
import SearchBarNavbar from "components/Navbars/Sections/SearchbarNavbar";
import GenericButton from "components/Buttons/GenericButton";
import { USER_TYPE } from "utils/const";

import componentStyles from "assets/theme/components/navbars/auth-navbar.js";

const useStyles = makeStyles(componentStyles);

function AuthNavbar(props) {
  const classes = useStyles();
  const intl = useIntl();

  const [loginModal, setStateModal] = React.useState(false);
  const [recoverModal, setRecoverModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openInfoDissemination, setOpenInfoDissemination] = useState(false);
  const [openInstitutionalInformation, setOpenInstitutionalInformation] = useState(false);
  const [openInformation, setOpenInformation] = useState(false);
  const anchorRefInformation = useRef(null);
  const [openHelp, setOpenHelp] = useState(false);
  const [openCompanyServices, setOpenCompanyServices] = useState(false);
  const [openCitizenServices, setOpenCitizenServices] = useState(false);

  const anchorRefHelp = useRef(null);

  const dropdownRefEmploymentServices = useRef(null);
  const [isMenuDropDownOpenEmploymentServices, setMenuDropDownOpenEmploymentServices] = useState(false);
  const dropdownRefEntrepreneurshipServices = useRef(null);
  const [isMenuDropDownOpenEntrepreneurshipServices, setMenuDropDownOpenEntrepreneurshipServices] = useState(false);
  const dropdownRefProfessionalTraining = useRef(null);
  const [isMenuDropDownOpenProfessionalTraining, setMenuDropDownOpenProfessionalTraining] = useState(false);

  const [state, setState] = React.useState({
    email: "",
    password: "",
    recoverPasswordPhone: "",
  });

  const onChange = (stateName, value) => {
    setState((prevState) => ({
      ...prevState,
      [stateName]: value,
    }));
  };

  const handleRegistrationResultClose = () => {
    props.hideRegistrationModal();
  };
  const handleRecoverPasswordResultClose = () => {
    props.hideRecoverPasswordModal();
  };
  const getShowRegistrationResultModal = () => {
    return props.authState.showRegistrationResultModal !== undefined
      ? props.authState.showRegistrationResultModal
      : false;
  };
  const getShowRecoverPasswordResultModal = () => {
    return props.authState.showRecoverPasswordResultModal !== undefined
      ? props.authState.showRecoverPasswordResultModal
      : false;
  };

  const handleLoginModal = () => {
    props.resetLoginForm();
    setRecoverModal(false);
    setStateModal(true);
  };

  const handlerecoverModal = () => {
    setStateModal(false);
    setRecoverModal(true);
  };

  const handleClose = () => {
    setStateModal(false);
  };

  const handleCloseRecover = () => {
    setRecoverModal(false);
  };

  const anchorRefInfoDissemination = useRef(null);

  const handleToggleInfoDissemination = () => {
    setOpenInfoDissemination((prevOpenInfoDissemination) => !prevOpenInfoDissemination);
  };

  const handleCloseInfoDissemination = (event) => {
    if (anchorRefInfoDissemination.current && anchorRefInfoDissemination.current.contains(event.target)) {
      return;
    }
    setOpenInfoDissemination(false);
  };

  const anchorRefInstitutionalInformation = useRef(null);

  const handleCloseInstitutionalInformation = (event) => {
    if (anchorRefInstitutionalInformation.current && anchorRefInstitutionalInformation.current.contains(event.target)) {
      return;
    }
    setOpenInstitutionalInformation(false);
  };

  const handleToggleInstitutionalInformation = () => {
    setOpenInstitutionalInformation((prevOpenInstitutionalInformation) => !prevOpenInstitutionalInformation);
  };

  const handleCloseInformation = (event) => {
    if (anchorRefInformation.current && anchorRefInformation.current.contains(event.target)) {
      return;
    }
    setOpenInformation(false);
  };

  const handleToggleInformation = () => {
    setOpenInformation((prevOpenInformation) => !prevOpenInformation);
  };

  const handleCloseHelp = (event) => {
    if (anchorRefHelp.current && anchorRefHelp.current.contains(event.target)) {
      return;
    }
    setOpenHelp(false);
  };

  const handleToggleHelp = () => {
    setOpenHelp((prevOpenHelp) => !prevOpenHelp);
  };

  const anchorRefCompanyServices = useRef(null);
  const anchorRefCitizenServices = useRef(null);
  const handleCloseCompanyServices = (event) => {
    if (anchorRefCompanyServices.current && anchorRefCompanyServices.current.contains(event.target)) {
      return;
    }
    setOpenCompanyServices(false);
  };
  const handleToggleCompanyServices = () => {
    setOpenCompanyServices((prevOpenCompanyServices) => !prevOpenCompanyServices);
  };

  const handleCloseCitizenServices = (event) => {
    if (anchorRefCitizenServices.current && anchorRefCitizenServices.current.contains(event.target)) {
      return;
    }
    setOpenCitizenServices(false);
  };

  const handleToggleCitizenServices = () => {
    setOpenCitizenServices((prevOpenCitizenServices) => !prevOpenCitizenServices);
  };

  const closeHoverMenuEmploymentServices = () => {
    setMenuDropDownOpenEmploymentServices(false);
  };

  useOnHoverOutside(dropdownRefEmploymentServices, closeHoverMenuEmploymentServices);

  const closeHoverMenuEntrepreneurshipServices = () => {
    setMenuDropDownOpenEntrepreneurshipServices(false);
  };

  useOnHoverOutside(dropdownRefEntrepreneurshipServices, closeHoverMenuEntrepreneurshipServices);

  const closeHoverMenuProfessionalTraining = () => {
    setMenuDropDownOpenProfessionalTraining(false);
  };

  useOnHoverOutside(dropdownRefProfessionalTraining, closeHoverMenuProfessionalTraining);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <AppBar elevation={0} classes={{ root: classes.appBarRoot }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "1rem",
          margin: "0 24px",
        }}
        className={classes.containerRoot}
      >
        <Link to="/auth/home">
          <Box
            component="img"
            alt="..."
            className={classes.logo}
            src={require("assets/img/brand/INEFOP_azul.png").default}
          />
        </Link>
        <GenericButton typeSubmit={false} onClick={handleLoginModal} color="orange">
          {intl.formatMessage(labels.Label_General_Enter_Button)}
        </GenericButton>
      </Box>
      <Toolbar disableGutters>
        <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom="1rem">
            {/* Navbar Menu for lower resolutions */}
            <Hidden mdUp implementation="css">
              <Box display="flex" justifyContent="center" alignItems="center">
                <AuthNavbarMenuDropdown />
              </Box>
            </Hidden>

            {/* The following items use the Hidden component to not be displayed on lower resolutions,
            instead the AuthNavbarMenuDropdown will display all the items. */}

            {/* Institucional */}
            <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  classes={{ root: classes.listItemRoot }}
                  ref={anchorRefInstitutionalInformation}
                  aria-controls={openInstitutionalInformation ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleInstitutionalInformation}
                >
                  {intl.formatMessage(labels.Label_Menu_INEFOP)}
                  <ListItemIcon className={classes.dropIcon}>
                    <ExpandMoreIcon />
                  </ListItemIcon>
                  <Popper
                    open={openInstitutionalInformation}
                    anchorEl={anchorRefInstitutionalInformation.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseInstitutionalInformation}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem
                                component={Link}
                                to={`/auth/institution-overview`}
                                className={classes.dropItems}
                                onClick={handleCloseInstitutionalInformation}
                              >
                                {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOverview)}
                              </MenuItem>

                              <MenuItem
                                component={Link}
                                to={`/auth/institution-history`}
                                className={classes.dropItems}
                                onClick={handleCloseInstitutionalInformation}
                              >
                                {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionHistory)}
                              </MenuItem>

                              <MenuItem
                                component={Link}
                                to={`/auth/institution-mission-vision-values`}
                                className={classes.dropItems}
                                onClick={handleCloseInstitutionalInformation}
                              >
                                {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionMissionVisionAndValues)}
                              </MenuItem>

                              <MenuItem
                                component={Link}
                                to={`/auth/institution-organic-status`}
                                className={classes.dropItems}
                                onClick={handleCloseInstitutionalInformation}
                              >
                                {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOrganicStatus)}
                              </MenuItem>

                              <MenuItem
                                component={Link}
                                to={`/auth/institution-organization-chart`}
                                className={classes.dropItems}
                                onClick={handleCloseInstitutionalInformation}
                              >
                                {intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOrganizationChart)}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to={`/auth/director-biography`}
                                className={classes.dropItems}
                                onClick={handleCloseInstitutionalInformation}
                              >
                                {intl.formatMessage(labels.Label_Menu_INEFOP_DirectorBiography)}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to={`/auth/institute-holders`}
                                className={classes.dropItems}
                                onClick={handleCloseInstitutionalInformation}
                              >
                                {intl.formatMessage(labels.Label_Menu_INEFOP_InstituteHolders)}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </ListItem>
              </Box>
            </Hidden>

            {/* Noticias */}
            <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  to={`/auth/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.NEWS}`}
                  component={Link}
                  classes={{
                    root: classes.listItemRoot,
                  }}
                >
                  {intl.formatMessage(labels.Label_Menu_Posts_News)}
                </ListItem>
              </Box>
            </Hidden>

            {/* Publicações */}
            <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  classes={{ root: classes.listItemRoot }}
                  ref={anchorRefInfoDissemination}
                  aria-controls={openInfoDissemination ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleInfoDissemination}
                >
                  {intl.formatMessage(labels.Label_Menu_Posts)}
                  <ListItemIcon className={classes.dropIcon}>
                    <ExpandMoreIcon />
                  </ListItemIcon>
                  <Popper
                    open={openInfoDissemination}
                    anchorEl={anchorRefInfoDissemination.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseInfoDissemination}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem
                                component={Link}
                                to={`/auth/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.LEGISLATION}`}
                                className={classes.dropItems}
                                onClick={handleCloseInfoDissemination}
                              >
                                {intl.formatMessage(labels.Label_Menu_Posts_Legislations)}
                              </MenuItem>

                              <MenuItem
                                component={Link}
                                to={`/auth/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.SCHEDULE}`}
                                className={classes.dropItems}
                                onClick={handleCloseInfoDissemination}
                              >
                                {intl.formatMessage(labels.Label_Menu_Posts_Schedule)}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </ListItem>
              </Box>
            </Hidden>

            {/* Citizen Services */}
            <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  classes={{ root: classes.listItemRoot }}
                  ref={anchorRefCitizenServices}
                  aria-controls={openCitizenServices ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleCitizenServices}
                >
                  {intl.formatMessage(labels.Label_Menu_Information_CitizenServices)}
                  <ListItemIcon className={classes.dropIcon}>
                    <ExpandMoreIcon />
                  </ListItemIcon>
                  <Popper
                    open={openCitizenServices}
                    anchorEl={anchorRefCitizenServices.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseCitizenServices}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem
                                component={Link}
                                to={"#"}
                                className={classes.dropItems}
                                onClick={(event) => {
                                  handleLoginModal();
                                  props.setTargetPathAfterLogin("/admin/available-opportunities", USER_TYPE.INDIVIDUAL);
                                  handleCloseCitizenServices(event);
                                }}
                              >
                                {intl.formatMessage(
                                  titles.Title_Menu_Information_CitizenServices_SeeEmploymentOpportunities
                                )}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to={"#"}
                                className={classes.dropItems}
                                onClick={(event) => {
                                  handleLoginModal();
                                  props.setTargetPathAfterLogin("/admin/formacao-redirect", USER_TYPE.INDIVIDUAL);
                                  handleCloseCitizenServices(event);
                                }}
                              >
                                {intl.formatMessage(
                                  titles.Title_Menu_Information_CitizenServices_SeeProfessionalTraining
                                )}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to={"#"}
                                className={classes.dropItems}
                                onClick={(event) => {
                                  window.open("http://procarteira.inefop.gov.ao/", "_blank", "noreferrer");
                                  handleCloseCitizenServices(event);
                                }}
                              >
                                {intl.formatMessage(
                                  titles.Title_Menu_Information_CitizenServices_SeeProfessionalWallet
                                )}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </ListItem>
              </Box>
            </Hidden>

            {/* Company Services */}
            <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  classes={{ root: classes.listItemRoot }}
                  ref={anchorRefCompanyServices}
                  aria-controls={openCompanyServices ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleCompanyServices}
                >
                  {intl.formatMessage(labels.Label_Menu_Information_CompanyServices)}
                  <ListItemIcon className={classes.dropIcon}>
                    <ExpandMoreIcon />
                  </ListItemIcon>
                  <Popper
                    open={openCompanyServices}
                    anchorEl={anchorRefCompanyServices.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseCompanyServices}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem
                                component={Link}
                                to={"#"}
                                className={classes.dropItems}
                                onClick={(event) => {
                                  handleLoginModal();
                                  props.setTargetPathAfterLogin("/admin/opportunities-create", USER_TYPE.COLLECTIVE);
                                  handleCloseCompanyServices(event);
                                }}
                              >
                                {intl.formatMessage(
                                  titles.Title_Menu_Information_CompanyServices_CreateEmploymentOrInternshipOpportunities
                                )}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to={`/auth/ratify-agreement`}
                                className={classes.dropItems}
                                onClick={handleCloseCompanyServices}
                              >
                                {intl.formatMessage(titles.Title_Menu_Information_CompanyServices_RatifyAgreement)}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                to={"/auth/information-training-centers-licensing"}
                                className={classes.dropItems}
                                onClick={handleCloseCompanyServices}
                              >
                                {intl.formatMessage(titles.Title_Menu_Information_CompanyServices_LicensingCenters)}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </ListItem>
              </Box>
            </Hidden>

            {/* Programas */}
            <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  to="/auth/structuringProjects"
                  component={Link}
                  classes={{
                    root: classes.listItemRoot,
                  }}
                >
                  {intl.formatMessage(labels.Label_Menu_StructuringProjects)}
                </ListItem>
              </Box>
            </Hidden>

            {/* Informação */}
            <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  classes={{
                    root: classes.listItemRoot,
                  }}
                  ref={anchorRefInformation}
                  aria-controls={openInformation ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleInformation}
                >
                  {intl.formatMessage(labels.Label_Menu_Information)}

                  <ListItemIcon className={classes.dropIcon}>
                    <ExpandMoreIcon />
                  </ListItemIcon>
                  <Popper
                    open={openInformation}
                    anchorEl={anchorRefInformation.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseInformation}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              {/* Submenu Group: Employment Services */}
                              <div ref={dropdownRefEmploymentServices}>
                                <MenuItem
                                  className={classes.dropItems}
                                  onMouseOver={() => setMenuDropDownOpenEmploymentServices(true)}
                                  onClick={handleCloseInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_Information_EmploymentServices)}
                                  {isMenuDropDownOpenEmploymentServices ? (
                                    <NavigateBeforeIcon className={classes.expandIcon} />
                                  ) : (
                                    <NavigateNextIcon className={classes.expandIcon} />
                                  )}
                                </MenuItem>

                                {isMenuDropDownOpenEmploymentServices && (
                                  <Paper className={classes.submenuGroup}>
                                    <ClickAwayListener onClickAway={handleCloseInformation}>
                                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem
                                          component={Link}
                                          to={`/auth/information-employment-centers`}
                                          className={classes.dropItems}
                                          onClick={handleCloseInformation}
                                        >
                                          {intl.formatMessage(labels.Label_Menu_Information_EmploymentCenters)}
                                        </MenuItem>
                                        <MenuItem
                                          component={Link}
                                          to={`/auth/information-employment-centers-network`}
                                          className={classes.dropItems}
                                          onClick={handleCloseInformation}
                                        >
                                          {intl.formatMessage(labels.Label_Menu_Information_EmploymentCentersNetwork)}
                                        </MenuItem>
                                        <MenuItem
                                          component={Link}
                                          to={`/auth/information-foreign-workers-contract-registration`}
                                          className={classes.dropItems}
                                          onClick={handleCloseInformation}
                                        >
                                          {intl.formatMessage(
                                            labels.Label_Menu_Information_ForeignWorkersContractRegistration
                                          )}
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                )}
                              </div>

                              {/* Submenu Group: Entrepreneurship Services */}
                              <div ref={dropdownRefEntrepreneurshipServices}>
                                <MenuItem
                                  className={classes.dropItems}
                                  onMouseOver={() => setMenuDropDownOpenEntrepreneurshipServices(true)}
                                  onClick={handleCloseInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipServices)}
                                  {isMenuDropDownOpenEntrepreneurshipServices ? (
                                    <NavigateBeforeIcon className={classes.expandIcon} />
                                  ) : (
                                    <NavigateNextIcon className={classes.expandIcon} />
                                  )}
                                </MenuItem>

                                {isMenuDropDownOpenEntrepreneurshipServices && (
                                  <Paper className={classes.submenuGroup} style={{ top: "34px" }}>
                                    <ClickAwayListener onClickAway={handleCloseInformation}>
                                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem
                                          component={Link}
                                          to={`/auth/information-entrepreneurship-centers`}
                                          className={classes.dropItems}
                                          onClick={handleCloseInformation}
                                        >
                                          {intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipCenters)}
                                        </MenuItem>
                                        <MenuItem
                                          component={Link}
                                          to={`/auth/information-entrepreneurship-centers-network`}
                                          className={classes.dropItems}
                                          onClick={handleCloseInformation}
                                        >
                                          {intl.formatMessage(
                                            labels.Label_Menu_Information_EntrepreneurshipCentersNetwork
                                          )}
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                )}
                              </div>

                              {/* Submenu Group: Professional Training */}
                              <div ref={dropdownRefProfessionalTraining}>
                                <MenuItem
                                  className={classes.dropItems}
                                  onMouseOver={() => setMenuDropDownOpenProfessionalTraining(true)}
                                  onClick={handleCloseInformation}
                                >
                                  {intl.formatMessage(labels.Label_Menu_Information_ProfessionalTraining)}
                                  {isMenuDropDownOpenProfessionalTraining ? (
                                    <NavigateBeforeIcon className={classes.expandIcon} />
                                  ) : (
                                    <NavigateNextIcon className={classes.expandIcon} />
                                  )}
                                </MenuItem>

                                {isMenuDropDownOpenProfessionalTraining && (
                                  <Paper className={classes.submenuGroup} style={{ top: "68px" }}>
                                    <ClickAwayListener onClickAway={handleCloseInformation}>
                                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem
                                          component={Link}
                                          to={`/auth/information-professional-wallet`}
                                          className={classes.dropItems}
                                          onClick={handleCloseInformation}
                                        >
                                          {intl.formatMessage(labels.Label_Menu_Information_ProfessionalWallet)}
                                        </MenuItem>
                                        <MenuItem
                                          component={Link}
                                          to={`/auth/information-formation-offer`}
                                          className={classes.dropItems}
                                          onClick={handleCloseInformation}
                                        >
                                          {intl.formatMessage(labels.Label_Menu_Information_FormationOffer)}
                                        </MenuItem>
                                        <MenuItem
                                          component={Link}
                                          to={`/auth/information-training-centers-licensing`}
                                          className={classes.dropItems}
                                          onClick={handleCloseInformation}
                                        >
                                          {intl.formatMessage(labels.Label_Menu_Information_TrainingCentersLicensing)}
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                )}
                              </div>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </ListItem>
              </Box>
            </Hidden>

            {/* Bolsa Nacional de Formadores */}
            {/* <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  to="/auth/national-scholarship-of-trainers"
                  component={Link}
                  classes={{
                    root: classes.listItemRoot,
                  }}
                >
                  {intl.formatMessage(labels.Label_Menu_Help_NationalScholarshipTrainers)}
                </ListItem>
              </Box>
            </Hidden> */}

            {/* Contactos */}
            <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  to="/auth/contacts"
                  component={Link}
                  classes={{
                    root: classes.listItemRoot,
                  }}
                >
                  Contactos
                </ListItem>
              </Box>
            </Hidden>

            {/* Ajuda */}
            <Hidden smDown implementation="css">
              <Box>
                <ListItem
                  classes={{ root: classes.listItemRoot }}
                  ref={anchorRefHelp}
                  aria-controls={openHelp ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleHelp}
                >
                  {intl.formatMessage(labels.Label_Menu_Help)}
                  <ListItemIcon className={classes.dropIcon}>
                    <ExpandMoreIcon />
                  </ListItemIcon>
                  <Popper open={openHelp} anchorEl={anchorRefHelp.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseHelp}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem
                                component={Link}
                                to={`/auth/frequently-asked-questions`}
                                className={classes.dropItems}
                                onClick={handleCloseHelp}
                              >
                                {intl.formatMessage(labels.Label_Menu_Help_FAQs)}
                              </MenuItem>

                              <MenuItem
                                component={Link}
                                to={`/auth/useful-links`}
                                className={classes.dropItems}
                                onClick={handleCloseHelp}
                              >
                                {intl.formatMessage(labels.Label_Menu_Help_UsefulLinks)}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </ListItem>
              </Box>
            </Hidden>

            {/* Barra de Pesquisa */}
            <Box display="flex" justifyContent="center" alignItems="center" marginRight="auto">
              <div className={classes.navbarDiv}>
                <SearchBarNavbar />
              </div>
            </Box>
          </Box>
        </Container>

        <LoginModal
          loginModal={loginModal}
          handleClose={handleClose}
          handlerecoverModal={handlerecoverModal}
          showAuthenticationModal={props.showAuthenticationModal}
          loginAction={props.loginAction}
          onChange={onChange}
          state={state}
        />

        <RecoverModal
          recoverModal={recoverModal}
          handleCloseRecover={handleCloseRecover}
          showAuthenticationModal={props.showAuthenticationModal}
          onChange={onChange}
          state={state}
        />

        {/* Recover Sucess Modal Start */}
        <Dialog
          open={getShowRecoverPasswordResultModal()}
          onClose={handleRecoverPasswordResultClose}
          classes={{ paper: classes.modalSentRecover }}
        >
          <IconButton edge="start" onClick={handleRecoverPasswordResultClose} classes={{ root: classes.modalClose }}>
            <CloseIcon />
          </IconButton>
          <CardContent classes={{ root: classes.cardContent }}>
            {props.authState.recoverPasswordResult ? (
              <>
                <Box className={classes.elipse}>
                  <Box
                    component="img"
                    alt="..."
                    className={classes.logoInfo}
                    src={require("assets/img/icons/common/Info.png").default}
                  ></Box>
                </Box>
                <Box className={classes.modalRecoverSentHeader}>
                  {intl.formatMessage(messages.Message_Auth_Recover_Password_Success)}
                </Box>
                <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                  <Button
                    className={classes.sentRecoverButton}
                    //TO-DO - Recover account button logic
                    onClick={function () {
                      handleRecoverPasswordResultClose();
                      handleLoginModal();
                    }}
                  >
                    {intl.formatMessage(labels.Label_General_Enter_Button)}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <CancelOutlinedIcon classes={{ root: classes.registerErrorResultModalIcon }} />
                <Typography classes={{ root: classes.modalRegisterResultHeader }}>
                  {intl.formatMessage(errorMessages.ErrorMessage_GenericError)}
                </Typography>
              </>
            )}
          </CardContent>
        </Dialog>
        {/* Recover Sucess Modal End */}
        {/* Registration Resul Modal Start*/}
        <RegisterDialog
          open={getShowRegistrationResultModal()}
          onClose={handleRegistrationResultClose}
          onClick={function () {
            handleRegistrationResultClose();
            handleLoginModal();
          }}
        />

        {/* Registration Resul Modal End*/}
        {/* Authentication dialog START*/}
        <AuthenticationModal />
        {/* Authentication dialog END*/}
        {/* Reset Password dialog START*/}
        <ResetPassword />
        {/* Reset Password dialog END*/}
        {/* Generic dialog START*/}
        <GenericDialog />
        {/* Generic dialog END*/}
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  loginAction: (email, password) => dispatch(loginAction(email, password)),
  hideRegistrationModal: () => dispatch(hideRegistrationModalAction()),
  recoverPassword: (mobilePhone) => dispatch(recoverPasswordAction(mobilePhone)),
  hideRecoverPasswordModal: () => dispatch(hideRecoverPasswordModalAction()),
  showAuthenticationModal: (toShow, username, action) =>
    dispatch(showAuthenticationModalAction(toShow, username, action)),
  resetLoginForm: () => dispatch({ type: AUTH.RESET_LOGIN_FORM }),
  setTargetPathAfterLogin: (targetPath, pathUserType) =>
    dispatch(setTargetPathAfterLoginAction(targetPath, pathUserType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthNavbar);
