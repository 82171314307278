import { React, useState } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import ReactDatetime from "react-datetime";
import { Controller } from "react-hook-form";
import { connect } from "react-redux";
import { getDatasetStateByName } from "../../../utils/datasets";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { errorMessages, labels } from "resources/resources";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormInput from "components/FormFields/FormInput";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import DateRange from "@material-ui/icons/DateRange";
import HelpIcon from "@material-ui/icons/Help";
//import custom components
import FormMultipleSelect from "../../../components/FormFields/FormMultipleSelect";
import FormSelect from "../../../components/FormFields/FormSelect";
import { REGISTRATION_CONSTS, REGEX_PATTERNS, DATASET } from "utils/const";
import "moment/locale/pt";
import moment from "moment";

function CompanyData({ control, getValues, setValue, classes, errors, theme, ...props }) {
  const [dateWarning, setDateWarning] = useState(false);
  const intl = useIntl();

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE_SIMPLE.test(date)) {
      onChange(date);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6} xl={6}>
        <FormInput
          name="name"
          label="Nome"
          isRequired
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <FormGroup>
          <FormLabel>Sector Económico</FormLabel>
          <FormMultipleSelect
            control={control}
            fieldName="businessPurpose"
            selectOptions={getDatasetStateByName(props, DATASET.BUSINESS_PURPOSE)}
            getValues={getValues}
            limitTags={1}
            errors={errors}
            classes={classes}
            required={true}
            requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
          />
          {errors["businessPurpose"] !== undefined && (
            <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
              {errors["businessPurpose"].message}
            </FormHelperText>
          )}
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={4} xl={2}>
        <FormInput
          name="taxIdNumber"
          label="NIF"
          isRequired
          control={control}
          errors={errors}
          numberType={true}
          rules={{
            validate: {
              maxLength: (value) => {
                return value.length === 10 || "O NIF deve ter 10 digitos";
              },
              minValue: (value) => {
                return value >= 0 || intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="permitCode"
          label="Código do alvará"
          isRequired
          control={control}
          errors={errors}
          numberType={true}
          rules={{
            validate: {
              maxLength: (value) => {
                return value.length <= 25 || "O código de alvará tem tamanho máximo de 25 caracteres";
              },
              minValue: (value) => {
                return value >= 0 || intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormGroup>
          <FormLabel>Data de validade do alvará</FormLabel>
          <Controller
            name="permitCodeValidUntil"
            control={control}
            component={Box}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
              },
              validate: {
                validDate: (value) => {
                  if (value && !REGEX_PATTERNS.DATE.test(value instanceof moment ? value.format("DD/MM/yyyy") : value))
                    return props.intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                  return true;
                },
                minDate: (value) => {
                  if (!value || value < moment().startOf("day")) setDateWarning(true);
                  else {
                    setDateWarning(false);
                  }
                },
              },
            }}
            render={({ field }) => (
              <ReactDatetime
                {...field}
                locale="pt"
                dateFormat={REGISTRATION_CONSTS.DATE_TIME_FORMAT}
                timeFormat={false}
                inputProps={{
                  placeholder: REGISTRATION_CONSTS.DATE_TIME_PLACEHOLDER,
                  className: "",
                }}
                renderInput={(dateInputProps, open) => (
                  <OutlinedInput
                    fullWidth
                    onFocus={open}
                    {...dateInputProps}
                    onChange={(e) => {
                      handleValidDate(e.target.value, dateInputProps.onChange);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box component={DateRange} marginRight=".5rem" />
                      </InputAdornment>
                    }
                    classes={{
                      notchedOutline: clsx({
                        [classes.borderWarning]: errors["permitCodeValidUntil"] !== undefined,
                      }),
                    }}
                  />
                )}
              />
            )}
          />
          {errors["permitCodeValidUntil"] !== undefined && (
            <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
              {errors["permitCodeValidUntil"].message}
            </FormHelperText>
          )}
          {dateWarning && (
            <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
              Atenção : Data de validade do alvará expirada.
            </FormHelperText>
          )}
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormGroup>
          <FormLabel>Província</FormLabel>
          <FormSelect
            control={control}
            fieldName="province"
            selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
            loadChildrenDataSet={props.getDataSetByName}
            childrenDataSet={DATASET.MUNICIPALITIES}
            childrenSelect="municipality"
            setValue={setValue}
            getValues={getValues}
            required={true}
            requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
            errors={errors}
            classes={classes}
          />
          {errors["province"] !== undefined && (
            <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
              {errors["province"].message}
            </FormHelperText>
          )}
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormGroup>
          <FormLabel>Município</FormLabel>
          <FormSelect
            control={control}
            fieldName="municipality"
            selectOptions={getDatasetStateByName(props, DATASET.MUNICIPALITIES)}
            getValues={getValues}
            required={true}
            requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
            errors={errors}
            classes={classes}
          />
          {errors["municipality"] !== undefined && (
            <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
              {errors["municipality"].message}
            </FormHelperText>
          )}
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="district"
          label="Bairro"
          isRequired
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={6}>
        <FormInput
          name="address"
          label="Endereço"
          isRequired
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="niss"
          label={
            <>
              NISS
              <Tooltip title="Número de Identificação da Segurança Social">
                <HelpIcon className={classes.tooltipInfoIcon} />
              </Tooltip>
            </>
          }
          isRequired
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="companyEmail"
          label="Email da empresa"
          isRequired
          control={control}
          errors={errors}
          rules={{
            validate: {
              validEmail: (value) => {
                if (value && !REGEX_PATTERNS.EMAIL.test(value))
                  return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                return true;
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="companyPhone"
          label="Telefone da empresa"
          isRequired
          control={control}
          errors={errors}
          numberType={true}
          rules={{
            validate: {
              validPhoneNumber: (value) => {
                if (
                  value &&
                  (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                    parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                )
                  return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                return true;
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="experience"
          label="Anos de experiência"
          isRequired
          control={control}
          errors={errors}
          numberType={true}
          rules={{
            validate: {
              minValue: (value) => {
                return value >= 0 || intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormGroup>
          <FormLabel>{intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_CompanyEACId)}</FormLabel>
          <FormSelect
            control={control}
            fieldName="companyEACId"
            selectOptions={getDatasetStateByName(props, DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION)}
            setValue={setValue}
            getValues={getValues}
            required={true}
            requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
            errors={errors}
            classes={classes}
          />
          {errors["companyEACId"] !== undefined && (
            <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
              {errors["companyEACId"].message}
            </FormHelperText>
          )}
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="propertyType"
          label={intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_PropertyType)}
          isRequired
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="subsidiaryCompany"
          label={intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompany)}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="subsidiaryCompanyPostalCode"
          label={intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyPostalCode)}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="subsidiaryCompanyActivity"
          label={intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyActivity)}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormGroup>
          <FormLabel>
            {intl.formatMessage(labels.Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyEAC)}
          </FormLabel>
          <FormSelect
            control={control}
            fieldName="subsidiaryCompanyEACId"
            selectOptions={getDatasetStateByName(props, DATASET.ECONOMIC_ACTIVITIES_CLASSIFICATION)}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            classes={classes}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="maleWorkersNumber"
          label={intl.formatMessage(
            labels.Label_UserProfile_UserCollective_CompanyData_MaleWorkersNumber
          )}
          control={control}
          errors={errors}
          numberType={true}
          rules={{
            validate: {
              validNumber: (value) => {
                if (value && parseInt(value) < 0) return intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                return true;
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} xl={3}>
        <FormInput
          name="femaleWorkersNumber"
          label={intl.formatMessage(
            labels.Label_UserProfile_UserCollective_CompanyData_FemaleWorkersNumber
          )}
          control={control}
          errors={errors}
          numberType={true}
          rules={{
            validate: {
              validNumber: (value) => {
                if (value && parseInt(value) < 0) return intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                return true;
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyData);
