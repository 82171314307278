import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function History() {
  const intl = useIntl();

  const historyData = [
    {
      title: "",
      content: [
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_description_1) + "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_description_2) + "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_description_3) + "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_description_4) + "\n\n",
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1),
      content: [
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_1) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_2) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_3) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_4) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_5) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_6) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_7) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_8) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_9_BulletPoint_3
          ),
        },
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_1_Description_10) +
          "\n\n",
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_2),
      content: [
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_2_Description_1) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_2_Description_2) +
          "\n\n",
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3),
      content: [
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_1) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_2) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_3) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/NacionalAndForeignTrainersInAngolaFrom87To89.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Table_1),
        },
        "\n\n" +
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_4) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/TrainersOfCentersRunByINEFOPAndOtherOrganizations.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Table_2),
        },
        "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/CentersInstalledCapacity.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Table_3),
        },
        "\n\n" +
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_5_BulletPoint_4
          ),
        },
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_6) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/ProfessionalTrainingCentersInAngola_InstalledCapacity.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Table_4),
        },
        "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/GuardianshipMinistries.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Table_5),
        },
        "\n\n" +
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_3_Description_7) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/ProfessionalTrainingCentersInAngolaByGuardianshipMinistry.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Table_6),
        },
        "\n\n",
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4),
      content: [
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_1) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_2) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_3) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_4) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_5) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_6) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_7) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/INAFOP_OrganizationChart_1992.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Image_1),
        },
        "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_8) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/Newsletter_1.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Image_2_3),
        },
        "\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/Newsletter_2.jpg",
          title: "",
        },
        "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_9) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_10_BulletPoint_3
          ),
        },
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_11) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_12) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_13) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_14) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_15) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_16) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_17) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/INEFOP_ProvincesByOrganism.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Table_7),
        },
        "\n\n" +
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_18) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/INEFOP_OrganizationChart_2002.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Image_4),
        },
        "\n\n" +
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_19) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_20) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/TraineesByYear.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Table_8),
        },
        "\n\n" +
          intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_Description_21) +
          "\n\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_9
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_10
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_11
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_12
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_1_BulletPoint_13
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_4_CooperationAndProjects_Description_2
        ) + "\n\n",
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5),
      content: [
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_1) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_2) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_3) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_4) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_5) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_6) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_7) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_8) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_5_Description_9_BulletPoint_3
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6),
      content: [
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_1) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_2) +
          "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_3_BulletPoint_8
          ),
        },
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_ProfessionalTraining_6_Description_4) +
          "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/INEFOP_OrganizationChart_2015.jpg",
          title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_History_Image_5),
        },
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(titles.Title_Menu_InstitutionalInformation_History)}
      data={historyData}
    />
  );
}

export default History;
