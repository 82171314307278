import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import "moment/locale/pt";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import DropzoneField from "components/FormFields/DropzoneField";
import { getOpportunityDocumentAction } from "redux/actions/opportunitiesAction";
import { DATASET, FORM_FIELDS_PREFIX } from "utils/const";
import { messages, labels } from "resources/resources";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export function DocumentSection(props) {
  const methods = useFormContext();
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [docFiles, setDocFiles] = useState([]);

  useEffect(() => {
    if (props.submetedDocuments?.length > 0) setDocFiles(props.submetedDocuments);
  }, [props.submetedDocuments]);

  const getFileInfo = (documentType) => {
    if (documentType && docFiles.length > 0) {
      const doc = docFiles.find((d) => d.documentTypeId === documentType.code);
      if (doc)
        return [
          {
            documentId: doc.documentId,
            documentName: doc.documentName,
          },
        ];
    }
  };

  const getDocumentFieldSize = (numDocs) => {
    if (12 % numDocs === 0) return 12 / numDocs;
    return 4;
  };
  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            title: classes.cardHeader,
          }}
          title={"Documentos"}
          action={
            <Grid container>
              {!props.isCreationPage && props.showEditButton && (
                <Grid item>
                  <GenericButton
                    typeSubmit={false}
                    color="tertiary"
                    size="small"
                    onClick={() => {
                      props.handleEditSection();
                    }}
                  >
                    Editar
                  </GenericButton>
                </Grid>
              )}
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            {props.datasetsState[DATASET.OPPORTUNITY_DOCUMENT_TYPE]?.map((documentType, key) => (
              <Grid
                item
                xs={12}
                md={getDocumentFieldSize(props.datasetsState[DATASET.OPPORTUNITY_DOCUMENT_TYPE].length)}
                className={classes.paddingRight0px}
                key={key}
              >
                <FormGroup>
                  <FormLabel>{documentType.label}</FormLabel>
                  {props.isEditable && (
                    <DropzoneField
                      name={FORM_FIELDS_PREFIX.DOCUMENT + documentType.code.toString()}
                      multiple={false}
                      label={intl.formatMessage(labels.Label_Generic_Upload_Document)}
                      register={methods.register}
                      errors={methods.formState.errors}
                      required={false}
                      requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                      setValue={methods.setValue}
                      getValues={methods.getValues}
                      trigger={methods.trigger}
                      dropzoneClass={classes.dropzone}
                    />
                  )}
                  {!props.isCreationPage && (
                    <SubmitedDocumentsSection
                      files={getFileInfo(documentType)}
                      getDocument={props.getOpportunityDocument}
                    />
                  )}
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getOpportunityDocument: (docId, docName) => dispatch(getOpportunityDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSection);
