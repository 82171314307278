import { React, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import "react-quill/dist/quill.snow.css";
//Header
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import DetailsSection from "./Sections/DetailsSection";
import {
  getInformationDetailsAction,
  getInformationDetailsPublicAction,
} from "redux/actions/informationDisseminationAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Details(props) {
  const classes = useStylesGeneric();
  const history = useHistory();

  useEffect(() => {
    if (
      props.informationDisseminationState.informationDetailsData ===
        undefined ||
      props.informationDisseminationState.informationDetailsData
        .alternateKey !== props.match.params.infoKey
    ) {
      if (props.authState?.loggedIn !== undefined && props.authState.loggedIn)
        props.getInformationDetails(props.match.params.infoKey, history);
      else
        props.getInformationDetailsPublic(props.match.params.infoKey, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.infoKey]);

  return (
    <>
      <GenericBackdrop
        open={
          props.informationDisseminationState.get_information_details_loading
        }
      />
      <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
        <LocationHeader
          section={"Detalhes de Publicação"}
          subsection={"Publicações"}
          subsectionLink={
            (props.authState.loggedIn ? "/admin" : "/auth") +
            "/informationDissemination"
          }
        />
        <PageTitleHeader
          title={
            props.informationDisseminationState.informationDetailsData?.title
          }
        />
        <DetailsSection
          informationDetailsData={
            props.informationDisseminationState.informationDetailsData
          }
        ></DetailsSection>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getInformationDetails: (infoKey, history) =>
    dispatch(getInformationDetailsAction(infoKey, history)),
  getInformationDetailsPublic: (infoKey, history) =>
    dispatch(getInformationDetailsPublicAction(infoKey, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
