import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useIntl } from "react-intl";

// @material-ui
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import useTokenHelper from "hooks/useTokenHelper";

// components
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import { PAGINATION } from "utils/const";
import { getUserUnemploymentHistoryAction } from "redux/actions/auth/getPersonalInformationAction";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";
import AddUnemploymentHistoryModal from "views/admin/UserArea/UserSingular/Modals/AddUnemploymentHistoryModal";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function UnemploymentHistorySection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const tokenHelper = useTokenHelper();
  const [page, setPage] = useState(1);

  const [showAddUnemploymentHistoryModal, setShowAddUnemploymentHistoryModal] = useState({
    open: false,
    editing: false,
    data: {},
  });

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const tableHead = [
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_StartDate),
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EndDate),
    intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Observations),
    "",
  ];

  const getUserUnemploymentHistory = useCallback(
    () => {
      const filter = {
        pageIndex: page <= 0 ? 1 : page,
        pageSize: PAGINATION.PAGE_SIZE,
        userId: tokenHelper.getUserId(),
      };

      props.getUserUnemploymentHistory(filter);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  useEffect(() => {
    getUserUnemploymentHistory();
  }, [getUserUnemploymentHistory]);

  const handleToggleAddUnemploymentHistoryModal = (data = {}, editing = false) => {
    setShowAddUnemploymentHistoryModal((prevState) => ({
      open: !prevState.open,
      editing,
      data,
    }));
  };

  function handleOnSuccessAction() {
    setPage(1);
    getUserUnemploymentHistory();
  }

  return (
    <>
      <CardTableContainer
        title={intl.formatMessage(
          labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentHistory
        )}
        count={props.authState?.getUserUnemploymentHistory_result?.totalCount}
        actionButtonLabel={intl.formatMessage(
          labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_AddRecord
        )}
        actionButtonOnClick={() => {
          handleToggleAddUnemploymentHistoryModal(null);
        }}
      >
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.authState?.getUserUnemploymentHistory_result?.data?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{moment(prop?.startDate).format("DD/MM/YYYY")}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>
                      {prop?.endDate
                        ? moment(prop?.endDate).format("DD/MM/YYYY")
                        : intl.formatMessage(labels.Label_Undefined)}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box>{prop?.observations}</Box>
                  </TableCell>

                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <GenericButton
                      typeSubmit={false}
                      color="tertiary"
                      size="small"
                      onClick={() => {
                        handleToggleAddUnemploymentHistoryModal(prop, true);
                      }}
                    >
                      {intl.formatMessage(labels.Label_Button_Edit)}
                    </GenericButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
          <TableBackdrop open={props?.authState?.getUserUnemploymentHistory_loading} />
        </TableContainer>

        <Pagination
          className={classes.alignPagination}
          count={props.authState?.getUserUnemploymentHistory_result?.totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        ></Pagination>
      </CardTableContainer>

      <GenericDialog
        maxWidth={"md"}
        open={showAddUnemploymentHistoryModal.open}
        onClose={handleToggleAddUnemploymentHistoryModal}
      >
        <AddUnemploymentHistoryModal
          data={showAddUnemploymentHistoryModal.data}
          onCloseModal={handleToggleAddUnemploymentHistoryModal}
          editing={showAddUnemploymentHistoryModal.editing}
          onSuccess={handleOnSuccessAction}
        />
      </GenericDialog>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUserUnemploymentHistory: (data) => dispatch(getUserUnemploymentHistoryAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnemploymentHistorySection);
