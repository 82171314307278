import {
  getPersonalInformation,
  getUserDocument,
  getUserEmploymentHistory,
  getUserUnemploymentHistory,
  getFoUSInterventionOtherArea,
} from "crud/auth.crud";
import { convertByteArrayToBlobUrl } from "utils/converters";
import { AUTH } from "utils/actionsConsts";

export const getPersonalInformationAction = () => async (dispatch) => {
  dispatch({
    type: AUTH.GET_PERSONAL_INFORMATION_LOADING,
    payload: true,
  });
  getPersonalInformation()
    .then((res) => {
      dispatch({
        type: AUTH.GET_PERSONAL_INFORMATION,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: AUTH.GET_PERSONAL_INFORMATION,
        payload: {},
      });

      console.log("error", error);
    });
};

export const getUserDocumentAction = (docId, docName) => async (dispatch) => {
  getUserDocument(docId)
    .then((res) => {
      const link = document.createElement("a");
      link.href = convertByteArrayToBlobUrl(res.data);
      link.setAttribute("download", docName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      dispatch({
        type: AUTH.GET_USER_DOCUMENT_DATA_DOWNLOAD,
        result: false,
        payload: null,
      });

      console.log("error", error);
    });
};

export const getUserEmploymentHistoryAction = (data) => async (dispatch) => {
  dispatch({
    type: AUTH.GET_USER_EMPLOYMENT_HISTORY_LOADING,
    payload: true,
  });
  getUserEmploymentHistory(data)
    .then((res) => {
      dispatch({
        type: AUTH.GET_USER_EMPLOYMENT_HISTORY,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: AUTH.GET_USER_EMPLOYMENT_HISTORY,
        payload: {},
      });

      console.log("error", error);
    });
};

export const getUserUnemploymentHistoryAction = (data) => async (dispatch) => {
  dispatch({
    type: AUTH.GET_USER_UNEMPLOYMENT_HISTORY_LOADING,
    payload: true,
  });
  getUserUnemploymentHistory(data)
    .then((res) => {
      dispatch({
        type: AUTH.GET_USER_UNEMPLOYMENT_HISTORY,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: AUTH.GET_USER_UNEMPLOYMENT_HISTORY,
        payload: {},
      });

      console.log("error", error);
    });
};

export const getFoUSInterventionOtherAreaAction = (data) => async (dispatch) => {
  dispatch({
    type: AUTH.GET_FOUS_INTERVENTION_OTHER_AREA_LOADING,
    payload: true,
  });
  getFoUSInterventionOtherArea(data)
    .then((res) => {
      dispatch({
        type: AUTH.GET_FOUS_INTERVENTION_OTHER_AREA,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: AUTH.GET_FOUS_INTERVENTION_OTHER_AREA,
        payload: {},
      });
      dispatch({
        type: AUTH.GET_FOUS_INTERVENTION_OTHER_AREA_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export default getPersonalInformationAction;
