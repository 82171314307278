import { React, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import ClearIcon from "@material-ui/icons/Clear";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export const DropzoneField = ({
  name,
  multiple,
  label,
  register,
  setValue,
  getValues,
  dropzoneClass,
  required,
  requiredMessage,
  errors,
  trigger,
}) => {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const [filesState, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    onDrop: (files) => {
      setValue(name, files);
      setFiles(files);
      trigger(name);
    },
  });
  
  useEffect(() => {
    const docs = getValues(name);
    setFiles(docs ? docs : acceptedFiles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const allowedFileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/pdf",
  ];

  const remove = (i) => {
    const newFiles = [...filesState]; // make a var for the new array
    newFiles.splice(i, 1); // remove the file from the array

    setFiles(newFiles);
    setValue(name, newFiles);
    trigger(name);
  };

  const files = filesState.map((file, i) => (
    <Box>
      {(file && file.size > 2000000) || !allowedFileTypes.includes(file.type) ? (
        <Box key={"file" + i}>
          <Box> O ficheiro apenas pode ser do tipo .jpeg .png .pdf e não pode ter mais que 2MB.</Box>{" "}
          {setValue(name, undefined)}
        </Box>
      ) : (
        <Box key={file.path} component="span" display="flex" className={classes.documentBadge}>
          <Box display="flex">
            <Box className={classes.documentNameRoot}>{file.name}</Box>
            <Box>
              <IconButton
                onClick={() => {
                  remove(i);
                }}
                classes={{ root: classes.documentDeleteButtonRoot }}
              >
                <ClearIcon classes={{ root: classes.documentDeleteButtonIconRoot }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  ));

  return (
    <>
      <div {...getRootProps({ className: dropzoneClass })}>
        <input
          {...register(name, {
            required: {
              value: required,
              message: requiredMessage,
            },
          })}
          {...getInputProps()}
        />
        <p>{label}</p>
      </div>
      <Grid container component={Box} marginTop="1rem" className={classes.documentBadgeContainer}>
        {files}
      </Grid>
      <Grid container component={Box} marginTop="1rem" className={classes.documentBadgeContainer}>
        {errors[name] !== undefined && (
          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
            {errors[name].message}
          </FormHelperText>
        )}
      </Grid>
    </>
  );
};

export default DropzoneField;
