import { React } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//MaterialUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import OpportunityCard from "../../../Components/Opportunities/Card/OpportunityCard";
import CardsListBackdrop from "components/Backdrops/CardsListBackdrop";
// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ResultSection(props) {
  const classes = useStylesGeneric();

  return (
    <>
      {props.opportunitiesState?.available_result?.data?.length > 0 ? (
        <Grid container style={{ position: "relative" }}>
          {props.opportunitiesState.available_result.data.map((info, key) => (
            <Grid key={key} item xs={12} md={6} lg={4} xl={3}>
              <OpportunityCard
                info={info}
              />
            </Grid>
          ))}
          <CardsListBackdrop open={props.opportunitiesState?.searchAvailableOpportunities_loading} />
        </Grid>

      ) : 
        !props.opportunitiesState?.searchAvailableOpportunities_loading && (
            <Box classes={{ root: classes.noResultsInfo }}>
              Não foram encontrados resultados para esta pesquisa.
            </Box>
          )
        
      }
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ResultSection);
