import React from "react";
import { labels, errorMessages, messages } from "resources/resources";
import { useIntl } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getDatasetStateByName } from "utils/datasets";
import FormMonetaryInput from "components/FormFields/FormMonetaryInput";

import Box from "@material-ui/core/Box";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { FormHelperText, FormGroup, FormLabel, OutlinedInput } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import HelpIcon from "@material-ui/icons/Help";
//custom components
import FormInput from "components/Forms/FormInput";
import FormSelect from "components/FormFields/FormSelect";
import FormDatePicker from "components/Forms/FormDatePicker";
import { SPECIAL_NEEDS_KEY, REGISTRATION_CONSTS, REGEX_PATTERNS } from "utils/const";

//styles
import { makeStyles, useTheme } from "@material-ui/core/styles";
import componentStylesUserProfile from "assets/theme/views/admin/user-profile.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesUserProfile = makeStyles(componentStylesUserProfile);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function PersonalForm(props) {
  const theme = useTheme();
  const classes = { ...useStylesGeneric(), ...useStylesUserProfile() };
  const intl = useIntl();

  const {
    getValues,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader classes={{ title: classes.cardHeader }} title={"Dados Pessoais"}></CardHeader>
        <CardContent>
          <Box>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="name"
                  label="Nome"
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="userSingular.lastName"
                  label="Apelido"
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
              <FormDatePicker
                  name="userSingular.dateOfBirth"
                  label="Data de Nascimento"
                  control={control}
                  getValues={getValues}
                  trigger={trigger}
                  maxDateToday
                  isRequired
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <FormGroup>
                    <FormLabel>Data de Nascimento</FormLabel>
                    <Controller
                      name="userSingular.dateOfBirth"
                      control={control}
                      rules={{
                        validate: {
                          isValidDateFormat: (value) => {
                            return !value || value._isAMomentObject || intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                          },
                          minDate: (value) => {
                            return (
                              !value ||
                              value >= moment().startOf("day") ||
                              intl.formatMessage(messages.Message_Generic_DateToGreaterThanOrEqualToTodayField)
                            );
                          },
                          isLessThanEndDate: (value) => {
                            if (!getValues("userSingular.dateOfBirth")) return true;
                            return (
                              (value &&
                                value instanceof moment &&
                                getValues("userSingular.dateOfBirth") &&
                                getValues("userSingular.dateOfBirth") instanceof moment &&
                                value.startOf("day") < getValues("userSingular.dateOfBirth")?.startOf("day")) ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateFromLessThanDateToField)
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <ReactDatetime
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            trigger("userSingular.dateOfBirth");
                          }}
                          locale="pt"
                          dateFormat={"DD/MM/YYYY"}
                          timeFormat={false}
                          isValidDate={(currentDate, _) => {
                            return currentDate >= moment().startOf("day");
                          }}
                          closeOnSelect={true}
                          closeOnTab={true}
                          closeOnClickOutside={true}
                          inputProps={{
                            placeholder: "DD/MM/AAAA",
                            className: "",
                          }}
                          renderInput={(dateInputProps) => (
                            <OutlinedInput
                              value={dateInputProps.value}
                              onClick={dateInputProps.onClick}
                              onFocus={dateInputProps.onFocus}
                              onKeyDown={dateInputProps.onKeyDown}
                              disabled={dateInputProps.disabled}
                              placeholder={dateInputProps.placeholder}
                              type={dateInputProps.type}
                              fullWidth
                              onChange={(e) => {
                                handleValidDate(e.target.value, dateInputProps.onChange);
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                                </InputAdornment>
                              }
                            />
                          )}
                        />
                      )}
                    />
                    {errors["userSingular.dateOfBirth"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {errors["userSingular.dateOfBirth"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid> */}
              <Grid item xs={12} md={4}>
                <FormInput
                  name="userSingular.identityCard"
                  label="Nº do BI"
                  control={control}
                  errors={errors}
                  disabled={!!props.authState.personalInformation?.userSingular?.identityCard}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                    validate: {
                      maxLength: (value) => {
                        return value.length <= 14 || "O número de BI tem tamanho máximo de 14 caracteres";
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>Necessidades Especiais</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="userSingular.specialNeedsId"
                    selectOptions={getDatasetStateByName(props, "SpecialNeeds")}
                    getValues={getValues}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={errors}
                    onOptionChange={() => trigger("userSingular.specialNeedsPercent")}
                    classes={classes}
                  />
                  {errors["userSingular"]?.specialNeedsId !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["userSingular"].specialNeedsId.message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  name="userSingular.specialNeedsPercent"
                  label="Percent. %"
                  control={control}
                  errors={errors}
                  rules={{
                    validate: {
                      requiredIf: (value) => {
                        if (
                          getValues("userSingular.specialNeedsId") !== undefined &&
                          getValues("userSingular.specialNeedsId")?.keyValue !== SPECIAL_NEEDS_KEY.NAO &&
                          !value
                        ) {
                          return intl.formatMessage(errorMessages.ErrorMessage_FieldRequired);
                        }
                      },
                      greaterThanZero: (value) => {
                        if (
                          getValues("userSingular.specialNeedsId") !== undefined &&
                          getValues("userSingular.specialNeedsId") !== null &&
                          getValues("userSingular.specialNeedsId").keyValue !== SPECIAL_NEEDS_KEY.NAO &&
                          (value <= REGISTRATION_CONSTS.MIN_LENGTH_PERCENTAGE ||
                            value > REGISTRATION_CONSTS.MAX_LENGTH_PERCENTAGE)
                        ) {
                          return intl.formatMessage(errorMessages.ErrorMessage_FieldPercentage);
                        }
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryForm}>Género</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="gender"
                    selectOptions={getDatasetStateByName(props, "Gender")}
                    getValues={getValues}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["gender"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["gender"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryForm}>Estado Civil</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="maritalStatus"
                    selectOptions={getDatasetStateByName(props, "MaritalStatus")}
                    getValues={getValues}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["maritalStatus"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["maritalStatus"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  name="userSingular.household"
                  label={intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_Household)}
                  numberType={true}
                  control={control}
                  errors={errors}
                  rules={{
                    validate: {
                      minValue: (value) => {
                        if (value < 0)
                          return intl.formatMessage(errorMessages.ErrorMessage_Generic_ValueEqualOrGreaterThanZeroField);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormMonetaryInput
                  name="userSingular.familyIncome"
                  label={intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_FamilyIncome)}
                  control={control}
                  errors={errors}
                  defaultValue={props.authState?.personalInformation?.userSingular?.familyIncome}
                  rules={{
                    pattern: {
                      value: REGEX_PATTERNS.MONETARY,
                      message: intl.formatMessage(messages.Message_Generic_InvalidField),
                    },
                    validate: {
                      minValue: (value) => {
                        if (value === "0" || value === "0,00" || value === "0,0")
                          return intl.formatMessage(errorMessages.ErrorMessage_Generic_ValueGreaterThanZeroField);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryForm}>Nacionalidade</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="nationalityId"
                    selectOptions={getDatasetStateByName(props, "Nationalities")}
                    getValues={getValues}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["nationalityId"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["nationalityId"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryForm}>Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    selectOptions={getDatasetStateByName(props, "Provinces")}
                    getValues={getValues}
                    loadChildrenDataSet={props.getDataSetByName}
                    childrenDataSet="Municipalities"
                    childrenSelect="municipality"
                    setValue={setValue}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["province"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["province"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategoryForm}>Município</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="municipality"
                    selectOptions={getDatasetStateByName(props, "Municipalities")}
                    getValues={getValues}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    disabled={!getValues("province")}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["municipality"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["municipality"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  name="userSingular.compositeAddress.district"
                  label="Bairro"
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  name="userSingular.compositeAddress.address"
                  label="Endereço (opcional)"
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: false,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  name="userSingular.niss"
                  label={
                    <>
                      NISS Individual
                      <Tooltip title="Número de Identificação da Segurança Social">
                        <HelpIcon className={classes.tooltipInfoIcon} />
                      </Tooltip>
                    </>
                  }
                  onChange={() => trigger("userSingular.hasNISS")}
                  control={control}
                  errors={errors}
                  rules={{
                    validate: {
                      requiredIf: (value) => {
                        if (!getValues("userSingular.hasNISS") && !value)
                          return intl.formatMessage(errorMessages.ErrorMessage_FieldRequired);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <FormGroup>
                    <Controller
                      name="userSingular.hasNISS"
                      control={control}
                      component={Box}
                      defaultValue={false}
                      rules={{
                        validate: {
                          requiredIf: (value) => {
                            if (!getValues("userSingular.niss") && !value)
                              return intl.formatMessage(errorMessages.ErrorMessage_FieldRequired);

                            if (
                              getValues("userSingular.niss") &&
                              getValues("userSingular.hasNISS")
                            )
                              return "Preencha apenas um dos campos (NISS Individual ou Sem NISS Individual)";

                            return true;
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <FormControlLabel
                          name={name}
                          onChange={(e) => {
                            onChange(e);
                            trigger("userSingular.niss");
                          }}
                          value="end"
                          control={<Checkbox color="primary" checked={value} />}
                          label="Sem NISS Individual"
                          labelPlacement="end"
                          classes={{
                            root: classes.formControlLabelRoot,
                            label: classes.formControlLabelLabel,
                          }}
                        />
                      )}
                    />
                    {errors["userSingular.hasNISS"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {errors["userSingular.hasNISS"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  name="mobilePhone"
                  label="Telemóvel"
                  onChange={() => trigger("mobilePhoneConfirmation")}
                  control={control}
                  errors={errors}
                  disabled={true}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                    validate: {
                      validPhoneNumber: (value) => {
                        if (
                          value &&
                          (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                            parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                        )
                          return "Formato inválido";
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  name="mobilePhoneConfirmation"
                  label="Confirmação do Telemóvel"
                  onChange={() => trigger("mobilePhone")}
                  control={control}
                  errors={errors}
                  disabled={true}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                    validate: {
                      validPhoneNumber: (value) => {
                        if (
                          value &&
                          (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                            parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                        )
                          return "Formato inválido";
                        return true;
                      },
                      phoneNumberMatch: (value) => {
                        return (
                          value === getValues("mobilePhone") || "O número inserido deve ser igual ao campo 'Telemóvel'"
                        );
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  name="alternativeMobilePhone"
                  label="Telemóvel Alternativo (opcional)"
                  control={control}
                  errors={errors}
                  rules={{
                    validate: {
                      validPhoneNumber: (value) => {
                        if (
                          value &&
                          (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                            parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                        )
                          return "Formato inválido";
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="email"
                  label="E-mail (opcional)"
                  // onChange={trigger("emailConfirmation")}
                  disabled={true}
                  control={control}
                  errors={errors}
                  rules={{
                    required: false,
                    validate: {
                      validEmail: (value) => {
                        if (value && !/\S+@\S+\.\S+/.test(value)) return "Formato inválido";
                        return true;
                      },
                    },
                  }}
                />
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>E-mail (opcional)</FormLabel>
                  <Controller
                    name="email"
                    defaultValue=""
                    control={control}
                    component={Box}
                    rules={{
                      validate: {
                        validEmail: (value) => {
                          if (value && !/\S+@\S+\.\S+/.test(value)) return "Formato inválido";
                          return true;
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        fullWidth
                        disabled={!!props.authState.personalInformation?.email}
                        autoComplete="off"
                        type="email"
                        onChange={(e) => {
                          onChange(e);
                          trigger("emailConfirmation");
                        }}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["email"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {errors["email"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["email"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="emailConfirmation"
                  label="Confirmação e-mail (opcional)"
                  // onChange={trigger("email")}
                  disabled={true}
                  control={control}
                  errors={errors}
                  rules={{
                    required: false,
                    validate: {
                      validEmail: (value) => {
                        if (value && !/\S+@\S+\.\S+/.test(value)) return "Formato inválido";
                        return true;
                      },
                      EmailMatch: (value) => {
                        return value === getValues("email") || "O e-mail inserido deve ser igual ao campo 'E-mail'";
                      },
                    },
                  }}
                />
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>Confirmação e-mail (opcional)</FormLabel>
                  <Controller
                    name="emailConfirmation"
                    defaultValue=""
                    control={control}
                    component={Box}
                    rules={{
                      validate: {
                        validEmail: (value) => {
                          if (value && !/\S+@\S+\.\S+/.test(value)) return "Formato inválido";
                          return true;
                        },
                        EmailMatch: (value) => {
                          return value === getValues("email") || "O e-mail inserido deve ser igual ao campo 'E-mail'";
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        fullWidth
                        autoComplete="off"
                        type="email"
                        disabled={
                          !!props.authState.personalInformation?.email ||
                          !getValues("email") ||
                          errors["email"] !== undefined
                        }
                        onChange={(e) => {
                          onChange(e);
                          trigger("email");
                        }}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["emailConfirmation"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {errors["emailConfirmation"] !== undefined && errors["email"] === undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["emailConfirmation"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormGroup>
                  <Typography component="div" variant="body2">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>
                        <Controller
                          name="userSingular.isAvailableForInternshipOpportunities"
                          control={control}
                          defaultValue={false}
                          rules={{
                            validate: {
                              oneIsChecked: (value) => {
                                return value || getValues("userSingular.isAvailableForEmploymentOpportunities") || "Seleccione pelo menos um dos campos";
                              },
                            },
                          }}
                          render={({ field: { onChange, name, value } }) => (
                            <Switch
                              name={name}
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                                trigger("userSingular.isAvailableForEmploymentOpportunities");
                              }}
                              color="primary"
                            />
                          )}
                        />
                        {errors["userSingular"] && errors["userSingular"].isAvailableForInternshipOpportunities && (
                          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                            {errors["userSingular"].isAvailableForInternshipOpportunities.message}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item>
                        <FormLabel>
                          {intl.formatMessage(
                            labels.Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForInternshipOpportunities
                          )}
                        </FormLabel>
                      </Grid>
                    </Grid>
                  </Typography>
                </FormGroup>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormGroup>
                  <Typography component="div" variant="body2">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>
                        <Controller
                          name="userSingular.isAvailableForEmploymentOpportunities"
                          control={control}
                          defaultValue={false}
                          rules={{
                            validate: {
                              oneIsChecked: (value) => {
                                return value || getValues("userSingular.isAvailableForInternshipOpportunities") || "Seleccione pelo menos um dos campos";
                              },
                            },
                          }}
                          render={({ field: { onChange, name, value } }) => (
                            <Switch
                              name={name}
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                                trigger("userSingular.isAvailableForInternshipOpportunities");
                              }}
                              color="primary"
                            />
                          )}
                        />
                        {errors["userSingular"] && errors["userSingular"].isAvailableForEmploymentOpportunities && (
                          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                            {errors["userSingular"].isAvailableForEmploymentOpportunities.message}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item>
                        <FormLabel>
                          {intl.formatMessage(
                            labels.Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForEmploymentOpportunities
                          )}
                        </FormLabel>
                      </Grid>
                    </Grid>
                  </Typography>
                </FormGroup>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);
