import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import componentStylesLandingPage from "assets/theme/components/landingPage/landing-page.js";
import componentStylesInefopDirectorMessage from "assets/theme/components/landingPage/inefop-director-message.js";
import { labels } from "resources/resources";
import GenericButton from "components/Buttons/GenericButton";

function InefopDirectorMessage(props) {
  const useStylesLandingPage = makeStyles(componentStylesLandingPage);
  const useStylesInefopDirectorMessage = makeStyles(
    componentStylesInefopDirectorMessage
  );

  const classes = {
    ...useStylesLandingPage(),
    ...useStylesInefopDirectorMessage(),
  };
  const intl = useIntl();
  const history = useHistory();

  return (
    <Container
      maxWidth={false}
      className={
        classes.homepageSectionContainer +
        " " +
        classes.centerContent +
        " " +
        classes.mainContainer
      }
    >
      <Grid container className={classes.mainGrid}>
        <img
          src={require("assets/img/brand/INEFOP_generalDirector.png").default}
          alt={intl.formatMessage(
            labels.Label_HomePage_DirectorMessage_ImageAlt
          )}
          className={classes.directorImage}
        />
        <Grid item xs={true} className={classes.directorQuoteArea}>
          <img
            src={require("assets/img/icons/common/Rounded_Quotes.png").default}
            alt={""}
            className={classes.quoteIcon}
          />
          <div className={classes.directorQuote}>
            {props.inefopDirectorMessage}
          </div>

          <div className={classes.directorName}>
            {intl.formatMessage(
              labels.Label_HomePage_DirectorMessage_DirectorName
            )}
          </div>

          <div className={classes.directorFullJobDescription}>
            {intl.formatMessage(
              labels.Label_HomePage_DirectorMessage_DirectorFullJobDescription
            )}
          </div>

          <GenericButton
            typeSubmit={false}
            color="tertiary"
            onClick={() =>
              history.push(
                (props.authState?.loggedIn ? "/admin" : "/auth") +
                  "/director-biography"
              )
            }
            classes={classes.sectionButton}
          >
            {intl.formatMessage(
              labels.Label_HomePage_DirectorMessage_SeeBiography
            )}
          </GenericButton>
          <GenericButton
            typeSubmit={false}
            color="tertiary"
            onClick={() =>
              history.push(
                (props.authState?.loggedIn ? "/admin" : "/auth") +
                  "/institute-holders"
              )
            }
            classes={classes.sectionButton}
          >
            {intl.formatMessage(
              labels.Label_HomePage_DirectorMessage_InstituteHolders
            )}
          </GenericButton>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(InefopDirectorMessage);
