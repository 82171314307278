import { GOVERNMENT_PROGRAMS_ACTIONS } from "utils/actionsConsts";

const getGovernmentProgramsReducer = (
  state = {
    getGovernmentPrograms_loading: false
  },
  action
) => {
  switch (action.type) {
    case GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAMS:
      return {
        ...state,
        getGovernmentPrograms_result: action.payload,
        getGovernmentPrograms_total_records: action.payload.total_records,
        getGovernmentPrograms_total_pages: action.payload.total_pages,
      };
    case GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAMS_LOADING:
      return { ...state, getGovernmentPrograms_loading: action.payload };
    case GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAM_DETAILS:
      return {
        ...state,
        getGovernmentProgramDetails_result: action.payload,
        getGovernmentProgramDetails_total_records: action.payload.total_records,
        getGovernmentProgramDetails_total_pages: action.payload.total_pages,
      };
    case GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAM_DETAILS_LOADING:
      return { ...state, getGovernmentProgramDetails_loading: action.payload };
    default:
      return state;
  }
};

export default getGovernmentProgramsReducer;
