export function notificationsMap(formData) {
    if (formData !== null && formData !== undefined) {
      return {
        search: formData.search !== undefined ? formData.search : "",
        origin: formData.origin?.code
      };
    }
  }
  
  export default notificationsMap;
  