import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
  },
  cardHeader: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "25px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  cardHeaderBig: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "27px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  cardHeaderAvatar: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "17px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  cardHeaderTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[700],
  },
  cardHeaderIcon: {
    width: "20px",
    height: "auto",
  },
  modalHeader: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: theme.palette.gray[700],
    marginBottom: "65px",
  },
  modalApproveLoan: {
    // maxWidth: "530px",
    // height: "571px",
    // position: "absolute",
    // marginLeft: "25.07%",
    // marginRight: "25.07%",
    // marginBottom: "31.35%",
  },
  containerRoot: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    paddingTop: "5rem",
    marginBottom: "2rem",
  },
  timelineDot: {
    color: theme.palette.primary.greyBlue,
  },
  timelineIcons: {
    color: theme.palette.white.main,
    width: "25px",
    height: "auto",
    marginTop: "5px",
  },
  timelineIconsBox: {
    background: theme.palette.gray[700],
    borderRadius: "35px",
    width: "37px",
    height: "37px",
    alignItems: "center",
    textAlign: "center",
  },
  navigateBefore: {
    color: theme.palette.primary.main,
    width: "30px",
    height: "auto",
    marginTop: "5px",
  },
  navigateBeforeBox: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "34px",
    width: "43px",
    height: "43px",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&:hover, &:focus": {
      boxShadow: "0px 0px 10px orange !important",
    },
  },
  titleGrid: {
    display: "flex",
  },
  ellipse: {
    marginLeft: "120px",
    marginTop: "-2px",
    width: "38px",
    height: "38px",
  },
  bigAreaEllipsis: {
    display: "-webkit-box",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": "11",
    lineHeight: "1.5rem",
    height: "13rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  descriptionText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
  },
  vector: {
    marginTop: "0px",
    marginLeft: "-23px",
    width: "7px",
    height: "14px",
  },
  pageTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginLeft: "22px",
  },
  alignRight: {
    textAlign: "right",
  },
  mb0: {
    marginBottom: "0!important",
  },
  containerPadding: {
    marginBottom: "37px",
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
  },
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },
  monetaryFields: {
    textAlign: "end",
  },
  saveButton: {
    background:
      "linear-gradient(90deg, " + theme.palette.info.main + " 0%, " + theme.palette.primary.mediumBlue + " 100%)",
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.white.main,
    width: "50%",
  },
  saveProfileButton: {
    background:
      "linear-gradient(90deg, " + theme.palette.info.main + " 0%, " + theme.palette.primary.mediumBlue + " 100%)",
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.white.main,
    width: "62%",
  },
  cancelButton: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    // color: theme.palette.primary.main,
    width: "50%",
    // border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  cdTypeAddButton: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    // color: theme.palette.primary.main,
    // border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    textTransform: "uppercase",
    padding: "6px 11px 6px 18px",
    marginBottom: "6px",
    marginTop: "6px",
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  cdTypeAddButtonLabel: {
    justifyContent: "normal",
  },
  cdButtons: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  cdLabels: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  saveButtonFormGroup: {
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  avatarRoot: {
    width: "36px",
    height: "36px",
    marginRight: "0.5rem",
  },
  avatarRootLarge: {
    width: "200px",
    height: "200px",
    fontSize: "4.875rem",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  paddingRight0px: {
    paddingRight: "0px",
  },
  paddingLeft0px: {
    paddingLeft: "0px",
  },
  cancelButtonFormGroup: {
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  containerButtons: {
    marginLeft: "20px",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  submitButton: {
    color: theme.palette.white.main,
    background:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    border: "0px",
    boxShadow: "0px 0px 32px rgba(136, 152, 170, 0.15)",
  },
  detailLabels: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  modalSentRecover: {
    maxWidth: "718px",
    width: "718px",
    height: "571px",
    position: "absolute",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginBottom: "31.35%",
  },
  modalResultClose: {
    marginLeft: "90%",
  },
  detailFormLabels: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  helpIconBox: {
    textAlign: "center",
    color: theme.palette.info.lightgreen,
  },
  helpIconRoot: {
    width: "100px",
    height: "auto",
  },
  modalUpdateLoan: {
    width: "530px",
    height: "600px",
    position: "absolute",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginBottom: "31.35%",
  },
  userProfileTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "35px",
    color: theme.palette.gray[700],
    textAlign: "center",
  },
  borderRadiusSemiCurved: {
    borderRadius: "4px 0px 0px 4px",
  },
  userProfileUpdatePasswordButton: {
    width: "100%",
    height: "45px",
    padding: "6px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "0px 4px 4px 0px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    textTransform: "uppercase",
  },
  editButton: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    width: "50%",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  opportunityAddQuestionsButton: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: "6px 11px",
  },
  opportunityAddQuestionsCardAction: {
    marginTop: "0px",
  },
  buttonProgress: {
    color: theme.palette.white.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  documentBadge: {
    backgroundColor: theme.palette.white.main,
    marginRight: "10px",
    marginBottom: "10px",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    borderRadius: "4px",
    border: "1px solid " + theme.palette.primary.main,
    padding: ".4em .3em .3em .85em",
    alignItems: "center",
  },
  changeButtonsGroup: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexFlow: "wrap",
      justifyContent: "center",
      marginTop: "1rem",
    },
  },
  changeButtonOf2: {
    //widths to use when there are 2 buttons
    width: "300px",
    [theme.breakpoints.down("lg")]: {
      width: "250px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "180px",
    },
    heigth: "43px",
    backgroundColor: theme.palette.white.light,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px 0px 0px 4px",
    textTransform: "none",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    /* identical to box height */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",

    /* Text Color/Dark Blue */

    color: theme.palette.primary.greyBlue,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.greyBlue + " !important",
      /* Color Border/Secondary */

      border: "1px solid " + theme.palette.white.light,
      boxSizing: "border-box",
      borderRadius: "4px",

      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      /* identical to box height */

      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.02em",
      textTransform: "none",

      /* Text Color/Dark Blue */

      color: theme.palette.white.main,
    },
  },
  dividerRoot: {
    height: "4px",
    marginBottom: "1rem",
  },
  dropzone: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "1px",
    borderRadius: "1px",
    height: "62px!important",
    borderColor: theme.palette.gray[600],
    borderStyle: "dashed",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray[500],
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  emptyLabelMargin: {
    marginBottom: "1.35rem",
  },
  touchAppIcon: {
    position: "absolute",
    marginLeft: "97%",
    marginTop: "1%",
    backgroundColor: theme.palette.white.smoke,
    width: "25px",
    height: "28px",
  },
  tooltip: {
    maxWidth: "280px",
    maxHeigth: "280px",
    marginTop: "1px",
    marginBottom: "1px",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.black.light,
    textAlign: "justify",
  },
  tooltipArrow: {
    color: theme.palette.grey[300],
  },
  align: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
  cardHeaderinfoIcon: {
    color: theme.palette.primary.main,
    width: "30px",
    height: "auto",
    marginTop: "5px",
  },
  tooltipDescritionSizeWidth: {
    maxWidth: 500,
  },
  messageTextBox: {
    "& .ql-container .ql-editor": {
      height: "150px",
      maxWidth: "460px",
      overflow: "auto",
      borderLeft: "0px",
      borderRight: "0px",
      borderBottom: "0px",
    },
  },
  preventWordOverflow: {
    overflowWrap: "anywhere",
    "& img": {
      maxWidth: "100%",
    },
  },
  documentsAreaDescription: {
    fontWeight: "300",
    fontSize: "14px",
    lineHeight: "23px",
    color: theme.palette.gray[700],
  },
  documentBadgeContainer: {
    paddingLeft: "15px",
  },
  textAlignEnd: {
    textAlign: "end",
  },
  paddingTop2px: {
    padding: "2px 0",
  },
  alertMessage: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
  },
  cardButton: {
    background:
      "linear-gradient(90deg, " + theme.palette.info.main + " 0%, " + theme.palette.primary.mediumBlue + " 100%)",
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.02em",
    color: theme.palette.white.main,
    marginTop: "10px",
    width: "200px",
    "&:hover": {
      color: theme.palette.gray[200],
    },
  },
  userNameBigBox: {
    fontSize: "20px",
    marginTop: "10px",
    fontWeight: "600",
  },
  modalHelpIcon: {
    color: theme.palette.info.lightgreen,
    width: "120px",
    height: "120px",
    boxSizing: "border-box",
    marginLeft: "200px",
  },
  subHeader: {
    fontStyle: "normal",
    fontSize: "1rem",
    alignItems: "center",
    textAlign: "center",
    marginTop: "-40px",
  },
  minHeight0PX: {
    minHeight: "auto!important",
  },
  verticalMenuList: {
    display: "inline-block",
    marginTop: "5px",
    marginRight: "20px",
    paddingBottom: "25px",
  },
  menuItem: {
    width: "270px",
    fontSize: "20px",
    fontWeight: "400",
    alignItems: "center",
    color: theme.palette.primary.main,
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.greyBlue + " !important",
      border: "1px solid " + theme.palette.white.light,
      boxSizing: "border-box",
      borderRadius: "2px",

      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "20px",
      letterSpacing: "0.02em",
      textTransform: "none",
      color: theme.palette.white.main,
    },
  },
  colorfulBackgroundBox: {
    backgroundColor: theme.palette.primary.main,
    margin: "30px 0 30px 0",
    padding: "20px",
  },
});

export default componentStyles;
