import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core components
import Box from "@material-ui/core/Box";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

import { BNF_URL } from "../../../index";
import LocationHeader from "components/Headers/LocationHeader";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function NationalScholarshipTrainers() {
  const classes = useStylesGeneric();

  return (
    <Box classes={{ root: classes.iframeBoxContainerRoot }}>
      <Box marginLeft="30px">
        <LocationHeader section="Bolsa Nacional de Formadores" />
      </Box>
      {/*This iframe is causing some unwanted console.log */}
      <iframe
        title="NST"
        width="100%"
        height="100%"
        frameBorder="0"
        src={BNF_URL}
      />
    </Box>
  );
}
