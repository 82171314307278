import React, { useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import componentStylesGeneric from "assets/theme/views/admin/generic";

import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import MyGovernmentProgramsApplicationsTab from "views/admin/UserArea/UserSingular/MyApplicationsTabs/MyGovernmentProgramsApplicationsTab";
import MyOpportunityApplicationsTab from "views/admin/UserArea/UserSingular/MyApplicationsTabs/MyOpportunityApplicationsTab";
import { OPERATIONS, OPPORTUNITY_TYPE_KEY } from "utils/const";
import { checkOperationPermission } from "utils/permission";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function MyApplicationsList(props) {
  const classes = useStylesGeneric();
  const [tabStep, setTabStep] = useState(props.match?.params?.applicationType ?? "government-program-application");

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);
    }
  };

  function loadTabContent() {
    switch (tabStep) {
      case OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL_URL:
        return <MyOpportunityApplicationsTab opportunityTypeKey={OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL} />;
      case OPPORTUNITY_TYPE_KEY.EMPREGO_URL:
        return <MyOpportunityApplicationsTab opportunityTypeKey={OPPORTUNITY_TYPE_KEY.EMPREGO} />;
      case "government-program-application":
      default:
        return <MyGovernmentProgramsApplicationsTab />;
    }
  }

  return (
    <>
      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
        <LocationHeader section={"Minhas Candidaturas"} />

        <PageTitleHeader title={"Minhas Candidaturas"} />
        <Box component={Grid} container justifyContent="center">
          <Grid item xs={12} lg={12} md={12}>
            <ToggleButtonGroup
              value={tabStep}
              exclusive
              onChange={handleTabStep}
              aria-label="tab Step"
              className={classes.changeTabsGroup}
              style={{ marginLeft: "15px" }}
            >
              <ToggleButton value="government-program-application" className={classes.changeTabs}>
                Apoios
              </ToggleButton>
              {checkOperationPermission(
                props.authState.operationsList,
                OPERATIONS.RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD_USER_SINGULAR
              ) && (
                <ToggleButton value={OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL_URL} className={classes.changeTabs}>
                  Estágios Profissionais
                </ToggleButton>
              )}
              {checkOperationPermission(
                props.authState.operationsList,
                OPERATIONS.RECENT_OPPORTUNITY_CANDIDATE_DASHBOARD_USER_SINGULAR
              ) && (
                <ToggleButton value={OPPORTUNITY_TYPE_KEY.EMPREGO_URL} className={classes.changeTabs}>
                  Emprego
                </ToggleButton>
              )}
            </ToggleButtonGroup>

            <Grid item xs={12} lg={12} md={12}>
              {loadTabContent()}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(MyApplicationsList);
