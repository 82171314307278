import { React } from "react";
import clsx from "clsx";
import { errorMessages, labels } from "resources/resources";
import { useIntl } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";

//redux
import { connect } from "react-redux";
import { getDataSetByNameAction, getGeographicDataByProvinceAction } from "redux/actions/datasetsAction";

// @material-ui/core components
import Box from "@material-ui/core/Box";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
//custom components
import FormInput from "components/Forms/FormInput";

//styles
import { makeStyles, useTheme } from "@material-ui/core/styles";
import componentStylesUserProfile from "assets/theme/views/admin/user-profile.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { REGISTRATION_CONSTS, REGEX_PATTERNS } from "utils/const";

const useStylesUserProfile = makeStyles(componentStylesUserProfile);
const useStylesGeneric = makeStyles(componentStylesGeneric); 

function PersonalForm() {
  const theme = useTheme();
  const classes = {...useStylesGeneric(), ...useStylesUserProfile()};
  const intl = useIntl();

  const methods = useFormContext();

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader classes={{ title: classes.cardHeader }} title={"Dados Utilizador"}></CardHeader>
        <CardContent>
          <Box>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="contactName"
                  label="Nome do responsável"
                  control={methods.control}
                  errors={methods.formState.errors}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Auth_ColectiveRegistrationSteps_UserData_Field_MobilePhone)}
                  </FormLabel>
                  <Controller
                    name="mobilePhone"
                    defaultValue=""
                    control={methods.control}
                    component={Box}
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                      },
                      validate: {
                        validPhoneNumber: (value) => {
                          if (
                            value &&
                            (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                              parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                          )
                            return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                          return true;
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        fullWidth
                        autoComplete="off"
                        type="number"
                        disabled={true}
                        onChange={(e) => {
                          onChange(e);
                          methods.trigger("mobilePhoneConfirmation");
                        }}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: methods.formState.errors["mobilePhone"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {methods.formState.errors["mobilePhone"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["mobilePhone"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormLabel>Confirmação telemóvel</FormLabel>
                  <Controller
                    name="mobilePhoneConfirmation"
                    defaultValue=""
                    control={methods.control}
                    component={Box}
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                      },
                      validate: {
                        validPhoneNumber: (value) => {
                          if (
                            value &&
                            (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                              parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                          )
                            return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                          return true;
                        },
                        phoneNumberMatch: (value) => {
                          return (
                            value === methods.getValues("mobilePhone") ||
                            intl.formatMessage(
                              errorMessages.ErrorMessage_MobilePhone_ConfirmationMobilePhone_Not_Match,
                              {
                                phoneLabel: intl.formatMessage(
                                  labels.Label_Auth_ColectiveRegistrationSteps_UserData_Field_MobilePhone
                                ),
                              }
                            )
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        fullWidth
                        autoComplete="off"
                        type="number"
                        disabled={true}
                        onChange={(e) => {
                          onChange(e);
                          methods.trigger("mobilePhone");
                        }}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: methods.formState.errors["mobilePhoneConfirmation"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {methods.formState.errors["mobilePhoneConfirmation"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["mobilePhoneConfirmation"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="alternativeMobilePhone"
                  label="Telemóvel alternativo (opcional)"
                  control={methods.control}
                  errors={methods.formState.errors}
                  numberType={true}
                  rules={{
                    validate: {
                      validPhoneNumber: (value) => {
                        if (
                          value &&
                          (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                            parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                        )
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="email"
                  label="E-mail (opcional)"
                  control={methods.control}
                  errors={methods.formState.errors}
                  disabled={true}
                  rules={{
                    validate: {
                      validEmail: (value) => {
                        if (value && !REGEX_PATTERNS.EMAIL.test(value))
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="emailConfirmation"
                  label="Confirmação e-mail (opcional)"
                  control={methods.control}
                  disabled={true}
                  errors={methods.formState.errors}
                  rules={{
                    validate: {
                      validEmail: (value) => {
                        if (value && !REGEX_PATTERNS.EMAIL.test(value))
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                      EmailMatch: (value) => {
                        return (
                          value === methods.getValues("email") ||
                          intl.formatMessage(errorMessages.ErrorMessage_Email_ConfirmationEmail_Not_Match, {
                            emailLabel: intl.formatMessage(
                              labels.Label_Auth_ColectiveRegistrationSteps_UserData_Field_Email
                            ),
                          })
                        );
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  getGeographicDataByProvince: (name, province) => dispatch(getGeographicDataByProvinceAction(name, province)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);
