import React from "react";
//material-ui
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
//material-ui icons
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutline";
//styles
import { makeStyles } from "@material-ui/core/styles";
import componentStylesButtons from "assets/theme/components/button.js";
import GenericButton from "components/Buttons/GenericButton";
//const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    width: "35%",
    height: "35%",
    color: theme.palette.error.main,
    margin: "0 auto",
    display: "block",
  },
  infoMessage: {
    textAlign: "center",
  },
}));

export default function ErrorContent(props) {
  const classes = { ...useStyles(), ...useStylesButtons() };

  return (
    <>
      <ErrorOutlinedIcon className={classes.iconStyle} />
      <Typography variant="h2" component="h2" className={classes.infoMessage}>
        {props.message}
      </Typography>
      <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
        <GenericButton 
          typeSubmit={false}
          onClick={props.onClick}
        >
          Ok
        </GenericButton>
      </Box>
    </>
  );
}
