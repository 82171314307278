import React from "react";
import { useIntl } from "react-intl";

import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CallIcon from "@material-ui/icons/Call";
// import ChatIcon from "@material-ui/icons/Chat";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import componentStyles from "assets/theme/components/landingPage/landing-page.js";
import { titles } from "resources/resources";
import NeedHelpCircle from "../Components/NeedHelpCircle";

function NeedHelp() {
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();
  const intl = useIntl();

  // Use this to program functionality into each of the Icon Buttons
  // Telefónico
  const onTelephoneClick = () => {
    const phone = "tel:+244921154482";
    const anchorTag = document.createElement("a");
    anchorTag.setAttribute("href", phone);
    anchorTag.click();
  };

  // Chat Online
  // const onChatOnlineClick = () => {
  //   console.log("Chat Online pressed");
  // };

  // Whatsapp
  const onWhatsappClick = () => {
    const link =
      "https://wa.me/244921154482?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20seus%20servi%C3%A7os.";
    const anchorTag = document.createElement("a");
    anchorTag.setAttribute("href", link);
    anchorTag.setAttribute("target", "_blank");
    anchorTag.click();
  };

  // Email
  const onEmailClick = () => {
    const mailtoLink = "mailto:contacto@inefop.gov.ao";
    const anchorTag = document.createElement("a");
    anchorTag.setAttribute("href", mailtoLink);
    anchorTag.setAttribute("target", "_blank");
    anchorTag.click();
  };

  return (
    <>
      <Container
        className={
          classes.homepageSectionContainer +
          " " +
          classes.needHelpSectionContainer
        }
      >
        <Typography
          component="h5"
          variant="h5"
          className={classes.needHelpTitle}
        >
          {intl.formatMessage(titles.Title_HomePage_Help)}
        </Typography>

        <Grid container spacing={3} className={classes.needHelpSectionGrid}>
          {/* Telefónico */}
          <Grid item xs={12} sm={4} md={4}>
            <NeedHelpCircle onClick={onTelephoneClick}>
              <CallIcon className={classes.needHelpIcon} />
            </NeedHelpCircle>
            <Typography
              className={classes.needHelpIconText}
              data-testid="telephone-label"
            >
              {intl.formatMessage(titles.Label_HomePage_TelephoneHelp)}
            </Typography>
          </Grid>

          {/* Chat Online - Do not remove comment*/}
          {/* <Grid item xs={12} sm={6} md={3}>
            <NeedHelpCircle
              data-testid="chatonline-button"
              onClick={onChatOnlineClick}
            >
              <ChatIcon className={classes.needHelpIcon} />
            </NeedHelpCircle>
            <Typography 
              className={classes.needHelpIconText} 
              data-testid="chatonline-label"
            > 
              {intl.formatMessage(titles.Label_HomePage_ChatOnlineHelp)}
            </Typography>
          </Grid> */}

          {/* Whatsapp */}
          <Grid item xs={12} sm={4} md={4}>
            <NeedHelpCircle
              data-testid="whatsapp-button"
              onClick={onWhatsappClick}
            >
              <WhatsAppIcon className={classes.needHelpIcon} />
            </NeedHelpCircle>
            <Typography
              className={classes.needHelpIconText}
              data-testid="whatsapp-label"
            >
              {intl.formatMessage(titles.Label_HomePage_WhatsappHelp)}
            </Typography>
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={4} md={4}>
            <NeedHelpCircle data-testid="email-button" onClick={onEmailClick}>
              <MailOutlineIcon className={classes.needHelpIcon} />
            </NeedHelpCircle>
            <Typography
              className={classes.needHelpIconText}
              data-testid="email-label"
            >
              {intl.formatMessage(titles.Label_HomePage_EmailHelp)}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default NeedHelp;
