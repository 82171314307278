import { getSocialMediaLinks } from "crud/socialMedia.crud";

import { SOCIAL_MEDIA_ACTIONS } from "utils/actionsConsts";

export const getSocialMediaLinksAction = () => async (dispatch) => {
  dispatch({
    type: SOCIAL_MEDIA_ACTIONS.SOCIAL_MEDIA_LINKS_LOADING,
    payload: true,
  });
  getSocialMediaLinks()
    .then((res) => {
      dispatch({
        type: SOCIAL_MEDIA_ACTIONS.SOCIAL_MEDIA_LINKS_LOADING,
        payload: false,
      });
      dispatch({
        type: SOCIAL_MEDIA_ACTIONS.SOCIAL_MEDIA_LINKS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: SOCIAL_MEDIA_ACTIONS.SOCIAL_MEDIA_LINKS_LOADING,
        payload: false,
      });
    });
};
