import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import componentStyles from "assets/theme/views/admin/static-page.js";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";
import GenericButton from "components/Buttons/GenericButton";
import { showAuthenticationModalAction } from "redux/actions/auth/authenticationAction";
import {
  loginAction,
  setTargetPathAfterLoginAction,
} from "redux/actions/auth/loginAction";
import { AUTH } from "utils/actionsConsts";
import LoginModal from "components/Modals/Auth/LoginModal";
import RecoverModal from "components/Modals/Auth/RecoverModal";

const useStyles = makeStyles(componentStyles);

function DisplayData(props) {
  const classes = useStyles();
  const history = useHistory();

  const [loginModal, setStateModal] = useState(false);
  const [recoverModal, setRecoverModal] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    recoverPasswordPhone: "",
  });

  const handleLoginModal = () => {
    props.resetLoginForm();
    setRecoverModal(false);
    setStateModal(true);
  };

  const handlerecoverModal = () => {
    setStateModal(false);
    setRecoverModal(true);
  };

  const handleClose = () => {
    setStateModal(false);
    props.setTargetPathAfterLogin(null, null);
  };

  const handleCloseRecover = () => {
    setRecoverModal(false);
  };

  const onChange = (stateName, value) => {
    setState((prevState) => ({
      ...prevState,
      [stateName]: value,
    }));
  };

  const setTargetPath = (path, pathUserType) => {
    props.setTargetPathAfterLogin(path, pathUserType);
  };

  return (
    <div key={props.dataKey}>
      <div className={classes.boxMargin}>
        <span className={classes.title}>{props.data?.title}</span>
      </div>
      {props.data?.content?.map((content, contentKey) => {
        if (content.type) {
          if (content.type === DISPLAY_DATA_CONTENT_TYPE.IMAGE) {
            return (
              <div key={contentKey}>
                <span className={classes.tableTitle}>{content.title}</span>
                <div>
                  <img
                    src={content.path}
                    className={classes.images}
                    alt={content.title}
                  />
                </div>
              </div>
            );
          } else if (content.type === DISPLAY_DATA_CONTENT_TYPE.BUTTON) {
            return content.mustLogIn && !props.authState?.loggedIn ? (
              <div key={contentKey}>
                <GenericButton
                  typeSubmit={false}
                  color="primary"
                  onClick={() => {
                    handleLoginModal();
                    setTargetPath(content.path, content.userType);
                  }}
                >
                  {content.title}
                </GenericButton>
              </div>
            ) : (
              <div key={contentKey}>
                {content.isDocument ? (
                  <GenericButton
                    typeSubmit={false}
                    color="primary"
                    component={Link}
                    to={content.path}
                    target="_blank"
                  >
                    {content.title}
                  </GenericButton>
                ) : (
                  <GenericButton
                    typeSubmit={false}
                    color="primary"
                    onClick={() => {
                      history.push(content.path);
                    }}
                  >
                    {content.title}
                  </GenericButton>
                )}
              </div>
            );
          } else if (content.type === DISPLAY_DATA_CONTENT_TYPE.SUBTITLE) {
            return (
              <div key={contentKey}>
                <span className={classes.subtitle}>{content.subtitle}</span>
              </div>
            );
          } else if (
            content.type.includes(DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT)
          ) {
            return (
              <List dense={true} key={contentKey}>
                <ListItem
                  className={classes.listItem}
                  style={
                    content.type ===
                    DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT
                      ? { marginTop: "0px" }
                      : {}
                  }
                >
                  <div
                    style={{
                      marginLeft:
                        content.type ===
                        DISPLAY_DATA_CONTENT_TYPE.SUB_BULLET_POINT
                          ? "20px"
                          : content.type ===
                            DISPLAY_DATA_CONTENT_TYPE.SUB_SUB_BULLET_POINT
                          ? "40px"
                          : "0px",
                    }}
                  >
                    <span className={classes.content}>
                      <FiberManualRecordIcon /> {content.description}
                    </span>
                  </div>
                </ListItem>
              </List>
            );
          } else {
            return <div></div>;
          }
        } else {
          return (
            <div key={contentKey}>
              <span className={classes.content}>{content}</span>
            </div>
          );
        }
      })}

      <LoginModal
        loginModal={loginModal}
        handleClose={handleClose}
        handlerecoverModal={handlerecoverModal}
        loginAction={props.loginAction}
        onChange={onChange}
        state={state}
      />

      <RecoverModal
        recoverModal={recoverModal}
        handleCloseRecover={handleCloseRecover}
        showAuthenticationModal={props.showAuthenticationModal}
        onChange={onChange}
        state={state}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  loginAction: (email, password) => dispatch(loginAction(email, password)),
  showAuthenticationModal: (toShow, username, action) =>
    dispatch(showAuthenticationModalAction(toShow, username, action)),
  resetLoginForm: () => dispatch({ type: AUTH.RESET_LOGIN_FORM }),
  setTargetPathAfterLogin: (targetPath, pathUserType) =>
    dispatch(setTargetPathAfterLoginAction(targetPath, pathUserType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayData);
