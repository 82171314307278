const componentStyles = (theme) => ({
    componentContainer: {
        position: "relative", 
        border: "1px solid " + theme.palette.primary.mediumBlue,
        height: "600px",
        marginTop: "-30px",
        marginBottom: "-40px"
    },
    selectedPostContainer: {
        marginLeft: "-15px", 
        marginRight: "-15px", 
        height: "100%", 
        width: "100%"
    },
    selectedPostImage: {
        height: 'inherit',
        width: "100%",
        maxWidth: "100%",
        objectFit: "cover",
    },
    selectedPostDetailsContainer: {
        background: theme.palette.orange[200], 
        opacity: 0.90, 
        position: "relative", 
        zIndex: 1, 
        width: "100%", 
        height: "170px",
        marginTop: "-170px", 
        color: theme.palette.white.main, 
        padding: "20px"
    },
    normalPostImage: {
        height: '186px',
        width: "296px",
        padding: "20px 20px 5px 20px"
    },
    normalPostContainer: {
        width: "100%", 
        height: "200px", 
        backgroundColor: theme.palette.transparent.main, 
        cursor: "pointer"
    },
    postTitle: {
        fontWeight: "bold", 
        marginTop: "10px"
    },
    postPublishedOn: {
        color: theme.palette.gray[600],
        textAlign: "right"
    },
    safariFriendlyDoubleLineEllipsis: {
        display: "block",
        lineHeight: "1.5rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: "calc(2 * 25px)",
    },
});

export default componentStyles;
  