import React from "react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { labels, errorMessages } from "resources/resources";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { REGISTRATION_CONSTS, REGEX_PATTERNS } from "utils/const";

export default function UserData({ control, getValues, errors, classes, theme, trigger, ...props }) {
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>Nome do responsável</FormLabel>
            <Controller
              name="contactName"
              control={control}
              component={Box}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["contactName"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["contactName"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["contactName"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>
              {props.intl.formatMessage(labels.Label_Auth_ColectiveRegistrationSteps_UserData_Field_MobilePhone)}
            </FormLabel>
            <Controller
              name="mobilePhone"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  validPhoneNumber: (value) => {
                    if (
                      value &&
                      (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                        parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                    )
                      return props.intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  onChange={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
                    onChange(e);
                    trigger("mobilePhoneConfirmation");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["mobilePhone"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["mobilePhone"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["mobilePhone"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>Confirmação telemóvel</FormLabel>
            <Controller
              name="mobilePhoneConfirmation"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  validPhoneNumber: (value) => {
                    if (
                      value &&
                      (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                        parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                    )
                      return props.intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                    return true;
                  },
                  phoneNumberMatch: (value) => {
                    return (
                      value === getValues("mobilePhone") ||
                      props.intl.formatMessage(
                        errorMessages.ErrorMessage_MobilePhone_ConfirmationMobilePhone_Not_Match,
                        {
                          phoneLabel: props.intl.formatMessage(
                            labels.Label_Auth_ColectiveRegistrationSteps_UserData_Field_MobilePhone
                          ),
                        }
                      )
                    );
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  onChange={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
                    onChange(e);
                    trigger("mobilePhone");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["mobilePhoneConfirmation"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["mobilePhoneConfirmation"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["mobilePhoneConfirmation"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>Telemóvel alternativo (opcional)</FormLabel>
            <Controller
              name="alternativeMobilePhone"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                validate: {
                  validPhoneNumber: (value) => {
                    if (
                      value &&
                      (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                        parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                    )
                      return props.intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  onChange={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
                    onChange(e);
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["alternativeMobilePhone"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["alternativeMobilePhone"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["alternativeMobilePhone"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>
              {props.intl.formatMessage(labels.Label_Auth_ColectiveRegistrationSteps_UserData_Field_Email)}
            </FormLabel>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                validate: {
                  validEmail: (value) => {
                    if (value && !REGEX_PATTERNS.EMAIL.test(value))
                      return props.intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  onChange={(e) => {
                    onChange(e);
                    trigger("emailConfirmation");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["email"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["email"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["email"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>Confirmação e-mail (opcional)</FormLabel>
            <Controller
              name="emailConfirmation"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                validate: {
                  validEmail: (value) => {
                    if (value && !REGEX_PATTERNS.EMAIL.test(value))
                      return props.intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                    return true;
                  },
                  EmailMatch: (value) => {
                    return (
                      value === getValues("email") ||
                      props.intl.formatMessage(errorMessages.ErrorMessage_Email_ConfirmationEmail_Not_Match, {
                        emailLabel: props.intl.formatMessage(
                          labels.Label_Auth_ColectiveRegistrationSteps_UserData_Field_Email
                        ),
                      })
                    );
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  onChange={(e) => {
                    onChange(e);
                    trigger("email");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["emailConfirmation"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["emailConfirmation"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {errors["emailConfirmation"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}
