import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { Container, Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { getHighligtedInformationAction } from "redux/actions/informationDisseminationAction";
import {
  PAGINATION,
  INFORMATION_DISSEMINATION_TYPE_KEYVALUE,
} from "utils/const";
import { ArrayUtils } from "utils/array_utils";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesLandingPage from "assets/theme/components/landingPage/landing-page.js";
import CustomCarousel from "components/Carousel/CarouselIndex";
import LastPostsCard from "../Components/LastPostsCard";
import LastPostsVerticalCaroussel from "../Components/LastPostsVerticalCaroussel";
import CardsListBackdrop from "components/Backdrops/CardsListBackdrop";
import useWindowSize from "hooks/useWindowSize";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";

function LastPosts(props) {
  const useStylesGeneric = makeStyles(componentStylesGeneric);
  const useStylesLandingPage = makeStyles(componentStylesLandingPage);

  const classes = { ...useStylesGeneric(), ...useStylesLandingPage() };
  const windowSize = useWindowSize();
  const history = useHistory();
  const intl = useIntl();

  const [filter] = useState({
    searchText: "",
    informationDisseminationTypeKeyValueList: [
      INFORMATION_DISSEMINATION_TYPE_KEYVALUE.NEWS,
      INFORMATION_DISSEMINATION_TYPE_KEYVALUE.LEGISLATION,
      INFORMATION_DISSEMINATION_TYPE_KEYVALUE.SCHEDULE,
    ],
    isPublic:
      props.authState.loggedIn !== undefined && props.authState.loggedIn
        ? null
        : true, // if user logged in show all, otherwise show only public ones
    published: true,
    isHighlighted: true,
    pageIndex: 1,
    pageSize: PAGINATION.PAGE_SIZE_HIGHLIGHTED_INFORMATION,
  });
  const [informationList, setInformationList] = useState([]);

  useEffect(() => {
    props.getHighligtedInformation(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !ArrayUtils.NullOrEmpty(
        props.informationDisseminationState?.highlightedInformation_result
      )
    ) {
      setInformationList(
        props.informationDisseminationState?.highlightedInformation_result?.data
      );
    } else {
      setInformationList([]);
    }
  }, [props.informationDisseminationState.highlightedInformation_result]);

  return (
    <>
      <Container maxWidth={false} className={classes.homepageSectionContainer}>
        <Typography
          component="h5"
          variant="h5"
          className={classes.homepageSectionMainHeader}
        >
          {intl.formatMessage(labels.Label_HomePage_LastPosts)}
          <GenericButton
            typeSubmit={false}
            color="ghost"
            onClick={() => {
              history.push(
                (props.authState?.loggedIn ? "/admin" : "/auth") +
                  "/informationDissemination"
              );
            }}
            classes={classes.seeMorePostsButton}
          >
            {intl.formatMessage(labels.Label_See_All_F)}
          </GenericButton>
        </Typography>

        {informationList.length > 0 ? (
          <Grid container style={{ position: "relative" }}>
            {windowSize?.width < 1220 ? (
              <>
                <CustomCarousel
                  ComponentCard={LastPostsCard}
                  itemsList={informationList}
                  activateNavigation={true}
                />
                <CardsListBackdrop
                  open={
                    props.informationDisseminationState
                      .highlightedInformation_loading
                  }
                />
              </>
            ) : (
              <LastPostsVerticalCaroussel informationList={informationList} />
            )}
          </Grid>
        ) : (
          <Box classes={{ root: classes.noResultsInfo }}>
            {intl.formatMessage(labels.Label_No_Results_Found)}
          </Box>
        )}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getHighligtedInformation: (data) =>
    dispatch(getHighligtedInformationAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPosts);
