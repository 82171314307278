// Libraries
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { titles } from "resources/resources";

// MUI
import { makeStyles, createTheme, useTheme } from "@material-ui/core/styles";
import { Typography, Grid, ThemeProvider, IconButton, Avatar, OutlinedInput } from "@material-ui/core";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import CallIcon from "@material-ui/icons/Call";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";

// Personalized Components
import componentStyles from "assets/theme/components/footers/footer.js";
import { getSocialMediaLinksAction } from "redux/actions/socialMediaAction.js";
import { postNewsletterAddContactAction } from "redux/actions/newsletterAction.js";
import GenericButton from "components/Buttons/GenericButton";
import { SOCIAL_MEDIA_TYPES_KEYVALUES } from "utils/const";

function Footer(props) {
  // Hooks and library funcions
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const [_, setUserIsAuthenticated] = useState(false);
  const [emailText, setEmailText] = React.useState("");

  const onChangeEmailField = (e) => {
    setEmailText(() => e.target.value);
  };

  const iconTheme = createTheme({
    overrides: {
      MuiIconButton: {
        root: {
          fontSize: "0",
          width: "50px",
          height: "50px",
        },
      },
    },
  });

  const inputTheme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          backgroundColor: theme.palette.white.main,
          borderRadius: "10px",
          height: "40px",
          [theme.breakpoints.down("lg")]: {
            width: "100%",
          },
        },
      },
    },
  });

  // UseEffect
  useEffect(() => {
    if (props.authState?.loggedIn !== undefined && props.authState.loggedIn) setUserIsAuthenticated(true);

    props.getSocialMediaLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const socials = props?.socialMediaState?.socialMediaLinks_result;

  // Functions and objects
  const links = {
    mission: "/institution-mission-vision-values",
    history: "/institution-history",
    organic: "/institution-organic-status",
    termsAndConditions: "/terms-and-conditions",
    privacyPolicies: "/privacy-policy",
    jobWeb: "",
    trainingWeb: "",
    facebook: socials?.find((s) => s.keyValue === SOCIAL_MEDIA_TYPES_KEYVALUES.FACEBOOK)?.defaultLink,
    twitter: socials?.find((s) => s.keyValue === SOCIAL_MEDIA_TYPES_KEYVALUES.TWITTER)?.defaultLink,
    youtube: socials?.find((s) => s.keyValue === SOCIAL_MEDIA_TYPES_KEYVALUES.YOUTUBE)?.defaultLink,
    instagram: socials?.find((s) => s.keyValue === SOCIAL_MEDIA_TYPES_KEYVALUES.INSTAGRAM)?.defaultLink,
  };

  const onClickAux = (link) => {
    const anchorTag = document.createElement("a");
    anchorTag.setAttribute("href", link);
    anchorTag.setAttribute("target", "_blank");
    anchorTag.click();
  };

  // Aux component
  function IconLabel(props) {
    // pros.text
    return (
      <div className={classes.iconLabel}>
        <ThemeProvider theme={iconTheme}>{props.children}</ThemeProvider>
        <div className={classes.labelText}>{props.text}</div>
      </div>
    );
  }

  const onSubmit = () => {
    if (emailText) props.postNewsletterAddContact(emailText);
  };

  // Main component return
  return (
    <>
      <div className={classes.footer}>
        <Grid container className={classes.footerGridContainer}>
          {/* ===== Sede Administrativa ===== */}
          <Grid item xs={12} md={6} lg={3} className={classes.gridItem}>
            <Typography variant="h2" className={classes.sectionTitle}>
              {intl.formatMessage(titles.Title_Footer_SedeAdministrativa)}
            </Typography>
            <IconLabel text={intl.formatMessage(titles.Label_Footer_SedeAdministrativaLocation)}>
              <LocationOnIcon style={{ fontSize: "20px" }} />
            </IconLabel>
            <IconLabel text={intl.formatMessage(titles.Label_Footer_SedeAdministrativaEmail)}>
              <EmailIcon style={{ fontSize: "20px" }} />
            </IconLabel>
            <IconLabel text={intl.formatMessage(titles.Label_Footer_SedeAdministrativaTelephone)}>
              <CallIcon style={{ fontSize: "20px" }} />
            </IconLabel>
          </Grid>
          {/* ===== Âmbito Institucional ===== */}
          <Grid item xs={12} md={6} lg={3} className={classes.gridItem}>
            <Typography variant="h2" className={classes.sectionTitle}>
              {intl.formatMessage(titles.Title_Footer_AmbitoInstitucional)}
            </Typography>
            <Link
              to={(props.authState?.loggedIn ? "/admin" : "/auth") + links.mission}
              className={classes.footerSmallLink}
            >
              {intl.formatMessage(titles.Label_Footer_AmbitoInstitucionalMission)}
            </Link>
            <br />
            <Link
              to={(props.authState?.loggedIn ? "/admin" : "/auth") + links.history}
              className={classes.footerSmallLink}
            >
              {intl.formatMessage(titles.Label_Footer_AmbitoInstitucionalHistory)}
            </Link>
            <br />
            <Link
              to={(props.authState?.loggedIn ? "/admin" : "/auth") + links.organic}
              className={classes.footerSmallLink}
            >
              {intl.formatMessage(titles.Label_Footer_AmbitoInstitucionalOrganic)}
            </Link>
            <br />
            <Typography variant="h6" className={classes.footerSmallTitle}>
              {intl.formatMessage(titles.Label_Footer_AmbitoInstitucionalLegislation)}
            </Typography>
            <Link
              to={(props.authState?.loggedIn ? "/admin" : "/auth") + links.termsAndConditions}
              className={classes.footerSmallLink}
            >
              {intl.formatMessage(titles.Label_Footer_AmbitoInstitucional_TermsAndConditions)}
            </Link>
            <br />
            <Link
              to={(props.authState?.loggedIn ? "/admin" : "/auth") + links.privacyPolicies}
              className={classes.footerSmallLink}
            >
              {intl.formatMessage(titles.Label_Footer_AmbitoInstitucional_PrivacyPolicies)}
            </Link>
          </Grid>
          {/* ===== Serviços ===== */}
          <Grid item xs={12} md={6} lg={3} className={classes.gridItem}>
            <Typography variant="h2" className={classes.sectionTitle}>
              {intl.formatMessage(titles.Title_Footer_Services)}
            </Typography>
            <Link to={links.jobWeb} className={classes.footerSmallLink}>
              {intl.formatMessage(titles.Label_Footer_ServicesJobWeb)}
            </Link>
            <br />
            <Link to={links.trainingWeb} className={classes.footerSmallLink}>
              {intl.formatMessage(titles.Label_Footer_ServicesTrainingWeb)}
            </Link>
            <br />
          </Grid>
          {/* ===== Newsletter ===== */}
          <Grid item xs={12} md={6} lg={3} className={classes.gridItem}>
            <Typography variant="h2" className={classes.sectionTitle}>
              {intl.formatMessage(titles.Title_Footer_Newsletter)}
            </Typography>
            <Typography className={classes.footerSmallText}>
              {intl.formatMessage(titles.Text_Footer_Newsletter)}
            </Typography>
            <br />
            <div className={classes.newsletterForm}>
              <ThemeProvider theme={inputTheme}>
                <OutlinedInput
                  placeholder={intl.formatMessage(titles.Text_Footer_NewsletterField)}
                  onChange={onChangeEmailField}
                />
              </ThemeProvider>
              <GenericButton
                typeSubmit={false}
                color="primary"
                classes={classes.subButton}
                onClick={onSubmit}
                loading={props.newsletterState?.postNewsletterAddContact_loading}
              >
                {intl.formatMessage(titles.Text_Footer_NewsletterButton)}
              </GenericButton>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} lg={6} className={classes.gridItem + " " + classes.bottomGridItem}>
            <img alt="footer-inefop" className={classes.inefopSVG} src="/images/footer/inefop.svg" />
          </Grid>
          <Grid item md={1} lg={3}></Grid>
          <Grid item xs={12} md={12} lg={3} className={classes.gridItem + " " + classes.bottomGridItem}>
            <div>{intl.formatMessage(titles.Text_Footer_FollowSocials)}</div>
            <div className={classes.followSocials}>
              <ThemeProvider theme={iconTheme}>
                <IconButton onClick={() => onClickAux(links.facebook)}>
                  <Avatar className={classes.iconAvatar}>
                    <FacebookIcon />
                  </Avatar>
                </IconButton>
                <IconButton onClick={() => onClickAux(links.twitter)}>
                  <Avatar className={classes.iconAvatar}>
                    <TwitterIcon />
                  </Avatar>
                </IconButton>
                <IconButton onClick={() => onClickAux(links.youtube)}>
                  <Avatar className={classes.iconAvatar}>
                    <YouTubeIcon />
                  </Avatar>
                </IconButton>
                <IconButton onClick={() => onClickAux(links.instagram)}>
                  <Avatar className={classes.iconAvatar}>
                    <InstagramIcon />
                  </Avatar>
                </IconButton>
              </ThemeProvider>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.copyrightLabel}>
        @ Copyright {new Date().getFullYear()} | {intl.formatMessage(titles.Label_Footer_Copyright)}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getSocialMediaLinks: () => dispatch(getSocialMediaLinksAction()),
  postNewsletterAddContact: (email) => dispatch(postNewsletterAddContactAction(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
