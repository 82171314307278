const componentStyles = (theme) => ({
  menu: {
    marginLeft: "100px",
  },
  mainHeader: {
    fontSize: "360%",
    [theme.breakpoints.down("md")]: {
      fontSize: "260%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "200%",
    },
  },
  homepageSectionMainHeader: {
    fontSize: "34px",
    color: theme.palette.primary.main,
    marginBottom: "25px",
    textTransform: "uppercase",
  },
  secondaryHeader: {
    fontWeight: "300",
    color: theme.palette.black.main,
  },
  mainBody: {
    color: theme.palette.white.main,
    fontWeight: "400",
    textAlign: "justify",
  },
  circleIcon: {
    width: "100px",
  },
  svg_icons: {
    width: "35px",
    height: "35px",
    color: theme.palette.info.brightGreen + " !important",
  },
  listItemTextAlign: {
    textAlign: "justify",
  },
  descriptionIcon: {
    transform: "scale(1.6)",
    marginLeft: "10px",
    marginBottom: "4px",
  },
  banner: {
    padding: "20px 60px",
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: theme.palette.white.smoke,
    color: theme.palette.white.smoke,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "20px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px",
    },
  },
  expectedResults: {
    paddingTop: "50px",
    fontSize: "40px",
  },
  expectedResultsBody: {
    fontWeight: "300",
  },
  imgSvg: {
    transform: "scale(0.5)",
    marginLeft: "-100px",
  },
  boxDescription: {
    marginLeft: "120px",
    marginTop: "-100px",
  },
  smallHeader: {
    fontSize: "16px",
    fontWeight: "700",
    marginTop: "15px",
  },
  smallBody: {
    color: theme.palette.gray[400],
    fontWeight: "300",
    fontSize: ".9125rem",
  },
  listItemPadding: {
    padding: "1rem 0rem",
  },
  svgSquare: {
    transform: "scale(.8)",
  },
  gridRight: {
    marginLeft: "100px",
  },
  textInsideSvg: {
    fontSize: "14px",
    fontWeight: "700",
    color: theme.palette.white.main,
    marginTop: "-68px",
    marginLeft: "35px",
    zIndex: "1",
    position: "absolute",
  },

  landingPageSection: {
    padding: "30px 30px 30px 30px",
    paddingBottom: "5px",
    marginLeft: 0,
    marginRight: 0,
  },
  programCardGrid: {
    display: "flex",
  },
  programPrincipalCard: {
    position: "relative",
    height: "250px",
    width: "100%",
    padding: "30px 20px 10px",
    marginTop: "10px",
    backgroundColor: theme.palette.primary.darkBlue,
    borderRadius: "4px",
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
    },
    [theme.breakpoints.down("md")]: {
      height: "220px",
    },
  },
  governmentProgramCard: {
    position: "relative",
    height: "250px",
    width: "100%",
    padding: "30px 20px 10px",
    marginTop: "10px",
    backgroundColor: theme.palette.white.main,
    borderRadius: "4px",
    color: theme.palette.gray[900],
    "&:hover": {
      backgroundColor: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
      transition: "all 0.2s 0.05s ease-in-out",
      "& .MuiSvgIcon-root": {
        color: "inherit",
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "220px",
    },
  },
  boxHeaderPrincipalCard: {
    fontSize: "19px",
    fontWeight: "700",
    color: theme.palette.white.main,
    paddingBottom: "10px",
  },
  boxHeaderSecondaryCard: {
    fontSize: "19px",
    fontWeight: "700",
    color: "inherit",
    paddingBottom: "10px",
  },
  boxBodyPrincipalCard: {
    fontWeight: "300",
    fontSize: "14px",
  },
  seeMoreButton: {
    position: "absolute",
    right: "20px",
    padding: "10px",
    marginBottom: "10px",
    bottom: "0",
  },
  arrowIcon: {
    color: "inherit",
    fontSize: "15px",
    width: "1.5rem!important",
    height: "1.5rem!important",
    fontWeight: "400",
  },
  arrowIconSecondary: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    width: "1.5rem!important",
    height: "1.5rem!important",
    fontWeight: "400",
  },
  submitButton: {
    border: "none",
    borderRadius: "5px",
    color: theme.palette.white.main,
    background: theme.palette.primary.main,

    fontStyle: " normal",
    padding: "5px",
    "&:hover": {
      color: theme.palette.gray[100],
      background: theme.palette.primary.greyBlue,
    },
  },
  homepageSectionContainer: {
    padding: "40px 30px 30px 30px",
    marginLeft: "0px",
    backgroundColor: theme.palette.white.main,
  },
  homepageSectionContainerGrey: {
    backgroundColor: theme.palette.white.smoke,
  },
  needHelpSectionContainer: {
    backgroundColor: theme.palette.blue.dark,
    maxWidth: "100%",
    margin: "0",
  },
  needHelpTitle: {
    color: theme.palette.white.main,
    display: "flex",
    justifyContent: "center",
    fontSize: "34px",
    marginBottom: "25px",
  },
  needHelpSectionGrid: {
    textAlign: "center",
    maxWidth: "100%",
    padding: "0 250px",
    [theme.breakpoints.down("md")]: {
      padding: "0 200px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  needHelpCircle: {
    backgroundColor: "transparent",
    width: "5rem",
    height: "5rem",
  },
  needHelpIcon: {
    color: theme.palette.orange[200],
    "&:hover": {
      color: theme.palette.orange[300],
    },
    fontSize: "4rem",
  },
  needHelpIconText: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.orange[200],
    marginTop: "20px",
  },
  partnershipsGrid: {
    paddingTop: "20px",
    position: "relative",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "100px",
    },
  },
  partnershipsCarousel: {
    margin: "auto",
  },
  noResultsInfo: {
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "20px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.black.main,
    marginLeft: "50px",
    marginBottom: "20px",
  },
  fiveLineDescriptionEllipsis: {
    display: "-webkit-box",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": "5",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  servicesButton: {
    height: "130px",
    width: "100%",
    padding: "20px",
    marginBottom: "10px",
    border: "none",
    borderRadius: "8px",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
    boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.15)",
    "&:hover": {
      backgroundColor: theme.palette.primary.greyBlue,
      color: theme.palette.white.main + "!important",
      "& .MuiSvgIcon-root": {
        color: theme.palette.white.main,
      },
      "& .MuiBox-root": {
        color: theme.palette.white.main,
      },
    },
  },
  servicesButtonBlue: {
    backgroundColor: theme.palette.secondary.lightSkyBlue,
    boxShadow: "0px 4px 4px rgba(25, 121, 181, 0.5)",
  },
  servicesButtonGreen: {
    backgroundColor: theme.palette.secondary.lightSkyBlue,
    boxShadow: "0px 4px 4px rgba(56, 201, 89, 0.5)",
  },
  servicesIcon: {
    "& .MuiSvgIcon-root": {
      width: "34px",
      height: "34px",
    },
  },
  servicesTitle: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    marginTop: "10px",
  },
  servicesHorizontalLine: {
    borderTop: "1px solid " + theme.palette.primary.darkBlue,
    marginBottom: "15px",
  },
  servicesSubtitle: {
    fontSize: "28px", 
    fontWeight: "bold",
    marginLeft: "5px",
    marginBottom: "10px",
    color: theme.palette.primary.darkBlue,
  },
  servicesWelcome: {
    fontFamily: "PoppinsRegular",
    fontSize: "24px",
  },
  servicesDescription: {
    fontFamily: "PoppinsRegular",
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "30px",
    color: theme.palette.primary.darkBlue,
  },
  informationTypeChipRoot: {
    display: "table-cell",
    color: theme.palette.white.main,
    padding: "5px",
    borderRadius: "20px",
    textTransform: "uppercase",
  },
  whoWeAreSectionImage: {
    width: "300px",
    height: "auto",
    margin: "-70px 50px 0px 100px",
    opacity: "0.1",
  },
  centerContent: {
    display: "grid",
    justifyContent: "center",
  },
  structuringProjectSectionDescription: {
    fontSize: "20px",
  },
  structuringProjectCardRoot: {
    height: "100%",
    marginBottom: "0px"
  },
  structuringProjectCardTitleDiv: {
    height: "80px",
    backgroundColor: theme.palette.gray[700],
    color: theme.palette.white.main,
    display: "grid",
    verticalAlign: "middle",
    fontSize: "20px",
  },
  structuringProjectCardDescription: {
    margin: "30px 0px",
    "& .ql-container .ql-editor": {
      color: theme.palette.black.main,
    },
  },
  structuringProjectCardKnowMore: {
    textAlign: "end",
  },
  seeMorePostsButton: {
    marginLeft: "10px",
    marginTop: "5px"
  },
  statisticsSectionMainDiv: {
    zIndex: "2",
    position: "relative",
    textAlign: "center",
    margin: "0px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "0px",
    },
  },
  statisticsSectionTitle: {
    color: theme.palette.white.main,
    fontFamily: "PoppinsRegular",
    textTransform: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  statisticsSectionBackgroundImage: {
    height: "400px",
    width: "100%",
    objectFit: "cover",
    position: "absolute",
    zIndex: "1",
    left: 0,
    marginTop: "-40px",
    [theme.breakpoints.down("xs")]: {
      height: "420px",
    },
  },
  statisticsSectionStatisticsCard: {
    position: "relative",
    zIndex: "2",
    backgroundColor: theme.palette.white.main,
    borderBottom: "3px solid " + theme.palette.orange[400],
    borderRadius: "8px 8px 0px 0px",
    boxShadow: "0px 5px 40px rgba(20, 20, 20, 0.08)",
    width: "900px",
    height: "180px",
    maxWidth: "100%",
    margin: "70px auto",
    textAlign: "center",
  },
  statisticsMetric: {
    fontFamily: "PoppinsBold",
    fontSize: "40px",
  },
  statisticsMetricDescription: {
    textTransform: "uppercase",
    color: theme.palette.gray[600],
    fontSize: "13px",
  },
  indicatorIcon: {
    width: "20px",
    height: "20px",
    zIndex: "3",
  },
  indicatorIconButton: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.gray[200],
    },
  },
});

export default componentStyles;
