import boxShadows from "assets/theme/box-shadow.js";
import hexToRgb from "assets/theme/hex-to-rgb.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
  },
  cardRootTranslucent: {
    backgroundColor: theme.palette.transparent.main,
  },
  cardRootStripped: {
    "& tbody tr:nth-child(odd)": {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.gray[100]) + ", 0.3)",
    },
  },
  cardRootCheckboxColors: {
    "& tbody tr:nth-child(1)": {
      backgroundColor: theme.palette.success.tableLight,
      "&:hover": {
        backgroundColor: theme.palette.success.tableLightHover,
      },
    },
    "& tbody tr:nth-child(3)": {
      backgroundColor: theme.palette.warning.tableLight,
      "&:hover": {
        backgroundColor: theme.palette.warning.tableLightHover,
      },
    },
  },
  cardRootDark: {
    backgroundColor: theme.palette.dark.main,
    "& *": {
      color: theme.palette.white.main,
    },
    "& $tableCellRoot, & $tableRoot": {
      color: theme.palette.white.main,
      borderColor: theme.palette.dark.tableBorder,
    },
    "& $tableCellRootHead": {
      color: theme.palette.dark.tableHeadColor,
      backgroundColor: theme.palette.dark.tableHeadBgColor,
    },
  },
  cardHeader: {
    backgroundColor: "initial",
    color: theme.palette.primary.main,
  },
  cardActionsRoot: {
    paddingBottom: "1.5rem!important",
    paddingTop: "1.5rem!important",
    borderTop: "0!important",
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  tableRoot: {
    marginBottom: "0!important",
  },
  tableCellRoot: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
  },
  tableCellRootHead: {
    backgroundColor: theme.palette.gray[100],
    color: theme.palette.gray[600],
    textAlign: "center",
  },
  tableCellHeadStickyRight: {
    textAlign: "center",
    position: "sticky",
    right: 0,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    boxShadow: "inset 2px 0" + theme.palette.gray[200],
  },
  tableCellRootBodyHead: {
    textTransform: "unset!important",
    fontSize: ".8125rem",
  },
  tableCellBodyStickyRight: {
    position: "sticky",
    right: 0,
    backgroundColor: theme.palette.white.main,
    textAlign: "center",
    boxShadow: "inset 2px 0" + theme.palette.gray[200],
  },
  linearProgressRoot: {
    height: "3px!important",
    width: "120px!important",
    margin: "0!important",
  },
  bgGradientError: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      ", " + theme.palette.primary.main + ")!important",
  },
  bgGradientSuccess: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      ", " + theme.palette.primary.mediumBlue + " )!important",
  },
  bgGradientPrimary: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      ", " + theme.palette.primary.greyBlue + ")!important",
  },
  bgGradientInfo: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      ", " + theme.palette.primary.mediumBlue + " )!important",
  },
  bgGradientWarning: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      "," + theme.palette.primary.mediumBlue + ")!important",
  },
  bgPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  bgPrimaryLight: {
    backgroundColor: theme.palette.primary.light,
  },
  bgError: {
    backgroundColor: theme.palette.error.main,
  },
  bgErrorLight: {
    backgroundColor: theme.palette.error.light,
  },
  bgWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  bgWarningLight: {
    backgroundColor: theme.palette.warning.light,
  },
  bgInfo: {
    backgroundColor: theme.palette.info.main,
  },
  bgInfoLight: {
    backgroundColor: theme.palette.info.light,
  },
  bgSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  verticalAlignMiddle: {
    verticalAlign: "middle",
  },
  avatarRoot: {
    width: "36px",
    height: "36px",
    fontSize: ".875rem",
  },
  anchorStyles: {
    fontWeight: "600",
  },
  buttonRootWhite: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.dark.main,
    },
  },
  pendingIcon: {
    color: theme.palette.warning.main,
    marginBottom: "2px",
    marginRight: "5px",
  },
  tableButtons: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },

  tableButtonsDisable: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.info.grey,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  programsTableCell: {
    background: theme.palette.gray[200],
    border: "1px solid " + theme.palette.gray[300] + "!important",
    boxSizing: "border-box",
    boxShadow: "0px 3px 2px rgba(233, 236, 239, 0.05)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.875rem",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[600],
    textTransform: "none",
  },
  programsTableBodyCell: {
    border: "1px solid " + theme.palette.gray[300] + "!important",
    boxSizing: "border-box",
    boxShadow: "0px 3px 2px rgba(233, 236, 239, 0.05)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.875rem",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[700],
    textTransform: "none",
  },
  rowEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth:"200px"
  },
});

export default componentStyles;
