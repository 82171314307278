import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "122px",
      paddingRight: "122px",
    },
  },
  navigateBefore: {
    color: theme.palette.primary.main,
    width: "30px",
    height: "auto",
    marginTop: "5px",
  },
  navigateBeforeBox: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "34px",
    width: "43px",
    height: "43px",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover, &:focus": {
      boxShadow: "0px 0px 10px orange !important",
    },
  },
  titleGrid: {
    display: "flex"
  },
  ellipse: {
    marginLeft: "120px",
    marginTop: "-2px",
    width: "38px",
    height: "38px"
  },
  vector: {
    marginTop: "0px",
    marginLeft: "-23px",
    width: "7px",
    height: "14px"
  },
  pageTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginLeft:"22px",
    textTransform: "uppercase"
  },
  alignRight: {
    textAlign: "right"
  },
  mb0: {
    marginBottom: "0!important",
  },
  containerPadding: {
    marginBottom: "37px",
    paddingRight: "1.5rem",
  },
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },
  monetaryFields: {
    textAlign: "end"
  }
});

export default componentStyles;
