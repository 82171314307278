const componentStyles = (theme) => ({
  appBarRoot: {
    height: "110px",
    borderBottom: "3px solid " + theme.palette.orange[200],
    backgroundColor: theme.palette.white.main,
    zIndex: "1299",
    boxShadow: "0px -5px 15px black",
    position: "fixed",
  },
  containerRoot: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  logo: {
    width: "150px",
    height: "auto",
    marginRight: "1rem",
    background: "url(Logo.png)",
    filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12))",
    verticalAlign: "middle",
    borderStyle: "none",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "10px",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "-19px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "-19px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-15px",
    },
  },
  listItemRoot: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    textTransform: "uppercase",
    lineHeight: "19.07px",
    paddingLeft: "0.5rem",
    paddingRight: "0.25rem",
    paddingTop: ".625rem",
    paddingBottom: ".625rem",
    transition: "all .15s linear",
    color: theme.palette.gray[900],
    margin: "0",
    "& i": {
      marginRight: "0.25rem",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "1.5rem",
      paddingLeft: "0",
      paddingRight: "0.5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      justifyContent: "center",
      "&:hover": {
        fontWeight: "900",
        color: theme.palette.primary.greyBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.greyBlue,
        },
      },
      "&:active": {
        color: theme.palette.primary.darkBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.darkBlue,
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      justifyContent: "center",
      "&:hover": {
        fontWeight: "900",
        color: theme.palette.primary.greyBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.greyBlue,
        },
      },
      "&:active": {
        color: theme.palette.primary.darkBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.darkBlue,
        },
      },
    },
  },
  dropIcon: {
    minWidth: "0px",
  },
  dropItems: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "220%",
    color: theme.palette.gray[900],
    "&:hover": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.greyBlue,
      fontWeight: "900",
    },
    "&:active": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.darkBlue,
      fontWeight: "900",
    },
  },
  recoverButton: {
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    marginTop: "30px",
    color: theme.palette.white.main,
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      "," +
      theme.palette.primary.mediumBlue +
      ")",
  },
  sentRecoverButton: {
    color: theme.palette.white.main,
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      "," +
      theme.palette.primary.mediumBlue +
      ")",
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    alignItems: "center",
  },
  modal: {
    height: "535px",
    width: "512px",
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  modalSentRecover: {
    height: "350px",
    width: "512px",
    position: "absolute",
  },
  logoInfo: {
    position: "absolute",
    right: "46%",
    top: "14.33%",
    bottom: "57.64%",

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "60px",
    lineHeight: "82px",
    /* identical to box height */
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.secondary.skyBlue,
  },
  elipse: {
    width: "82px",
    height: "82px",
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "14.33%",
    bottom: "57.64%",
    borderRadius: "150px",
    border: "4px solid " + theme.palette.secondary.skyBlue,
    boxSizing: "border-box",
  },
  modalClose: {
    marginLeft: "90%",
  },
  modalOkButton: {
    color: theme.palette.white.main,
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      "," +
      theme.palette.primary.mediumBlue +
      ")",
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    top: "30px",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    border: "0px",
  },
  modalHeader: {
    top: "50px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    alignItems: "center",
    textAlign: "left",
    color: theme.palette.primary.darkBlue,
  },
  modalRecoverHeader1: {
    maxWidth: "400px",
    fontStyle: "normal",
    lineHeight: "19px",
    fontSize: "14px",
    fontWeight: "normal",
    textAlign: "center",
    marginBottom: "2.05rem",
    marginTop: "2rem",
    marginLeft: "30px",
    color: theme.palette.primary.darkBlue,
  },
  modalRecoverHeader2: {
    maxWidth: "400px",
    marginLeft: "30px",
    fontStyle: "normal",
    lineHeight: "19px",
    fontSize: "14px",
    fontWeight: "normal",
    textAlign: "center",
    marginBottom: "2.05rem",
    marginTop: "-20px",
    color: theme.palette.primary.darkBlue,
  },
  recoverFormControl: {
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "19px",
    variant: "filled",
    width: "100%",
    marginBottom: "1rem!important",
    fontSize: "1rem",
    color: theme.palette.dark.main,
    marginTop: "45px",
  },
  modalRecoverSentHeader: {
    fontStyle: "normal",
    lineHeight: "33px",
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "1.5rem",
    marginTop: "5rem",
    color: theme.palette.dark.main,
  },
  registerErrorResultModalIcon: {
    marginRight: "8px",
    color: theme.palette.error.main,
    width: "88px",
    height: "88px",
    /* align-self: center; */
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "14.33%",
    bottom: "57.64%",
    boxSizing: "border-box",
  },
  modalRegisterResultHeader: {
    fontStyle: "normal",
    lineHeight: "33px",
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "1.5rem",
    marginTop: "5rem",
    color: theme.palette.dark.main,
  },
  boxHeader: {
    color: theme.palette.primary.greyBlue,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "19px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  loginHeader: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "left",
    color: theme.palette.primary.darkBlue,
  },
  authenticationLabels: {
    color: theme.palette.gray[900],
  },
  loginRecover: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: theme.palette.primary.darkBlue,
    textDecorationLine: "underline",
    marginTop: "500px",
    "&:hover": {
      color: theme.palette.primary.darkBlue,
      fontWeight: "900",
      textDecorationLine: "underline",
    },
  },
  registerButton: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: theme.palette.primary.darkBlue,
    textDecorationLine: "underline",
    marginTop: "500px",
    "&:hover": {
      color: theme.palette.primary.darkBlue,
      fontWeight: "900",
      textDecorationLine: "underline",
    },
  },
  invalidLogin: {
    marginTop: "-20px",
  },
  buttonProgress: {
    color: theme.palette.white.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  expandIcon: {
    marginLeft: "0.5rem",
    width: "20px",
    height: "20px",
  },
  submenuGroup: {
    position: "absolute",
    top: "0px",
    left: "233px"
  },
  navbarDiv: {
    marginLeft: "20px", 
    marginTop: "-4px"
  }
});

export default componentStyles;
