import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function TrainingCentersLicensing() {
  const intl = useIntl();

  const trainingCentersLicensingData = [
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_1_BulletPoint_9
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_Description_2
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BUTTON,
          isDocument: true,
          path: "/Licenciamento_Centros_Formação_Angola.pdf",
          title: intl.formatMessage(
            labels.Label_Menu_Information_ProfessionalTraining_TrainingCentersLicensing_NecessaryDocuments_DownloadDocument
          ),
        },
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(
        titles.Title_Menu_Information_ProfessionalTraining_TrainingCentersLicensing
      )}
      data={trainingCentersLicensingData}
    />
  );
}

export default TrainingCentersLicensing;
