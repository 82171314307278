const componentStyles = (theme) => ({
  containerSwiper: {
    padding: "50px 60px 0px 60px",
    marginLeft: "16px",
    "--swiper-navigation-size": "25px",

    '& .swiper-button-next': {
      color: theme.palette.primary.greyBlue,
      border: "1px solid " + theme.palette.primary.greyBlue,
      borderRadius: "20px",
      padding: "20px",
      right: 0,
      "&:hover": {
        backgroundColor: theme.palette.primary.greyBlue,
        color: theme.palette.white.main,
        borderColor: theme.palette.primary.greyBlue,
      },
      "&:after": {
        fontSize: "12px",
        fontWeight: "bold",
        marginLeft: "2px"
      },
    },
    '& .swiper-button-prev': {
      color: theme.palette.primary.greyBlue,
      border: "1px solid " + theme.palette.primary.greyBlue,
      borderRadius: "20px",
      padding: "20px",
      left: "0",
      "&:hover": {
        backgroundColor: theme.palette.primary.greyBlue,
        color: theme.palette.white.main,
        borderColor: theme.palette.primary.greyBlue,
      },
      "&:after": {
        fontSize: "12px",
        fontWeight: "bold",
        marginRight: "2px"
      },
    }
  },
  containerSwiperSlide: {
    height: "auto",
    marginBottom: '20px',
  }
});

export default componentStyles;
