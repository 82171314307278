import boxShadows from "assets/theme/box-shadow.js";
const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    backgroundColor: theme.palette.white.main,
  },
  cardContent: {
    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },
  },
  buttonImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  buttonRoot: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    borderColor: theme.palette.white.main + "!important",
    "&:hover": {
      color: theme.palette.gray[900],
      borderColor: theme.palette.white.main + "!important",
      backgroundColor: theme.palette.white.main,
    },
  },
  editButton: {
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    width: "50%",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  formControlLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
  },
  formControlLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: theme.palette.gray[600],
  },
  footerLinks: {
    color: theme.palette.gray[400],
    textDecoration: "none",
    fontSize: "80%",
  },
  gridButtonsBackSaveForth: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  boxButtonRegister: {
    textAlign: "right",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: "1.5rem",
      marginBottom: "1rem",
    },
  },
  buttonRegisterRoot: {
    background: "linear-gradient(90deg, " + theme.palette.primary.greyBlue + " 0%, " + theme.palette.primary.mediumBlue + " 100%)",
    borderRadius: "4px",
  },
  boxButtonSave: {
    textAlign: "center",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
  },
  buttonAddOptionRoot: {
    background: "linear-gradient(90deg, " + theme.palette.primary.greyBlue + " 0%, " + theme.palette.primary.mediumBlue + " 100%)",
    borderRadius: "4px",
    marginLeft: "1.5rem",
  },
  boxButtonBack: {
    textAlign: "left",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: "1rem",
      marginBottom: "1.5rem",
    },
  },
  buttonBackRoot: {
    background: theme.palette.white.main,
    /* Default */
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.white.main,
      /* Default */
      boxShadow:
        "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
      borderRadius: "4px",
      color: theme.palette.primary.main,
    },
  },
  toggleButtonRoot: {
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.primary.greyBlue + " !important",
      /* Color Border/Secondary */

      border: "1px solid " + theme.palette.white.light,
      boxSizing: "border-box",
      borderRadius: "4px",

      
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      /* identical to box height */

      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.02em",
      textTransform: "uppercase",

      /* Text Color/Dark Blue */

      color: theme.palette.white.main,
    },
    backgroundColor: theme.palette.white.light,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px 0px 0px 4px",

    
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    /* identical to box height */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    textTransform: "uppercase",

    /* Text Color/Dark Blue */

    color: theme.palette.primary.greyBlue,
  },
  monetaryFields: {
    textAlign: "end",
  },
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },

  selectBorderWarningInputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.warning.main + "!important",
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  sliderBadge: {
    backgroundColor: theme.palette.gray[700],
    fontWeight: "400",
    fontSize: "12px",
  },
  documentBadgeContainer: {
    paddingLeft: "15px",
  },
  deleteButtonIconRoot: {
    color: theme.palette.primary.main,
    width: "20px",
    height: "20px",
  },
  deleteButtonRoot: {
    padding: 0,
  },
  documentNameRoot: {
    paddingRight: "10px",
  },
  detailLabels: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  documentBadge: {
    backgroundColor: theme.palette.white.main,
    marginRight: "10px",
    marginBottom: "10px",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    borderRadius: "4px",
    border: "1px solid " + theme.palette.primary.main,
    padding: ".4em .3em .3em .85em",
    alignItems: "center",
    wordBreak: "break-all"
  },
  sliderRoot: {
    color: theme.palette.primary.main + "!important",
  },
  sliderTooltip: {
    border: "1px solid " + theme.palette.gray[300] + "!important",
    color: theme.palette.gray[700] + "!important",
    backgroundColor: theme.palette.white.main,
    fontSize: "12px",
    fontWeight: "700",
  },
  alertIcon: {
    padding: "12px 0",
  },
  alertFilledWarning: {
    //backgroundColor: theme.palette.warning.light,
    color: theme.palette.white.main,
    
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "19px",
    alignItems: "center",
  },
  buttonProgress: {
    color: theme.palette.white.main, 
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textAlignEnd: {
    textAlign: "end",
  },
  paddingTop2px: {
    padding: "2px 0",
  },
  alertMessage: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
  },
  cardButton: {
    background:
      "linear-gradient(90deg, " +
      theme.palette.info.main +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.02em",
    color: theme.palette.white.main,
    marginTop: "10px",
    width: "200px",
    "&:hover": {
      color: theme.palette.gray[200],
    },
  },
  dropzone: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "1px",
    borderRadius: "1px",
    height: "62px!important",
    borderColor: theme.palette.gray[600],
    borderStyle: "dashed",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray[500],
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  docsContainer: {
    width: "100%"
  },
  subprogramDescriptionContainerBox: {
    background: theme.palette.gray[200],
    border: "1px solid " + theme.palette.gray[300],
    boxSizing: "border-box",
    boxShadow: "0px 3px 2px rgb(233 236 239 / 5%)",
    borderRadius: "4px",
    padding: "20px 25px 39px 25px",
  },
  subprogramDescriptionGrid: {
    paddingLeft: "15%",
    paddingRight: "15%",
  },
  subprogramDescriptionBox: {
    
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.gray[700],
  },
  documentsAreaDescription: {
    fontWeight: "300",
    fontSize: "14px",
    lineHeight: "23px",
    color: theme.palette.gray[700],
  },
  confirmationFieldsBox: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  applicationHeader: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "34px",
    color: theme.palette.primary.main,
    paddingBottom: "30px",
    marginLeft: "25px"
  },
  tableButtons: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    marginTop: "10px"
  },
  pendingIcon: {
    color: theme.palette.warning.main,
    marginBottom: "2px",
    marginRight: "5px",
  },
});

export default componentStyles;
