import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function Overview() {
  const intl = useIntl();

  const overviewData = [
    {
      title: "",
      content: [
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_1) + "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_2) + "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_3) + "\n\n",
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_9
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_10
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_11
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_12
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_Overview_INEFOP_Description_4_BulletPoint_13
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_InstitutionalInformation_Overview_Contacts),
      content: [
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_Overview_Contacts_PhoneNumber),
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_Overview_Contacts_Email),
        intl.formatMessage(labels.Label_Menu_InstitutionalInformation_Overview_Contacts_Adress),
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(titles.Title_Menu_InstitutionalInformation_Overview)}
      data={overviewData}
    />
  );
}

export default Overview;
