import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import componentStyles from "assets/theme/components/landingPage/landing-page.js";
import { USER_TYPE } from "utils/const";

const useStyles = makeStyles(componentStyles);

function ServiceButton(props) {
  const classes = { ...useStyles() };
  
  return (
    <button 
      className={
        classes.servicesButton 
        + " " + 
        (props.isBeforeLogin 
          && (props.userType === USER_TYPE.INDIVIDUAL 
              ? classes.servicesButtonBlue 
              : classes.servicesButtonGreen)
        )
      }
      onClick={props.handleOnClick ?? null}
      data-testid={"services-" + props.id}
    >
      <div style={{marginBottom: "30px"}}>
        <Box className={classes.servicesIcon}>
          {props.icon}
        </Box>
        <Box className={classes.servicesTitle}>
          {props.title}
        </Box>
      </div>  
    </button>
  );
}
const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ServiceButton);
