import { React } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
//MaterialUI
import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";

import limitaString from "utils/string_utils";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import Tooltip from "@material-ui/core/Tooltip";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import { OPPORTUNITY_CANDIDATE_STATE_KEY, TABLE_STATE_CHIP_COLOR } from "utils/const";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityApplicationsResultSection(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();

  const tableHead = ["Nº Candidatura", "Nome", "Nº BI", "Nº Oferta", "Tipo de Oferta", "Data de Candidatura", "Estado"];

  const mapOpportunityCandidateStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_STATE_KEY.APROVADA:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.REJEITADA:
        return TABLE_STATE_CHIP_COLOR.RED;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.EMRECRUTAMENTO:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      default:
        return <></>;
    }
  };

  return (
    <>
      {props.opportunitiesState?.userColectiveOpportunityApplications_result?.data?.length > 0 && (
        <CardTableContainer
          title={"Lista de Candidaturas a Emprego e Estágio Profissional"}
          count={props.opportunitiesState?.userColectiveOpportunityApplications_total_records}
        >
          <TableContainer>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                  <TableCell
                    key="detailsCol"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootHead +
                        " " +
                        classes.tableCellHeadStickyRight,
                    }}
                  >
                    Detalhes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.opportunitiesState.userColectiveOpportunityApplications_result.data.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }} title={prop.title ?? ""}>
                      <Box>{prop.candidateCode}</Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.userName}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.userIdentityCard}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.opportunityReference}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }} title={prop.title ?? ""}>
                      <Box fontWeight={"bold"}>{prop.opportunityTypeDescription}</Box>
                      <Box>{limitaString(prop.opportunityTitle, 60)}</Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box>{prop.creationDate}</Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <TableStateChip
                        state={mapOpportunityCandidateStateToTableChipColors(prop.candidateStateKey)}
                        message={prop.candidateStateDescription}
                      />
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                      <Tooltip title="Detalhes">
                        <IconButton
                          aria-label="details"
                          component={Link}
                          to={`/admin/candidate-details/${prop.candidateCode}`}
                        >
                          <Box
                            component={ArrowForwardIosIcon}
                            width="12px!important"
                            height="12px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
            <TableBackdrop open={props.opportunitiesState?.getUserColectiveOpportunityApplications_loading} />
          </TableContainer>

          <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end"></Box>

          <Pagination
            className={classes.alignPagination}
            count={props.opportunitiesState?.userColectiveOpportunityApplications_total_pages}
            page={props.page}
            onChange={props.handleChangePage}
            color="primary"
          ></Pagination>
        </CardTableContainer>
      )}
      {props.opportunitiesState?.userColectiveOpportunityApplications_result?.data?.length === 0 && (
        <Box classes={{ root: classes.noResultsInfo }}>Não foram encontrados resultados para esta pesquisa.</Box>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityApplicationsResultSection);
