const componentStyles = (theme) => ({
  labelIcon: {
    fontSize: "20px",
  },
  footerGridContainer: {
    margin: "0",
  },
  footerSmallLink: {
    fontSize: "10px",
    color: theme.palette.grey[400],
    "&:hover": {
      color: theme.palette.grey[300],
    },
  },
  footerSmallText: {
    fontSize: "10px",
    color: theme.palette.grey[400],
  },
  footerSmallTitle: {
    color: theme.palette.white.main,
    marginTop: "10px",
    fontSize: "12px",
    marginDown: "0",
  },
  footer: {
    background: theme.palette.grey[800],
    color: theme.palette.white.main,
    position: "relative",
    width: "100%",
    left: "0%",
    right: "0%",
    bottom: "0%",
    padding: "40px 130px 20px 130px",
    [theme.breakpoints.down("md")]: {
      padding: "40px 65px 10px 65px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  sectionTitle: {
    color: theme.palette.white.main,
  },
  gridItem: {
    marginTop: "40px",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  bottomGridItem: {
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "20px auto",
    },
  },
  iconLabel: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",

    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  subButton: {
    color: theme.palette.white.main,
    border: "none",
    marginTop: "10px",
    height: "40px",
    backgroundColor: theme.palette.blue.dark,
    [theme.breakpoints.up("xl")]: {
      marginLeft: "5px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%"
    },
  },
  inefopSVG: {
    width: "370px",
    [theme.breakpoints.down("sm")]: {
      width: "250px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  newsletterForm: {
    width: "200px",
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      width: "330px",
      flexDirection: "row",
      alignItems: "baseline",
    },
    [theme.breakpoints.down("lg")]: {
      width: "290px",
      flexDirection: "column",
    },
    [theme.breakpoints.down("md")]: {
      width: "300px",
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "100%"
    },
  },
  followSocials: {
    width: "300px",
    paddingLeft: "0",
    [theme.breakpoints.up("md")]: {
      margin: "0 auto",
      marginLeft: "-9px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      width: "100%",
    },
  },
  copyrightLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.black.main,
    height: "35px",
    color: theme.palette.white.main,
    fontSize: "11px",
  },
  labelText: {
    fontSize: "12px",
    marginLeft: "10px",
    letterSpacing: "0.2px",
  },
  iconAvatar: {
    backgroundColor: theme.palette.white.main,
    width: "35px",
    height: "35px",
    color: theme.palette.grey[800],
  },
});

export default componentStyles;
