import { React, Fragment, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { usePasswordValidation } from "hooks/usePasswordValidation";
import { errorMessages, messages } from "resources/resources";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { green, red } from "@material-ui/core/colors";

//@material-ui/icons components
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

function Password({
  control,
  getValues,
  errors,
  trigger,
  classes,
  theme,
  ...props
}) {
  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });
  const [passwordValidation] = usePasswordValidation({
    firstPassword: getValues("password"),
    secondPassword: getValues("passwordConfirmation"),
  });

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  useEffect(() => {
    trigger("password");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordValidation?.isValid]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>Palavra-passe</FormLabel>
            <Controller
              name="password"
              control={control}
              component={Box}
              defaultValue=""
              value={getValues("password")}
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(
                    errorMessages.ErrorMessage_FieldRequired
                  ),
                },
                validate: {
                  validPassword: () => {
                    if (!passwordValidation.isValid)
                      return props.intl.formatMessage(
                        errorMessages.ErrorMessage_Password_Not_Valid
                      );
                    return true;
                  },
                  passwordMatch: (value) => {
                    if (
                      getValues("passwordConfirmation") !== undefined &&
                      getValues("passwordConfirmation") !== null &&
                      getValues("passwordConfirmation") !== ""
                      
                    )
                      return (
                        value === getValues("passwordConfirmation") ||
                        "O valor inserido deve ser igual ao campo 'Confirmação da palavra-passe'"||
                        trigger("passwordConfirmation")
                      );
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="password"
                  onChange={(e) => {
                    setFirst(e);
                    onChange(e);
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: errors["password"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["password"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {errors["password"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>Confirmação da palavra-passe</FormLabel>
            <Controller
              name="passwordConfirmation"
              defaultValue=""
              value={getValues("passwordConfirmation")}
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(
                    errorMessages.ErrorMessage_FieldRequired
                  ),
                },
                validate: {
                  PasswordMatch: (value) => {
                    return (
                      value === getValues("password") ||
                      "O valor inserido deve ser igual ao campo 'Palavra-passe'" ||
                      trigger("password")
                    );
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="password"
                  onChange={(e) => {
                    onChange(e);
                    setSecond(e);
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]:
                        errors["passwordConfirmation"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["passwordConfirmation"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {errors["passwordConfirmation"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormLabel>
              {props.intl.formatMessage(messages.Message_Auth_Password_Rules_1)}
            </FormLabel>
            <List dense={true}>
              <ListItem>
                <Fragment>
                  {passwordValidation.validLength ? (
                    <CheckCircleOutlinedIcon
                      style={{ marginRight: 8, color: green[500] }}
                    />
                  ) : (
                    <CancelOutlinedIcon
                      style={{ marginRight: 8, color: red[500] }}
                    />
                  )}
                  {props.intl.formatMessage(
                    messages.Message_Auth_Password_Rules_2
                  )}
                </Fragment>
              </ListItem>
              <ListItem>
                <Fragment>
                  {passwordValidation.upperCase ? (
                    <CheckCircleOutlinedIcon
                      style={{ marginRight: 8, color: green[500] }}
                    />
                  ) : (
                    <CancelOutlinedIcon
                      style={{ marginRight: 8, color: red[500] }}
                    />
                  )}
                  {props.intl.formatMessage(
                    messages.Message_Auth_Password_Rules_3
                  )}
                </Fragment>
              </ListItem>
              <ListItem>
                <Fragment>
                  {passwordValidation.lowerCase ? (
                    <CheckCircleOutlinedIcon
                      style={{ marginRight: 8, color: green[500] }}
                    />
                  ) : (
                    <CancelOutlinedIcon
                      style={{ marginRight: 8, color: red[500] }}
                    />
                  )}
                  {props.intl.formatMessage(
                    messages.Message_Auth_Password_Rules_4
                  )}
                </Fragment>
              </ListItem>
              <ListItem>
                <Fragment>
                  {passwordValidation.hasNumber ? (
                    <CheckCircleOutlinedIcon
                      style={{ marginRight: 8, color: green[500] }}
                    />
                  ) : (
                    <CancelOutlinedIcon
                      style={{ marginRight: 8, color: red[500] }}
                    />
                  )}
                  {props.intl.formatMessage(
                    messages.Message_Auth_Password_Rules_5
                  )}
                </Fragment>
              </ListItem>
              <ListItem>
                <Fragment>
                  {passwordValidation.specialChar ? (
                    <CheckCircleOutlinedIcon
                      style={{ marginRight: 8, color: green[500] }}
                    />
                  ) : (
                    <CancelOutlinedIcon
                      style={{ marginRight: 8, color: red[500] }}
                    />
                  )}
                  {props.intl.formatMessage(
                    messages.Message_Auth_Password_Rules_6
                  )}
                </Fragment>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12} md={12}>
          <FormGroup>
            <Controller
              name="agreeTerms"
              control={control}
              component={Box}
              defaultValue={false}
              rules={{
                required: {
                  value: true,
                  message: props.intl.formatMessage(
                    errorMessages.ErrorMessage_FieldRequired
                  ),
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <FormControlLabel
                  name={name}
                  onChange={onChange}
                  value="end"
                  control={<Checkbox color="primary" checked={value} />}
                  label={
                    <span>
                      Eu concordo com os&nbsp;
                      <a href="/TermosCondicoes_INEFOP.pdf" target="_blank">
                        termos e condições deste portal
                      </a>
                    </span>
                  }
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.formControlLabelLabel,
                  }}
                />
              )}
            />
            {errors["agreeTerms"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {errors["agreeTerms"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(Password);
