import { changePassword } from "crud/auth.crud";
import { DIALOG } from "utils/actionsConsts";

const changePasswordAction = (formData, _onCloseAction) => async (dispatch) => {
  changePassword(formData)
    .then((res) => {
      if (res.data.succeeded) {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: true,
              onCloseAction: res.data ? _onCloseAction : undefined,
              okAction: res.data ? _onCloseAction : undefined,
              message: "Palavra-passe alterada com sucesso",
            },
          },
        });
      } else {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: "A Palavra-passe actual está errada",
            },
          },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response.data.errors
              ? error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export default changePasswordAction;
