import React from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { showAuthenticationModalAction } from "redux/actions/auth/authenticationAction";
import { setTargetPathAfterLoginAction } from "redux/actions/auth/loginAction";
import { hideRegistrationModalAction } from "redux/actions/registerAction";
import { getSSOLoginUrl } from "utils/auth";
// core components
import ResetPassword from "components/Modals/Auth/ResetPassword";
import GenericDialog from "components/Dialogs/GenericDialog";
import AuthenticationModal from "components/Modals/Auth/AuthenticationModal";
import RegisterDialog from "views/auth/Dialogs/RegisterDialog";
import routes from "routes.js";

import componentStyles from "assets/theme/layouts/auth.js";

const useStyles = makeStyles(componentStyles);

const SSO = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const mainContent = React.useRef(null);
  const history = useHistory();

  React.useEffect(() => {
    document.body.classList.add(classes.bgDefault);
    return () => {
      document.body.classList.remove(classes.bgDefault);
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const getShowRegistrationResultModal = () => {
    return props.authState.showRegistrationResultModal !== undefined
      ? props.authState.showRegistrationResultModal
      : false;
  };
  const handleRegistrationResultClose = () => {
    props.hideRegistrationModal();
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/sso") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>

        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/sso" />
        </Switch>

        {/* Registration Result Modal Start*/}
        <RegisterDialog
          open={getShowRegistrationResultModal()}
          onClose={handleRegistrationResultClose}
          onClick={function () {
            handleRegistrationResultClose();
            history.push(getSSOLoginUrl(props.ssoState.token, props.ssoState.redirectURL));
          }}
        />
        {/* Authentication dialog START*/}
        <AuthenticationModal />
        {/* Authentication dialog END*/}
        {/* Reset Password dialog START*/}
        <ResetPassword sso={true} />
        {/* Reset Password dialog END*/}
        {/* Generic dialog START*/}
        <GenericDialog />
        {/* Generic dialog END*/}
      </div>
    </>
  );

};

// export default Auth;
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  showAuthenticationModal: (toShow, username, action) =>
    dispatch(showAuthenticationModalAction(toShow, username, action)),
  hideRegistrationModal: () => dispatch(hideRegistrationModalAction()),
  setTargetPathAfterLogin: (targetPath, pathUserType) => dispatch(setTargetPathAfterLoginAction(targetPath, pathUserType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SSO);
