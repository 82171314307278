import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
// @material-ui/icons components
import MenuIcon from "@material-ui/icons/Menu";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { errorMessages, labels, messages, titles } from "resources/resources";
import CloseIcon from "@material-ui/icons/Close";

import { AUTH } from "utils/actionsConsts";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

import RegisterDialog from "views/auth/Dialogs/RegisterDialog";
import LoginModal from "components/Modals/Auth/LoginModal";
import RecoverModal from "components/Modals/Auth/RecoverModal";
import Dialog from "@material-ui/core/Dialog";

import AuthenticationModal from "components/Modals/Auth/AuthenticationModal";
import { hideRegistrationModalAction } from "redux/actions/registerAction";
import { recoverPasswordAction, hideRecoverPasswordModalAction } from "redux/actions/auth/recoverPasswordAction";
import { showAuthenticationModalAction } from "redux/actions/auth/authenticationAction";

import ResetPassword from "components/Modals/Auth/ResetPassword";
import GenericDialog from "components/Dialogs/GenericDialog";
import { USER_TYPE } from "utils/const";

import { loginAction, setTargetPathAfterLoginAction } from "redux/actions/auth/loginAction";

// core components
import { INFORMATION_DISSEMINATION_TYPE_KEYVALUE } from "utils/const";

import componentStyles from "assets/theme/components/dropdowns/navbar-menu-dropdown.js";

const useStyles = makeStyles(componentStyles);

function AuthNavbarMenuDropdown(props) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [recoverModal, setRecoverModal] = React.useState(false);
  const [loginModal, setStateModal] = React.useState(false);

  const [state, setState] = React.useState({
    email: "",
    password: "",
    recoverPasswordPhone: "",
  });

  const [openInformationDissemination, setOpenInformationDissemination] = useState(false);
  const [openInstitutionalInformation, setOpenInstitutionalInformation] = useState(false);
  const [openInformation, setOpenInformation] = useState(false);
  const [openInformationCompanyServices, setOpenInformationCompanyServices] = useState(false);
  const [openInformationCitizenServices, setOpenInformationCitizenServices] = useState(false);
  const [openInformationEmploymentServices, setOpenInformationEmploymentServices] = useState(false);
  const [openInformationEntrepreneurshipServices, setOpenInformationEntrepreneurshipServices] = useState(false);
  const [openInformationProfessionalTraining, setOpenInformationProfessionalTraining] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  const handleNavbarMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenInformationDissemination(false);
    setOpenInstitutionalInformation(false);
    setOpenInformation(false);
    setOpenInformationCompanyServices(false);
    setOpenInformationCitizenServices(false);
    setOpenInformationEmploymentServices(false);
    setOpenInformationEntrepreneurshipServices(false);
    setOpenInformationProfessionalTraining(false);
    setOpenHelp(false);
  };

  const handleClickInformationDissemination = () => {
    setOpenInformationDissemination(!openInformationDissemination);
  };

  const handleClickInstitutionalInformation = () => {
    setOpenInstitutionalInformation(!openInstitutionalInformation);
  };

  const handleClickInformation = () => {
    setOpenInformation(!openInformation);
  };

  const handleClickInformationCompanyServices = () => {
    setOpenInformationCompanyServices(!openInformationCompanyServices);
  };

  const handleClickInformationCitizenServices = () => {
    setOpenInformationCitizenServices(!openInformationCitizenServices);
  };

  const handleClickInformationEmploymentServices = () => {
    setOpenInformationEmploymentServices(!openInformationEmploymentServices);
  };

  const handleClickInformationEntrepreneurshipServices = () => {
    setOpenInformationEntrepreneurshipServices(!openInformationEntrepreneurshipServices);
  };

  const handleClickInformationProfessionalTraining = () => {
    setOpenInformationProfessionalTraining(!openInformationProfessionalTraining);
  };

  const handleClickHelp = () => {
    setOpenHelp(!openHelp);
  };

  const handleLoginModal = () => {
    props.resetLoginForm();
    setRecoverModal(false);
    setStateModal(true);
  };

  const handleClose = () => {
    setStateModal(false);
  };

  const handlerecoverModal = () => {
    setStateModal(false);
    setRecoverModal(true);
  };

  const onChange = (stateName, value) => {
    setState((prevState) => ({
      ...prevState,
      [stateName]: value,
    }));
  };

  const handleCloseRecover = () => {
    setRecoverModal(false);
  };

  const getShowRegistrationResultModal = () => {
    return props.authState.showRegistrationResultModal !== undefined
      ? props.authState.showRegistrationResultModal
      : false;
  };
  const getShowRecoverPasswordResultModal = () => {
    return props.authState.showRecoverPasswordResultModal !== undefined
      ? props.authState.showRecoverPasswordResultModal
      : false;
  };

  const handleRegistrationResultClose = () => {
    props.hideRegistrationModal();
  };
  const handleRecoverPasswordResultClose = () => {
    props.hideRecoverPasswordModal();
  };

  const menuId = "dropdowns-navbar-menu-dropdown-id";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography variant="h6" component="h6" classes={{ root: classes.menuTitle }}>
        Menu
      </Typography>
      <List component="nav" aria-labelledby="navbar-menu-list" className={classes.menuRoot}>
        <>
          {/* Institucional */}
          <>
            <ListItem button onClick={handleClickInstitutionalInformation} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(labels.Label_Menu_INEFOP)}
              />
              {openInstitutionalInformation ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openInstitutionalInformation} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/institution-overview`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOverview)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/institution-history`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionHistory)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/institution-mission-vision-values`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionMissionVisionAndValues)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/institution-organic-status`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOrganicStatus)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/institution-organization-chart`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOrganizationChart)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/director-biography`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_DirectorBiography)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/institute-holders`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstituteHolders)}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>

          {/* Notícias */}
          <ListItem
            button
            onClick={() => {
              handleMenuClose();
              history.push(`/auth/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.NEWS}`);
            }}
            className={classes.listItemRoot}
          >
            <ListItemText
              classes={{ primary: classes.listItemTextPrimary }}
              primary={intl.formatMessage(labels.Label_Menu_Posts_News)}
            />
          </ListItem>

          {/* Publicações */}
          <>
            <ListItem button onClick={handleClickInformationDissemination} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(labels.Label_Menu_Posts)}
              />
              {openInformationDissemination ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openInformationDissemination} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(
                      `/auth/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.LEGISLATION}`
                    );
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Posts_Legislations)}
                  />
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.SCHEDULE}`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Posts_Schedule)}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>
          {/* INFORMAÇÃO */}
          <>
            <ListItem button onClick={handleClickInformation} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(labels.Label_Menu_Information)}
              />
              {openInformation ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openInformation} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* Submenu Group: Company Services */}
                <ListItem button onClick={handleClickInformationCompanyServices} className={classes.nestedItem}>
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Information_CompanyServices)}
                  />
                  {openInformationCompanyServices ? (
                    <ExpandLessIcon className={classes.expandIcon} />
                  ) : (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )}
                </ListItem>
                <Collapse in={openInformationCompanyServices} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => {
                        handleLoginModal();
                        props.setTargetPathAfterLogin("/admin/opportunities-create", USER_TYPE.COLLECTIVE);
                        handleMenuClose();
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(
                          titles.Title_Menu_Information_CompanyServices_CreateEmploymentOrInternshipOpportunities
                        )}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/auth/ratify-agreement`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(titles.Title_Menu_Information_CompanyServices_RatifyAgreement)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/auth/information-training-centers-licensing`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(titles.Title_Menu_Information_CompanyServices_LicensingCenters)}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                {/* Submenu Group: Citizen Services */}
                <ListItem button onClick={handleClickInformationCitizenServices} className={classes.nestedItem}>
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Information_CitizenServices)}
                  />
                  {openInformationCitizenServices ? (
                    <ExpandLessIcon className={classes.expandIcon} />
                  ) : (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )}
                </ListItem>
                <Collapse in={openInformationCitizenServices} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => {
                        handleLoginModal();
                        props.setTargetPathAfterLogin("/admin/available-opportunities", USER_TYPE.INDIVIDUAL);
                        handleMenuClose();
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(
                          titles.Title_Menu_Information_CitizenServices_SeeEmploymentOpportunities
                        )}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleLoginModal();
                        props.setTargetPathAfterLogin("/admin/formacao-redirect", USER_TYPE.INDIVIDUAL);
                        handleMenuClose();
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(
                          titles.Title_Menu_Information_CitizenServices_SeeProfessionalTraining
                        )}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        window.open("http://procarteira.inefop.gov.ao/", "_blank", "noreferrer");
                        handleMenuClose();
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(
                          titles.Title_Menu_Information_CitizenServices_SeeProfessionalWallet
                        )}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                {/* Submenu Group: Employment Services */}
                <ListItem button onClick={handleClickInformationEmploymentServices} className={classes.nestedItem}>
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Information_EmploymentServices)}
                  />
                  {openInformationEmploymentServices ? (
                    <ExpandLessIcon className={classes.expandIcon} />
                  ) : (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )}
                </ListItem>
                <Collapse in={openInformationEmploymentServices} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/auth/information-employment-centers`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_EmploymentCenters)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/auth/information-employment-centers-network`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_EmploymentCentersNetwork)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/auth/information-foreign-workers-contract-registration`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_ForeignWorkersContractRegistration)}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                {/* Submenu Group: Entrepreneurship Services */}
                <ListItem
                  button
                  onClick={handleClickInformationEntrepreneurshipServices}
                  className={classes.nestedItem}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipServices)}
                  />
                  {openInformationEntrepreneurshipServices ? (
                    <ExpandLessIcon className={classes.expandIcon} />
                  ) : (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )}
                </ListItem>
                <Collapse in={openInformationEntrepreneurshipServices} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/auth/information-entrepreneurship-centers`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipCenters)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/auth/information-entrepreneurship-centers-network`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipCentersNetwork)}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                {/* Submenu Group: Professional Training */}
                <ListItem button onClick={handleClickInformationProfessionalTraining} className={classes.nestedItem}>
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Information_ProfessionalTraining)}
                  />
                  {openInformationProfessionalTraining ? (
                    <ExpandLessIcon className={classes.expandIcon} />
                  ) : (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )}
                </ListItem>
                <Collapse in={openInformationProfessionalTraining} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/auth/information-professional-wallet`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_ProfessionalWallet)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/auth/information-training-centers-licensing`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_TrainingCentersLicensing)}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>
          </>
          {/* Programas */}
          <ListItem
            button
            onClick={() => {
              handleMenuClose();
              history.push("/auth/structuringProjects");
            }}
            className={classes.listItemRoot}
          >
            <ListItemText
              classes={{ primary: classes.listItemTextPrimary }}
              primary={intl.formatMessage(labels.Label_Menu_StructuringProjects)}
            />
          </ListItem>

          {/* Bolsa Nacional de Formadores */}
          {/* <ListItem
            button
            onClick={() => {
              handleMenuClose();
              history.push("/auth/national-scholarship-of-trainers");
            }}
            className={classes.listItemRoot}
          >
            <ListItemText
              classes={{ primary: classes.listItemTextPrimary }}
              primary={intl.formatMessage(labels.Label_Menu_Help_NationalScholarshipTrainers)}
            />
          </ListItem> */}

          {/* Contactos */}
          <ListItem
            button
            onClick={() => {
              handleMenuClose();
              history.push("/auth/contacts");
            }}
            className={classes.listItemRoot}
          >
            <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary="Contactos" />
          </ListItem>

          {/* Ajuda */}
          <>
            <ListItem button onClick={handleClickHelp} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(labels.Label_Menu_Help)}
              />
              {openHelp ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openHelp} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/frequently-asked-questions`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Help_FAQs)}
                  />
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/auth/useful-links`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Help_UsefulLinks)}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>
        </>
      </List>
      <LoginModal
        loginModal={loginModal}
        handleClose={handleClose}
        handlerecoverModal={handlerecoverModal}
        showAuthenticationModal={props.showAuthenticationModal}
        loginAction={props.loginAction}
        onChange={onChange}
        state={state}
      />

      <RecoverModal
        recoverModal={recoverModal}
        handleCloseRecover={handleCloseRecover}
        showAuthenticationModal={props.showAuthenticationModal}
        onChange={onChange}
        state={state}
      />

      {/* Recover Sucess Modal Start */}
      <Dialog
        open={getShowRecoverPasswordResultModal()}
        onClose={handleRecoverPasswordResultClose}
        classes={{ paper: classes.modalSentRecover }}
      >
        <IconButton edge="start" onClick={handleRecoverPasswordResultClose} classes={{ root: classes.modalClose }}>
          <CloseIcon />
        </IconButton>
        <CardContent classes={{ root: classes.cardContent }}>
          {props.authState.recoverPasswordResult ? (
            <>
              <Box className={classes.elipse}>
                <Box
                  component="img"
                  alt="..."
                  className={classes.logoInfo}
                  src={require("assets/img/icons/common/Info.png").default}
                ></Box>
              </Box>
              <Box className={classes.modalRecoverSentHeader}>
                {intl.formatMessage(messages.Message_Auth_Recover_Password_Success)}
              </Box>
              <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                <Button
                  className={classes.sentRecoverButton}
                  //TO-DO - Recover account button logic
                  onClick={function () {
                    handleRecoverPasswordResultClose();
                    handleLoginModal();
                  }}
                >
                  {intl.formatMessage(labels.Label_General_Enter_Button)}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <CancelOutlinedIcon classes={{ root: classes.registerErrorResultModalIcon }} />
              <Typography classes={{ root: classes.modalRegisterResultHeader }}>
                {intl.formatMessage(errorMessages.ErrorMessage_GenericError)}
              </Typography>
            </>
          )}
        </CardContent>
      </Dialog>
      {/* Recover Sucess Modal End */}
      {/* Registration Resul Modal Start*/}
      <RegisterDialog
        open={getShowRegistrationResultModal()}
        onClose={handleRegistrationResultClose}
        onClick={function () {
          handleRegistrationResultClose();
          handleLoginModal();
        }}
      />

      {/* Registration Resul Modal End*/}
      {/* Authentication dialog START*/}
      <AuthenticationModal />
      {/* Authentication dialog END*/}
      {/* Reset Password dialog START*/}
      <ResetPassword />
      {/* Reset Password dialog END*/}
      {/* Generic dialog START*/}
      <GenericDialog />
      {/* Generic dialog END*/}
    </Menu>
  );

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" marginLeft="auto">
        <IconButton onClick={handleNavbarMenuOpen} className={classes.menuButton}>
          <Box
            component={MenuIcon}
            color={theme.palette.dark.main}
            width="1.5rem!important"
            height="1.5rem!important"
          />
          <Box
            fontStyle="normal"
            fontSize=".875rem"
            fontWeight="600"
            alignContent="center"
            paddingLeft="0.1rem"
            paddingRight="0.1rem"
            paddingTop="0.5rem"
            paddingBottom="0.5rem"
            margin="0"
          >
            Menu
          </Box>
        </IconButton>
        {renderMenu}
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  loginAction: (email, password) => dispatch(loginAction(email, password)),
  hideRegistrationModal: () => dispatch(hideRegistrationModalAction()),
  recoverPassword: (mobilePhone) => dispatch(recoverPasswordAction(mobilePhone)),
  hideRecoverPasswordModal: () => dispatch(hideRecoverPasswordModalAction()),
  showAuthenticationModal: (toShow, username, action) =>
    dispatch(showAuthenticationModalAction(toShow, username, action)),
  resetLoginForm: () => dispatch({ type: AUTH.RESET_LOGIN_FORM }),
  setTargetPathAfterLogin: (targetPath, pathUserType) =>
    dispatch(setTargetPathAfterLoginAction(targetPath, pathUserType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthNavbarMenuDropdown);
