import { React } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// @material-ui/icons components
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";

//import custom components

import componentStyles from "assets/theme/views/admin/static-page.js";
import LocationHeader from "components/Headers/LocationHeader";

const useStyles = makeStyles(componentStyles);

function ContactsPage() {
  const theme = useTheme();
  const classes = useStyles();

  const mapsLocation =
    "https://www.google.com/maps/place/INSTITUTO+NACIONAL+DO+EMPREGO+E+FORMA%C3%87%C3%83O+PROFISSIONAL+-+INEFOP/@-8.818023,13.229561,17z/data=!4m5!3m4!1s0x0:0xd6881b4f40169953!8m2!3d-8.8179957!4d13.2294988?hl=pt    ";

  return (
    <>
      <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
        <Box marginLeft="-30px">
          <LocationHeader section="Contactos" />
        </Box>
        <Grid container>
          <Grid item xs={12} md={7}>
            <Card className={classes.cardRoot}>
              <CardContent>
                <Grid item xs={12} md={12}>
                  <Box classes={{ root: classes.pageTitle }}>Contactos</Box>
                </Grid>

                <Grid item xs={12} md={12} className={classes.itemRoot}>
                  <Box color={theme.palette.black.main}>
                    <Box
                      component={PhoneIcon}
                      color={theme.palette.black.main}
                      width="1.25rem!important"
                      height="1.25rem!important"
                      marginRight="1rem"
                    />
                    <span className={classes.phoneNumberGrey}>+244</span>{" "}
                    <span>921 154 482</span>
                  </Box>
                </Grid>

                <Grid item xs={12} md={12} className={classes.itemRoot}>
                  <Link
                    className={classes.linkRoot}
                    to={{ pathname: "mailto:contacto@inefop.gov.ao" }}
                    target="_blank"
                    rel="noopener"
                  >
                    <Box
                      component={EmailIcon}
                      width="1.25rem!important"
                      height="1.25rem!important"
                      marginRight="1rem"
                    />
                    <span>contacto@inefop.gov.ao</span>
                  </Link>
                </Grid>

                <Grid item xs={12} md={12} className={classes.itemRoot}>
                  <Link
                    className={classes.linkRoot}
                    to={{ pathname: mapsLocation }}
                    target="_blank"
                    rel="noopener"
                  >
                    <Box
                      component={LocationOnIcon}
                      width="1.25rem!important"
                      height="1.25rem!important"
                      marginRight="1rem"
                    />
                    <span>
                      Av. do 1º Congresso do MPLA N.º 5, Luanda, Angola
                    </span>
                  </Link>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginBottom: "30px" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3942.6584788398613!2d13.228272!3d-8.8181189!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f252f83d7563%3A0xd6881b4f40169953!2sINSTITUTO%20NACIONAL%20DO%20EMPREGO%20E%20FORMA%C3%87%C3%83O%20PROFISSIONAL%20-%20INEFOP!5e0!3m2!1sen!2spt!4v1634049205707!5m2!1spt-PT!2spt"
              width="900"
              height="1600"
              allowFullScreen=""
              loading="lazy"
              className={classes.iframe}
              title={"mapsIFrame"}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ContactsPage);
