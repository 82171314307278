import { React, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//import custom components
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getOpportunityApplicationDetailsAction } from "redux/actions/opportunitiesAction";
import OpportunityData from "../Opportunities/OpportunityData";

import { OPPORTUNITY_TYPE_KEY } from "utils/const";

import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityDetails(props) {
  const classes = useStylesGeneric();

  useEffect(() => {
    if (
      props.opportunitiesState.opportunityApplicationDetailsData === undefined ||
      props.opportunitiesState.opportunityApplicationDetailsData.applicationCode !== props.match.params.candidateCode
    ) {
      props.getOpportunityApplicationDetails(props.match.params.candidateCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.candidateCode]);

  return (
    <>
      <GenericBackdrop
        open={props.opportunitiesState.get_opportunity_application_details_loading}
      />
      <Container
        maxWidth={false}
        component={Box}
        classes={{ root: classes.containerRoot }}
      >
        <LocationHeader
          subsection={
            props.opportunitiesState?.opportunityApplicationDetailsData?.opportunityTypeKey ===
            OPPORTUNITY_TYPE_KEY.EMPREGO
              ? "Emprego"
              : "Estágio Profissional"
          }
          section={"Detalhes da Candidatura"}
          subsectionLink={`/admin/my-applications/${
            props.opportunitiesState?.opportunityApplicationDetailsData?.opportunityTypeKey ===
            OPPORTUNITY_TYPE_KEY.EMPREGO
              ? OPPORTUNITY_TYPE_KEY.EMPREGO_URL
              : OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL_URL
          }`}
        />

        <PageTitleHeader
          title={props.opportunitiesState.opportunityApplicationDetailsData?.applicationCode}
          goBackLink={`/admin/my-applications/${
            props.opportunitiesState?.opportunityApplicationDetailsData?.opportunityTypeKey ===
            OPPORTUNITY_TYPE_KEY.EMPREGO
              ? OPPORTUNITY_TYPE_KEY.EMPREGO_URL
              : OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL_URL
          }`}
        />

        <Box component={Grid} container>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <OpportunityData
              opportunityDetailsData={props.opportunitiesState.opportunityApplicationDetailsData}
              isOpportunityApplication={true}
            />
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getOpportunityApplicationDetails: (opportunityId) => dispatch(getOpportunityApplicationDetailsAction(opportunityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetails);
