export const b64ToImage = (b64Data) => {
  if (!b64Data) return null;
  return "data:image/*;base64, " + b64Data;
};
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return URL.createObjectURL(blob);
};

export const convertByteArrayToBlobUrl = (byteArray) => {
  if (byteArray === undefined || byteArray == null) return null;
  // var arrayBufferView = new Uint8Array(byteArray);
  // const blob = new Blob([byteArray], {type: 'image/png'});
  return URL.createObjectURL(byteArray);
};

export const getNameInitials = (name) => {
  if (!name) return "";
  let rgx = new RegExp(/(\p{L}{1})\p{L}*/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  return ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();
};