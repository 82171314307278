import { React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Card, CardHeader, CardContent } from "@material-ui/core";
// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
// components
import GenericButton from "components/Buttons/GenericButton";
//import custom components
import FormSelect from "components/FormFields/FormSelect";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { getFilteredAvailableOpportunitiesAction } from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { PAGINATION, DATASET, OPPORTUNITY_TYPE_KEY } from "utils/const";
import { getFilteredAvailableOpportunitiesMap } from "mappers/GetFilteredOpportunitiesMap";
import { getDatasetStateByName } from "utils/datasets";
import { getSelectOptionsElementByKeyValue } from "utils/formSelect";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSection(props) {
  const classes = useStylesGeneric();
  const [opportunityType, setOpportunityType] = useState();
  const [filter, setFilter] = useState({
    provinceId: null,
    opportunityTypeId: null,
    professionalCategoryId: null,
    contractTypeId: null,
    internshipTypeId: null,
    timeTableTypeId: null,
    companyName: null,
    searchText: null,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE_AVAILABLE_OPPORTUNITIES,
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.datasetsState[DATASET.PROVINCES] === undefined || props.datasetsState[DATASET.PROVINCES]?.length === 0) {
      props.getDataSetByName(DATASET.PROVINCES);
    }
    if (
      props.datasetsState[DATASET.OPPORTUNITY_TYPE] === undefined ||
      props.datasetsState[DATASET.OPPORTUNITY_TYPE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_TYPE);
    } else {
      setOpportunitytypeFormSelect();
    }
    if (
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY] === undefined ||
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]?.length === 0
    ) {
      props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    }
    if (
      props.datasetsState[DATASET.CONTRACT_TYPE] === undefined ||
      props.datasetsState[DATASET.CONTRACT_TYPE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.CONTRACT_TYPE);
    }
    if (
      props.datasetsState[DATASET.TIMETABLE_TYPE] === undefined ||
      props.datasetsState[DATASET.TIMETABLE_TYPE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.TIMETABLE_TYPE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.showRecommendedOpportunities) refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  useEffect(() => {
    if (!props.showRecommendedOpportunities && props.preLoadOpportunities) refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preLoadOpportunities]);

  useEffect(() => {
    setOpportunitytypeFormSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.OPPORTUNITY_TYPE]]);

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  const setOpportunitytypeFormSelect = () => {
    if (props.match?.params?.oppType === OPPORTUNITY_TYPE_KEY.EMPREGO_URL) {
      let auxData = [];
      auxData["opportunityTypeKey"] = OPPORTUNITY_TYPE_KEY.EMPREGO;
      setOpportunityType(auxData);
      setValue(
        "opportunityType",
        getSelectOptionsElementByKeyValue(props.datasetsState[DATASET.OPPORTUNITY_TYPE], OPPORTUNITY_TYPE_KEY.EMPREGO)
      );

      handleSubmit(onSubmit)();
    } else if (props.match?.params?.oppType === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL_URL) {
      let auxData = [];
      auxData["opportunityTypeKey"] = OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL;
      setOpportunityType(auxData);
      setValue(
        "opportunityType",
        getSelectOptionsElementByKeyValue(
          props.datasetsState[DATASET.OPPORTUNITY_TYPE],
          OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL
        )
      );

      handleSubmit(onSubmit)();
    }
  };

  const cleanFilter = () => {
    setValue("province", undefined);
    setValue("opportunityType", undefined);
    setValue("opportunityProfessionalCategory", undefined);
    setValue("internshipType", undefined);
    setValue("contractType", undefined);
    setValue("timeTableType", undefined);
    setValue("companyName", "");
    setValue("searchText", "");

    setFilter(0);
  };

  const getContractTypeFieldByOpportunityType = (selectedOpportunityType) => {
    switch (selectedOpportunityType) {
      case OPPORTUNITY_TYPE_KEY.EMPREGO:
        return (
          <Grid item xs={12} sm={12} md={4}>
            <FormGroup>
              <FormLabel>Tipo de Contracto</FormLabel>
              <FormSelect
                control={control}
                fieldName="contractType"
                selectOptions={getDatasetStateByName(props, DATASET.CONTRACT_TYPE)}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                classes={classes}
                handleSetFilter={handleSetFilter}
                filterListKey={"contractType"}
              />
            </FormGroup>
          </Grid>
        );
      default:
        return "";
    }
  };
  const controlOpportunityTypeRelatedFields = (selectedOpportunityType) => {
    if (selectedOpportunityType) {
      let auxData = [];
      auxData["opportunityTypeKey"] = selectedOpportunityType.keyValue;
      switch (selectedOpportunityType.keyValue) {
        case OPPORTUNITY_TYPE_KEY.EMPREGO:
          window.history.replaceState(null, "", `employment`);
          break;
        case OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL:
          window.history.replaceState(null, "", `internship`);
          break;
        default:
          window.history.replaceState(null, "", ``);
      }
      setOpportunityType(auxData);
    } else {
      setOpportunityType(undefined);
    }
  };
  //Refresh Results
  const refreshResults = () => {
    props.getFilteredAvailableOpportunities({
      ...filter,
      pageIndex: props.page <= 0 ? 1 : props.page,
    });
  };

  const onSubmit = (data) => {
    props.setShowRecommendedOpportunities(false);
    let filter = getFilteredAvailableOpportunitiesMap(data);
    setFilter(filter);
    if (props.page === PAGINATION.FIRST_PAGE) {
      props.getFilteredAvailableOpportunities(filter);
    } else {
      props.handleChangePage(PAGINATION.FIRST_PAGE);
    }
  };
  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={"Pesquisa de Ofertas"}
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Tipo de Oferta</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="opportunityType"
                    selectOptions={getDatasetStateByName(props, DATASET.OPPORTUNITY_TYPE)}
                    getValues={getValues}
                    setValue={setValue}
                    onOptionChange={controlOpportunityTypeRelatedFields}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"opportunityType"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
                    getValues={getValues}
                    setValue={setValue}
                    onOptionChange={controlOpportunityTypeRelatedFields}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"province"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Área Profissional</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="opportunityProfessionalCategory"
                    selectOptions={getDatasetStateByName(props, DATASET.PROFESSIONAL_CATEGORY)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"opportunityProfessionalCategory"}
                  />
                </FormGroup>
              </Grid>
              {opportunityType && getContractTypeFieldByOpportunityType(opportunityType)}
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Horário</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="timeTableType"
                    selectOptions={getDatasetStateByName(props, DATASET.TIMETABLE_TYPE)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"FormSelect"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Pesquisa (título/descrição)</FormLabel>
                  <Controller
                    name="searchText"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("searchText", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Empresa</FormLabel>
                  <Controller
                    name="companyName"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("companyName", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              {/* Botões de Pesquisa */}

              <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  Limpar Filtros
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={
                    props.opportunitiesState?.searchAvailableOpportunities_loading ||
                    props.opportunitiesState?.searchRecommendedOpportunities_loading
                  }
                >
                  Pesquisar
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getFilteredAvailableOpportunities: (data) => dispatch(getFilteredAvailableOpportunitiesAction(data)),
  getDataSetByName: (data, parentKeyValue) => dispatch(getDataSetByNameAction(data, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilterSection));
