const componentStyles = (theme) => ({
  buttonRootPurchase: {
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    minWidth: "10.5rem",
    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
  },
  buttonLabelPurchase: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listRootRight: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto",
    },
  },
  listItemRoot: {
    display: "flex",
    alignItems: "center",

    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    paddingTop: ".625rem",
    paddingBottom: ".625rem",
    transition: "all .15s linear",
    fontWeight: "400",
    margin: "0px 60px",
    "& i": {
      marginRight: "0.25rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: ".5rem",
      paddingLeft: ".5rem",
      paddingRight: ".5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      color: theme.palette.authNavbarLink.dark,
      justifyContent: "center",
      "&:hover": {
        color: theme.palette.authNavbarLink.main,
      },
    },
  },
  recoverButton: {
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    marginTop: "15px",
    color: theme.palette.white.main,
    background: "linear-gradient(87deg," + theme.palette.info.main + "," + theme.palette.primary.mediumBlue + ")",
  },
  sentRecoverButton: {
    color: theme.palette.white.main,
    background: "linear-gradient(87deg," + theme.palette.info.main + "," + theme.palette.primary.mediumBlue + ")",
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    alignItems: "center",
  },
  headerImg: {
    position: "absolute",
    width: "70px",
    height: "54px",
    left: "30px",
    top: "12px",
    background: "url(Logo.png)",
    filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12))",
    verticalAlign: "middle",
    borderStyle: "none",
  },
  outlineNone: {
    outline: "none!important",
  },
  flexDirectionColumn: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  menuItems: {
    widht: "795px",
    height: "28px",
    borderRadius: "0px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px",
    position: "absolute",
    left: "42.71%",
    right: "2.08%",
    top: "25.28px",
    bottom: "95.21%",
  },
  modal: {
    //height: "424px",
    width: "462px",
    position: "absolute",
  },
  modalSentRecover: {
    height: "350px",
    width: "512px",
    position: "absolute",
  },
  logo: {
    position: "absolute",
    width: "70px",
    height: "54px",
    left: "30px",
    top: "12px",
    background: "url(Logo.png)",
    filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12))",
    verticalAlign: "middle",
    borderStyle: "none",
  },
  logoInfo: {
    position: "absolute",
    right: "46%",
    top: "14.33%",
    bottom: "57.64%",

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "60px",
    lineHeight: "82px",
    /* identical to box height */
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.secondary.skyBlue,
  },
  elipse: {
    widht: "82px",
    height: "82px",
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "14.33%",
    bottom: "57.64%",
    borderRadius: "150px",
    border: "4px solid " + theme.palette.secondary.skyBlue,
    boxSizing: "border-box",
  },
  modalClose: {
    marginLeft: "90%",
  },
  modalOkButton: {
    color: theme.palette.white.main,
    background: "linear-gradient(87deg," + theme.palette.info.main + "," + theme.palette.primary.mediumBlue + ")",
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    top: "30px",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
  },
  modalHeader: {
    top: "50px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.darkBlue,
  },
  modalRecoverHeader1: {
    width: "340px",
    height: "54px",
    fontStyle: "normal",
    lineHeight: "19px",
    fontSize: "14px",
    fontWeight: "normal",
    textAlign: "center",
    marginBottom: "2.05rem",
    marginTop: "2rem",
    marginLeft: "40px",
    color: theme.palette.primary.darkBlue,
  },
  modalRecoverHeader2: {
    width: "400px",
    marginLeft: "30px",
    fontStyle: "normal",
    lineHeight: "19px",
    fontSize: "14px",
    fontWeight: "normal",
    textAlign: "center",
    marginBottom: "2.05rem",
    marginTop: "-20px",
    color: theme.palette.primary.darkBlue,
  },
  modalRecoverFooter: {
    width: "400px",
    fontStyle: "normal",
    lineHeight: "19px",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1rem",
    marginTop: "-20px",
    color: theme.palette.gray[900],
  },
  modalRecoverFooterContacts: {
    width: "400px",
    fontStyle: "normal",
    lineHeight: "19px",
    fontSize: "14px",
    fontWeight: "normal",
    textAlign: "center",
    marginBottom: "1rem",
    marginTop: "0px",
    color: theme.palette.gray[900],
  },
  recoverFormControl: {
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "19px",
    variant: "filled",
    width: "100%",
    marginBottom: "1rem!important",
    fontSize: "1rem",
    color: theme.palette.dark.main,
    marginTop: "45px",
  },
  modalRecoverSentHeader: {
    fontStyle: "normal",
    lineHeight: "33px",
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "1.5rem",
    marginTop: "5rem",
    color: theme.palette.dark.main,
  },
  registerResultModal: {
    position: "absolute",
    left: "30%",
    top: "0%",
    bottom: "-11.46%",
    backgroundColor: theme.palette.white.main,
    borderRadius: "5px",
    width: "512px",
    height: "350px",
  },
  registerResultModalIcon: {
    marginRight: "8px",
    color: theme.palette.success.main,
    width: "88px",
    height: "88px",
    /* align-self: center; */
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "14.33%",
    bottom: "57.64%",
    boxSizing: "border-box",
  },
  registerErrorResultModalIcon: {
    marginRight: "8px",
    color: theme.palette.error.main,
    width: "88px",
    height: "88px",
    /* align-self: center; */
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "14.33%",
    bottom: "57.64%",
    boxSizing: "border-box",
  },
  registerResultModalText: {
    position: "absolute",
    left: "20%",
    right: "20%",
    top: "43.59%",
    bottom: "36.94%",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    display: "flex",
    textAlign: "center",
    color: theme.palette.primary.greyBlue,
  },
  registerResultModalClose: {
    marginLeft: "90%",
  },
  sentRegisterButton: {
    background: "linear-gradient(87deg," + theme.palette.info.main + "," + theme.palette.primary.mediumBlue + ")",
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    alignItems: "center",
  },
  modalRegisterResultHeader: {
    fontStyle: "normal",
    lineHeight: "33px",
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "1.5rem",
    marginTop: "5rem",
    color: theme.palette.dark.main,
  },
  boxHeader: {
    color: theme.palette.primary.greyBlue,

    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "19px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  loginHeader: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: theme.palette.primary.greyBlue,
  },
  loginRecover: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: theme.palette.primary.greyBlue,
    textDecorationLine: "underline",
    marginTop: "500px",
  },
  invalidLogin: {
    marginTop: "-20px",
  },
  errorMessageBox: {
    height: "20px",
    marginTop: "15px",
    fontSize: "14px",
    fontWeight: "normal",
  },
  authenticationLabels: {
    color: theme.palette.primary.darkBlue,
  },
});

export default componentStyles;
