import { React } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { getNameInitials } from "utils/converters";
import "moment/locale/pt";
import moment from "moment";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityCard(props) {
  const classes = { ...useStylesGeneric() };
  const history = useHistory();

  const getOpportunityCreationOffset = (createdOn) => {
    if (createdOn && createdOn instanceof moment) {
      const days = Math.abs(createdOn.diff(moment().startOf("day"), "days"));
      const yesterday = moment().subtract(1, "day");
      if (moment(createdOn).isSame(yesterday, "day")) return `Publicada Ontem`;
      if (days > 1) return `Publicada há ${days} dia(s)`;
      if (days === 0) return `Publicada Hoje`;
    }
  };

  return (
    <Card className={classes.cardRoot + classes.noPadding} style={{height: "300px"}}>
      <CardHeader
        avatar={
          props.info?.userPhoto ? (
            <Avatar
              alt="..."
              src={props.info.userPhoto}
              classes={{
                root: classes.cardAvatarRoot,
              }}
            />
          ) : (
            <Box
              component={Avatar}
              alt="..."
              classes={{ root: classes.avatarRoot + " " + classes.cardAvatarRoot }}
            >
              {" "}
              {getNameInitials(props.info?.userName)}
            </Box>
          )
        }
        title={
          <Box
            style={{marginTop: "5px"}}
          >
            <Box className={classes.cardContentTitle}>
              {props.info?.opportunityTypeDescription}
            </Box>
            <Box>{props.info?.userName}</Box>
          </Box>
        }
        classes={{
          content: classes.cardHeaderTitleAlignedEnd,
          root: classes.cardHeaderBorderless,
        }}
      />

      <CardContent>
        <Tooltip
          title={props.info.title}
          placement="bottom"
          arrow
          interactive
          enterDelay={300}
          enterNextDelay={300}
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        >
          <Typography
            className={
              classes.doubleLineTitleEllipsis + " " + classes.cardContentTitle
            }
          >
            {props.info.title}
          </Typography>
        </Tooltip>
        <Typography className={classes.cardContentSubTitle}>
          {props.info.provinceDescription}
        </Typography>
      </CardContent>

      <CardActions
        disableSpacing
        classes={{
          root: classes.cardActionBorderless,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <Box alignSelf={"center"}>
              {getOpportunityCreationOffset(props.info.createdOn)}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <FormGroup classes={{ root: classes.cardButtonFormGroup }}>
              <GenericButton
                typeSubmit={false}
                color="primary"
                onClick={() => {
                  history.push(`/admin/available-opportunity-details/${props.info.id}`);
                }}
              >
                Ver mais
              </GenericButton>
            </FormGroup>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(OpportunityCard);
